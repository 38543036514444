import React from 'react';

/**
 *
 * @param {Object} Props.bodyPanelData The Array of fields where an error were found
 */

const ErrorPanel = ({ bodyPanelData }) => (
  <>
    <h4>There were some errors with these fields in lab submission </h4>
    <div>
      {bodyPanelData.data.map((field, index) => (
        <p key={`${field}-${index.toString()}`}>
          <span>{field}</span>
        </p>
      ))}
    </div>
  </>
);

export default ErrorPanel;
