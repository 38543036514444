/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';
import { connect } from 'redux-bundler-react';
import { Form, Formik, Field } from 'formik';

import pocUpdatePasswordSchema from '../form-schemas/pocUpdatePasswordSchema';

/**
 *
 * Form to edit user password. User need to be logged in
 * @type {React.Component}
 * @param {Object}   params
 * @param {Function} params.doUpdateUserPassword
 */
const UserUpdatePasswordForm = ({ doUpdateUserPassword }) => {
  const initValues = {
    password: '',
    confirmPassword: '',
  };

  // let's declare our hook for the enabling of the controls on screen
  const [editUserLoginInfo, setEditUserLoginInfo] = useState(true);

  const updateUserPassword = async (values, { setSubmitting, resetForm }) => {
    try {
      const { password } = values;
      await doUpdateUserPassword({ password });
      setSubmitting(false);
      setEditUserLoginInfo(true);
      resetForm();
    } catch (e) {
      setSubmitting(false);
    }
  };

  const cancelUpdatePassword = (setSubmitting, resetForm) => {
    setSubmitting(false);
    resetForm();
    setEditUserLoginInfo(true);
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={initValues}
        validationSchema={pocUpdatePasswordSchema}
        onSubmit={updateUserPassword}
      >
        {({ errors, isSubmitting, touched, dirty, setSubmitting, resetForm }) => (
          <Form>
            <div className="card container mb-3">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-xl-12 col-lg-12 d-flex justify-content-between">
                    <p className="mt-3 header-pretitle">PASSWORD RESET</p>
                    <p className="mt-3 pb-4">
                      {editUserLoginInfo ? (
                        <button
                          type="button"
                          className="btn btn-link p-0"
                          data-toggle="collapse"
                          onClick={() => setEditUserLoginInfo(false)}
                        >
                          <span className="fe fe-edit lead" />
                        </button>
                      ) : (
                        ''
                      )}
                    </p>
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-lg-6 col-xl-6 col-sm-12 mb-3">
                    <label htmlFor="password">Password</label>
                    <Field
                      id="password"
                      name="password"
                      placeholder="********"
                      type="password"
                      className="form-control"
                      disabled={editUserLoginInfo}
                      style={{
                        backgroundColor: 'white',
                      }}
                    />
                    {!editUserLoginInfo && errors.password && touched.password ? (
                      <div className="text-danger">{errors.password}</div>
                    ) : (
                      ''
                    )}
                  </div>

                  <div className="col-lg-6 col-xl-6 col-sm-12 mb-3">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <Field
                      id="confirmPassword"
                      name="confirmPassword"
                      placeholder="*********"
                      type="password"
                      className="form-control"
                      disabled={editUserLoginInfo}
                      style={{
                        backgroundColor: 'white',
                      }}
                    />
                    {!editUserLoginInfo && errors.confirmPassword && touched.confirmPassword ? (
                      <div className="text-danger">{errors.confirmPassword}</div>
                    ) : (
                      ''
                    )}
                  </div>

                  <div
                    id="editLoginInformation"
                    // className="collapse col-lg-12 col-xl-12 col-sm-12 text-right"
                    className={
                      editUserLoginInfo
                        ? 'col-sm-12 col-lg-12 col-xl-12  text-right collapse'
                        : 'col-sm-12 col-lg-12 col-xl-12 text-right'
                    }
                  >
                    <button
                      name="submit"
                      data-toggle="collapse"
                      disabled={isSubmitting || !dirty}
                      type="submit"
                      className="btn btn-primary mr-2"
                    >
                      Update
                    </button>
                    <button
                      name="cancel"
                      data-toggle="collapse"
                      onClick={() => cancelUpdatePassword(setSubmitting, resetForm)}
                      type="button"
                      className="btn btn-outline-danger"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default connect('doUpdateUserPassword', UserUpdatePasswordForm);
