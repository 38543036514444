import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'redux-bundler-react';
import ReactToPrint from 'react-to-print';
import EIATestAnswersView from '../views/eia-answers-view/EIATestAnswersView';
import GenotypingAnswersView from '../views/genotyping-answers/GenotypingAnswersView';
import NoDataAndRedirect from '../lib/NoDataAndRedirect';
import { fileFormattedTimestamp } from '../../utils';

const TestAnswersDetailPage = ({ filteredTestAnswer, routeParams }) => {
  const { testId } = routeParams;
  const [data, setData] = useState({});

  useEffect(() => {
    setData(filteredTestAnswer);
  }, [filteredTestAnswer, testId]);

  const element = useRef(null);
  const componentToRender = {
    1: {
      component: <EIATestAnswersView data={data} ref={element} />,
    },
    2: {
      component: <GenotypingAnswersView data={data} ref={element} />,
    },
  };

  const getFileName = () => {
    const timestamp = fileFormattedTimestamp();

    return `Rota Report ${timestamp}`;
  };

  return (
    <>
      <div className="mb-4 text-right">
        <ReactToPrint
          trigger={() => (
            <button type="button" className="btn btn-light">
              Print Report
            </button>
          )}
          content={() => element.current}
          documentTitle={getFileName()}
        />
      </div>
      {+testId && Object.keys(data).length ? (
        componentToRender[testId].component
      ) : (
        <NoDataAndRedirect />
      )}
    </>
  );
};

export default connect('selectFilteredTestAnswer', 'selectRouteParams', TestAnswersDetailPage);
