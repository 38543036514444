import React from 'react';
import { connect } from 'redux-bundler-react';
import { panelShippingStatus } from '../../helpers/constants';
import KitStatusMessage from '../lib/KitStatusMessage';
import KitVerificationForm from '../forms/KitVerificactionForm';

const PanelVerification = ({ currentEnrollStudy, doUpdateShippingStatus }) => {
  return (
    <div className="container">
      <div className="row">
        {currentEnrollStudy &&
          (currentEnrollStudy.Enrollment.shipmentStatus === panelShippingStatus.SHIPPED ? (
            <KitVerificationForm
              panelSentDate={currentEnrollStudy.Enrollment.panelSentDate}
              LabParticipantId={currentEnrollStudy.Enrollment.LabParticipantId}
              currentStudyId={currentEnrollStudy.id}
              action={doUpdateShippingStatus}
            />
          ) : (
            <KitStatusMessage kitStatus={currentEnrollStudy.Enrollment.shipmentStatus} />
          ))}
      </div>
    </div>
  );
};

export default connect('selectCurrentEnrollStudy', 'doUpdateShippingStatus', PanelVerification);
