import { createSelector } from 'redux-bundler';
import { cloneDeep } from 'lodash';
import { roles } from '../helpers/constants';

const ActionTypes = {
  FETCH_START: 'FETCH_STUDY_PROGRESS_START',
  FETCH_ERROR: 'FETCH_STUDY_PROGRESS_ERROR',
  FETCH_SUCCESS: 'FETCH_STUDY_PROGRESS_SUCCESS',
};
const REFRESH_TIME = 300000;

export default {
  name: 'studyProgress',
  getReducer: () => {
    const initialState = {
      loading: false,
      lastError: null,
      lastFetch: null,
      data: [],
      error: null,
    };

    return (state = initialState, { type, payload }) => {
      switch (type) {
        case ActionTypes.FETCH_START:
          return {
            ...cloneDeep(state),
            loading: true,
          };
        case ActionTypes.FETCH_ERROR:
          return {
            ...cloneDeep(state),
            lastError: Date.now(),
            loading: false,
            error: payload,
            data: [],
          };
        case ActionTypes.FETCH_SUCCESS:
          return {
            ...cloneDeep(state),
            lastFetch: Date.now(),
            loading: false,
            lastError: null,
            error: null,
            data: payload,
          };
        default:
          return state;
      }
    };
  },
  selectStudyProgressDataRaw: state => state.studyProgress,
  selectStudyProgressData: state => state.studyProgress.data,
  doFetchStudyProgress: studyId => ({ dispatch, apiFetch }) => {
    dispatch({ type: ActionTypes.FETCH_START });
    apiFetch({
      endpoint: `studies/${studyId}/studyProgress`,
    })
      .then(response => {
        dispatch({ type: ActionTypes.FETCH_SUCCESS, payload: response.results });
      })
      .catch(error => {
        dispatch({ type: ActionTypes.FETCH_ERROR, payload: error });
      });
  },
  reactShouldFetchStudyProgressData: createSelector(
    'selectStudyProgressDataRaw',
    'selectRouteInfo',
    'selectRouteParams',
    'selectUserRole',
    'selectAppTime',
    (studyProgressDataRaw, routeInfo, routeParams, userRole, appTime) => {
      const routeToMatch = '/studies/:studyId';

      if (
        !routeInfo.pattern.includes(routeToMatch) ||
        !routeParams.studyId ||
        userRole !== roles.ADMIN ||
        studyProgressDataRaw.loading
      ) {
        return null;
      }

      let shouldFetch = false;

      if (!studyProgressDataRaw.lastError && studyProgressDataRaw.data.length === 0) {
        shouldFetch = true;
      } else if (studyProgressDataRaw.error) {
        const timePassed = appTime - studyProgressDataRaw.lastError;
        if (timePassed > REFRESH_TIME) {
          shouldFetch = true;
        }
      }

      if (shouldFetch) {
        return { actionCreator: 'doFetchStudyProgress', args: [routeParams.studyId] };
      }
    },
  ),
};
