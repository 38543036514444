import React from 'react';

/**
 *
 * @param {String} title The Message title of the Interpretation
 * @param {Number} score The Score of the Test
 * @param {String} interpretation The Interpretation Message of the Test
 */
const ResultReportInterpretation = ({ title, score, interpretation }) => {
  return (
    <>
      <div className="col-12 col-lg-6 d-flex">
        <p className="text-muted">
          <strong className="text-body mr-3">{title}</strong> {score}
        </p>
      </div>
      <div className="col-12 col-lg-6 d-flex">
        <p className="text-muted">
          <strong className="text-body mr-3">Interpretation</strong> {interpretation}
        </p>
      </div>
    </>
  );
};

export default ResultReportInterpretation;
