/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { testInterpretationStoreResult } from '../../../../helpers/constants';
import { strCapitalize } from '../../../../utils';

const SelectEIAResult = ({ value, onChange }) => {
  return (
    <>
      <div className="col-12 col-sm-6 col-lg-3 align-self-center text-center">
        <label htmlFor="testInterpretation " className="text-muted">
          EIA
        </label>

        <select
          id="testInterpretation"
          className="form-control custom-select text-secondary"
          value={value}
          onChange={e => onChange('eiaResult', e.target.value)}
        >
          <option className="px-2" value="">
            Select a Value
          </option>
          {Object.values(testInterpretationStoreResult).map((optValue, id) => (
            <option key={`${id.toString()}-${optValue}`} value={optValue}>
              {strCapitalize(true, optValue)}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default SelectEIAResult;
