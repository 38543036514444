import React from 'react';

/**
 *
 * @param {String} param0.message This is the message to render in the panel
 */
const PanelMessage = ({ message }) => (
  <div className="row align-items-center" key={message}>
    <div className="col-auto">
      <div className="avatar avatar-sm">
        <div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
          <i className="fe fe-mail" />
        </div>
      </div>
    </div>
    <div className="col ml-n2">
      <p className="small text-gray-700 mb-0">{message}</p>
    </div>
  </div>
);

export default PanelMessage;
