/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Field, Formik } from 'formik';
import Row from '../../lib/Row';
import Input from '../Input';
import DatePicker from '../EIA/DatePicker';
import TextArea from './TextArea';
import {
  GenotypingAmplificationInit,
  GenotypingAmplificationSchema,
} from '../../form-schemas/testsSchemas';

const AmplificationCard = ({ initValues, pushData, clearData }) => {
  const arrayHandle = (values, resetForm) => {
    pushData(values);
    clearData();
    resetForm();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initValues || GenotypingAmplificationInit}
      validationSchema={GenotypingAmplificationSchema}
      onSubmit={(values, { resetForm }) => {
        arrayHandle(values, resetForm);
      }}
    >
      {({ isSubmitting, handleSubmit }) => (
        <form
          className="col-12 col-xl-8"
          onSubmit={e => {
            e.stopPropagation();
            handleSubmit(e);
          }}
        >
          <div className="card">
            <div className="card-header">
              <h4 className="card-header-title">Round of amplification:</h4>
              <div className="d-flex justify-content-end row">
                <h5 className="card-subtitle mt-2 mr-2 text-muted">
                  At least one round must be submitted.
                </h5>
                <h5 className="card-subtitle mt-2 mr-2 text-muted">
                  Each round must be submitted.
                </h5>
              </div>
            </div>
            <div className="card-body">
              <Row>
                <Field
                  // AmplificationKitId is 0
                  type="hidden"
                  name="AmplificationKitId"
                />
                <Field
                  type="text"
                  name="otherAmplificationKitBrand"
                  placeholder="Kit's Brand Name"
                  label="Kit Name"
                  col="6"
                  component={Input}
                />
                <Field
                  type="text"
                  name="vendorName"
                  placeholder="Vendor's name"
                  label="Vendor's name"
                  col="6"
                  component={Input}
                />
              </Row>
              <Row>
                <Field
                  type="text"
                  name="serialNumber"
                  placeholder="Batch/Lot No."
                  label="Batch/Lot No."
                  col="6"
                  component={Input}
                />
                <Field
                  name="expiryDate"
                  label="Expire Date"
                  placeholder="Select a expire Date"
                  component={DatePicker}
                  col="6"
                />
              </Row>
              <Row>
                <Field
                  name="gGenotypeVP7"
                  placeholder="G-Genotyping (VP7)"
                  label="G-Genotyping (VP7)"
                  col="6"
                  component={TextArea}
                />
                <Field
                  name="pGenotypeVP4"
                  placeholder="P-Genotyping (VP4)"
                  label="P-Genotyping (VP4)"
                  col="6"
                  component={TextArea}
                />
              </Row>
              <div className="row align-items-center float-right">
                <div className="col-auto">
                  <button className="btn btn-primary" type="submit" disabled={isSubmitting}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default AmplificationCard;
