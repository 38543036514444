/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { connect } from 'redux-bundler-react';
import { enrolledTestStatus, testResultStatus } from '../../helpers/constants';

/**
 *
 * @param {Integer} Props.currentStudyId The Id of the study
 * @param {Object} Props.data The data object for the submission data
 * @param {Function} Props.callback The callback options for the submission
 * @param {Function} Props.doUpdateUrl The function to update the URL when redirec to expected results
 * @param {Object} Props.errors The formik object with the errors
 */
const ActionsForGradingTest = ({
  currentStudyId,
  data = {},
  callback,
  doUpdateUrl,
  errors = {},
}) => {
  const singleActionForButtons = () =>
    enrolledTestStatus.UNLOCKED === data.status || enrolledTestStatus.FINALIZED === data.status;

  function renderButtonsForDropdown() {
    if (
      data.testStatus === testResultStatus.PASSED &&
      data.status === enrolledTestStatus.GRADING_PENDING
    ) {
      return (
        <button
          type="submit"
          className="btn btn-light"
          onClick={() =>
            Object.keys(errors).length
              ? () => {}
              : callback({ ...data, status: enrolledTestStatus.FINALIZED })
          }
        >
          Finalize this Test
        </button>
      );
    }

    if (
      data.testStatus === testResultStatus.FAILED &&
      data.status === enrolledTestStatus.GRADING_PENDING
    ) {
      return (
        <button
          type="button"
          className="btn btn-light"
          onClick={() => callback({ ...data, status: enrolledTestStatus.UNLOCKED })}
        >
          Allow Resubmission
        </button>
      );
    }

    return (
      <button
        type="button"
        className="btn btn-light"
        onClick={() => doUpdateUrl(`/studies/${currentStudyId}/expected-results`)}
      >
        Go to expected results
      </button>
    );
  }

  return (
    <div className="col-auto">
      {!!Object.keys(data).length && (
        <div className="btn-group">
          {renderButtonsForDropdown()}
          <button
            type="button"
            className="btn btn-light dropdown-toggle dropdown-toggle-split"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            disabled={singleActionForButtons()}
          >
            <span className="sr-only">Toggle Dropdown</span>
          </button>

          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            {enrolledTestStatus.GRADING_PENDING === data.status &&
              testResultStatus.FAILED !== data.testStatus && (
                <button
                  type="button"
                  className="dropdown-item btn-link"
                  onClick={() => callback({ ...data, status: enrolledTestStatus.UNLOCKED })}
                >
                  Allow Resubmission
                </button>
              )}
            {enrolledTestStatus.GRADING_PENDING === data.status &&
              testResultStatus.PASSED !== data.testStatus && (
                <button
                  className="dropdown-item btn-link"
                  onClick={() =>
                    Object.keys(errors).length
                      ? () => {}
                      : callback({ ...data, status: enrolledTestStatus.FINALIZED })
                  }
                  type="submit"
                >
                  Finalize Test
                </button>
              )}
            {enrolledTestStatus.GRADING_PENDING === data.status &&
              testResultStatus.PASSED !== data.testStatus && (
                <button
                  className="dropdown-item btn-link"
                  onClick={() =>
                    Object.keys(errors).length
                      ? () => {}
                      : callback({ ...data, status: enrolledTestStatus.GRADING_PENDING })
                  }
                  type="submit"
                >
                  Save Draft
                </button>
              )}
            <a
              className="dropdown-item"
              href={`/studies/${currentStudyId}/expected-results`}
              title="redirect to expected results"
            >
              Go to Expected Results
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default connect('doUpdateUrl', ActionsForGradingTest);
