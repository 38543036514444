import React from 'react';
import format from 'date-fns/format';

const AmplificationRounds = ({ amplificationRounds, showMethodColumn }) => {
  const columns = ["Kit's Brand Name", "Vendor's name", 'Batch/Lot No.', 'Expiry Date'];
  return (
    <div className="table-responsive">
      <table className="table table-nowrap">
        <thead>
          <tr>
            {showMethodColumn && (
              <th className="border-top-0 text-center">
                <span className="h6">Method</span>
              </th>
            )}
            {columns.map((column, index) => (
              <th className="border-top-0 text-center" key={index.toString()}>
                <span className="h6">{column}</span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {amplificationRounds &&
            amplificationRounds.map(round => (
              <tr key={round.id} className="text-center">
                {showMethodColumn && <td>{`${round.numberOfRound} round`}</td>}
                <td>{round.AmplificationKitName}</td>
                <td>{round.vendorName}</td>
                <td>{round.serialNumber}</td>
                <td>{format(new Date(round.expiryDate), 'PP')}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default AmplificationRounds;
