import React from 'react';
import ErrorMessage from '../../lib/errorMessage';

const RowInputExpectedResults = ({
  field, // { name, value, onChange, onBlur }
  handleChange,
  cbForHandleChange,
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <>
      <input
        type="text"
        {...props}
        className="form-control text-secondary bg-white text-center"
        onChange={e => {
          handleChange(e);
          if (cbForHandleChange) {
            cbForHandleChange(e.target.value);
          }
        }}
        name={field.name}
        value={field.value ? field.value.toUpperCase() : ''}
      />
      <ErrorMessage name={field.name} />
    </>
  );
};

export default RowInputExpectedResults;
