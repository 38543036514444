import React from 'react';
import orderBy from 'lodash/orderBy';
import { FieldArray, Field } from 'formik';

import { strCapitalize } from '../../utils';
import '../../assets/scss/_user.scss';
import { enrolledTestStatus } from '../../helpers/constants';
import FinalScore from '../lib/scoring/FinalScore';
import TextArea from '../lib/scoring/TextArea';

/**
 *
 * @param {Array} props.data The values for EIA grading table
 * @param {Integer} props.POINTS_PER_RESPONSE The max points given a right answer
 * @param {Function} props.handleChange This is a formik handle change to track the input change of the fields
 */

const EIATestResultsGradingBodyTable = ({ data, POINTS_PER_RESPONSE, handleChange, status }) => {
  const records = orderBy(data, ['id']);

  return (
    <tbody className="text-center">
      <FieldArray
        name="gradedResults"
        render={() =>
          records &&
          records.map(
            (
              {
                sampleIDNumber,
                expectedResult,
                submittedResult,
                score,
                error,
                finalScore,
                overrideNote,
              },
              index,
            ) => (
              <tr key={index.toString()}>
                <td>{sampleIDNumber?.value}</td>
                {expectedResult === 'NO_MATCH' ? (
                  <td>No Match</td>
                ) : (
                  <td>{strCapitalize(true, expectedResult?.value)}</td>
                )}
                <td>{strCapitalize(true, submittedResult?.value)}</td>
                <td className={score?.value === POINTS_PER_RESPONSE ? '' : 'text-muted'}>
                  <b>{score?.value}</b>
                </td>
                {status !== enrolledTestStatus.FINALIZED ? (
                  <td className={finalScore?.value === POINTS_PER_RESPONSE ? '' : 'text-muted '}>
                    <div className="input-group input-group-lg input-group-flush text-center p-0 m-0">
                      <Field
                        name={`gradedResults[${index}].finalScore.value`}
                        className="form-control result-row-input-numeric text-center bg-white border"
                        component={FinalScore}
                        handleChange={handleChange}
                        step="0.1"
                        validate={value => {
                          if (value === undefined || value === '' || value < 0 || value > 10) {
                            return 'Invalid Score';
                          }
                          return null;
                        }}
                      />
                    </div>
                  </td>
                ) : (
                  <td className={finalScore?.value === POINTS_PER_RESPONSE ? '' : 'text-muted '}>
                    {finalScore?.value}
                  </td>
                )}
                {status !== enrolledTestStatus.FINALIZED ? (
                  <td>
                    <div className="input-group input-group-lg input-group-flush text-center p-0 m-0">
                      <Field
                        name={`gradedResults[${index}].overrideNote.value`}
                        className="form-control bg-white border custom-text-area"
                        component={TextArea}
                        handleChange={handleChange}
                      />
                    </div>
                  </td>
                ) : (
                  <td>{overrideNote?.value}</td>
                )}
                {!error?.value?.message ? (
                  <td />
                ) : (
                  <td className="comment-text">{error.value.message}</td>
                )}
              </tr>
            ),
          )
        }
      />
    </tbody>
  );
};
export default EIATestResultsGradingBodyTable;
