/* eslint-disable no-alert */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { connect } from 'redux-bundler-react';

import UserProfileInformationForm from '../forms/UserProfileInformationForm';
import UserUpdatePasswordForm from '../forms/UserUpdatePasswordForm';
import UserLabInformationForm from '../forms/UserLabInformationForm';

import { roles } from '../../helpers/constants';

/**
 *
 * Edit the user's profile
 * @type {React.Component}
 * @param {Object} params
 * @param {Object} params.userData
 * @param {string} params.userErrorMessage
 * @param {string} params.userRole
 */
const UserProfile = ({ userData, userErrorMessage, userRole }) => {
  return (
    <div className="w-100">
      {userData && (
        <React.Fragment>
          <div className="row">
            <div className="col-12">
              <UserProfileInformationForm />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <UserUpdatePasswordForm />
            </div>
          </div>

          {userRole === roles.POC && (
            <div className="row">
              <div className="col-12">
                <UserLabInformationForm userId={userData.id} />
              </div>
            </div>
          )}
        </React.Fragment>
      )}

      {userErrorMessage && (
        <div className="alert alert-danger alert-dismissible fade show" role="alert">
          There was an error retrieving the user&apos;s information!
        </div>
      )}
    </div>
  );
};

export default connect('selectUserData', 'selectUserErrorMessage', 'selectUserRole', UserProfile);
