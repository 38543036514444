/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import omit from 'lodash/omit';
import { Field, Form, Formik } from 'formik';
import { connect } from 'redux-bundler-react';
import laboratorySchema from '../form-schemas/laboratorySchema';
import { PocConfirmationSchema } from '../form-schemas/pocSchema';
import { searchIdFromOptions } from '../../utils';

import Text from '../lib/Text';
import DataList from '../lib/DataList';

const PrimaryPointOfContactVerificationForm = ({
  confirmationToken,
  poc,
  laboratory,
  whoRegionsData,
  statesData,
  countriesData,
  unitedStatesValues,
  whoNetworkLabCategoriesData,
  doSetPasswordOnVerification,
  doUpdateLabDetails,
  doUpdateUserProfile,
}) => {
  const initialPocValues = { ...omit(poc, 'id'), password: '', confirmPassword: '' };
  const labInitialData = omit(laboratory, 'id');
  const initialFormState = { ...labInitialData, ...initialPocValues };
  const formValidationSchema = laboratorySchema.concat(PocConfirmationSchema);

  const submitForm = async (values, { setSubmitting }) => {
    const {
      firstName,
      lastName,
      email,
      countryCode,
      phoneNumber,
      title,
      password,
      laboratoryName,
      WHORegionId,
      WHONetworkLabCategoryId,
      address1,
      address2,
      city,
      state,
      StateId,
      zip,
      CountryId,
    } = values;
    const pocValues = {
      firstName,
      lastName,
      email,
      countryCode,
      phoneNumber,
      title,
      password,
      isVerified: true,
    };
    const labValues = {
      id: laboratory.id,
      laboratoryName,
      WHORegionId,
      WHONetworkLabCategoryId,
      address1,
      address2,
      city,
      state,
      StateId,
      zip,
      CountryId,
    };

    if (pocValues.phoneNumber === '' && (!pocValues.countryCode || pocValues.countryCode === '')) {
      delete pocValues.phoneNumber;
      delete pocValues.countryCode;
    }

    try {
      const { token } = await doSetPasswordOnVerification(
        { password: pocValues.password },
        confirmationToken,
      );
      if (token) {
        delete pocValues.password;
        await doUpdateUserProfile(poc.UserId, pocValues);

        const labObjectData = {
          ...labValues,
          WHORegionId: searchIdFromOptions(Object.values(whoRegionsData), labValues.WHORegionId),
          WHONetworkLabCategoryId: searchIdFromOptions(
            Object.values(whoNetworkLabCategoriesData),
            labValues.WHONetworkLabCategoryId,
          ),
          StateId: searchIdFromOptions(Object.values(statesData), labValues.StateId),
          CountryId: searchIdFromOptions(Object.values(countriesData), labValues.CountryId),
        };
        await doUpdateLabDetails(laboratory.id, labObjectData, false); // only alert on fail
      }
    } catch (error) {
      //
    }
    setSubmitting(false);
  };

  const onInputFieldChange = (setFieldValue, CountryId) => {
    if (CountryId !== unitedStatesValues.id && CountryId !== unitedStatesValues.name) {
      setFieldValue('StateId', 0);
    }
  };

  const renderStateDropDownOrInput = CountryId => {
    return CountryId &&
      (CountryId === unitedStatesValues.id || CountryId === unitedStatesValues.name) ? (
      <Field
        name="StateId"
        placeholder="State"
        component={DataList}
        options={statesData ? Object.values(statesData) : {}}
      />
    ) : (
      <Field name="state" placeholder="Province" component={Text} />
    );
  };

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        initialValues={initialFormState}
        validationSchema={formValidationSchema}
        onSubmit={submitForm}
        validate={values => {
          const errors = {};
          if (
            values.CountryId === unitedStatesValues.id ||
            values.CountryId === unitedStatesValues.name
          ) {
            if (!values.StateId) {
              delete errors.state;
              errors.StateId = 'State is required here';
            }
          }
          if (
            values.CountryId !== unitedStatesValues.id &&
            values.CountryId !== unitedStatesValues.name
          ) {
            if (!values.state) {
              delete errors.StateId;
              errors.state = 'Province is required here';
            }
          }

          return errors;
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          isValid,
          handleChange,
          handleReset,
          setFieldValue,
        }) => (
          <Form
            onChange={e => {
              handleChange(e);
              onInputFieldChange(setFieldValue, values.CountryId);
            }}
          >
            <div className="card">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col">
                    <h4 className="card-header-title">Edit Laboratory Information</h4>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="form-row">
                  <Field name="laboratoryName" placeholder="Laboratory Name" component={Text} />
                  <Field
                    name="WHORegionId"
                    placeholder="WHO Region"
                    component={DataList}
                    options={whoRegionsData ? Object.values(whoRegionsData) : {}}
                  />
                  <Field
                    name="WHONetworkLabCategoryId"
                    placeholder="WHO Network Laboratory Level"
                    component={DataList}
                    options={
                      whoNetworkLabCategoriesData ? Object.values(whoNetworkLabCategoriesData) : {}
                    }
                  />
                </div>
                <div className="form-row">
                  <Field name="address1" placeholder="Address 1" component={Text} />
                  <Field name="address2" placeholder="Address 2" component={Text} />
                  <Field name="city" placeholder="City/Municipality" component={Text} />
                </div>
                <div className="form-row">
                  <Field
                    name="CountryId"
                    placeholder="Country"
                    component={DataList}
                    options={countriesData ? Object.values(countriesData) : {}}
                  />
                  {renderStateDropDownOrInput(values.CountryId)}
                  <Field name="zip" placeholder="Zip Code" component={Text} />
                </div>
              </div>
            </div>

            <div className="row">
              {/* The POC info card */}
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-header-title">My profile</h4>
                  </div>
                  <div className="card-body">
                    <div className="form-row">
                      <div className="col-sm-12 col-lg-6 mb-3">
                        <label htmlFor="pocFirstName">First Name</label>
                        <Field
                          id="pocFirstName"
                          type="text"
                          name="firstName"
                          placeholder="First Name"
                          className={
                            errors.firstName && touched.firstName
                              ? 'form-control is-invalid'
                              : 'form-control'
                          }
                        />
                        {errors.firstName && touched.firstName && (
                          <div className="invalid-feedback">{errors.firstName}</div>
                        )}
                      </div>
                      <div className="col-sm-12 col-lg-6 mb-3">
                        <label htmlFor="pocLastName">Last Name</label>
                        <Field
                          type="text"
                          name="lastName"
                          id="pocLastName"
                          placeholder="Last Name"
                          className={
                            errors.lastName && touched.lastName
                              ? 'form-control is-invalid'
                              : 'form-control'
                          }
                        />
                        {errors.lastName && touched.lastName && (
                          <div className="invalid-feedback">{errors.lastName}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-sm-12 col-lg-6 mb-3">
                        <label htmlFor="pocEmail">Email</label>
                        <Field
                          type="text"
                          name="email"
                          id="pocEmail"
                          placeholder="Email"
                          className={
                            errors.email && touched.email
                              ? 'form-control is-invalid'
                              : 'form-control'
                          }
                        />
                        {errors.email && touched.email && (
                          <div className="invalid-feedback">{errors.email}</div>
                        )}
                      </div>

                      <div className="col-sm-12 col-lg-6 mb-3">
                        <label htmlFor="pocPhoneCountryCode">Phone Country Code</label>
                        <Field
                          type="text"
                          value={values.countryCode || ''}
                          name="countryCode"
                          id="pocPhoneCountryCode"
                          placeholder="Country Code"
                          className={
                            errors.countryCode ? 'form-control is-invalid' : 'form-control'
                          }
                        />
                        {errors.countryCode && (
                          <div className="invalid-feedback">{errors.countryCode}</div>
                        )}
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-sm-12 col-lg-6 mb-3">
                        <label htmlFor="pocPhone">Phone #</label>
                        <Field
                          type="text"
                          name="phoneNumber"
                          id="pocPhone"
                          placeholder="Phone #"
                          className={
                            errors.phoneNumber && touched.phoneNumber
                              ? 'form-control is-invalid'
                              : 'form-control'
                          }
                        />
                        {errors.phoneNumber && touched.phoneNumber && (
                          <div className="invalid-feedback">{errors.phoneNumber}</div>
                        )}
                      </div>
                      <div className="col-sm-12 col-lg-6 mb-3">
                        <label htmlFor="pocTitle">Title</label>
                        <Field
                          type="text"
                          name="title"
                          id="pocTitle"
                          placeholder="Title"
                          className={
                            errors.title && touched.title
                              ? 'form-control is-invalid'
                              : 'form-control'
                          }
                        />
                        {errors.title && touched.title && (
                          <div className="invalid-feedback">{errors.title}</div>
                        )}
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-sm-12 col-lg-6 mb-3">
                        <div className="form-group mb-3">
                          <label htmlFor="pocPassword">Password:</label>
                          <Field
                            type="password"
                            name="password"
                            id="pocPassword"
                            placeholder="Set password account"
                            className={
                              errors.password && touched.password
                                ? 'form-control is-invalid'
                                : 'form-control'
                            }
                          />
                          {errors.password && touched.password && (
                            <div className="invalid-feedback">{errors.password}</div>
                          )}
                        </div>

                        <label htmlFor="pocConfirmPassword">Confirm Password:</label>
                        <Field
                          type="password"
                          name="confirmPassword"
                          id="pocConfirmPassword"
                          placeholder="Confirm the password"
                          className={
                            errors.confirmPassword && touched.confirmPassword
                              ? 'form-control is-invalid'
                              : 'form-control'
                          }
                        />
                        {errors.confirmPassword && touched.confirmPassword && (
                          <div className="invalid-feedback">{errors.confirmPassword}</div>
                        )}
                      </div>
                    </div>

                    <div>
                      <button
                        type="submit"
                        className="btn btn-primary mr-2"
                        disabled={!isValid || isSubmitting}
                      >
                        {isSubmitting ? 'Submitting...' : 'Update & verify'}
                      </button>
                      <button
                        type="button"
                        className="btn btn-link text-danger"
                        onClick={() => {
                          handleReset();
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default connect(
  'selectWhoRegionsData',
  'selectWhoNetworkLabCategoriesData',
  'selectStatesData',
  'selectCountriesData',
  'selectUnitedStatesValues',
  'doUpdateUserProfile',
  'doUpdateLabDetails',
  'doSetPasswordOnVerification',
  PrimaryPointOfContactVerificationForm,
);
