/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { connect } from 'redux-bundler-react';
import { getNavHelper } from 'internal-nav-helper';
import Header from '../Header';
import Navigation from '../Navigation';

const UserWrapper = ({ title, nav, children, doUpdateUrl }) => (
  <div onClick={getNavHelper(doUpdateUrl)}>
    <Navigation mainNav={nav} horizontalAlign />
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-10 offset-md-1">
          <Header title={title} />
          <div className="row">
            <div className="col-12">{children}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default connect('doUpdateUrl', UserWrapper);
