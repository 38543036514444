import { createSelector } from 'redux-bundler';
import { roles, expectedTestResultsTypes } from '../helpers/constants';
import { has } from '../utils';

const ERROR_TIME = 15000;
const REFRESH_TIME = 300000;
const ActionTypes = {
  // FETCHING
  FETCH_START_EXPECTED_TEST_RESULTS: 'FETCH_START_EXPECTED_TEST_RESULTS',
  FETCH_ERROR_EXPECTED_TEST_RESULTS: 'FETCH_ERROR_EXPECTED_TEST_RESULTS',
  FETCH_SUCCESS_EXPECTED_TEST_RESULTS: 'FETCH_SUCCESS_EXPECTED_TEST_RESULTS',
  // Create and Update EIA
  CREATE_EIA_EXPECTED_TEST_RESULTS: 'CREATE_EIA_SUCCESS_EXPECTED_TEST_RESULTS',
  CREATE_EIA_ERROR: 'CREATE_EIA_ERROR_EXPECTED_TESTS_RESULTS',
  UPDATE_EIA_SUCCESS: 'UPDATE_EIA_SUCCESS_EXPECTED_TEST_RESULTS',
  UPDATE_EIA_ERROR: 'UPDATE_EIA_ERROR_EXPECTED_TEST_RESULTS',
  // Create and Update Genotype
  CREATE_GENOTYPE_EXPECTED_TEST_RESULTS: 'CREATE_GENOTYPE_SUCCESS_EXPECTED_TEST_RESULTS',
  CREATE_GENOTYPE_ERROR: 'CREATE_GENOTYPE_ERROR_EXPECTED_TEST_RESULTS',
  UPDATE_GENOTYPE_SUCCESS: 'UPDATE_GENOTYPE_SUCCESS_EXPECTED_TEST_RESULTS',
  UPDATE_GENOTYPE_ERROR: 'UPDATE_GENOTYPE_ERROR_EXPECTED_TEST_RESULTS',
  // Delete Records Actions for Genotype
  DELETE_GENOTYPING_RECORDS_START: 'DELETE_GENOTYPING_RECORDS_START_EXPECTED_RESULTS',
  DELETE_GENOTYPING_RECORDS_SUCCESS: 'DELETE_GENOTYPING_RECORDS_SUCCESS_EXPECTED_RESULTS',
  DELETE_GENOTYPING_RECORDS_ERROR: 'DELETE_GENOTYPING_RECORDS_ERROR_EXPECTED_RESULTS',
  // update both tests
  UPDATE_EIA_AND_GENOTYPE_EXPECTED_SUCCESS_TESTS_RESULTS:
    'UPDATE_EIA_AND_GENOTYPE_EXPECTED_SUCCESS_TESTS_RESULTS',
  UPDATE_EIA_AND_GENOTYPE_EXPECTED_ERROR_TESTS_RESULTS:
    'UPDATE_EIA_AND_GENOTYPE_EXPECTED_ERROR_TESTS_RESULTS',
  // create both tests
  CREATE_EIA_AND_GENOTYPE_EXPECTED_SUCCESS_TESTS_RESULTS:
    'CREATE_EIA_AND_GENOTYPE_EXPECTED_SUCCESS_TESTS_RESULTS',
  CREATE_EIA_AND_GENOTYPE_EXPECTED_ERROR_TESTS_RESULTS:
    'CREATE_EIA_AND_GENOTYPE_EXPECTED_ERROR_TESTS_RESULTS',
  // update eia and create genotype
  UPDATE_EIA_AND_CREATE_GENOTYPE_EXPECTED_SUCCESS_TESTS_RESULTS:
    'UPDATE_EIA_AND_CREATE_GENOTYPE_EXPECTED_SUCCESS_TESTS_RESULTS',
  UPDATE_EIA_AND_CREATE_GENOTYPE_EXPECTED_ERROR_TESTS_RESULTS:
    'UPDATE_EIA_AND_CREATE_GENOTYPE_EXPECTED_ERROR_TESTS_RESULTS',

  // CLEAR
  CLEAR: 'CLEAR_EXPECTED_RESULTS',
};

export default {
  name: 'expectedTestsResults',
  getReducer: () => {
    const initialState = {
      loading: false,
      lastError: null,
      lastFetch: null,
      data: null,
      error: null,
    };

    return (state = initialState, { type, payload }) => {
      switch (type) {
        case ActionTypes.FETCH_START_EXPECTED_TEST_RESULTS:
        case ActionTypes.DELETE_GENOTYPING_RECORDS_START:
          return {
            ...state,
            loading: true,
          };

        case ActionTypes.FETCH_ERROR_EXPECTED_TEST_RESULTS:
        case ActionTypes.CREATE_EIA_ERROR:
        case ActionTypes.CREATE_GENOTYPE_ERROR:
        case ActionTypes.UPDATE_EIA_ERROR:
        case ActionTypes.UPDATE_GENOTYPE_ERROR:
        case ActionTypes.UPDATE_EIA_AND_GENOTYPE_EXPECTED_ERROR_TESTS_RESULTS:
        case ActionTypes.CREATE_EIA_AND_GENOTYPE_EXPECTED_ERROR_TESTS_RESULTS:
        case ActionTypes.UPDATE_EIA_AND_CREATE_GENOTYPE_EXPECTED_ERROR_TESTS_RESULTS:
        case ActionTypes.DELETE_GENOTYPING_RECORDS_ERROR:
          return {
            ...state,
            lastError: Date.now(),
            loading: false,
            error: payload,
          };

        case ActionTypes.FETCH_SUCCESS_EXPECTED_TEST_RESULTS:
        case ActionTypes.CREATE_EIA_EXPECTED_TEST_RESULTS:
        case ActionTypes.CREATE_GENOTYPE_EXPECTED_TEST_RESULTS:
        case ActionTypes.UPDATE_EIA_SUCCESS:
        case ActionTypes.UPDATE_GENOTYPE_SUCCESS:
        case ActionTypes.UPDATE_EIA_AND_GENOTYPE_EXPECTED_SUCCESS_TESTS_RESULTS:
        case ActionTypes.CREATE_EIA_AND_GENOTYPE_EXPECTED_SUCCESS_TESTS_RESULTS:
        case ActionTypes.UPDATE_EIA_AND_CREATE_GENOTYPE_EXPECTED_SUCCESS_TESTS_RESULTS:
          return {
            ...state,
            lastFetch: Date.now(),
            loading: false,
            lastError: null,
            error: null,
            data: payload,
          };

        case ActionTypes.CLEAR:
          return initialState;
        default:
          return state;
      }
    };
  },
  selectExpectedTestsResultsDataRaw: state => state.expectedTestsResults,
  selectExpectedTestsResultsData: state => state.expectedTestsResults.data,
  selectExpectedTestsResultsList: createSelector(
    'selectExpectedTestsResultsDataRaw',
    expectedTestsResultsData =>
      expectedTestsResultsData.data ? Object.values(expectedTestsResultsData.data) : [],
  ),
  selectExpectedTestResultForStudy: createSelector(
    'selectExpectedTestsResultsData',
    'selectRouteParams',
    (expectedData, routeParams) => {
      const expectedResultStudy = expectedData?.[routeParams.studyId] || {};

      return Object.values(expectedResultStudy).map(exp => exp);
    },
  ),
  selectCurrentExpectedTestResults: createSelector(
    'selectExpectedTestsResultsData',
    'selectExpectedTestsResultsList',
    'selectRouteParams',
    (expectedTestsResultsData, expectedTestsResultsList, routeParams) => {
      if (!expectedTestsResultsData || !routeParams) {
        return null;
      }
      if (
        has.call(routeParams, 'testId') &&
        has.call(expectedTestsResultsData, routeParams.studyId)
      ) {
        const mapping = {
          1: expectedTestResultsTypes.EIATest,
          2: expectedTestResultsTypes.GenotypingTest,
        };

        const [filterValue] = expectedTestsResultsList.filter(exp =>
          has.call(exp, mapping[routeParams.testId]),
        );
        if (filterValue) {
          return filterValue[mapping[routeParams.testId]];
        }
        return null;
      }
    },
  ),

  /**
   * Get expected result for the enrolledTest to be graded
   * in the URL pattern -> `/labs/:labId/enrolledTests/:enrolledTestId/grading`
   */
  selectCurrentExpectedTestResultsForGrading: createSelector(
    'selectExpectedTestsResultsData',
    'selectLabCurrentSubscribedTestsResult',
    (expectedTestsResultsData, labCurrentSubscribedTestsResult) => {
      if (
        labCurrentSubscribedTestsResult &&
        expectedTestsResultsData &&
        expectedTestsResultsData[labCurrentSubscribedTestsResult.study.id]
      ) {
        const studyExpectedResults = Object.values(
          expectedTestsResultsData[labCurrentSubscribedTestsResult.study.id],
        );

        return studyExpectedResults.find(
          expectedResult =>
            expectedResult.StudyTestId === labCurrentSubscribedTestsResult.StudyTestId,
        );
      }

      if (labCurrentSubscribedTestsResult && !expectedTestsResultsData) {
        return false;
      }

      return null;
    },
  ),

  selectExpectedResultErrorMessage: createSelector(
    'selectExpectedTestsResultsDataRaw',
    expectedTestsResultsDataRaw => {
      return expectedTestsResultsDataRaw.error && expectedTestsResultsDataRaw.error.message
        ? expectedTestsResultsDataRaw.error.message
        : null;
    },
  ),

  // Action Creators
  doMergeExpectedResultInTheStore: ({
    type,
    data: { studyId, eiaResponse, genotypeResponse },
  }) => ({ dispatch, getState }) => {
    const expectedTestsResultsData = getState().expectedTestsResults.data || {};
    const data = {
      ...expectedTestsResultsData,
      [studyId]: {
        ...expectedTestsResultsData[studyId],
        EIATest: { ...eiaResponse },
        GenotypingTest: { ...genotypeResponse },
      },
    };
    dispatch({
      type,
      payload: { ...data },
    });
  },
  doFetchExpectedTestsResultsList: id => ({ dispatch, apiFetch, getState }) => {
    dispatch({ type: ActionTypes.FETCH_START_EXPECTED_TEST_RESULTS });
    apiFetch({
      endpoint: `studies/${id}/expectedResults`,
    })
      .then(response => {
        const existingExpectedResults = getState().expectedTestsResults.data || {};

        const storedResults = response.results.reduce(
          (acc, expectedTestResult) => {
            if (expectedTestResult.EIATest) {
              acc[expectedTestResultsTypes.EIATest] = {
                ...expectedTestResult,
              };
            }
            if (expectedTestResult.GenotypingTest) {
              acc[expectedTestResultsTypes.GenotypingTest] = {
                ...expectedTestResult,
              };
            }

            return acc;
          },
          {}, // Useful to avoid a loop in the reactor when no data from the server
        );

        const payload = {
          ...existingExpectedResults,
          [id]: storedResults,
        };

        dispatch({
          type: ActionTypes.FETCH_SUCCESS_EXPECTED_TEST_RESULTS,
          payload,
        });
      })
      .catch(error =>
        dispatch({ type: ActionTypes.FETCH_ERROR_EXPECTED_TEST_RESULTS, payload: error }),
      );
  },
  doCreateEIAExpectedResults: (studyId, payload) => ({ dispatch, apiFetch, getState }) => {
    dispatch({ type: ActionTypes.FETCH_START_EXPECTED_TEST_RESULTS });

    return apiFetch({
      method: 'post',
      endpoint: `studies/${studyId}/eia/expectedResults`,
      data: { ...payload },
      alertOnFail: true,
    })
      .then(response => {
        const expectedTestsResultsData = getState().expectedTestsResults.data || {};
        const data = {
          ...expectedTestsResultsData,
          [studyId]: {
            ...expectedTestsResultsData[studyId],
            EIATest: { ...response },
          },
        };

        dispatch({
          type: ActionTypes.CREATE_EIA_EXPECTED_TEST_RESULTS,
          payload: data,
        });

        dispatch({
          actionCreator: 'doCreateAlert',
          args: [
            {
              msg: `EIA Expected Results has been successfully submitted`,
              title: 'EIA Test Expected Results',
            },
          ],
        });

        return response;
      })
      .catch(error => {
        dispatch({ type: ActionTypes.CREATE_EIA_ERROR, payload: error });

        throw error;
      });
  },
  doCreateGenotypeExpectedResults: (studyId, payload) => ({ dispatch, apiFetch, getState }) => {
    dispatch({ type: ActionTypes.FETCH_START_EXPECTED_TEST_RESULTS });

    return apiFetch({
      method: 'post',
      endpoint: `studies/${studyId}/genotyping/expectedResults`,
      data: { ...payload },
      alertOnFail: true,
    })
      .then(response => {
        const expectedTestsResultsData = getState().expectedTestsResults.data || {};

        const data = {
          ...expectedTestsResultsData,
          [studyId]: {
            ...expectedTestsResultsData[studyId],
            GenotypingTest: { ...response },
          },
        };

        dispatch({
          type: ActionTypes.CREATE_GENOTYPE_EXPECTED_TEST_RESULTS,
          payload: data,
        });

        dispatch({
          actionCreator: 'doCreateAlert',
          args: [
            {
              msg: `Genotype Expected Results has been successfully submitted`,
              title: 'Genotype Test Expected Results',
            },
          ],
        });

        return response;
      })
      .catch(error => {
        dispatch({ type: ActionTypes.CREATE_GENOTYPE_ERROR, payload: error });

        throw error;
      });
  },
  doUpdateAllTheExpectedResultsByStudyId: (studyId, eiaPayload, genotypePayload) => ({
    dispatch,
    apiFetch,
  }) => {
    // Prepare the EIA endpoint hit
    // Prepare the Genotype endpoint hitf

    apiFetch({
      method: 'patch',
      endpoint: `studies/${studyId}/eia/expectedResults`,
      data: { ...eiaPayload },
      alertOnFail: true,
    })
      .then(eiaResult => {
        apiFetch({
          method: 'patch',
          endpoint: `studies/${studyId}/genotyping/expectedResults`,
          data: { ...genotypePayload },
          alertOnFail: true,
        }).then(genotypeResult => {
          const payload = {
            type: ActionTypes.UPDATE_EIA_AND_GENOTYPE_EXPECTED_SUCCESS_TESTS_RESULTS,
            data: {
              studyId,
              eiaResponse: eiaResult,
              genotypeResponse: genotypeResult,
            },
          };

          dispatch({ actionCreator: 'doMergeExpectedResultInTheStore', args: [payload] });

          dispatch({
            actionCreator: 'doCreateAlert',
            args: [
              {
                msg: `Expected Results has been successfully updated`,
                title: 'Expected Test Results',
              },
            ],
          });
        });
      })
      .catch(error => {
        dispatch({
          type: ActionTypes.UPDATE_EIA_AND_GENOTYPE_EXPECTED_ERROR_TESTS_RESULTS,
          payload: error,
        });
      });
  },
  doCreateAllTheExpectedResultsByStudyId: (studyId, eiaPayload, genotypePayload) => ({
    dispatch,
    apiFetch,
  }) => {
    // Prepare the EIA endpoint hit
    // Prepare the Genotype endpoint hitf
    apiFetch({
      method: 'post',
      endpoint: `studies/${studyId}/eia/expectedResults`,
      data: { ...eiaPayload },
      alertOnFail: true,
    })
      .then(eiaResult => {
        apiFetch({
          method: 'post',
          endpoint: `studies/${studyId}/genotyping/expectedResults`,
          data: { ...genotypePayload },
          alertOnFail: true,
        }).then(genotypeResult => {
          const payload = {
            type: ActionTypes.CREATE_EIA_AND_GENOTYPE_EXPECTED_SUCCESS_TESTS_RESULTS,
            data: {
              studyId,
              eiaResponse: eiaResult,
              genotypeResponse: genotypeResult,
            },
          };

          dispatch({ actionCreator: 'doMergeExpectedResultInTheStore', args: [payload] });

          dispatch({
            actionCreator: 'doCreateAlert',
            args: [
              {
                msg: `Expected Results has been successfully updated`,
                title: 'Expected Test Results',
              },
            ],
          });
        });
      })
      .catch(error => {
        dispatch({
          type: ActionTypes.CREATE_EIA_AND_GENOTYPE_EXPECTED_ERROR_TESTS_RESULTS,
          payload: error,
        });
      });
  },
  doUpdateEiaAndCreateGenotypeExpectedResultsByStudyId: (studyId, eiaPayload, genotypePayload) => ({
    dispatch,
    apiFetch,
  }) => {
    // Prepare the EIA endpoint hit
    // Prepare the Genotype endpoint hitf

    apiFetch({
      method: 'patch',
      endpoint: `studies/${studyId}/eia/expectedResults`,
      data: { ...eiaPayload },
      alertOnFail: true,
    })
      .then(eiaResult => {
        apiFetch({
          method: 'post',
          endpoint: `studies/${studyId}/genotyping/expectedResults`,
          data: { ...genotypePayload },
          alertOnFail: true,
        }).then(genotypeResult => {
          const payload = {
            type: ActionTypes.UPDATE_EIA_AND_CREATE_GENOTYPE_EXPECTED_SUCCESS_TESTS_RESULTS,
            data: {
              studyId,
              eiaResponse: eiaResult,
              genotypeResponse: genotypeResult,
            },
          };

          dispatch({ actionCreator: 'doMergeExpectedResultInTheStore', args: [payload] });

          dispatch({
            actionCreator: 'doCreateAlert',
            args: [
              {
                msg: `Expected Results has been successfully updated`,
                title: 'Expected Test Results',
              },
            ],
          });
        });
      })
      .catch(error => {
        dispatch({
          type: ActionTypes.UPDATE_EIA_AND_CREATE_GENOTYPE_EXPECTED_ERROR_TESTS_RESULTS,
          payload: error,
        });
      });
  },
  doUpdateEIAExpectedResults: (studyId, payload) => ({ dispatch, apiFetch, getState }) => {
    dispatch({ type: ActionTypes.FETCH_START_EXPECTED_TEST_RESULTS });

    return apiFetch({
      method: 'patch',
      endpoint: `studies/${studyId}/eia/expectedResults`,
      data: { ...payload },
      alertOnFail: true,
    })
      .then(response => {
        const expectedTestsResultsData = getState().expectedTestsResults.data || {};
        const data = {
          ...expectedTestsResultsData,
          [studyId]: {
            ...expectedTestsResultsData[studyId],
            EIATest: { ...response },
          },
        };

        dispatch({
          type: ActionTypes.UPDATE_EIA_SUCCESS,
          payload: { ...data },
        });

        dispatch({
          actionCreator: 'doCreateAlert',
          args: [
            {
              msg: `EIA Expected Results has been successfully updated`,
              title: 'EIA Test Expected Results',
            },
          ],
        });

        return response;
      })
      .catch(error => {
        dispatch({ type: ActionTypes.UPDATE_EIA_ERROR, payload: error });

        throw error;
      });
  },
  doUpdateGenotypeExpectedResults: (studyId, payload) => ({ dispatch, apiFetch, getState }) => {
    dispatch({ type: ActionTypes.FETCH_START_EXPECTED_TEST_RESULTS });

    return apiFetch({
      method: 'patch',
      endpoint: `studies/${studyId}/genotyping/expectedResults`,
      data: { ...payload },
      alertOnFail: true,
    })
      .then(response => {
        const expectedTestsResultsData = getState().expectedTestsResults.data || {};
        const data = {
          ...expectedTestsResultsData,
          [studyId]: {
            ...expectedTestsResultsData[studyId],
            GenotypingTest: { ...response },
          },
        };

        dispatch({
          type: ActionTypes.UPDATE_GENOTYPE_SUCCESS,
          payload: { ...data },
        });

        dispatch({
          actionCreator: 'doCreateAlert',
          args: [
            {
              msg: `Genotype Expected Results has been successfully updated`,
              title: 'Genotype Test Expected Results',
            },
          ],
        });

        return response;
      })
      .catch(error => {
        dispatch({ type: ActionTypes.UPDATE_GENOTYPE_ERROR, payload: error });

        throw error;
      });
  },
  doDeleteGenotypingRecordsExpectedResults: (studyId, ids) => ({ dispatch, apiFetch }) => {
    /** Here the delete logic for genotyping records */
    dispatch({ type: ActionTypes.DELETE_GENOTYPING_RECORDS_START });

    return apiFetch({
      method: 'delete',
      endpoint: `studies/${studyId}/genotyping/expectedResults`,
      data: { ids },
      alertOnFail: true,
    })
      .then(response => response)
      .catch(error => {
        dispatch({ type: ActionTypes.DELETE_GENOTYPING_RECORDS_ERROR, payload: error });
        throw error;
      });
  },

  doClearExpectedResults: () => ({ dispatch }) => {
    dispatch({ type: ActionTypes.CLEAR });
  },

  // Reactors
  reactShouldFetchExpectedTestsResultsData: createSelector(
    'selectExpectedTestsResultsDataRaw',
    'selectCurrentStudy',
    'selectAppTime',
    'selectUserRole',
    'selectRouteParams',
    'selectRouteInfo',
    (expectedTestsResultsData, currentStudy, appTime, userRole, routeParams, routeInfo) => {
      const routeToMatch = '/studies/:studyId';
      if (
        expectedTestsResultsData.loading ||
        !currentStudy ||
        !routeInfo.pattern.includes(routeToMatch) ||
        userRole !== roles.ADMIN ||
        !has.call(routeParams, 'studyId')
      ) {
        return null;
      }

      let shouldFetch = false;

      if (
        (!expectedTestsResultsData.data && !expectedTestsResultsData.lastError) ||
        (expectedTestsResultsData.data && !expectedTestsResultsData.data[routeParams.studyId])
      ) {
        shouldFetch = true;
      } else if (expectedTestsResultsData.lastError) {
        const timePassed = appTime - expectedTestsResultsData.lastError;
        if (timePassed > ERROR_TIME) {
          shouldFetch = true;
        }
      } else if (expectedTestsResultsData.lastFetch) {
        const timePassed = appTime - expectedTestsResultsData.lastFetch;
        if (timePassed > REFRESH_TIME) {
          shouldFetch = true;
        }
      }

      if (shouldFetch) {
        return { actionCreator: 'doFetchExpectedTestsResultsList', args: [routeParams.studyId] };
      }
    },
  ),

  /**
   * This reactor is triggered once we visit any grading related page
   * We have created this one as a separate reactor since we do not have `selectCurrentStudy` selector available
   */
  reactShouldFetchExpectedResultDetailsForGrading: createSelector(
    'selectExpectedTestsResultsDataRaw',
    'selectCurrentLab',
    'selectLabCurrentSubscribedTestsResult',
    'selectRouteInfo',
    'selectAppTime',
    'selectUserRole',
    (
      expectedTestsResultsDataRaw,
      currentLab,
      labCurrentSubscribedTestsResult,
      routeInfo,
      appTime,
      userRole,
    ) => {
      const routeToMatch = '/labs/:labId/enrolledTests/:enrolledTestId/grading';
      if (
        userRole !== roles.ADMIN ||
        expectedTestsResultsDataRaw.loading ||
        !currentLab ||
        !labCurrentSubscribedTestsResult ||
        !routeInfo.pattern.includes(routeToMatch)
      ) {
        return null;
      }

      const { study } = labCurrentSubscribedTestsResult;
      let shouldFetch = false;

      if (
        (!expectedTestsResultsDataRaw.data && !expectedTestsResultsDataRaw.lastError) ||
        (expectedTestsResultsDataRaw.data &&
          !expectedTestsResultsDataRaw.data[study.id] &&
          !expectedTestsResultsDataRaw.lastError)
      ) {
        shouldFetch = true;
      } else if (expectedTestsResultsDataRaw.lastError) {
        const timePassed = appTime - expectedTestsResultsDataRaw.lastError;
        if (timePassed > ERROR_TIME) {
          shouldFetch = true;
        }
      } else if (expectedTestsResultsDataRaw.lastFetch) {
        const timePassed = appTime - expectedTestsResultsDataRaw.lastFetch;
        if (timePassed > REFRESH_TIME) {
          shouldFetch = true;
        }
      }

      if (shouldFetch) {
        return { actionCreator: 'doFetchExpectedTestsResultsList', args: [study.id] };
      }
    },
  ),
};
