import React from 'react';
import ErrorMessage from '../../lib/errorMessage';

const RowNumericInput = ({
  field, // { name, value, onChange, onBlur }
  handleChange,
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <>
      <input type="number" step="0.01" {...props} onChange={handleChange} {...field} />
      <ErrorMessage name={field.name} />
    </>
  );
};
export default RowNumericInput;
