import React from 'react';

const HeadTable = () => (
  <thead>
    <tr>
      <th className="text-center">
        <div className="text-muted">Sum Codes</div>
      </th>
      <th className="text-center">
        <div className="text-muted">EIA Results</div>
      </th>
      <th className="text-center">
        <div className="text-muted">Expected Genotype</div>
      </th>
      <th className="text-center">
        <div className="text-muted">Action</div>
      </th>
    </tr>
  </thead>
);

export default HeadTable;
