import { useState } from 'react';

const useResubmissionNote = initialValue => {
  const [note, setNote] = useState(initialValue);

  function handleChange(value) {
    setNote(value);
  }

  return [note, handleChange];
};

export default useResubmissionNote;
