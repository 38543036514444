import React from 'react';
import ErrorMessage from '../errorMessage';
import '../../../assets/scss/_user.scss';

const FinalScore = ({
  field, // { name, value, onChange, onBlur }
  handleChange,

  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  step,
  ...props
}) => {
  return (
    <>
      <input
        type="number"
        style={{
          height: '1.1875rem',
          fontSize: '0.8125rem',
          padding: '3px',
        }}
        step={step}
        {...props}
        onChange={handleChange}
        name={field.name}
        value={field.value}
        min={0}
        max={10}
      />
      <ErrorMessage name={field.name} />
    </>
  );
};
export default FinalScore;
