/* eslint-disable no-irregular-whitespace */

import React, { useCallback } from 'react';
import { connect } from 'redux-bundler-react';
import TestGeneralInfo from '../../lib/TestGeneralInfo';
import AmplificationRounds from '../../lib/AmplificationRounds';
import TestResultsTable from '../../lib/TestResultsTable';
import ResultsSummary from '../../lib/ResultsSummary';
import useGradedResultsData from '../../hooks/useGradedResultsData';
import {
  testTypes,
  testResultStatus,
  TestTargetScores,
  expectedTestResultsTypes,
  enrolledTestStatus,
} from '../../../helpers/constants';

const EIATestAnswersView = React.forwardRef(
  (
    {
      enrollmentList,
      data,
      enrolledPendingTests,
      verifyCurrentLabIsRegional,
      pocCurrentGradingData,
    },
    ref,
  ) => {
    const headers = ['Sample ID NUmber', 'Antigen OD', 'Test Interpretation'];
    let headersColumns = [];
    if (pocCurrentGradingData?.enrolledTestStatus === enrolledTestStatus.FINALIZED) {
      headersColumns = [...headers, 'Score', 'Comments'];
    } else {
      headersColumns = [...headers];
    }

    const {
      EnrollmentId,
      investigator,
      testDate,
      createdAt,
      createdBy,
      amplificationRounds,
      EIATest,
      GenotypingTest,
      status,
    } = data;

    const [gradedResultsData] = useGradedResultsData(
      expectedTestResultsTypes.EIATest,
      pocCurrentGradingData,
      EIATest,
    );

    const studyData = useCallback(() => {
      return enrollmentList.filter(enroll => enroll.Enrollment.id === EnrollmentId)[0];
    }, [EnrollmentId, enrollmentList]);

    const resultControl = {
      antigenODPositiveControl: EIATest.antigenODPositiveControl,
      antigenODNegativeControl: EIATest.antigenODNegativeControl,
      positiveControlTestInterpretation: EIATest.positiveControlTestInterpretation,
      negativeControlTestInterpretation: EIATest.negativeControlTestInterpretation,
      kitCutOffFormula: EIATest.kitCutOffFormula,
      kitCutOff: EIATest.kitCutOff,
    };

    const targetScore = verifyCurrentLabIsRegional
      ? TestTargetScores.RRL_SCORE // 80
      : TestTargetScores.DEFAULT_SCORE; // 80

    const currentScore =
      pocCurrentGradingData && pocCurrentGradingData.gradedResults
        ? pocCurrentGradingData.gradedResults.reduce((accumulator, { finalScore }) => {
            return accumulator + finalScore.value;
          }, 0)
        : 0;

    // inform if the test has passed or not
    const testStatus =
      targetScore <= currentScore ? testResultStatus.PASSED : testResultStatus.FAILED;

    const {
      name,
      year,
      Enrollment: { id },
    } = studyData();
    return (
      <div className="card p-4" ref={ref}>
        <div className="card-header px-0">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              {enrolledPendingTests.length > 0 && (
                <li className="breadcrumb-item active" aria-current="page">
                  <small className="d-inline d-sm-none">
                    <a href={`/enrolled-studies/${id}/submit-tests`}>
                      {`Pending Tests (${enrolledPendingTests.length})`}
                    </a>
                  </small>
                  <span className="d-none d-sm-inline">
                    <a href={`/enrolled-studies/${id}/submit-tests`}>
                      {`Pending Tests (${enrolledPendingTests.length})`}
                    </a>
                  </span>
                </li>
              )}
              <li className="breadcrumb-item">
                <small className="d-inline d-sm-none">
                  <a href={`/enrolled-studies/${id}/submitted-tests/answers`}>View Test Answers</a>
                </small>
                <span className="d-none d-sm-inline">
                  <a href={`/enrolled-studies/${id}/submitted-tests/answers`}>View Test Answers</a>
                </span>
              </li>
            </ol>
          </nav>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col text-right mb-3">
              ​<div className="badge badge-success text-white">Submitted</div>​
            </div>
          </div>

          <div className="row">
            <div className="col text-center">
              <h2 className="text-muted mb-2 text-uppercase">{`${name}, ${year}`}</h2>
              <p className="text-muted mb-5">EIA Test</p>
            </div>
          </div>

          <div className="row justify-content-around text-center">
            <TestGeneralInfo
              investigator={investigator}
              testDate={testDate}
              createdAt={createdAt}
              createdBy={createdBy}
            />
          </div>

          <div className="row pt-6 pb-3">
            <div className="col text-center">
              <h2 className="mt-0 mb-4 text-info text-uppercase">Amplification Rounds</h2>
              <AmplificationRounds
                amplificationRounds={amplificationRounds}
                showMethodColumn={!!GenotypingTest}
              />
            </div>
          </div>

          {pocCurrentGradingData &&
          pocCurrentGradingData.gradedResults &&
          status === enrolledTestStatus.FINALIZED ? (
            <>
              <div className="row text-center">
                <div className="col-12 col-lg-6 col-xl-4 card-container score-card-container">
                  <div className="card">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col">
                          <h5 className="card-title text-uppercase text-muted"> Target Score</h5>
                          <span className="h2 mb-0">{targetScore}</span>
                        </div>
                        <div className="col-auto">
                          <span className="h2 fe fe-crosshair text-muted mb-0" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-4 card-container score-card-container">
                  <div className="card">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col">
                          <h5 className="card-title text-uppercase text-muted">Current Score</h5>
                          <span className="h2 mb-0">{currentScore}</span>
                        </div>
                        <div className="col-auto">
                          <span className="h2 fe fe-activity text-muted mb-0" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-12 col-xl-4 card-container score-card-container">
                  <div className="card">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col">
                          <h5 className="card-title text-uppercase text-muted">Test Status</h5>
                          {testStatus === null ? (
                            <span className="text-muted">--</span>
                          ) : (
                            <span
                              className={
                                testStatus === testResultStatus.PASSED
                                  ? 'h2 mb-0 text-success'
                                  : 'h2 mb-0 text-danger'
                              }
                            >
                              {testStatus}
                            </span>
                          )}
                        </div>
                        <div className="col-auto">
                          <span
                            className={
                              testStatus === testResultStatus.PASSED
                                ? 'h2 fe fe-check-circle text-muted mb-0'
                                : 'h2 fe fe-slash text-muted mb-0'
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="card-body">
              <div className="m-0 text-center">
                <h2 className="header-pretitle text-primary">
                  Evaluation Results for this test has not yet been submitted
                  <br />
                  <small className="small text-secondary">
                    Test Submitted Information can be seen by lab participants on the table below
                  </small>
                </h2>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col text-center">
              <div className="card">
                <div className="card-header">
                  <h2 className="my-2 text-info text-uppercase">Results</h2>
                </div>
                <div className="card-body p-0">
                  <TestResultsTable
                    columnsHeaders={headersColumns}
                    items={gradedResultsData}
                    EIATest
                    status={status}
                  >
                    <ResultsSummary testType={testTypes.EIA} resultControl={resultControl} />
                  </TestResultsTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
);

export default connect(
  'selectEnrollmentList',
  'selectEnrolledPendingTests',
  'selectVerifyCurrentLabIsRegional',
  'selectPocCurrentGradingData',
  EIATestAnswersView,
);
