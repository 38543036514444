import React from 'react';
import ErrorMessage from '../errorMessage';

const TextArea = ({
  field, // { name, value, onChange, onBlur }
  handleChange,
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <>
      <textarea
        {...props}
        onChange={handleChange}
        name={field.name}
        value={!field.value ? '' : field.value}
      />
      <ErrorMessage name={field.name} />
    </>
  );
};
export default TextArea;
