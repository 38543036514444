import React from 'react';
import { panelShippingStatus } from '../../helpers/constants';

const KitStatusMessage = ({ kitStatus }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 justify-content-center">
          <div className="alert alert-light" role="alert">
            The shipping status of your kit is:{' '}
            <b
              className={kitStatus !== panelShippingStatus.RECEIVED ? 'text-warning' : 'text-info'}
            >
              {kitStatus}
            </b>
          </div>
          <small>
            <a href="/labs-studies" className="header-pretitle text-primary">
              Return to Labs & Studies Dashboard
            </a>
          </small>
        </div>
      </div>
    </div>
  );
};

export default KitStatusMessage;
