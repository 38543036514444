import React from 'react';

const Alert = ({ text = '', type = 'primary' }) => {
  return (
    <div className={`alert alert-${type}`}>
      {text}
      <button type="button" className="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
    </div>
  );
};

export default Alert;
