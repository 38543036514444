import { useState, useEffect } from 'react';

const useResultReportPenalties = reportPenalties => {
  const initialState = {
    businessDaysToSubmit: 15,
    extensionRequested: false,
    penaltyForLateEIASubmission: false,
    penaltyForLateGenotypingSubmission: false,
  };
  const [penalties, setPenalties] = useState(initialState);

  useEffect(() => {
    if (reportPenalties) {
      const penaltiesState = {
        businessDaysToSubmit: reportPenalties.businessDaysToSubmit,
        extensionRequested: reportPenalties.extensionRequested,
        penaltyForLateEIASubmission: reportPenalties.penaltyForLateEIASubmission,
        penaltyForLateGenotypingSubmission: reportPenalties.penaltyForLateGenotypingSubmission,
      };

      setPenalties(penaltiesState);
    }
  }, [reportPenalties]);

  return [penalties];
};

export default useResultReportPenalties;
