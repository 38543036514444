import React from 'react';
import { connect } from 'redux-bundler-react';
import { roles } from '../../helpers/constants';
import Card from '../lib/Card';
import ListingStudies from './ListingStudies';

const MainDashboard = ({ userData, pocsList, enrolledTestsSummary }) =>
  userData.role === roles.ADMIN && (
    <>
      <div className="row">
        <Card title="Total Users" amount={pocsList?.length ?? '--'} iconClass="fe-user" />
        <Card
          title="Tests Passed"
          amount={enrolledTestsSummary?.PASSED ?? '--'}
          iconClass="fe-check"
        />
        <Card
          title="Tests Pending"
          amount={enrolledTestsSummary?.PENDING ?? '--'}
          iconClass="fe-clock"
        />
        <Card
          title="Tests Failed"
          amount={enrolledTestsSummary?.FAILED ?? '--'}
          iconClass="fe-alert-circle"
        />
      </div>
      <ListingStudies />
    </>
  );

export default connect(
  'selectUserData',
  'selectPocsList',
  'selectEnrolledTestsSummary',
  MainDashboard,
);
