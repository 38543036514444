import React from 'react';
import { connect } from 'redux-bundler-react';
import UserNewForm from '../forms/UserNewForm';

const UserNewPage = () => (
  <div>
    <UserNewForm />
  </div>
);

export default connect('doCreateUser', UserNewPage);
