/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable prefer-spread */
import React from 'react';
import { Formik, Form, Field } from 'formik';
import { kitVerificationInitValues, ShippingStatusSchema } from '../form-schemas/studiesSchema';
import DatePicker from './study-create-form/DatePicker';
import { monthNames, panelShippingStatus } from '../../helpers/constants';

/**
 *
 * @param {Function} action action creator from the parent component
 * @param {number|string} currentStudyId the current study the lab participant is enrolled in
 * @param {number|string} LabParticipantId the id of the logged lab participant
 * @param {Date|string} panelSentDate the date the panel was send by the admin
 * @return {void}
 */
const KitVerificationForm = ({ action, currentStudyId, LabParticipantId, panelSentDate }) => {
  // Get the current year
  const currentYear = new Date().getFullYear();

  const handleSubmit = async values => {
    const { cboMonth, cboYear } = values;
    const concatDate = `${cboYear}/${cboMonth}/01`;
    const dateOfManufacture = new Date(concatDate);

    const panelReceivedDate = new Date(values.panelReceivedDate).toISOString();
    const kitVerificationData = {
      LabParticipantId,
      shipmentStatus: panelShippingStatus.RECEIVED,
      dateOfManufacture,
      panelReceivedDate,
    };
    try {
      await action(currentStudyId, kitVerificationData);
    } catch (error) {
      console.log('Error', error);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={kitVerificationInitValues}
      validationSchema={ShippingStatusSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched }) => (
        <Form>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title header-pretitle">Kit Verification</h5>
              <p className="card-text text-secondary">
                In case you may have received it early, please verify the kit using the form below.
              </p>

              <div className="form-row">
                <label htmlFor="panelMYearCustomDatePicker" className="col-sm-12">
                  Panel Manufacture Date
                </label>
                <div className="col-lg-6 form-group">
                  <Field
                    name="cboMonth"
                    component="select"
                    placeholder="Select Month"
                    className={
                      touched.cboMonth && errors.cboMonth
                        ? 'mr-2 form-control form-control is-invalid'
                        : 'mr-2 form-control form-control'
                    }
                  >
                    <option defaultValue>Select a month</option>
                    {monthNames.map(month => (
                      <option value={month} key={month}>
                        {month}
                      </option>
                    ))}
                  </Field>
                  {touched.cboMonth && errors.cboMonth && (
                    <span className="invalid-feedback">{errors.cboMonth}</span>
                  )}
                </div>
                <div className="col-lg-6 form-group">
                  <Field
                    name="cboYear"
                    component="select"
                    placeholder="Select Year"
                    className={
                      touched.cboYear && errors.cboYear
                        ? 'mr-2 form-control form-control is-invalid'
                        : 'mr-2 form-control form-control'
                    }
                  >
                    <option defaultValue>Select a year</option>
                    {Array.apply(null, { length: 10 }).map((e, i) => (
                      <option value={currentYear - i} key={i.toString()}>
                        {currentYear - i}
                      </option>
                    ))}
                  </Field>
                  {touched.cboYear && errors.cboYear && (
                    <span className="invalid-feedback">{errors.cboYear}</span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <Field
                  name="panelReceivedDate"
                  placeholder="Date of reception"
                  label="Date of reception"
                  upperClass="form-group"
                  component={DatePicker}
                />
              </div>

              <button type="submit" className="btn btn-primary">
                Verify
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default KitVerificationForm;
