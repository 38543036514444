/**
 * [
 * This function validate the length of the sampleID with the first index, also validates
 * whether the index is equal to zero and the index should be different from the other
 * ]
 *
 * @param {[Number]} firstIndex [firstIndex to take for a sum to get the expected result]
 * @param {[Number]} sampleLength [the length of the SampleID Number]
 * @param   {[Number]}  secondIndex   [secondIndex to take for a sum to get the expected value]
 */
const validateFirstIndex = (firstIndex, sampleLength, secondIndex) => {
  let error;

  if (+firstIndex > +sampleLength) {
    error = 'First Index should be lower than Sample Length';
    return error;
  }
  if (+firstIndex === 0) {
    error = 'Each Index should be greater than 0';
    return error;
  }

  if (+secondIndex === +firstIndex) {
    error = 'First Index and Second Index should not be equal';
    return error;
  }

  return error;
};

/**
 * [validateSecondIndex description]
 *
 * @param   { [Number] }  sampleLength  [the length of the SampleID Number]
 * @param   {[Number]}  secondIndex   [secondIndex to take for a sum to get the expected value]
 *
 * @return  {[type]}                [return the message of the error]
 */
function validateSecondIndex(sampleLength, secondIndex) {
  let error;

  if (+secondIndex === 0) {
    error = 'Each Index should be greater than 0';
    return error;
  }

  if (+secondIndex > +sampleLength) {
    error = 'Second Index should be lower  than Sample Length';
  }

  return error;
}

export { validateFirstIndex, validateSecondIndex };
