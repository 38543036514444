import React from 'react';
import ErrorMessage from '../../lib/errorMessage';

const RowNumericInputExpectedResults = ({
  field, // { name, value, onChange, onBlur }
  handleChange,
  cbForHandleChange,
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <>
      <input
        type="number"
        step="0.01"
        {...props}
        onChange={e => {
          handleChange(e);
          if (cbForHandleChange) {
            cbForHandleChange(e.target.value);
          }
        }}
        name={field.name}
        value={field.value}
      />
      <ErrorMessage name={field.name} />
    </>
  );
};

export default RowNumericInputExpectedResults;
