import React from 'react';
import { testTypes, customTestInterpretation } from '../../helpers/constants';
import { strCapitalize } from '../../utils';

const ResultsSummary = ({ testType, resultControl }) => {
  return (
    <React.Fragment>
      {testType && testType === testTypes.EIA ? (
        <>
          <tr>
            <td className="px-0">
              <strong>Positive Control</strong>
            </td>
            <td className="px-0">{resultControl.antigenODPositiveControl}</td>
            <td className="px-0">
              {resultControl.positiveControlTestInterpretation === customTestInterpretation.NA
                ? 'Not Applicable'
                : strCapitalize(true, resultControl.positiveControlTestInterpretation)}
            </td>
            <td className="px-0" />
          </tr>
          <tr>
            <td className="px-0">
              <strong>Negative Control</strong>
            </td>
            <td className="px-0">{resultControl.antigenODNegativeControl}</td>
            <td className="px-0">
              {resultControl.negativeControlTestInterpretation === customTestInterpretation.NA
                ? 'Not Applicable'
                : strCapitalize(true, resultControl.negativeControlTestInterpretation)}
            </td>
            <td className="px-0" />
          </tr>
          <tr>
            <td className="px-0">
              <strong>Kit Cut-Off Formula</strong>
            </td>
            <td className="px-0">{resultControl.kitCutOffFormula}</td>
            <td className="px-0" />
            <td className="px-0" />
          </tr>
          <tr>
            <td className="px-0">
              <strong>Kit Cut-Off Value</strong>
            </td>
            <td className="px-0">{resultControl.kitCutOff}</td>
            <td className="px-0" />
            <td className="px-0" />
          </tr>
        </>
      ) : (
        <>
          <tr>
            <td className="px-0">
              <strong>Positive Control</strong>
            </td>
            <td className="px-0">{resultControl.gGenotypeVP7PositiveControl}</td>
            <td className="px-0">{resultControl.pGenotypeVP4PositiveControl}</td>
            <td className="px-0">{resultControl.finalGenotypePositiveControl}</td>
            <td className="px-0" />
          </tr>
          <tr>
            <td className="px-0">
              <strong>Negative Control</strong>
            </td>
            <td className="px-0">{resultControl.gGenotypeVP7NegativeControl}</td>
            <td className="px-0">{resultControl.pGenotypeVP4NegativeControl}</td>
            <td className="px-0">{resultControl.finalGenotypeNegativeControl}</td>
            <td className="px-0" />
          </tr>
        </>
      )}
    </React.Fragment>
  );
};

export default ResultsSummary;
