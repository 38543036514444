/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';

const InputEIA = ({
  field,
  form: { touched, errors },
  labelTxt,
  labelId,
  handleChange,
  labelContainerClassName,
  inputContainerClassName,
  extraInputClass,
  ...props
}) => {
  const isNotValid = touched[field.name] && errors[field.name];

  return (
    <>
      <div className={labelContainerClassName}>
        <label htmlFor={labelId} className="text-muted mb-0">
          {labelTxt}
        </label>
      </div>
      <div className={inputContainerClassName}>
        <input
          id={labelId}
          type="text"
          {...field}
          value={field.value || ''}
          {...props}
          className={
            isNotValid
              ? `form-control text-center text-muted is-invalid ${extraInputClass || ''}`
              : `form-control text-center text-muted ${extraInputClass || ''}`
          }
          onChange={handleChange}
        />
      </div>
    </>
  );
};

export default InputEIA;
