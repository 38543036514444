import React from 'react';
import { connect } from 'redux-bundler-react';
import { strCapitalize } from '../../utils';
import AdminStudyNotesForm from '../forms/admin-study-notes-form/AdminStudyNotesForm';

const EnrollmentCard = ({
  enrollment,
  currentLabEnrollmentPoc,
  currentLab,
  currentLabHasTestFinalized = false,
  currentLabSomeTestResultsSubmitted = false,
  currentLabSomeTestResultsSubmittedOrFinalizedOrUnlocked = false,
  currentLabEnrollmentDetails,
}) => {
  return (
    <div className="card">
      <div className="card-header">
        <p>
          <strong>Laboratory Name</strong>
        </p>
        <p className="text-secondary">
          {currentLab ? `${currentLab.laboratoryName}` : enrollment.LabId}
        </p>
      </div>

      <div className="card-body">
        <div className="form-row">
          <div className="col-12 col-sm-6 col-lg-4">
            <p>
              <strong>Enrolled By</strong>
            </p>
            <p className="text-secondary">
              {currentLabEnrollmentPoc
                ? `${currentLabEnrollmentPoc.firstName} ${currentLabEnrollmentPoc.lastName}`
                : enrollment.enrolledBy}
            </p>
          </div>
          <div className="col-12 col-sm-6 col-lg-4">
            <p>
              <strong>Enroll Date</strong>
            </p>
            <p className="text-secondary">{new Date(enrollment.enrollDate).toDateString()}</p>
          </div>
          <div className="col-12 col-sm-6 col-lg-4">
            <p>
              <strong>Shipment Status</strong>
            </p>
            <p className="text-secondary">{strCapitalize(true, enrollment.shipmentStatus)}</p>
          </div>

          <div className="col-12 col-sm-6 col-lg-4">
            <p>
              <strong>Panel Sent Date</strong>
            </p>
            <p className="text-secondary">
              {enrollment.panelSentDate
                ? new Date(enrollment.panelSentDate).toDateString()
                : 'Panel not send yet'}
            </p>
          </div>
          <div className="col-12 col-sm-6 col-lg-4">
            <p>
              <strong>Panel Received Date</strong>
            </p>
            <p className="text-secondary">
              {enrollment.panelSentDate
                ? new Date(enrollment.panelReceivedDate).toDateString()
                : 'Panel has not been received'}
            </p>
          </div>
          <div className="col-12 col-sm-6 col-lg-4">
            <p>
              <strong>Date Of Manufacture</strong>
            </p>
            <p className="text-secondary">
              {enrollment.dateOfManufacture
                ? new Date(enrollment.dateOfManufacture).toDateString()
                : ''}
            </p>
          </div>
        </div>
        <hr />
        <AdminStudyNotesForm
          labId={currentLabEnrollmentDetails.LabParticipantId}
          enrollmentId={currentLabEnrollmentDetails.id}
          notes={currentLabEnrollmentDetails.adminNote}
        />
      </div>
      {currentLabHasTestFinalized && (
        <div className="card-footer">
          <a
            href={`/labs/${enrollment.LabParticipantId}/enrollments/${enrollment.id}/studyResultsReport`}
            title="Go to the Result Report"
          >
            <strong>Check Results Report</strong>
          </a>
        </div>
      )}
      {!currentLabHasTestFinalized && currentLabSomeTestResultsSubmittedOrFinalizedOrUnlocked && (
        <div className="card-footer">
          <a
            href={`/labs/${enrollment.LabParticipantId}/enrollments/${enrollment.id}/studyResultsReportPreview`}
            title="Go to the Result Report"
          >
            <strong>Preview Results Report</strong>
          </a>
        </div>
      )}
    </div>
  );
};

export default connect(
  'selectCurrentLabEnrollmentPoc',
  'selectCurrentLabEnrollmentDetails',
  'selectCurrentLab',
  EnrollmentCard,
);
