import omit from 'lodash/omit';
import { has } from '../../utils';
import { expectedTestResultsTypes, testInterpretationStoreResult } from '../../helpers/constants';

/**
 * [Get the genotype given a sum code in the expected results]
 *
 * @param   Array  gen      [Genotype Expected array]
 * @param   Number  sumCode  [sum Code]
 *
 * @return  {Object}           [the matched genotyped with sum code]
 */
function getGenotype(gen, sumCode) {
  let result;
  gen.forEach(geno => {
    if (geno.sumCode === sumCode) {
      result = { ...geno };
    }
  });
  return result;
}

/**
 * [This function was set the expected results table given eia records and genotype]
 *
 * @param   {Array}  eia       [eia records]
 * @param   {Array}  genotype  [genotype records]
 *
 * @return  {Array}                   [Return the array with the expected results]
 */
function setExpectedResultsValuesForTable(eia = [], genotype = []) {
  return eia.map(({ sumCode, eiaResult }) => ({
    sumCode,
    eiaResult,
    expectedGenotype: getGenotype(genotype, sumCode),
  }));
}
/**
 *
 * @param {Array} expectedResultList The array of expected result for a study
 * @returns {Object} The entire object with the data of eia and genotype
 */
const useFormatExpectedResultsResponse = (expectedResultList = []) => {
  let data = {};
  expectedResultList.forEach(exp => {
    if (has.call(exp, expectedTestResultsTypes.EIATest)) {
      const { positive, negative } = exp.EIATest;

      data = {
        eIAExpectedResults: [
          ...positive.map(code => ({
            sumCode: code,
            eiaResult: testInterpretationStoreResult.POSITIVE,
          })),
          ...negative.map(code => ({
            sumCode: code,
            eiaResult: testInterpretationStoreResult.NEGATIVE,
          })),
        ],
        ...omit(exp.EIATest, ['positive', 'negative', 'equivocal']),
        genotypeExpectedResults: expectedResultList.find(expItem =>
          has.call(expItem, 'GenotypingTest'),
        ),
      };
    }
  });

  if (data.eIAExpectedResults?.length) {
    return [
      {
        ...data,
        expectedResults: setExpectedResultsValuesForTable(
          data.eIAExpectedResults,
          data.genotypeExpectedResults?.GenotypingTest,
        ),
      },
    ];
  }

  return [null];
};

export default useFormatExpectedResultsResponse;
