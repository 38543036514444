/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-spread */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { connect } from 'redux-bundler-react';
import isEmpty from 'lodash/isEmpty';
import { otherOptionsForSelect, enrolledTestStatus } from '../../../helpers/constants';
import useModal from '../../hooks/useModal';
import Modal from '../../lib/Modal';
import ConfirmEIATestSubmission from '../../lib/ConfirmEIATestSubmission';
import Input from '../Input';
import Row from '../../lib/Row';
import Select from './Select';
import TextArea from '../../lib/TextArea';
import { useCurrentEIADataHook } from '../../hooks/useCurrentDataHook';

import EditableRows from './EditableRows';
import DatePicker from './DatePicker';
import HeadTable from './HeadTable';
import InputField from './InputField';
import RowNumericInput from './RowNumericInput';
import eIAResultsErrorString from './EIAResultsErrorString';

import { EiaSchema, initEIAValues } from '../../form-schemas/testsSchemas';
import customRowSelect from './customRowSelect';

const EiaResults = ({ amplificationKitsData, routeParams, currentAnswers }) => {
  const { showing, toggleVisibility, setModalTitle, title } = useModal();
  const [data, setData] = useState(null);
  const currentData = useCurrentEIADataHook(currentAnswers || {});
  const currentStatus = currentAnswers?.status;

  const callModal = () => {
    setModalTitle('Test Result Confirmation');
    toggleVisibility();
  };

  const onCancel = () => {
    toggleVisibility();
  };

  function renderOtherAmplificationKitBrandsNameField(kitId) {
    return kitId && +kitId === otherOptionsForSelect.id ? (
      <Field
        type="text"
        name="otherAmplificationKitBrand"
        placeholder="Other Kit's Brand Name"
        label="Other"
        col="3"
        component={Input}
      />
    ) : null;
  }

  const handleSubmit = (values, { setSubmitting }) => {
    setData(values);
    callModal();
    setSubmitting(false);
  };

  return (
    <React.Fragment>
      {currentAnswers?.EIATest && currentStatus === enrolledTestStatus.UNLOCKED && (
        <div className="text-danger text-sm-right">
          <p className="mb-3">
            <i className="fe fe-unlock" />
            <small> This test has been unlocked by the admin</small>
          </p>
        </div>
      )}
      <Formik
        enableReinitialize
        initialValues={isEmpty(currentData) ? initEIAValues : currentData}
        validationSchema={EiaSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, dirty, isSubmitting, handleChange }) => (
          <Form className="mb-3">
            <div className="card">
              <div className="card-body">
                <Row>
                  <Field
                    type="text"
                    name="investigator"
                    placeholder="Investigator"
                    label="Investigator"
                    col="6"
                    component={Input}
                  />
                  <Field
                    name="testDate"
                    label="Test Date"
                    placeholder="Test Date"
                    component={DatePicker}
                    col="6"
                  />
                </Row>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <Row>
                  <Field
                    name="AmplificationKitId"
                    component={Select}
                    col={
                      values.AmplificationKitId &&
                      +values.AmplificationKitId === otherOptionsForSelect.id
                        ? '3'
                        : '6'
                    }
                    options={
                      amplificationKitsData
                        ? Object.values({
                            ...amplificationKitsData,
                            [otherOptionsForSelect.id]: otherOptionsForSelect,
                          })
                        : {}
                    }
                  />

                  {renderOtherAmplificationKitBrandsNameField(values.AmplificationKitId)}
                  <Field
                    type="text"
                    name="vendorName"
                    placeholder="Vendor's name"
                    label="Vendor's name"
                    col="6"
                    component={Input}
                  />
                </Row>
                <Row>
                  <Field
                    type="text"
                    name="serialNumber"
                    placeholder="Batch/Lot No."
                    label="Batch/Lot No."
                    col="6"
                    component={Input}
                  />

                  <Field
                    name="expiryDate"
                    label="Expire Date"
                    placeholder="Select a expire Date"
                    component={DatePicker}
                    col="6"
                  />
                </Row>
              </div>
            </div>
            <div className="card">
              <div className="table-responsive">
                <table className="table table-sm table-nowrap table-outline card-table">
                  <HeadTable />
                  <tbody>
                    <EditableRows handleChange={handleChange} />
                    <tr>
                      <td className="align-middle border border-light text-secondary">
                        Positive Control
                      </td>
                      <td className="border border-light">
                        <div className="input-group input-group-lg input-group-flush">
                          <Field
                            name="antigenODPositiveControl"
                            className="form-control text-secondary result-row-input-numeric"
                            component={RowNumericInput}
                          />
                        </div>
                      </td>
                      <td className="border border-light text-secondary">
                        <Field
                          name="positiveControlTestInterpretation"
                          component={customRowSelect}
                          handleChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle border border-light text-secondary">
                        Negative Control
                      </td>

                      <td className="border border-light ">
                        <div className="input-group input-group-lg input-group-flush">
                          <Field
                            name="antigenODNegativeControl"
                            className="form-control text-secondary result-row-input-numeric"
                            component={RowNumericInput}
                          />
                        </div>
                      </td>
                      <td className="border border-light text-secondary">
                        <Field
                          name="negativeControlTestInterpretation"
                          component={customRowSelect}
                          handleChange={handleChange}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="align-middle border border-light text-secondary">
                        <span className="text-danger">* </span>Kit Cut Off Formula
                      </td>
                      <td className="border border-light">
                        <div className="input-group input-group-lg input-group-flush">
                          <Field type="text" name="kitCutOffFormula" component={InputField} />
                        </div>
                      </td>
                      <td className="border border-light" />
                    </tr>

                    <tr>
                      <td className="align-middle border border-light text-secondary">
                        Kit Cut Off Value
                      </td>
                      <td className="border border-light">
                        <div className="input-group input-group-lg input-group-flush">
                          <Field
                            name="kitCutOff"
                            className="form-control text-secondary result-row-input-numeric"
                            component={RowNumericInput}
                          />
                        </div>
                      </td>
                      <td className="border border-light" />
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <h4>Justification Notes</h4>
                <Field
                  name="justificationNotes"
                  classNameParent="col-12 p-0"
                  textPlaceHolder="Optional: notes justifying late submission to the study administrators"
                  component={TextArea}
                />
              </div>
            </div>
            <div className="row flex-column flex-md-row justify-content-md-between align-items-md-center">
              <div className="col-auto mb-3 mb-md-0">
                <small className="text-muted">
                  <span className="text-danger">* </span>
                  The formula used to calculate the Kit&apos;s Cut Off Value.
                </small>
              </div>
              <div className="col-auto ">
                <button type="submit" className="btn btn-primary" disabled={!dirty || isSubmitting}>
                  Submit Results
                </button>
                <a
                  href={`/enrolled-studies/${routeParams.enrollmentId}/submit-tests`}
                  className="btn btn-danger ml-3"
                >
                  Cancel
                </a>
                {eIAResultsErrorString(errors)}
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <Modal.Centered showing={showing} hide={toggleVisibility} title={title}>
        <ConfirmEIATestSubmission
          routeParams={routeParams}
          otherOptionsForSelect={otherOptionsForSelect}
          valuesData={data}
          currentStatus={currentStatus}
          onCancelCallback={onCancel}
        />
      </Modal.Centered>
    </React.Fragment>
  );
};
// export default EiaResults;
export default connect('selectAmplificationKitsData', 'selectRouteParams', EiaResults);
