import React from 'react';
import { connect } from 'redux-bundler-react';

import ExpectedResultForm from '../forms/expected-results-forms/eia-form/ExpectedResultForm';
import {
  initExpectedResultsValues,
  ExpectedResultSchema,
} from '../form-schemas/expectedTestsResultsSchema';

const ExpectedResultsPage = ({
  routeParams,
  expectedTestResultForStudy,
  doCreateEIAExpectedResults,
  doCreateGenotypeExpectedResults,
  doUpdateEIAExpectedResults,
  doUpdateGenotypeExpectedResults,
  doUpdateAllTheExpectedResultsByStudyId,
  doCreateAllTheExpectedResultsByStudyId,
  doUpdateEiaAndCreateGenotypeExpectedResultsByStudyId,
  doDeleteGenotypingRecordsExpectedResults,
}) => {
  const { studyId } = routeParams;

  return (
    <ExpectedResultForm
      submitText="Save Expected Results"
      schema={ExpectedResultSchema}
      initValues={initExpectedResultsValues}
      studyId={studyId}
      expectedTestResultForStudy={expectedTestResultForStudy}
      createEIA={doCreateEIAExpectedResults}
      createGenotype={doCreateGenotypeExpectedResults}
      updateEIA={doUpdateEIAExpectedResults}
      updateGenotype={doUpdateGenotypeExpectedResults}
      updateAllTheExpectedResults={doUpdateAllTheExpectedResultsByStudyId}
      createAllTheExpectedResults={doCreateAllTheExpectedResultsByStudyId}
      updateEiaAndCreateGenotype={doUpdateEiaAndCreateGenotypeExpectedResultsByStudyId}
      deleteGenotypeRecords={doDeleteGenotypingRecordsExpectedResults}
    />
  );
};

export default connect(
  'selectRouteParams',
  'selectExpectedTestResultForStudy',
  'doCreateEIAExpectedResults',
  'doCreateGenotypeExpectedResults',
  'doUpdateEIAExpectedResults',
  'doUpdateGenotypeExpectedResults',
  'doUpdateAllTheExpectedResultsByStudyId',
  'doCreateAllTheExpectedResultsByStudyId',
  'doUpdateEiaAndCreateGenotypeExpectedResultsByStudyId',
  'doDeleteGenotypingRecordsExpectedResults',
  ExpectedResultsPage,
);
