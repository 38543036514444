/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-spread */
import React, { useState } from 'react';
import { Formik, FieldArray, Form, Field } from 'formik';
import { connect } from 'redux-bundler-react';

// Genotype Components
import AmplificationCard from './AmplificationCard';
import ListGroup from './ListGroup';
import GenotypeResultsErrorString from './GenotypeResultsErrorString';
import Input from '../Input';
import DatePicker from '../EIA/DatePicker';
import ListGroupTable from './ListGroupTable';
import ListItemInput from './ListItemInput';

// Lib
import Row from '../../lib/Row';
import ConfirmGenotypeTestSubmission from '../../lib/ConfirmGenotypeTestSubmission';
import Modal from '../../lib/Modal';
import TextArea from '../../lib/TextArea';

// Hooks
import useModal from '../../hooks/useModal';
import {
  useGenotypeSampleIdsHook,
  useCurrentGenotypeDataHook,
} from '../../hooks/useCurrentDataHook';

// Utils, Constants and Schemas
import { initGenotypingValues, GenotypingSchema } from '../../form-schemas/testsSchemas';
import { enrolledTestStatus } from '../../../helpers/constants';

const GenotypingResults = ({
  amplificationKitsData,
  submittedEIASampleIds,
  routeParams,
  currentAnswers,
}) => {
  const { showing, toggleVisibility, setModalTitle, title } = useModal();
  const [data, setData] = useState();
  const [formValues, setFormValues] = useState();
  const initValuesWithSamples = useGenotypeSampleIdsHook(submittedEIASampleIds || {});
  const currentData = useCurrentGenotypeDataHook(currentAnswers || {});
  const currentStatus = currentAnswers?.status;

  const callModal = () => {
    setModalTitle('Test Result Confirmation');
    toggleVisibility();
  };

  const onCancel = () => {
    toggleVisibility();
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setFormValues(values);
    callModal();
    setSubmitting(false);
  };

  return (
    <React.Fragment>
      {currentAnswers?.GenotypingTest && currentStatus === enrolledTestStatus.UNLOCKED && (
        <div className="text-danger text-sm-right">
          <p className="mb-3">
            <i className="fe fe-unlock" />
            <small> This test has been unlocked by the admin</small>
          </p>
        </div>
      )}
      <Formik
        enableReinitialize
        initialValues={initValuesWithSamples || currentData || initGenotypingValues}
        validationSchema={GenotypingSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors }) => (
          <React.Fragment>
            <div className="card">
              <div className="card-body">
                <Row>
                  <Field
                    type="text"
                    name="investigator"
                    placeholder="Investigator"
                    label="Investigator"
                    col="6"
                    component={Input}
                  />
                  <Field
                    name="testDate"
                    label="Test Date"
                    placeholder="Test Date"
                    component={DatePicker}
                    col="6"
                  />
                </Row>
              </div>
            </div>
            <Row>
              <FieldArray
                name="amplificationRounds"
                render={({ push }) =>
                  Array.apply(null, { length: 1 }).map((e, i) => (
                    <AmplificationCard
                      key={`card-${i.toString()}`}
                      kitsData={amplificationKitsData}
                      initValues={{ ...data, AmplificationKitId: 0 }}
                      pushData={push}
                      clearData={setData}
                    />
                  ))
                }
              />
              <div className="col-12 col-xl-4">
                <FieldArray
                  name="amplificationRounds"
                  render={({ remove }) =>
                    Array.apply(null, { length: 1 }).map((e, i) => (
                      <ListGroup
                        key={`list-${i.toString()}`}
                        currentKitData={amplificationKitsData}
                        items={values.amplificationRounds}
                        removeItem={remove}
                        editItem={setData}
                        errorBadge={errors}
                      />
                    ))
                  }
                />
              </div>
            </Row>
            <Form>
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive pt-4 mb-4">
                    <ListGroupTable
                      isSampleFill={submittedEIASampleIds?.isSubmitted}
                      sampleId={submittedEIASampleIds?.submittedSamples}
                    />
                    <ul className="list-group list-group-horizontal-lg double-margin-bottom">
                      <li className="list-group-item d-lg-flex w-lg-20">
                        <span className="font-weight-bold my-auto text-muted">Positive</span>
                      </li>
                      <Field
                        component={ListItemInput}
                        className="list-group-item flex-fill"
                        name="gGenotypeVP7PositiveControl"
                        title="G-Genotype (VP7)"
                      />
                      <Field
                        component={ListItemInput}
                        className="list-group-item flex-fill"
                        name="pGenotypeVP4PositiveControl"
                        title="P-Genotype (VP4)"
                      />
                      <Field
                        component={ListItemInput}
                        className="list-group-item flex-fill"
                        name="finalGenotypePositiveControl"
                        title="Final Genotype"
                      />
                    </ul>
                    <ul className="list-group list-group-horizontal-lg double-margin-bottom">
                      <li className="list-group-item d-lg-flex w-lg-20">
                        <span className="font-weight-bold my-auto text-muted">Negative</span>
                      </li>
                      <Field
                        component={ListItemInput}
                        className="list-group-item flex-fill"
                        name="gGenotypeVP7NegativeControl"
                        title="G-Genotype (VP7)"
                      />
                      <Field
                        component={ListItemInput}
                        className="list-group-item flex-fill"
                        name="pGenotypeVP4NegativeControl"
                        title="P-Genotype (VP4)"
                      />
                      <Field
                        component={ListItemInput}
                        className="list-group-item flex-fill"
                        name="finalGenotypeNegativeControl"
                        title="Final Genotype"
                      />
                    </ul>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <h4>Justification Notes</h4>
                  <Field
                    name="justificationNotes"
                    classNameParent="col-12 p-0"
                    textPlaceHolder="Optional: notes justifying late submission to the study administrators"
                    component={TextArea}
                  />
                </div>
              </div>
              <div className="row justify-content-end">
                <div className="col-auto">
                  <button type="submit" className="btn btn-primary">
                    Submit Results
                  </button>
                </div>
                <div className="col-auto">
                  <a
                    href={`/enrolled-studies/${routeParams.enrollmentId}/submit-tests`}
                    className="btn btn-danger"
                  >
                    Cancel
                  </a>
                </div>
              </div>
              <GenotypeResultsErrorString errors={errors} />
            </Form>
          </React.Fragment>
        )}
      </Formik>
      <Modal.Centered showing={showing} hide={toggleVisibility} title={title}>
        <ConfirmGenotypeTestSubmission
          routeParams={routeParams}
          valuesData={formValues}
          currentStatus={currentStatus}
          onCancelCallback={onCancel}
        />
      </Modal.Centered>
    </React.Fragment>
  );
};

export default connect(
  'selectAmplificationKitsData',
  'selectSubmittedEIASampleIds',
  'selectRouteParams',
  GenotypingResults,
);
