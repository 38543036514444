import { useState, useEffect, useCallback } from 'react';
import { formatedLongDate, getScoreAndInterpretationForTests } from '../../utils';
import { testTypes } from '../../helpers/constants';

/**
 * [This return some formatted data for the Persons, Dates and Interpretation]
 *
 * @param   {[Object]}  testResults  [The Test Results of the Report]
 * @param   {Number}  minLabScore  [minLabScore value for passing the test]
 *
 * @return  {[Array]}               [Some formatted values]
 */
const useReportTestData = (testResults, minLabScore) => {
  const [usersInfo, setUsersInfo] = useState([]);
  const [datesInfo, setDatesInfo] = useState([]);
  const [interpretations, setInterpretations] = useState([]);

  // Filter out empty objects
  const validTestResults = Object.entries(testResults).reduce((result, [key, value]) => {
    if (Object.keys(value).length > 0) {
      result[key] = value;
    }
    return result;
  }, {});

  const setData = () => {
    const users = [];
    const dates = [];
    const interpretationData = [];

    Object.keys(validTestResults).forEach(test => {
      users.push({
        title:
          testResults[test].type === testTypes.EIA
            ? 'Name of Person Who Submitted the EIA Results Report'
            : 'Name of Person Who Submitted the Genotyping Results Report',
        user: `${testResults[test]?.submittedBy?.firstName} ${testResults[test]?.submittedBy?.lastName}`,
        type: testResults[test].type,
      });

      dates.push({
        title:
          testResults[test]?.type === testTypes.EIA
            ? 'Date EIA Report Received by GRL'
            : 'Date Genotyping Report Received by GRL',
        type: testResults[test]?.type,
        date: formatedLongDate(new Date(testResults[test]?.submittedAt)),
      });

      const getInterpretation =
        testResults[test]?.type === testTypes.EIA
          ? getScoreAndInterpretationForTests(
              () =>
                testResults[test]?.gradedResults.reduce(
                  (acc, value) => acc + value.finalScore.value,
                  0,
                ),
              minLabScore,
            )
          : getScoreAndInterpretationForTests(
              () =>
                testResults[test]?.gradedResults.reduce(
                  (acc, value) => acc + value.finalScore.value,
                  0,
                ),
              minLabScore,
            );

      interpretationData.push({
        title: `${
          testResults[test]?.type === testTypes.EIA ? 'EIA' : 'Genotyping'
        } Test Results Score`,
        score: `${
          testResults[test]?.type === testTypes.EIA
            ? getInterpretation.score
            : getInterpretation.score
        }`,
        interpretation: `${
          testResults[test]?.type === testTypes.EIA
            ? getInterpretation.interpretation
            : getInterpretation.interpretation
        }`,
        type: testResults[test]?.type,
      });
    });

    setUsersInfo(users);
    setDatesInfo(dates);
    setInterpretations(interpretationData);
  };

  const memoizedSetData = useCallback(() => {
    setData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testResults]);

  useEffect(() => {
    memoizedSetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testResults]);

  return [usersInfo, datesInfo, interpretations];
};

export default useReportTestData;
