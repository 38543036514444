/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'redux-bundler-react';

import TestToSubmitCard from '../lib/TestToSubmitCard';
import NoDataAndRedirect from '../lib/NoDataAndRedirect';

const SubmitTestPage = ({ testsToSubmit, enrollmentList, routeParams }) => {
  const [tests, setTests] = useState([]);
  const studyData = useCallback(() => {
    const data = enrollmentList.filter(
      enroll => enroll.Enrollment.id === +routeParams.enrollmentId,
    );
    if (!data.length) {
      return { name: '', year: '' };
    }
    return data[0];
  }, [enrollmentList, routeParams.enrollmentId]);

  useEffect(() => {
    setTests([]);
    if (testsToSubmit && testsToSubmit.length) {
      setTests(testsToSubmit);
    }
  }, [testsToSubmit]);

  function renderTests() {
    return tests.map(test => <TestToSubmitCard key={test.id} test={test} study={studyData()} />);
  }

  return (
    <div className="row align-items-center justify-content-center">
      {tests.length ? renderTests() : <NoDataAndRedirect />}
    </div>
  );
};

export default connect(
  'selectTestsToSubmit',
  'selectEnrollmentList',
  'selectRouteParams',
  SubmitTestPage,
);
