/* eslint-disable prefer-spread */
import * as Yup from 'yup';
import orderBy from 'lodash/orderBy';

/**
 * Genotype Graded Results schema, this is to validate the schema when finalize a test
 */
const GenotypeGradedResultSchema = Yup.object().shape({
  id: Yup.object().shape({
    value: Yup.number().required('This id value is required'),
  }),
  correct: Yup.object().shape({
    value: Yup.string().required('This correct value is required'),
  }),
  suggestedScore: Yup.object().shape({
    value: Yup.number().required('The suggested score value is required'),
  }),
  finalScore: Yup.object().shape({
    value: Yup.number()
      .typeError('Must be a number')
      .min(0)
      .max(10)
      .required('The Final Score value is required'),
  }),
});

const EIAGradedResultSchema = Yup.object().shape({
  id: Yup.object().shape({
    value: Yup.number().required('This id value is required'),
  }),
  score: Yup.object().shape({
    value: Yup.number().required('The suggested score value is required'),
  }),
  finalScore: Yup.object().shape({
    value: Yup.number()
      .typeError('Must be a number')
      .min(0)
      .max(10)
      .required('The Final Score value is required'),
  }),
});

const getInitialValues = data =>
  ({
    gradedResults: orderBy(data?.gradedResults, ['id.value']),
  } || { gradedResults: [] });

/**
 * This is the genotype scoring schema when the test is finalized  by the admin
 */
const GenotypeScoringSchema = Yup.object().shape({
  gradedResults: Yup.array()
    .of(GenotypeGradedResultSchema)
    .min(10)
    .max(10),
});

const EIAScoringSchema = Yup.object().shape({
  gradedResults: Yup.array()
    .of(EIAGradedResultSchema)
    .min(10)
    .max(10),
});

// eslint-disable-next-line import/prefer-default-export
export { GenotypeScoringSchema, EIAScoringSchema, getInitialValues };
