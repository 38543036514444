import * as Yup from 'yup';
import { monthNames } from '../../helpers/constants';

export const kitVerificationInitValues = {
  LabParticipantId: '',
  shipmentStatus: '',
  panelReceivedDate: '',
  kitSerialNumber: '',
  cboMonth: '',
  cboYear: '',
};

const StudiesSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  year: Yup.string()
    .matches(/^[0-9]{4}$/, {
      message: 'Year not valid',
    })
    .required('Year is required'),
  startDate: Yup.date().required('Start date is required'),
  endDate: Yup.date().nullable(true),
  cboMonth: Yup.string()
    // .required('Should be a valid month')
    .oneOf(monthNames, 'Should be a valid month'),
  cboYear: Yup.string(),
  // .required('Should be a valid year'),
  panelManufactureDate: Yup.date().nullable(true),
  notes: Yup.string(),
});

export const ShippingStatusSchema = Yup.object().shape({
  cboMonth: Yup.string()
    .required('Should be a valid month')
    .oneOf(monthNames, 'Should be a valid month'),
  cboYear: Yup.string().required('Should be a valid year'),
  kitSerialNumber: Yup.string(),
  panelReceivedDate: Yup.date().required('This Field is required'),
});

export default StudiesSchema;
