import { createSelector } from 'redux-bundler';

// const ERROR_TIME = 15000;
// const REFRESH_TIME = 300000;
const ActionTypes = {
  FETCH_START: 'FETCH_TESTS_START',
  FETCH_TESTS_ERROR: 'FETCH_TESTS_ERROR',
  SUBMIT_TEST_SUCCESS: 'SUBMIT_TEST_SUCCESS',
};

export default {
  name: 'tests',
  getReducer: () => {
    const initialState = {
      loading: false,
      lastError: null,
      lastFetch: null,
      data: null,
      error: null,
    };
    // Reducers

    return (state = initialState, { type, payload }) => {
      switch (type) {
        case ActionTypes.FETCH_START:
          return { ...state, loading: true };

        case ActionTypes.FETCH_TESTS_ERROR:
          return {
            ...state,
            lastError: Date.now(),
            loading: false,
            error: payload,
          };

        case ActionTypes.SUBMIT_TEST_SUCCESS:
          return {
            ...state,
            lastFetch: Date.now(),
            loading: false,
            lastError: null,
            error: null,
            data: payload,
          };

        default:
          return state;
      }
    };
  },
  // selectors
  selectTestsDataRaw: state => state.tests,
  selectTestsList: createSelector('selectTestsDataRaw', testsData =>
    testsData.data ? Object.values(testsData.data) : [],
  ),

  // Actions Creators
  doSubmitTest: data => ({ dispatch, apiFetch, getState }) => {
    dispatch({ type: ActionTypes.FETCH_START });
    const labId = getState().user.profile.role.LabParticipant.id;

    return apiFetch({
      method: 'post',
      endpoint: `enrollments/${data.enrolledStudyId}/tests/${data.testId}/results`,
      data: data.payload,
      alertOnFail: true,
    })
      .then(payload => {
        dispatch({
          actionCreator: 'doCreateAlert',
          args: [
            {
              msg: `Test has been successfully submitted.`,
              title: 'Test Submission Info',
            },
          ],
        });
        dispatch({ actionCreator: 'doFetchEnrollList', args: [labId] });
        dispatch({
          actionCreator: 'doUpdateUrl',
          args: [`/enrolled-studies/${+data.enrolledStudyId}/submit-tests`],
        });

        return payload;
      })
      .catch(error => {
        dispatch({
          type: ActionTypes.CREATE_ERROR,
          payload: 'An error has occurred while trying to submit the Test.',
        });
        dispatch({
          actionCreator: 'doCreateAlert',
          args: [
            {
              msg: error.message,
              title: 'Submission Error',
            },
          ],
        });
        throw error;
      });
  },

  doUpdateTest: data => ({ dispatch, apiFetch, getState }) => {
    dispatch({ type: ActionTypes.FETCH_START });
    const labId = getState().user.profile.role.LabParticipant.id;

    return apiFetch({
      method: 'PATCH',
      endpoint: `enrollments/${data.enrolledStudyId}/tests/${data.testId}/results`,
      data: data.payload,
      alertOnFail: true,
    })
      .then(payload => {
        dispatch({
          actionCreator: 'doCreateAlert',
          args: [
            {
              msg: 'Test has been successfuly updated',
              title: 'Test Submission Info',
            },
          ],
        });
        dispatch({ actionCreator: 'doFetchEnrollList', args: [labId] });
        dispatch({ actionCreator: 'doFetchEnrolledTestsList', args: [labId] });
        dispatch({
          actionCreator: 'doUpdateUrl',
          args: [`/enrolled-studies/${+data.enrolledStudyId}/submit-tests`],
        });

        return payload;
      })
      .catch(error => {
        dispatch({
          type: ActionTypes.CREATE_ERROR,
          payload: 'An error has occurred while updating the Test',
        });
        dispatch({
          actionCreator: 'doCreateAlert',
          args: [
            {
              msg: error.message,
              title: 'Updating Error',
            },
          ],
        });
        throw error;
      });
  },
};
