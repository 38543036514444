import React from 'react';

const InputField = ({ field, type, form: { errors, touched }, ...props }) => {
  const isNotValid = touched[field.name] && errors[field.name];
  return (
    <>
      <input type={type} {...props} {...field} className="form-control text-secondary" />
      {isNotValid && (
        <div className="invalid-feedback mb-2" style={{ display: 'block' }}>
          {errors[field.name]}
        </div>
      )}
    </>
  );
};

export default InputField;
