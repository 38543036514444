import React from 'react';

const LabStudyCard = ({ testName, year, startDate, endDate, testStatuses, participants }) => {
  const list = () => {
    return Object.keys(testStatuses).map(item => {
      return (
        <li
          key={testStatuses[item].id}
          className="list-group-item d-flex align-items-center justify-content-between px-0"
        >
          <small className="text-dark">
            {testStatuses[item].number} {testStatuses[item].text}
          </small>
          <i className={`fe fe-${testStatuses[item].icon} text-${testStatuses[item].class}`} />
        </li>
      );
    });
  };

  return (
    <div className="card">
      <div className="card-body">
        <h3 className="text-uppercase text-primary mb-1">{testName}</h3>

        <div className="display-3 mb-0 text-dark">{year}</div>

        <div className="d-flex flex-column flex-sm-row justify-content-between mb-2">
          <h4 className="text-primary">
            <i className="fe fe-calendar" /> {startDate} - {endDate}
          </h4>
          <h4 className="text-primary text-uppercase">{participants ?? '--'} Participants</h4>
        </div>

        <div>
          <ul className="list-group list-group-flush">{list()}</ul>
        </div>
      </div>
    </div>
  );
};

export default LabStudyCard;
