import * as Yup from 'yup';

const pocProfileInformationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  phoneNumber: Yup.string(),
  countryCode: Yup.string()
    .nullable()
    .when('phoneNumber', {
      is: val => val == null,
      then: Yup.string().matches(/^(?:[+][0-9]{1,2}[-][\d]{3,4})$|(?:[+][0-9]{1,3})$/, {
        message: 'Invalid country code',
      }),
      otherwise: Yup.string()
        .matches(/^(?:[+][0-9]{1,2}[-][\d]{3,4})$|(?:[+][0-9]{1,3})$/, {
          message: 'Invalid country code',
        })
        .required('Country code is required'),
    }),
  title: Yup.string(),
  email: Yup.string()
    .email()
    .required('Email Address is required'),
});

export default pocProfileInformationSchema;
