/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import format from 'date-fns/format';

const TestGeneralInfo = ({
  investigator,
  testDate,
  createdAt,
  createdBy: { firstName, lastName, email },
}) => {
  return (
    <React.Fragment>
      <div className="table-responsive">
        <table className="table table-sm table-nowrap text-center">
          <thead>
            <tr>
              <th scope="col ">Investigator</th>
              <th scope="col">Submitted By</th>
              <th scope="col">Submitter's Email</th>
              <th scope="col">Performed Test Date</th>
              <th scope="col">Submitted Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{investigator}</td>
              <td>{`${firstName} ${lastName}`}</td>
              <td>{email}</td>
              <td>{format(new Date(testDate), 'PP')}</td>
              <td>{format(new Date(createdAt), 'PP')}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default TestGeneralInfo;
