import { createSelector } from 'redux-bundler';

const ERROR_TIME = 15000;
const REFRESH_TIME = 600000;
const ROLE_ACCESS = ['ADMIN'];

/**
 * Redux Action Types
 * @type {Object}
 * @property {string} FETCH_START
 * @property {string} FETCH_ERROR
 * @property {string} FETCH_SUCCESS
 */
const ActionTypes = {
  FETCH_START: 'FETCH_REF_TESTS_START',
  FETCH_ERROR: 'FETCH_REF_TESTS_ERROR',
  FETCH_SUCCESS: 'FETCH_REF_TESTS_SUCCESS',
};

export default {
  name: 'refTests',
  getReducer: () => {
    /** The inital state of the bundle */
    const initialState = {
      loading: false,
      lastError: null,
      lastFetch: null,
      data: null,
      error: null,
    };

    /** The reducer */
    return (state = initialState, { type, payload }) => {
      switch (type) {
        case ActionTypes.FETCH_START:
          return { ...state, loading: true };

        case ActionTypes.FETCH_SUCCESS:
          return {
            ...state,
            lastFetch: Date.now(),
            loading: false,
            lastError: null,
            error: null,
            data: payload,
          };

        case ActionTypes.FETCH_ERROR:
          return {
            ...state,
            lastError: Date.now(),
            loading: false,
            error: payload,
          };

        default:
          return state;
      }
    };
  },

  /** Selectors */
  selectRefTestsDataRaw: state => state.refTests,
  selectRefTestsData: state => state.refTests.data,
  selectRefTestsList: createSelector('selectRefTestsDataRaw', refTestsData => {
    return refTestsData.data && Object.values(refTestsData.data).length !== 0
      ? Object.values(refTestsData.data)
      : [];
  }),
  selectRefTestsErrorMessage: createSelector('selectRefTestsDataRaw', refTestsDataRaw =>
    refTestsDataRaw.error && refTestsDataRaw.error.message ? refTestsDataRaw.message : null,
  ),

  /** Actions Creators */
  doFetchRefTestsList: () => ({ dispatch, apiFetch }) => {
    dispatch({ type: ActionTypes.FETCH_START });

    apiFetch({
      endpoint: 'tests',
    })
      .then(payload => {
        dispatch({
          type: ActionTypes.FETCH_SUCCESS,
          payload: payload.results.reduce((refTests, refTest) => {
            // eslint-disable-next-line no-param-reassign
            refTests[refTest.id] = refTest;
            return refTests;
          }, {}),
        });
      })
      .catch(error => {
        dispatch({ type: ActionTypes.FETCH_ERROR, payload: error });
      });
  },

  /** Reactors */
  reactShouldFetchRefTestsData: createSelector(
    'selectRefTestsDataRaw',
    'selectAppTime',
    'selectUserLogin',

    'selectUserRole',
    (refTestsData, appTime, userLogin, userRole) => {
      if (refTestsData.loading || !ROLE_ACCESS.includes(userRole)) {
        return null;
      }
      if (!userLogin || refTestsData.loading) {
        return null;
      }

      let shouldFetch = false;

      if (!refTestsData.data && !refTestsData.lastError) {
        shouldFetch = true;
      } else if (refTestsData.lastError) {
        const elapsedTime = appTime - refTestsData.lastError;
        if (elapsedTime > ERROR_TIME) {
          shouldFetch = true;
        }
      } else if (refTestsData.lastFetch) {
        const elapsedTime = appTime - refTestsData.lastFetch;
        if (elapsedTime > REFRESH_TIME) {
          shouldFetch = true;
        }
      }

      if (shouldFetch) {
        return { actionCreator: 'doFetchRefTestsList' };
      }
    },
  ),
};
