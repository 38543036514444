/* eslint-disable prefer-spread */
/* eslint-disable react/no-array-index-key */

import React from 'react';
import { Field, FieldArray } from 'formik';

import RowInput from './RowInput';
import RowNumericInput from './RowNumericInput';
import RowSelect from './RowSelect';

const EditableRows = ({ handleChange }) => {
  return (
    <React.Fragment>
      <FieldArray
        name="eiaResults"
        render={() =>
          Array.apply(null, { length: 10 }).map((e, i) => {
            return (
              <tr key={`row-${i}`}>
                <td className="border border-light">
                  <div className="input-group input-group-lg input-group-flush">
                    <Field
                      name={`eiaResults[${i}].sampleIDNumber`}
                      className="form-control text-secondary"
                      handleChange={handleChange}
                      component={RowInput}
                    />
                  </div>
                </td>

                <td className="border border-light">
                  <div className="input-group input-group-lg input-group-flush  ">
                    <Field
                      name={`eiaResults[${i}].antigenOD`}
                      className="form-control text-secondary result-row-input-numeric"
                      component={RowNumericInput}
                      handleChange={handleChange}
                    />
                  </div>
                </td>
                <td className="border border-light text-secondary">
                  <Field
                    name={`eiaResults[${i}].testInterpretation`}
                    component={RowSelect}
                    handleChange={handleChange}
                  />
                </td>
              </tr>
            );
          })
        }
      />
    </React.Fragment>
  );
};

export default EditableRows;
