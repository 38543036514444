import { createSelector } from 'redux-bundler';
import { roles, enrolledTestStatus } from '../helpers/constants';
import { has } from '../utils';

const ERROR_TIME = 15000;
const REFRESH_TIME = 300000;

const ActionTypes = {
  FETCH_START: 'FETCH_ENROLLED_TESTS',
  FETCH_ENROLLED_TESTS_SUMMARY_START: 'FETCH_ENROLLED_TESTS_SUMMARY_START',
  FETCH_ENROLLED_TESTS_SUMMARY: 'FETCH_ENROLLED_TESTS_SUMMARY',
  FETCH_ENROLLED_TESTS_ERROR: 'FETCH_ENROLLED_TESTS_ERROR',
  FETCH_ENROLLED_TESTS_SUCCESS: 'FETCH_ENROLLED_TESTS_SUCCESS',
  FETCH_ENROLLED_TESTS_ANSWERS_SUCCESS: 'FETCH_ENROLLED_TESTS_ANSWERS_SUCCESS',
  FETCH_ENROLLED_TESTS_SUMMARY_SUCCESS: 'FETCH_ENROLLED_TESTS_SUMMARY_SUCCESS',
  FETCH_ENROLLED_TESTS_SUMMARY_ERROR: 'FETCH_ENROLLED_TESTS_SUMMARY_ERROR',
  FETCH_ENROLLED_TESTS_ANSWERS_ERROR: 'FETCH_ENROLLED_TESTS_ANSWERS_ERROR',
  FETCH_CURRENT_TESTS_ANSWERS_SUCCESS: 'FETCH_CURRENT_TESTS_ANSWERS_SUCCESS',
  FETCH_CURRENT_TESTS_ANSWERS_ERROR: 'FETCH_CURRENT_TESTS_ANSWERS_ERROR',
  CLEAR: 'CLEAR',
};

export default {
  name: 'enrolledTests',
  getReducer: () => {
    const initialState = {
      loading: false,
      loadingSummary: false,
      lastError: null,
      lastFetch: null,
      data: null,
      error: null,
      answers: null,
      answersError: null,
      summary: null,
      summaryError: null,
      lastFetchSummary: null,
      lastErrorSummary: null,
      lastFetchAnswers: null,
      lastAnswerError: null,
      currentAnswersError: null,
      currentAnswers: null,
      lastCurrentTestFetch: null,
      lastCurrentTestError: null,
    };
    // Reducers

    return (state = initialState, { type, payload }) => {
      switch (type) {
        case ActionTypes.FETCH_START:
          return { ...state, loading: true };

        case ActionTypes.FETCH_ENROLLED_TESTS_SUMMARY_START:
          return {
            ...state,
            loadingSummary: true,
          };

        case ActionTypes.FETCH_ENROLLED_TESTS_ERROR:
          return {
            ...state,
            lastError: Date.now(),
            loading: false,
            error: payload,
          };

        case ActionTypes.FETCH_ENROLLED_TESTS_SUCCESS:
          return {
            ...state,
            lastFetch: Date.now(),
            loading: false,
            lastError: null,
            data: payload,
          };

        case ActionTypes.FETCH_ENROLLED_TESTS_SUMMARY_ERROR:
          return {
            ...state,
            lastErrorSummary: Date.now(),
            loadingSummary: false,
            summaryError: payload,
          };
        case ActionTypes.FETCH_ENROLLED_TESTS_ANSWERS_ERROR:
          return {
            ...state,
            lastAnswerError: Date.now(),
            loading: false,
            answersError: payload,
          };

        case ActionTypes.FETCH_ENROLLED_TESTS_SUMMARY_SUCCESS:
          return {
            ...state,
            lastFetchSummary: Date.now(),
            loadingSummary: false,
            lastErrorSummary: null,
            summary: payload,
          };

        case ActionTypes.FETCH_ENROLLED_TESTS_ANSWERS_SUCCESS:
          return {
            ...state,
            lastFetchAnswers: Date.now(),
            loading: false,
            lastError: null,
            answers: payload,
          };
        case ActionTypes.FETCH_CURRENT_TESTS_ANSWERS_ERROR:
          return {
            ...state,
            lastCurrentTestError: Date.now(),
            loading: false,
            currentAnswersError: payload,
          };

        case ActionTypes.FETCH_CURRENT_TESTS_ANSWERS_SUCCESS:
          return {
            ...state,
            lastCurrentTestFetch: Date.now(),
            loading: false,
            lastError: null,
            currentAnswers: payload,
          };

        case ActionTypes.CLEAR:
          return { ...initialState };

        default:
          return state;
      }
    };
  },

  selectEnrolledTestsDataRaw: state => state.enrolledTests,
  selectEnrolledTestsData: state => state.enrolledTests.data,
  selectEnrolledTestsList: createSelector('selectEnrolledTestsDataRaw', enrolledTestsData =>
    enrolledTestsData.data ? Object.values(enrolledTestsData.data) : [],
  ),
  selectEnrolledTestsSummary: createSelector('selectEnrolledTestsDataRaw', enrolledTestsDataRaw => {
    return enrolledTestsDataRaw.summary ? enrolledTestsDataRaw.summary : {};
  }),
  selectEnrolledSubmittedTests: createSelector('selectEnrolledTestsList', enrolledTestsList => {
    if (!enrolledTestsList) {
      return [];
    }
    return enrolledTestsList.filter(tests => tests.EnrolledTest.submitted);
  }),
  selectEnrolledPendingTests: createSelector('selectEnrolledTestsList', enrolledTestsList => {
    if (!enrolledTestsList) {
      return [];
    }
    return enrolledTestsList.filter(tests => !tests.EnrolledTest.submitted);
  }),
  selectAnswersTestsList: createSelector('selectEnrolledTestsDataRaw', enrolledTestsData => {
    if (!enrolledTestsData.answers) {
      return [];
    }
    return enrolledTestsData.answers;
  }),
  selectCurrentAnswersTestsList: createSelector('selectEnrolledTestsDataRaw', enrolledTestsData => {
    if (!enrolledTestsData.currentAnswers) {
      return [];
    }
    return enrolledTestsData.currentAnswers;
  }),
  selectSubmittedEIASampleIds: createSelector('selectEnrolledTestsDataRaw', enrolledTestsData => {
    if (!enrolledTestsData.currentAnswers) {
      return {};
    }
    const [submittedOppositeTestData] = enrolledTestsData.currentAnswers;
    if (submittedOppositeTestData.submitted && !submittedOppositeTestData.EIATest) {
      return {};
    }
    const submittedSamples = submittedOppositeTestData?.EIATest.EIAResults.map(
      items => items.sampleIDNumber,
    );
    const isSubmitted = submittedOppositeTestData.submitted;
    return { submittedSamples, isSubmitted };
  }),
  selectEnrolledTestFromTestsSubmitted: createSelector(
    'selectEnrolledSubmittedTests',
    enrolledSubmittedTests => {
      if (enrolledSubmittedTests.length) {
        return enrolledSubmittedTests.map(submittedTest => submittedTest.EnrolledTest.id);
      }
      return [];
    },
  ),

  selectFilteredTestAnswer: createSelector(
    'selectRouteParams',
    'selectAnswersTestsList',
    'selectEnrolledSubmittedTests',

    (routeParams, answersTestsList, enrolledSubmittedTests) => {
      const { testId } = routeParams;

      const test = enrolledSubmittedTests.filter(t => t.id === +testId);
      if (!test || !answersTestsList) {
        return null;
      }

      let currentData = {};
      const [activeResult] = enrolledSubmittedTests.filter(subTest => subTest.id === +testId);

      if (activeResult) {
        const [result] = answersTestsList.filter(
          answer => answer.id === activeResult.EnrolledTest.id,
        );
        if (result) {
          if (has.call(result, 'EIATest')) {
            currentData = { ...result };
          } else if (has.call(result, 'GenotypingTest')) {
            currentData = { ...result };
          }
        }
      } else {
        return {};
      }

      return currentData;
    },
  ),

  // Action creators

  doFetchEnrollTestsSummary: () => ({ dispatch, apiFetch }) => {
    dispatch({ type: ActionTypes.FETCH_ENROLLED_TESTS_SUMMARY_START });
    apiFetch({
      endpoint: 'enrolledTests/summary',
    })
      .then(payload => {
        dispatch({
          type: ActionTypes.FETCH_ENROLLED_TESTS_SUMMARY_SUCCESS,
          payload,
        });
      })
      .catch(error => {
        dispatch({ type: ActionTypes.FETCH_ENROLLED_TESTS_SUMMARY_ERROR, payload: error });
      });
  },

  doFetchEnrolledTestsList: labId => ({ dispatch, apiFetch, store }) => {
    dispatch({ type: ActionTypes.FETCH_START });

    const routeParams = store.selectRouteParams();
    const { enrollmentId } = routeParams;

    apiFetch({
      endpoint: `laboratories/${+labId}/enrollments/${enrollmentId}/tests`,
    })
      .then(response => {
        const payload = response.results.reduce((acc, test) => {
          acc[test.id] = test;
          return acc;
        }, {});
        dispatch({
          type: ActionTypes.FETCH_ENROLLED_TESTS_SUCCESS,
          payload,
        });
      })
      .catch(error => {
        dispatch({ type: ActionTypes.FETCH_ENROLLED_TESTS_ERROR, payload: error });
      });
  },

  doFetchAnswersTests: () => ({ dispatch, apiFetch, store }) => {
    dispatch({ type: ActionTypes.FETCH_START });
    const submittedTests = store.selectEnrolledSubmittedTests();
    const routeParams = store.selectRouteParams();
    const { enrollmentId } = routeParams;

    const results = Promise.all(
      submittedTests.map(async subTest => {
        const res = await apiFetch({
          endpoint: `enrollments/${enrollmentId}/tests/${subTest.id}/results`,
        });
        return res;
      }),
    );

    Promise.all([results]).then(promiseValues => {
      const [answers] = promiseValues;
      const payload = answers.map(answer => answer);
      dispatch({
        type: ActionTypes.FETCH_ENROLLED_TESTS_ANSWERS_SUCCESS,
        payload,
      });
    });
  },

  doFetchCurrentAnswersTests: (enrollmentId, testId) => ({ dispatch, apiFetch }) => {
    dispatch({ type: ActionTypes.FETCH_START });
    apiFetch({
      endpoint: `enrollments/${enrollmentId}/tests/${testId}/results`,
      method: 'GET',
    })
      .then(results => {
        dispatch({
          type: ActionTypes.FETCH_CURRENT_TESTS_ANSWERS_SUCCESS,
          payload: [results],
        });
      })
      .catch(error => {
        dispatch({
          type: ActionTypes.FETCH_CURRENT_TESTS_ANSWERS_ERROR,
          payload: error,
        });
      });
  },

  doClearEnrolledTestData: () => ({ dispatch }) => {
    dispatch({ type: ActionTypes.CLEAR });
  },

  // Reactor

  reactShouldFetchEnrolledTestsData: createSelector(
    'selectEnrolledTestsDataRaw',
    'selectAppTime',
    'selectRouteParams',
    'selectUserRole',
    'selectRouteInfo',
    'selectUserLabInfo',
    (enrolledTestsData, appTime, routeParams, userRole, routeInfo, userLabInfo) => {
      const routeToMatch = '/enrolled-studies/:enrollmentId/submitted-tests/answers';

      if (
        enrolledTestsData.loading ||
        !routeInfo.pattern.includes(routeToMatch) ||
        !routeParams.enrollmentId ||
        roles.POC !== userRole ||
        !userLabInfo
      ) {
        return null;
      }

      let shouldFetch = false;

      if (!enrolledTestsData.data && !enrolledTestsData.lastError) {
        shouldFetch = true;
      } else if (enrolledTestsData.lastError) {
        const timePassed = appTime - enrolledTestsData.lastError;
        if (timePassed > ERROR_TIME) {
          shouldFetch = true;
        }
      } else if (enrolledTestsData.lastFetch) {
        const timePassed = appTime - enrolledTestsData.lastFetch;
        if (timePassed > REFRESH_TIME) {
          shouldFetch = true;
        }
      }
      if (shouldFetch) {
        return { actionCreator: 'doFetchEnrolledTestsList', args: [userLabInfo.id] };
      }
    },
  ),

  reactShouldFetchAnswersTestsData: createSelector(
    'selectEnrolledTestsDataRaw',
    'selectAppTime',
    'selectRouteParams',
    'selectUserRole',
    'selectRouteInfo',
    'selectEnrolledSubmittedTests',
    (enrolledTestsDataRaw, appTime, routeParams, userRole, routeInfo, enrolledSubmittedTests) => {
      const routeToMatch = '/enrolled-studies/:enrollmentId/submitted-tests/answers';

      if (
        enrolledTestsDataRaw.loading ||
        !routeInfo.pattern.includes(routeToMatch) ||
        !routeParams.enrollmentId ||
        roles.POC !== userRole ||
        !enrolledSubmittedTests.length
      ) {
        return null;
      }

      let shouldFetch = false;

      if (!enrolledTestsDataRaw.answers && !enrolledTestsDataRaw.lastAnswerError) {
        shouldFetch = true;
      } else if (enrolledTestsDataRaw.lastAnswerError) {
        const timePassed = appTime - enrolledTestsDataRaw.lastAnswerError;
        if (timePassed > ERROR_TIME) {
          shouldFetch = true;
        }
      } else if (enrolledTestsDataRaw.lastFetchAnswers) {
        const timePassed = appTime - enrolledTestsDataRaw.lastFetchAnswers;
        if (timePassed > REFRESH_TIME) {
          shouldFetch = true;
        }
      }
      if (shouldFetch) {
        return { actionCreator: 'doFetchAnswersTests' };
      }
    },
  ),

  reactShouldFetchCurrentAnswers: createSelector(
    'selectEnrolledTestsDataRaw',
    'selectTestsToSubmit',
    'selectAppTime',
    'selectRouteParams',
    'selectUserRole',
    'selectRouteInfo',
    (enrolledTestsData, testsToSubmit, appTime, routeParams, userRole, routeInfo) => {
      const routeToMatch = '/enrolled-studies/:enrollmentId/submit-tests/:testId';

      if (
        enrolledTestsData.loading ||
        !testsToSubmit?.length ||
        !routeInfo.pattern.includes(routeToMatch) ||
        !routeParams.enrollmentId ||
        roles.POC !== userRole
      ) {
        return null;
      }

      const currentTest = testsToSubmit.filter(test => test.id === +routeParams.testId);
      const [enrolled] = currentTest;

      let shouldFetch = false;

      if (
        !enrolledTestsData.currentAnswers &&
        !enrolledTestsData.lastCurrentTestError &&
        enrolled.EnrolledTest.submitted &&
        enrolled.EnrolledTest.status === enrolledTestStatus.UNLOCKED
      ) {
        shouldFetch = true;
      } else if (
        enrolledTestsData.lastCurrentTestError &&
        enrolled.EnrolledTest.status === enrolledTestStatus.UNLOCKED
      ) {
        const timePassed = appTime - enrolledTestsData.lastCurrentTestError;
        if (timePassed > ERROR_TIME) {
          shouldFetch = true;
        }
      } else if (
        enrolledTestsData.lastCurrentTestFetch &&
        enrolled.EnrolledTest.status === enrolledTestStatus.UNLOCKED
      ) {
        const timePassed = appTime - enrolledTestsData.lastCurrentTestFetch;
        if (timePassed > REFRESH_TIME) {
          shouldFetch = true;
        }
      }
      if (shouldFetch) {
        return {
          actionCreator: 'doFetchCurrentAnswersTests',
          args: [routeParams.enrollmentId, routeParams.testId],
        };
      }
    },
  ),

  reactShouldFetchSubmittedSampleIds: createSelector(
    'selectEnrolledTestsDataRaw',
    'selectTestsForEnrollment',
    'selectAppTime',
    'selectRouteParams',
    'selectUserRole',
    'selectRouteInfo',
    (enrolledTestsData, testsForEnrollment, appTime, routeParams, userRole, routeInfo) => {
      const routeToMatch = '/enrolled-studies/:enrollmentId/submit-tests/:testId';
      const EiaTestId = 1;
      const GenotypeTestId = 2;
      const oppositeTest = routeParams.testId === EiaTestId ? GenotypeTestId : EiaTestId;

      if (
        enrolledTestsData.loading ||
        !testsForEnrollment?.tests ||
        !routeInfo.pattern.includes(routeToMatch) ||
        !routeParams.enrollmentId ||
        roles.POC !== userRole
      ) {
        return null;
      }

      const { tests } = testsForEnrollment;
      const oppositeSubmittedTest = tests.filter(test => test.id === oppositeTest);
      const currentTest = tests.filter(test => test.id === +routeParams.testId);
      const [oppositeEnrolledTest] = oppositeSubmittedTest;
      const [currentlyEnrolledTest] = currentTest;

      let shouldFetch = false;

      if (
        !enrolledTestsData.currentAnswers &&
        !enrolledTestsData.lastCurrentTestError &&
        !currentlyEnrolledTest?.EnrolledTest.submitted &&
        (oppositeEnrolledTest?.EnrolledTest.status === enrolledTestStatus.GRADING_PENDING ||
          oppositeEnrolledTest?.EnrolledTest.status === enrolledTestStatus.FINALIZED)
      ) {
        shouldFetch = true;
      } else if (
        enrolledTestsData.lastCurrentTestError &&
        !currentlyEnrolledTest?.EnrolledTest.submitted &&
        (oppositeEnrolledTest?.EnrolledTest.status === enrolledTestStatus.GRADING_PENDING ||
          oppositeEnrolledTest?.EnrolledTest.status === enrolledTestStatus.FINALIZED)
      ) {
        const timePassed = appTime - enrolledTestsData.lastCurrentTestError;
        if (timePassed > ERROR_TIME) {
          shouldFetch = true;
        }
      } else if (
        enrolledTestsData.lastCurrentTestFetch &&
        !currentlyEnrolledTest?.EnrolledTest.submitted &&
        (oppositeEnrolledTest?.EnrolledTest.status === enrolledTestStatus.GRADING_PENDING ||
          oppositeEnrolledTest?.EnrolledTest.status === enrolledTestStatus.FINALIZED)
      ) {
        const timePassed = appTime - enrolledTestsData.lastCurrentTestFetch;
        if (timePassed > REFRESH_TIME) {
          shouldFetch = true;
        }
      }
      if (shouldFetch) {
        return {
          actionCreator: 'doFetchCurrentAnswersTests',
          args: [routeParams.enrollmentId, oppositeTest],
        };
      }
    },
  ),
  reactShouldFetchEnrolledTestSummaryData: createSelector(
    'selectEnrolledTestsDataRaw',
    'selectAppTime',
    'selectUserLogin',
    'selectRouteInfo',
    'selectUserRole',
    (enrolledTests, appTime, userLogin, routeInfo, userRole) => {
      if (
        !userLogin ||
        userRole !== roles.ADMIN ||
        routeInfo.pattern !== '/dashboard' ||
        enrolledTests.loadingSummary
      ) {
        return null;
      }

      let shouldFetch = false;

      if (!enrolledTests.summary && !enrolledTests.lastErrorSummary) {
        shouldFetch = true;
      } else if (enrolledTests.lastErrorSummary) {
        const timePassed = appTime - enrolledTests.lastErrorSummary;
        if (timePassed > ERROR_TIME) {
          shouldFetch = true;
        }
      } else if (enrolledTests.lastFetchSummary) {
        const timePassed = appTime - enrolledTests.lastFetchSummary;
        if (timePassed > REFRESH_TIME) {
          shouldFetch = true;
        }
      }

      if (shouldFetch) {
        return { actionCreator: 'doFetchEnrollTestsSummary' };
      }
    },
  ),
};
