/* eslint-disable prefer-spread */
import React from 'react';
import { Field, FieldArray } from 'formik';
import ListItemInput from './ListItemInput';
import RadioButtonGroup from './RadioButtonGroup';

const ListGroupTable = ({ isSampleFill, sampleId }) => {
  return (
    <FieldArray
      name="genotypingResults"
      render={() =>
        Array.apply(null, { length: 10 }).map((e, i) => {
          return (
            <ul
              key={i.toString()}
              className="list-group list-group-horizontal-lg double-margin-bottom"
            >
              {!isSampleFill ? (
                <Field
                  component={ListItemInput}
                  name={`genotypingResults[${i}].sampleIDNumber`}
                  title="Sample Id Number"
                />
              ) : (
                <li className="list-group-item d-lg-flex w-20">
                  <span className="column-title text-muted">Sample ID Number</span>
                  <span className="font-weight-bold my-auto text-muted">{`${sampleId[i]}`}</span>
                </li>
              )}
              <Field
                component={RadioButtonGroup}
                interpretation={`genotypingResults[${i}].gGenotypeVP7`}
                genotypeValue={`genotypingResults[${i}].gGenotypeVP7Value`}
                title="G-Genotype (VP7)"
              />
              <Field
                component={RadioButtonGroup}
                interpretation={`genotypingResults[${i}].pGenotypeVP4`}
                genotypeValue={`genotypingResults[${i}].pGenotypeVP4Value`}
                title="P-Genotype (VP4)"
              />
              <Field
                component={RadioButtonGroup}
                interpretation={`genotypingResults[${i}].finalGenotype`}
                genotypeValue={`genotypingResults[${i}].finalGenotypeValue`}
                title="Final Genotype"
              />
            </ul>
          );
        })
      }
    />
  );
};

export default ListGroupTable;
