import React from 'react';

const ResultReportInfo = ({ year = 0 }) => {
  return (
    <div className="col text-center">
      <h1 className="mb-2">WHO GLOBAL REFERENCE LABORATORY FOR ROTAVIRUS SURVEILLANCE</h1>
      <h2 className="mb-2"> Viral Gastroenteritis Branch</h2>
      <h2 className="mb-2"> Division of Viral Diseases, NCIRD, CDC</h2>
      <h2 className="mb-2">
        Mailstop H18-7, 1600 Clifton Rd. N.E., Atlanta, Georgia 30329-4027 U.S.A.
      </h2>
      <h2 className="mb-2">Phone: +1 (404) 639-1628; +1 (404) 639-4379; Fax: +1 (404) 639-3645</h2>
      <h2 className="mb-2"> Emails: rotavirus.eqa@cdc.gov, ijs0@cdc.gov</h2>
      <h2 className="mt-5">{`${year} WHO EQA ROTAVIRUS PROFICIENCY TESTING CHALLENGE RESULTS REPORT`}</h2>
    </div>
  );
};

export default ResultReportInfo;
