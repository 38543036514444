/* eslint-disable prefer-spread */
/* eslint-disable react/no-array-index-key */

import React from 'react';
import { Field, FieldArray } from 'formik';

import RowInputExpectedResults from '../../../test-results/EIA/RowInputExpectedResults';
import RowNumericInputExpectedResults from '../../../test-results/EIA/RowNumericInputExpectedResults';
import RowSelectExpectedResults from '../../../test-results/EIA/RowSelectExpectedResults';
import { testInterpretationStoreResult } from '../../../../helpers/constants';
import { validateExpectedGenotypeInFormikRow } from '../../../../utils';

const EditableRowsForExpectedResults = ({
  handleChange,
  values = [],
  validateOnRepeatedSumCode,
  setFieldValue,
  deleteGenotypeByIds,
}) => {
  if (!values.length) {
    return null;
  }

  return (
    <React.Fragment>
      <FieldArray
        name="expectedResults"
        render={({ remove }) =>
          values.map((e, i, arr) => {
            return (
              <tr key={`row-${i}`}>
                <td className="border border-light">
                  <div className="input-group input-group-lg input-group-flush">
                    <Field
                      name={`expectedResults[${i}].sumCode`}
                      className="form-control text-secondary result-row-input-numeric text-center bg-white"
                      component={RowNumericInputExpectedResults}
                      handleChange={handleChange}
                      validate={() => {
                        let error;
                        if (validateOnRepeatedSumCode(arr, e.sumCode, i)) {
                          error = 'You cannot set repeated values';
                        } else {
                          error = null;
                        }
                        return error;
                      }}
                      cbForHandleChange={value => {
                        setFieldValue(`expectedResults[${i}].expectedGenotype.sumCode`, +value);
                      }}
                    />
                  </div>
                </td>
                <td className="border border-light text-secondary">
                  <Field
                    name={`expectedResults[${i}].eiaResult`}
                    component={RowSelectExpectedResults}
                    options={Object.values(testInterpretationStoreResult).map(value => value)}
                    value={e.eiaResult}
                    handleChange={handleChange}
                    cbForHandleChange={value => {
                      if (value === testInterpretationStoreResult.NEGATIVE) {
                        setFieldValue(
                          `expectedResults[${i}].expectedGenotype.expectedGenotype`,
                          testInterpretationStoreResult.NEGATIVE,
                        );
                      } else {
                        setFieldValue(
                          `expectedResults[${i}].expectedGenotype.expectedGenotype`,
                          '',
                        );
                      }
                      setFieldValue(`expectedResults[${i}].expectedGenotype.EIAResult`, value);
                      setFieldValue(`expectedResults[${i}].expectedGenotype.sumCode`, +e.sumCode);
                    }}
                  />
                </td>
                <td className="border border-light">
                  <div className="input-group input-group-lg input-group-flush">
                    <Field
                      name={`expectedResults[${i}].expectedGenotype.expectedGenotype`}
                      className="form-control text-secondary"
                      handleChange={handleChange}
                      component={RowInputExpectedResults}
                      disabled={e.eiaResult === testInterpretationStoreResult.NEGATIVE}
                      validate={() => validateExpectedGenotypeInFormikRow(e)}
                      cbForHandleChange={() => {
                        setFieldValue(
                          `expectedResults[${i}].expectedGenotype.EIAResult`,
                          e.eiaResult,
                        );
                        setFieldValue(`expectedResults[${i}].expectedGenotype.sumCode`, +e.sumCode);
                      }}
                    />
                  </div>
                </td>
                <td className="border border-light">
                  <div className="input-group input-group-lg input-group-flush justify-content-center">
                    <button
                      type="button"
                      className="btn btn-link p-0 text-center"
                      aria-haspopup="true"
                      title=""
                      onClick={() => {
                        if (e.expectedGenotype?.id) {
                          deleteGenotypeByIds(e.expectedGenotype.id, i, remove);
                        } else {
                          remove(i);
                        }
                      }}
                    >
                      <span className="delete fe fe-trash text-danger" />
                    </button>
                  </div>
                </td>
              </tr>
            );
          })
        }
      />
    </React.Fragment>
  );
};

export default EditableRowsForExpectedResults;
