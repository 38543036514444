import React, { useState } from 'react';
import { connect } from 'redux-bundler-react';
import LabForm from '../forms/LabForm';
import LabInformation from '../views/LabInformation';
import RedirectionNav from '../lib/RedirectionNav';

import PointsOfContactTable from '../views/PointsOfContactTable';
import LabEnrolledStudiesTable from '../views/LabEnrolledStudiesTable';

const LabEditPage = ({ currentLab, doUpdateLabDetails }) => {
  const [isEditMode, setEditMode] = useState(false);

  function renderContent() {
    return isEditMode ? (
      <LabForm
        action={doUpdateLabDetails}
        id={currentLab.id}
        edit
        initialValues={currentLab}
        setViewMode={setEditMode}
      />
    ) : (
      <LabInformation {...currentLab} />
    );
  }

  const redirectionNav = {
    navItems: [
      {
        route: `/labs`,
        text: 'Labs',
      },
    ],

    lastNavItemText: 'Edit Lab',
  };

  return (
    <React.Fragment>
      {currentLab && (
        <RedirectionNav
          navItems={redirectionNav.navItems}
          lastNavItemText={redirectionNav.lastNavItemText}
        />
      )}
      <div className="card">
        <div className="card-header">
          <div className="row align-items-center">
            <div className="col">
              <h4 className="card-header-title">
                {isEditMode ? 'Edit Laboratory Information' : 'Laboratory Information'}
                {currentLab && currentLab.isVerified && (
                  <span className="d-table mt-2 d-sm-inline-block mt-sm-0 ml-sm-2 badge badge-soft-success">
                    Information Verified
                  </span>
                )}
                {currentLab && !currentLab.isVerified && (
                  <span className="d-table mt-2 d-sm-inline-block mt-sm-0 ml-sm-2 badge badge-warning">
                    Pending verification
                  </span>
                )}
              </h4>
            </div>
            {!isEditMode && (
              <React.Fragment>
                <button
                  type="button"
                  onClick={() => setEditMode(true)}
                  className="btn btn-xl btn-primary"
                  aria-haspopup="true"
                >
                  <span>Edit</span>
                </button>
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="card-body px-md-3 px-lg-4">{currentLab && renderContent()}</div>
      </div>
      <PointsOfContactTable />
      <LabEnrolledStudiesTable />
    </React.Fragment>
  );
};
export default connect('selectCurrentLab', 'doUpdateLabDetails', LabEditPage);
