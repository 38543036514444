/* eslint-disable no-console */
import React from 'react';
import { connect } from 'redux-bundler-react';
import { Formik, Form } from 'formik';

const UserForm = ({ edit = false, id = null }) => {
  const onSubmit = values => {
    // Handle form submission here
    console.log('The button has not been implemented.', values);
  };

  return (
    <Formik enableReinitialize onSubmit={onSubmit}>
      {() => (
        <Form>
          <div className="btn-group" role="group" aria-label="Basic example">
            <button className="btn btn-primary" type="submit" disabled={false}>
              Submit
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default connect(UserForm);
