import { createSelector } from 'redux-bundler';
import {
  roles,
  enrolledTestStatusMessages,
  testTypes,
  enrolledTestStatus,
} from '../helpers/constants';

const ERROR_TIME = 15000;
const REFRESH_TIME = 300000;
const ActionTypes = {
  FETCH_LAB_SUBSCRIBED_TESTS_START: 'FETCH_LAB_SUBSCRIBED_TESTS_START',
  FETCH_LAB_SUBSCRIBED_TESTS_ERROR: 'FETCH_LAB_SUBSCRIBED_TESTS_ERROR',
  FETCH_LAB_SUBSCRIBED_TESTS_SUCCESS: 'FETCH_LAB_SUBSCRIBED_TESTS_SUCCESS',

  UPDATE_LAB_SUBSCRIBED_TEST_STATUS_START: 'UPDATE_LAB_SUBSCRIBED_TEST_STATUS_START',
  UPDATE_LAB_SUBSCRIBED_TEST_STATUS_ERROR: 'UPDATE_LAB_SUBSCRIBED_TEST_STATUS_ERROR',
  UPDATE_LAB_SUBSCRIBED_TEST_STATUS_SUCESS: 'UPDATE_LAB_SUBSCRIBED_TEST_STATUS_SUCCESS',

  CLEAR_LAB_SUBSCRIBED_TESTS: 'CLEAR_LAB_SUBSCRIBED_TESTS',
};

/**
 * Bundle to store labs subscribedTest
 * @typedef labsSubscribedTests
 * @property {string} name
 * @property {Function} getReducer
 *
 */
export default {
  name: 'labsSubscribedTests',
  getReducer: () => {
    const initialState = {
      loading: false,
      lastError: null,
      lastFetch: null,
      data: null,
      error: null,
    };

    return (state = initialState, { type, payload }) => {
      switch (type) {
        case ActionTypes.FETCH_LAB_SUBSCRIBED_TESTS_START:
        case ActionTypes.UPDATE_LAB_SUBSCRIBED_TEST_STATUS_START:
          return { ...state, loading: true };
        case ActionTypes.FETCH_LAB_SUBSCRIBED_TESTS_SUCCESS:
        case ActionTypes.UPDATE_LAB_SUBSCRIBED_TEST_STATUS_SUCESS:
          return {
            ...state,
            error: null,
            loading: false,
            lastError: null,
            data: payload,
            lastFetch: new Date(),
          };
        case ActionTypes.FETCH_LAB_SUBSCRIBED_TESTS_ERROR:
        case ActionTypes.UPDATE_LAB_SUBSCRIBED_TEST_STATUS_ERROR: {
          return {
            ...state,
            loading: false,
            error: payload,
            lastError: new Date(),
          };
        }
        case ActionTypes.CLEAR_LAB_SUBSCRIBED_TESTS: {
          return { ...initialState };
        }

        default:
          return state;
      }
    };
  },

  // Selectors
  selectLabsSubscribedTestsDataRaw: state => state.labsSubscribedTests,
  selectLabsSubscribedTestsData: state => state.labsSubscribedTests.data,
  selectLabCurrentSubscribedTestResultErrorMessage: createSelector(
    'selectLabsSubscribedTestsDataRaw',
    labsSubscribedTestsDataRaw => {
      return labsSubscribedTestsDataRaw.error && labsSubscribedTestsDataRaw.error.message
        ? labsSubscribedTestsDataRaw.error.message
        : null;
    },
  ),
  selectLabCurrentSubscribedTestsResult: createSelector(
    'selectCurrentLab',
    'selectLabsSubscribedTestsData',
    'selectRouteParams',
    (currentLab, labsSubscribedTestsData, routeParams) => {
      if (
        currentLab &&
        labsSubscribedTestsData &&
        labsSubscribedTestsData[currentLab.id] &&
        labsSubscribedTestsData[currentLab.id][routeParams.enrolledTestId] &&
        labsSubscribedTestsData[currentLab.id][routeParams.enrolledTestId].hasResults
      ) {
        return labsSubscribedTestsData[currentLab.id][routeParams.enrolledTestId];
      }
      return null;
    },
  ),

  selectLabSubscribedTestInvestigator: createSelector(
    'selectLabCurrentSubscribedTestsResult',
    'selectCurrentLab',
    'selectCurrentLabPocs',
    (labCurrentSubscribedTestsResult, currentLab, currentLabPocs) => {
      if (labCurrentSubscribedTestsResult && currentLab && currentLabPocs.length > 0) {
        return currentLabPocs.find(
          poc => poc.UserId === labCurrentSubscribedTestsResult.submittedBy,
        );
      }
      return null;
    },
  ),

  // Action creators

  /**
   * Fetch enrolledTest results
   *
   * @param   {integer}  labId
   * @param   {integer}  enrollmentId
   * @param   {testId}  testId
   *
   */
  doFetchLabSubscribedTestResults: (labId, enrollmentId, testId) => ({
    dispatch,
    apiFetch,
    getState,
  }) => {
    dispatch({ type: ActionTypes.FETCH_LAB_SUBSCRIBED_TESTS_START });
    apiFetch({
      method: 'GET',
      endpoint: `enrollments/${enrollmentId}/tests/${testId}/results`,
      redirectOnNotFound: true,
    })
      .then(response => {
        const existingEnrolledTests = getState().labsSubscribedTests.data || {};
        let payload = {};
        if (!existingEnrolledTests[labId][response.id]) {
          payload = {
            ...existingEnrolledTests,
            [labId]: {
              [response.id]: { ...response, hasResults: true },
            },
          };
        }
        payload = {
          ...existingEnrolledTests,
          [labId]: {
            ...existingEnrolledTests[labId],
            [response.id]: {
              ...existingEnrolledTests[labId][response.id],
              hasResults: true,
              ...response,
            },
          },
        };
        dispatch({ type: ActionTypes.FETCH_LAB_SUBSCRIBED_TESTS_SUCCESS, payload });
      })
      .catch(err => {
        dispatch({ type: ActionTypes.FETCH_LAB_SUBSCRIBED_TESTS_ERROR, payload: err });
      });
  },

  doReOpenOrFinalizeLabSubscribedTest: (
    labId,
    enrollmentId,
    testId,
    status,
    note,
    results = [],
    testType,
    enrolledTestId,
    adminGradingNotes,
  ) => ({ dispatch, apiFetch, getState }) => {
    dispatch({ type: ActionTypes.UPDATE_LAB_SUBSCRIBED_TEST_STATUS_START });
    let data;
    if (
      testType === testTypes.GENOTYPING &&
      (status === enrolledTestStatus.FINALIZED || status === enrolledTestStatus.GRADING_PENDING)
    ) {
      const gradedResults = results.map(grading => {
        return {
          id: grading.id,
          correct: grading.correct,
          penalization: grading.penalization,
          suggestedScore: grading.suggestedScore,
          finalScore: grading.finalScore,
          overrideNote: !grading.overrideNote.value ? { value: '' } : grading.overrideNote,
        };
      });
      data = { status, note, gradedResults, adminGradingNotes };
    } else if (
      testType === testTypes.EIA &&
      (status === enrolledTestStatus.FINALIZED || status === enrolledTestStatus.GRADING_PENDING)
    ) {
      const gradedResults = results.map(grading => {
        return {
          id: grading.id,
          correct: { value: 'N/A' },
          penalization: { value: 0 },
          suggestedScore: grading.score,
          finalScore: grading.finalScore,
          overrideNote: !grading.overrideNote.value ? { value: '' } : grading.overrideNote,
        };
      });
      data = { status, note, gradedResults, adminGradingNotes };
    } else {
      data = { status, note };
    }

    return apiFetch({
      method: 'PATCH',
      endpoint: `enrollments/${enrollmentId}/tests/${testId}/results`,
      data,
      redirectOnNotFound: true,
    })
      .then(response => {
        const existingEnrolledTests = getState().labsSubscribedTests.data || {};

        let payload = {};
        if (!existingEnrolledTests[labId][response.results.id]) {
          payload = {
            ...existingEnrolledTests,
            [labId]: {
              [response.results.id]: { ...response.results, hasResults: true },
            },
          };
        }
        payload = {
          ...existingEnrolledTests,
          [labId]: {
            ...existingEnrolledTests[labId],
            [response.results.id]: {
              ...existingEnrolledTests[labId][response.results.id],
              hasResults: true,
              ...response.results,
            },
          },
        };

        dispatch({ type: ActionTypes.UPDATE_LAB_SUBSCRIBED_TEST_STATUS_SUCESS, payload });
        dispatch({
          actionCreator: 'doFetchLabParticipantGradeResults',
          args: [labId, enrolledTestId],
        });
        dispatch({
          actionCreator: 'doFetchLabEnrollment',
          args: [labId],
        });
        dispatch({
          actionCreator: 'doCreateAlert',
          args: [
            {
              title: enrolledTestStatusMessages[status].title,
              msg: enrolledTestStatusMessages[status].msg,
            },
          ],
        });
        dispatch({ actionCreator: 'doFetchEnrollTestsSummary' });
        return payload;
      })
      .catch(err => {
        dispatch({ type: ActionTypes.UPDATE_LAB_SUBSCRIBED_TEST_STATUS_ERROR, payload: err });
        throw err;
      });
  },

  /**
   * Set enrolled test after  fetching lab enrollments
   * @param {number} labId
   */
  doSetLabSubscribedTest: labId => ({ dispatch, getState }) => {
    if (!labId) {
      return;
    }
    const existingLabEnrollments = getState().labsEnrollments.data || {};
    const existingLabSubscribedTest = getState().labsSubscribedTests.data || {};
    if (!existingLabEnrollments[labId]) {
      return;
    }

    const currentLabEnrollments = existingLabEnrollments[labId];

    /* eslint-disable no-param-reassign */
    const labSubscribedTest = Object.values(currentLabEnrollments).reduce(
      (enrollmentAcc, enrollment) => {
        const currentEnrollmentSubscribedTests = enrollment.Tests.reduce((testAcc, test) => {
          testAcc[test.EnrolledTest.id] = {
            ...test.EnrolledTest,
            testName: test.name,
            testId: test.id,
            type: test.type,
            hasResults: false,
          };
          return testAcc;
        }, {});
        const enrolledTest = enrollmentAcc[enrollment.LabParticipantId] || {};
        enrollmentAcc[enrollment.LabParticipantId] = {
          ...enrolledTest,
          ...currentEnrollmentSubscribedTests,
        };

        return enrollmentAcc;
      },
      { [labId]: {} },
    );
    /* eslint-disable no-param-reassign */

    dispatch({
      type: ActionTypes.FETCH_LAB_SUBSCRIBED_TESTS_SUCCESS,
      payload: {
        ...existingLabSubscribedTest,
        ...labSubscribedTest,
      },
    });
  },

  doCleanLabsSubscribedTestsData: () => ({ dispatch }) => {
    dispatch({ type: ActionTypes.CLEAR_LAB_SUBSCRIBED_TESTS });
  },

  // Reactors
  reactShouldFetchLabSubscribedTestResults: createSelector(
    'selectUserRole',
    'selectCurrentLab',
    'selectRouteParams',
    'selectLabsSubscribedTestsDataRaw',
    'selectAppTime',
    (userRole, currentLab, routeParams, labsSubscribedTestsDataRaw, appTime) => {
      if (
        userRole !== roles.ADMIN ||
        !currentLab ||
        !routeParams.enrolledTestId ||
        !labsSubscribedTestsDataRaw.data ||
        labsSubscribedTestsDataRaw.loading
      ) {
        return null;
      }

      if (
        !labsSubscribedTestsDataRaw.data[currentLab.id] ||
        !labsSubscribedTestsDataRaw.data[currentLab.id][parseInt(routeParams.enrolledTestId, 10)]
      ) {
        // this means that the lab has no enrollments or the enrolledTests ID was not found
        return { actionCreator: 'doUpdateUrl', args: ['/not-found'] };
      }

      let shouldFetch = false;
      if (
        labsSubscribedTestsDataRaw.data &&
        !labsSubscribedTestsDataRaw.data[currentLab.id][parseInt(routeParams.enrolledTestId, 10)]
          .hasResults &&
        !labsSubscribedTestsDataRaw.lastError
      ) {
        shouldFetch = true;
      } else if (labsSubscribedTestsDataRaw.lastError) {
        const timePassed = appTime - labsSubscribedTestsDataRaw.lastError;
        if (timePassed > ERROR_TIME) {
          shouldFetch = true;
        }
      } else if (labsSubscribedTestsDataRaw.lastFetch) {
        const timePassed = appTime - labsSubscribedTestsDataRaw.lastFetch;
        if (timePassed > REFRESH_TIME) {
          shouldFetch = true;
        }
      }

      if (shouldFetch) {
        const { EnrollmentId, testId } = labsSubscribedTestsDataRaw.data[currentLab.id][
          parseInt(routeParams.enrolledTestId, 10)
        ];

        return {
          actionCreator: 'doFetchLabSubscribedTestResults',
          args: [currentLab.id, EnrollmentId, testId],
        };
      }
    },
  ),
  /**
   * Reactor to trigger the action creator to populate the
   * labSubscribedTest with helpful initial Data
   * We have to rely on the data that lives in the labEnrollment bundle
   * Once that data exist in our store module we could build the data
   * needed in this bundle
   *
   */
  reactShouldSetLabSubscribedTestInitialData: createSelector(
    'selectUserRole',
    'selectCurrentLab',
    'selectLabsEnrollmentDataRaw',
    'selectLabsSubscribedTestsDataRaw',
    'selectAppTime',
    (userRole, currentLab, labsEnrollmentDataRaw, labsSubscribedTestsDataRaw, appTime) => {
      if (
        !userRole ||
        userRole !== roles.ADMIN ||
        !currentLab ||
        !labsEnrollmentDataRaw.data ||
        labsEnrollmentDataRaw.loading ||
        labsSubscribedTestsDataRaw.loading
      ) {
        return null;
      }

      let shouldSetSubscribedTest = false;

      if (
        (!labsSubscribedTestsDataRaw.data &&
          labsEnrollmentDataRaw.data[currentLab.id] &&
          !labsEnrollmentDataRaw.lastError) ||
        (labsSubscribedTestsDataRaw.data && !labsSubscribedTestsDataRaw.data[currentLab.id])
      ) {
        shouldSetSubscribedTest = true;
      } else if (labsSubscribedTestsDataRaw.lastError) {
        const timePassed = appTime - labsSubscribedTestsDataRaw.lastError;
        if (timePassed > ERROR_TIME) {
          shouldSetSubscribedTest = true;
        }
      } else if (labsSubscribedTestsDataRaw.lastFetch) {
        const timePassed = appTime - labsSubscribedTestsDataRaw.lastFetch;
        if (timePassed > REFRESH_TIME) {
          shouldSetSubscribedTest = true;
        }
      }

      if (shouldSetSubscribedTest) {
        return {
          actionCreator: 'doSetLabSubscribedTest',
          args: [currentLab.id],
        };
      }
    },
  ),
};
