import React, { useState, useEffect } from 'react';

// Importing the Compoonents
import ResultReportTestTable from '../lib/results-report/ResultReportTestTable';
import ResultReportTestCard from '../lib/results-report/ResultReportTestCard';
import ResultReportNote from '../lib/results-report/ResultReportNote';
import ResultReportJustificationNote from '../lib/results-report/ResultReportJustificationNote';
import ResultReportGradingNote from '../lib/results-report/ResultReportGradingNote';
import ResultReportInterpretation from '../lib/results-report/ResultReportInterpretation';
import ResultReportLabInfo from '../lib/results-report/ResultReportLabInfo';
import ResultReportPenalties from '../lib/results-report/ResultReportPenalties';
import ResultReportInfo from '../lib/results-report/ResultReportInfo';
import ResultReportUsersInfo from '../lib/results-report/ResultReportUsersInfo';
import ResultReportDateReceived from '../lib/results-report/ResultReportDateReceived';

// Importing Utils and Helpers
import { testTypes, EIATableHeader, GenotypeTableHeader } from '../../helpers/constants';
import { getFilteredTestDataForTableHook } from '../../utils';

// Importing HOOKs
import useLabInfoReport from '../hooks/useLabInfoReport';
import useReportTestData from '../hooks/useReportTestData';
import useReportTestTableData from '../hooks/useReportTestTableData';
import useResultReportPenalties from '../hooks/useResultReportPenalties';
/**
 *
 * @param {Object} labResultReport This is the report from an given enrollment.
 * @param {String} minScoreToPass The Min Score to Pass the Test
 */

const ResultReportView = React.forwardRef(({ labResultReport, minScoreToPass }, ref) => {
  const [matches, setMatches] = useState(window.matchMedia('(min-width: 1200px)').matches);

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    const media = window.matchMedia('(min-width: 1200px)');
    media.addEventListener('change', handler);

    return () => media.removeEventListener('change', handler);
  });

  const [labInfoReport] = useLabInfoReport(labResultReport.labParticipant);
  const [usersInfo, datesInfo, interpretations] = useReportTestData(
    labResultReport.testResults,
    minScoreToPass,
  );
  const [eiaTableData, totalEIAScore] = useReportTestTableData(
    getFilteredTestDataForTableHook(labResultReport.testResults, testTypes.EIA),
    testTypes.EIA,
  );
  const [genotypingTableData, totalGenotypingScore] = useReportTestTableData(
    getFilteredTestDataForTableHook(labResultReport.testResults, testTypes.GENOTYPING),
    testTypes.GENOTYPING,
  );
  const [penalties] = useResultReportPenalties(labResultReport.penalties);

  function renderDates() {
    return datesInfo.map(date => {
      return <ResultReportDateReceived date={date.date} title={date.title} key={date.type} />;
    });
  }

  function renderResultInterpretation() {
    return interpretations.map(({ title, score, type, interpretation }, index) => (
      <React.Fragment key={type}>
        <div className={index > 0 ? `row side-by-side mb-4` : 'row side-by-side'}>
          <ResultReportInterpretation title={title} score={score} interpretation={interpretation} />
        </div>
      </React.Fragment>
    ));
  }

  return labResultReport?.testResults ? (
    <div className="card-container" ref={ref}>
      <div className="card card-body p-5">
        <div className="row">
          <ResultReportInfo year={labResultReport?.study?.year} />
        </div>
        <div className="row mt-5">
          {matches && (
            <table className="printable">
              <tbody>
                <tr>
                  <td>
                    <ResultReportLabInfo
                      laboratoryName={labInfoReport.laboratoryName}
                      address1={labInfoReport.address1}
                      address2={labInfoReport.address2}
                    />
                    {renderResultInterpretation()}
                    <div className="row mt-print">
                      <ResultReportPenalties {...penalties} />
                    </div>
                  </td>
                  <td>
                    <ResultReportUsersInfo users={usersInfo} />
                    {renderDates()}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          {!matches && (
            <>
              <div className="col-12">
                <ResultReportLabInfo
                  laboratoryName={labInfoReport.laboratoryName}
                  address1={labInfoReport.address1}
                  address2={labInfoReport.address2}
                />
                {renderResultInterpretation()}
                <div className="row">
                  <ResultReportPenalties {...penalties} />
                </div>
              </div>

              <div className="col-12 mt-4">
                <ResultReportUsersInfo users={usersInfo} />
                {renderDates()}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="page-break" />
      {eiaTableData.length > 0 && (
        <ResultReportTestCard title="EIA Testing Results and Individual Sample Scores">
          <ResultReportTestTable
            headers={EIATableHeader}
            total={totalEIAScore}
            data={eiaTableData}
          />
          <ResultReportJustificationNote
            justificationNotes={labResultReport.testResults.eiaGradedTest.justificationNotes}
          />
          <ResultReportGradingNote
            gradingNotes={labResultReport.testResults.eiaGradedTest.gradingNotes}
          />
        </ResultReportTestCard>
      )}
      <div className="page-break" />
      {genotypingTableData.length > 0 && (
        <ResultReportTestCard title="Genotyping Results and Individual Sample Scores">
          <ResultReportTestTable
            headers={GenotypeTableHeader}
            total={totalGenotypingScore}
            data={genotypingTableData}
          />
          <ResultReportJustificationNote
            justificationNotes={labResultReport.testResults.genotypeGradedTest.justificationNotes}
          />
          <ResultReportGradingNote
            gradingNotes={labResultReport.testResults.genotypeGradedTest.gradingNotes}
          />
        </ResultReportTestCard>
      )}
      <div className="page-break" />
      {labResultReport.adminNote && (
        <ResultReportNote notes={labResultReport.adminNote} />
      )}
    </div>
  ) : null;
});

export default ResultReportView;
