/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';
import { connect } from 'redux-bundler-react';
import { Form, Formik, Field } from 'formik';
import pocProfileInformationSchema from '../form-schemas/pocProfileInformationSchema';

/**
 * Edit user basic information
 * @type {React.Component}
 * @param {Object}   params
 * @param {Object}   params.userProfileBasicInformation 'Selector from user bundle
 * @param {Function} params.doUpdateUserProfile         'ActionCreator from user bundle
 *
 */
const UserProfileInformationForm = ({ userProfileBasicInformation, doUpdateUserProfile }) => {
  const initValues = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    countryCode: '',
    email: '',
    title: '',
  };
  // If no data is return, let's initialize as empty
  const initialValues = userProfileBasicInformation || initValues;

  // let's declare our hook for the enabling of the controls on screen
  const [editUserProfileInfo, setEditUserProfileInfo] = useState(true);

  const updateUserProfile = async (values, { setSubmitting, resetForm }) => {
    try {
      await doUpdateUserProfile(values.id, values);
      setSubmitting(false);
      setEditUserProfileInfo(true);
    } catch (e) {
      resetForm();
      setSubmitting(false);
    }
  };

  const cancelUpdatePassword = (setSubmitting, resetForm) => {
    setSubmitting(false);
    resetForm();
    setEditUserProfileInfo(true);
  };

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={pocProfileInformationSchema}
        onSubmit={updateUserProfile}
      >
        {({ values, errors, touched, isSubmitting, dirty, setSubmitting, resetForm }) => (
          <Form className="mb-3">
            <div className="card container mb-3">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-xl-12 col-lg-12 d-flex justify-content-between">
                    <p className="mt-3 header-pretitle">Personal Information</p>
                    <p className="mt-3 pb-4">
                      {editUserProfileInfo ? (
                        <button
                          type="button"
                          className="btn btn-link p-0"
                          onClick={() => setEditUserProfileInfo(false)}
                        >
                          <span className="fe fe-edit lead" />
                        </button>
                      ) : (
                        ''
                      )}
                    </p>
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-lg-6 col-xl-6 col-sm-12 mb-3">
                    <label htmlFor="firstName">First Name</label>
                    <Field
                      id="firstName"
                      name="firstName"
                      placeholder="Joe"
                      type="text"
                      className="form-control"
                      readOnly={editUserProfileInfo}
                      style={{
                        backgroundColor: 'white',
                      }}
                    />
                    {!editUserProfileInfo && errors.firstName && touched.firstName ? (
                      <div className="text-danger">{errors.firstName}</div>
                    ) : (
                      ''
                    )}
                  </div>

                  <div className="col-lg-6 col-xl-6 col-sm-12 mb-3">
                    <label htmlFor="lastName">Last Name</label>
                    <Field
                      id="lastName"
                      name="lastName"
                      placeholder="Doe"
                      type="text"
                      className="form-control"
                      readOnly={editUserProfileInfo}
                      style={{
                        backgroundColor: 'white',
                      }}
                    />
                    {errors.lastName && touched.lastName ? (
                      <div className="text-danger">{errors.lastName}</div>
                    ) : (
                      ''
                    )}
                  </div>

                  <div className="col-lg-6 col-xl-6 col-sm-12 mb-3">
                    <label htmlFor="countryCode">Phone Country Code</label>
                    <Field
                      id="countryCode"
                      name="countryCode"
                      placeholder="0-001"
                      type="text"
                      className="form-control"
                      readOnly={editUserProfileInfo}
                      style={{
                        backgroundColor: 'white',
                      }}
                      value={values.countryCode || ''}
                    />
                    {!editUserProfileInfo && errors.countryCode && touched.countryCode ? (
                      <div className="text-danger">{errors.countryCode}</div>
                    ) : (
                      ''
                    )}
                  </div>

                  <div className="col-lg-6 col-xl-6 col-sm-12 mb-3">
                    <label htmlFor="phoneNumber">Phone #</label>
                    <Field
                      id="phoneNumber"
                      name="phoneNumber"
                      placeholder="98765432"
                      type="text"
                      className="form-control"
                      readOnly={editUserProfileInfo}
                      style={{
                        backgroundColor: 'white',
                      }}
                    />
                    {errors.phoneNumber && touched.phoneNumber ? (
                      <div className="text-danger">{errors.phoneNumber}</div>
                    ) : (
                      ''
                    )}
                  </div>

                  <div className="col-lg-6 col-xl-6 col-sm-12 mb-3">
                    <label htmlFor="email">Email</label>
                    <Field
                      id="email"
                      name="email"
                      placeholder={initialValues.email}
                      type="text"
                      className="form-control"
                      readOnly={editUserProfileInfo}
                      style={{
                        backgroundColor: 'white',
                      }}
                    />
                    {!editUserProfileInfo && errors.email && touched.email ? (
                      <div className="text-danger">{errors.email}</div>
                    ) : (
                      ''
                    )}
                  </div>

                  <div className="col-lg-6 col-xl-6 col-sm-12 mb-3">
                    <label htmlFor="title">Title</label>
                    <Field
                      id="title"
                      name="title"
                      placeholder="Web Developer"
                      type="text"
                      className="form-control"
                      readOnly={editUserProfileInfo}
                      style={{
                        backgroundColor: 'white',
                      }}
                    />
                    {errors.title && touched.title ? (
                      <div className="text-danger">{errors.title}</div>
                    ) : (
                      ''
                    )}
                  </div>

                  <div
                    id="EditUserProfile"
                    className={
                      editUserProfileInfo
                        ? 'col-sm-12 col-lg-12 col-xl-12  text-right collapse'
                        : 'col-sm-12 col-lg-12 col-xl-12 text-right'
                    }
                  >
                    <button
                      name="submit"
                      data-toggle="collapse"
                      type="submit"
                      disabled={isSubmitting || !dirty}
                      className="btn btn-primary mr-2 mt-4"
                    >
                      Update
                    </button>
                    <button
                      name="cancel"
                      data-toggle="collapse"
                      type="button"
                      onClick={() => cancelUpdatePassword(setSubmitting, resetForm)}
                      className="btn btn-outline-danger mt-4"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default connect(
  'doUpdateUserProfile',
  'selectUserProfileBasicInformation',
  UserProfileInformationForm,
);
