import React from 'react';
import { getIn, Field } from 'formik';

const ErrorMessage = ({ name }) => {
  return (
    <Field
      name={name}
      render={({ form }) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);
        return error && touch ? (
          <div className="invalid-feedback mb-2" style={{ display: 'block' }}>
            {error}
          </div>
        ) : null;
      }}
    />
  );
};

export default ErrorMessage;
