import React from 'react';
import { connect } from 'redux-bundler-react';
import { formatDistanceToNow } from 'date-fns';
import ShipmentStatus from '../lib/ShipmentStatus';

/**
 * @typedef {React.Component}
 * @param {Object} studiesData           Studies in Store - selectStudiesData
 * @param {Array} currentLabEnrollments  Array of current lab's enrollment - selectCurrentLabEnrollments
 */
const LabEnrolledStudiesTable = ({ studiesData, currentLabEnrollments }) => {
  return (
    <div className="card">
      <div className="card-header">
        <div className="row align-items-center">
          <div className="col">
            <h4 className="card-header-title">Enrolled Studies</h4>
          </div>
        </div>
      </div>
      <div className="table-responsive mb-0">
        <table className="table table-sm table-nowrap card-table table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>
                <div className="text-muted">Name</div>
              </th>
              <th>
                <div className="text-muted">Year</div>
              </th>
              <th>
                <div className="text-muted">Enrolled at</div>
              </th>
              <th>
                <div className="text-muted">Shipping status</div>
              </th>

              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody className="list">
            {studiesData &&
              currentLabEnrollments &&
              currentLabEnrollments.map((enrollment, index) => (
                <tr key={enrollment.id}>
                  <td>{index + 1}</td>
                  <td>{studiesData[enrollment.StudyId].name}</td>
                  <td>{studiesData[enrollment.StudyId].year}</td>
                  <td>
                    {enrollment.createdAt
                      ? formatDistanceToNow(new Date(enrollment.enrollDate))
                      : ''}
                  </td>
                  <td>
                    <ShipmentStatus shipmentStatus={enrollment.shipmentStatus} />
                  </td>
                  <td>
                    <a
                      title="See submitted results"
                      href={`/labs/${enrollment.LabParticipantId}/enrollments/${enrollment.id}`}
                      className="
                        text-center nav-link pt-0 pb-0"
                    >
                      View details
                    </a>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default connect('selectStudiesData', 'selectCurrentLabEnrollments', LabEnrolledStudiesTable);
