/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { connect } from 'redux-bundler-react';
import { getNavHelper } from 'internal-nav-helper';

const NoWrapper = ({ children, doUpdateUrl }) => (
  <div onClick={getNavHelper(doUpdateUrl)}>{children}</div>
);

export default connect('doUpdateUrl', NoWrapper);
