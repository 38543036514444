/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';

const Select = ({ field, col, form: { touched, errors }, options, placeholder, ...props }) => {
  const isNotValid = touched[field.name] && errors[field.name];

  return (
    <div className={`form-group col-md-${col}`}>
      <label htmlFor="kitBrandsName">Kit&apos;s Brand Name</label>
      {options.length && (
        <select
          id="kitBrandsName"
          {...field}
          {...props}
          placeholder="Select Kit's Brand Name"
          className={
            isNotValid ? 'form-control is-invalid custom-select' : 'form-control custom-select'
          }
        >
          <option value="">Select Kit&apos;s Brand Name</option>
          {options.length &&
            options.map(option => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
        </select>
      )}
      {isNotValid && <div className="invalid-feedback">{errors[field.name]}</div>}
    </div>
  );
};

export default Select;
