import React, { useState } from 'react';
import { connect } from 'redux-bundler-react';
import { roleHomeRedirect } from '../../utils';
import ptestingLogo from '../../assets/img/rotaeqa_hightres_logo.png';

const LeftNav = ({ mainNav, doUserLogOut, userRole, menuToggleAction, isMenuDisplayed }) => {
  return (
    <nav className="navbar navbar-vertical fixed-left navbar-expand-md navbar-light" id="sidebar">
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          aria-controls="sidebarCollapse"
          aria-expanded={isMenuDisplayed}
          aria-label="Toggle navigation"
          onClick={() => menuToggleAction(!isMenuDisplayed)}
        >
          <span className="navbar-toggler-icon" />
        </button>
        <a
          className="navbar-brand"
          href={roleHomeRedirect(userRole)}
          onClick={() => menuToggleAction(false)}
          title="Home"
        >
          <img src={ptestingLogo} className="navbar-brand-img mx-auto" alt="ROTA Logo" />
        </a>
        <div
          className={isMenuDisplayed ? 'navbar-collapse' : 'collapse navbar-collapse'}
          id="sidebarCollapse"
        >
          <ul className="navbar-nav">
            {mainNav.map(navItem => (
              <li key={navItem.label} className="nav-item">
                <a
                  className="nav-link"
                  href={navItem.url}
                  onClick={() => menuToggleAction(!isMenuDisplayed)}
                >
                  <i className={`fe fe-${navItem.icon || 'file'}`} />
                  {navItem.label}
                </a>
              </li>
            ))}
            <li className="nav-item">
              <a className="nav-link" href="/" onClick={() => doUserLogOut()}>
                <i className="fe fe-log-out" /> Logout
              </a>{' '}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

const HorizontalNav = ({ mainNav, doUserLogOut, userRole, menuToggleAction, isMenuDisplayed }) => {
  return (
    <nav className="navbar navbar-expand-xl navbar-light mb-3">
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        aria-controls="navbarNav"
        aria-expanded={isMenuDisplayed}
        aria-label="Toggle navigation"
        onClick={() => menuToggleAction(!isMenuDisplayed)}
      >
        <span className="navbar-toggler-icon" />
      </button>
      <a
        className="navbar-brand"
        href={roleHomeRedirect(userRole)}
        title="Home"
        onClick={() => menuToggleAction(false)}
      >
        <img className="ml-3" src={ptestingLogo} alt="ROTA Logo" />
      </a>
      <div
        className={`${
          isMenuDisplayed ? 'navbar-collapse' : 'collapse navbar-collapse'
        } mx-0 px-0 flex-grow-1`}
        id="navbarNav"
      >
        <ul className="navbar-nav ml-auto">
          {mainNav.map(navItem => (
            <li key={navItem.label} className="nav-item">
              <a
                className="nav-link text-uppercase"
                href={navItem.url}
                onClick={() => menuToggleAction(!isMenuDisplayed)}
              >
                {navItem.label}
              </a>
            </li>
          ))}

          <li className="nav-item">
            <button
              type="button"
              className="btn btn-danger btn-sm text-uppercase m-2"
              onClick={() => doUserLogOut()}
            >
              Log out
            </button>
          </li>
        </ul>
      </div>
    </nav>
  );
};

// Let's open the nav bar choosing its alignment based on the second parameter. By default the nav renders vertically
const Navigation = ({ mainNav, horizontalAlign, doUserLogOut, userRole }) => {
  const [isMenuDisplayed, setMenuDisplayed] = useState(false);
  const timeOut = 150;

  const toggleNavigation = isToggled => {
    setTimeout(() => {
      setMenuDisplayed(isToggled);
    }, timeOut);
  };

  return !horizontalAlign ? (
    <LeftNav
      mainNav={mainNav}
      doUserLogOut={doUserLogOut}
      userRole={userRole}
      menuToggleAction={toggleNavigation}
      isMenuDisplayed={isMenuDisplayed}
    />
  ) : (
    <HorizontalNav
      mainNav={mainNav}
      doUserLogOut={doUserLogOut}
      userRole={userRole}
      menuToggleAction={toggleNavigation}
      isMenuDisplayed={isMenuDisplayed}
    />
  );
};
export default connect('doUserLogOut', 'selectUserRole', Navigation);
