import React from 'react';
import { connect } from 'redux-bundler-react';
import Col from '../lib/Col';
import Row from '../lib/Row';

import StudyDetailFrom from '../forms/study-detail-form/StudyDetailForm';
import EnrolledLabsTable from '../views/EnrolledLabsTable';
import StudyProgressChart from '../views/StudyProgressChart';

const AdminStudyDetailPage = ({
  currentStudy,
  doUpdateEnrollment,
  hasStudyTests,
  expectedTestResultForStudy,
  studyProgressData,
  doFetchStudyCsvReport,
}) => {
  return (
    <Row>
      <Col className="offset-xl-1" lg={12} xl={10}>
        <div className="card">
          <div className="card-header">
            <nav aria-label="breadcrumb" className="d-none d-sm-block">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/studies">Studies</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {currentStudy && `${currentStudy.year} ${currentStudy.name}`}
                </li>
              </ol>
            </nav>
            {hasStudyTests && (
              <div className="text-right ">
                <a
                  href={`/studies/${currentStudy && currentStudy.id}/expected-results`}
                  className="btn btn-md btn-link"
                >
                  {expectedTestResultForStudy.length
                    ? 'Edit Expected Results'
                    : 'Add Expected Results'}
                </a>
              </div>
            )}
          </div>

          <div className="card-body">
            <StudyDetailFrom />
          </div>
        </div>

        <div className="card">
          <div className="card-header">
            <h4 className="card-text text-muted">Study Progress</h4>
          </div>
          <div className="card-body">
            <div className="study-progress-dashboard">
              {studyProgressData.map((chart, i) => {
                return (
                  <StudyProgressChart
                    key={i}
                    title={chart.title}
                    seriesData={chart.seriesData}
                    categories={chart.categories}
                  />
                );
              })}
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-header">
            <h4 className="card-text text-muted">Enrolled Laboratories to This Study</h4>
            <button
              className="btn btn-link pt-0"
              style={{ flex: '0 1 auto' }}
              onClick={async () => await doFetchStudyCsvReport(currentStudy.id)}
            >
              Download CSV Report
            </button>
          </div>
          <div className="card-body">
            <EnrolledLabsTable
              enrollments={(currentStudy && currentStudy.enrollLabs) || []}
              updateEnrollment={doUpdateEnrollment}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default connect(
  'selectCurrentStudy',
  'doUpdateEnrollment',
  'selectHasStudyTests',
  'selectExpectedTestResultForStudy',
  'selectStudyProgressData',
  'doFetchStudyCsvReport',
  AdminStudyDetailPage,
);
