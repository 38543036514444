import { createSelector } from 'redux-bundler';
import { roles } from '../helpers/constants';

const ERROR_TIME = 15000;
const REFRESH_TIME = 1800000;
const ActionTypes = {
  FETCH_STUDIES_SUMMARY_START: 'FETCH_STUDIES_SUMMARY_START',
  FETCH_STUDIES_SUMMARY_ERROR: 'FETCH_STUDIES_SUMMARY_ERROR',
  FETCH_STUDIES_SUMMARY_SUCCESS: 'FETCH_STUDIES_SUMMARY_SUCCESS',
  CLEAR_STUDY_SUMMARY: 'CLEAR_STUDY_SUMMARY',
};

export default {
  name: 'studiesSummary',
  getReducer: () => {
    const initialState = {
      loading: false,
      lastError: null,
      lastFetch: null,
      data: null,
      error: null,
    };

    // Reducer
    return (state = initialState, { type, payload }) => {
      switch (type) {
        case ActionTypes.FETCH_STUDIES_SUMMARY_START:
          return { ...state, loading: true };
        case ActionTypes.FETCH_STUDIES_SUMMARY_ERROR:
          return { ...state, lastError: Date.now(), loading: false, error: payload };
        case ActionTypes.FETCH_STUDIES_SUMMARY_SUCCESS:
          return {
            ...state,
            lastFetch: Date.now(),
            loading: false,
            lastError: null,
            error: null,
            data: payload,
          };

        case ActionTypes.CLEAR_STUDY_SUMMARY:
          return {
            ...initialState,
          };
        default:
          return state;
      }
    };
  },

  // Selectors
  selectStudiesSummaryDataRaw: state => state.studiesSummary,
  selectStudiesSummaryData: state => state.studiesSummary.data,
  selectStudiesSummaryList: createSelector('selectStudiesSummaryData', studiesSummaryData => {
    return studiesSummaryData ? Object.values(studiesSummaryData) : [];
  }),
  selectStudiesSummaryErrorMessage: createSelector(
    'selectStudiesSummaryDataRaw',
    studiesSummaryDataRaw =>
      studiesSummaryDataRaw.error && studiesSummaryDataRaw.error.message
        ? studiesSummaryDataRaw.error.message
        : null,
  ),

  // Action Creators
  doFetchStudiesSummaryList: () => ({ dispatch, apiFetch }) => {
    dispatch({ type: ActionTypes.FETCH_STUDIES_SUMMARY_START });
    apiFetch({
      endpoint: 'studies/summary',
    })
      .then(payload => {
        dispatch({
          type: ActionTypes.FETCH_STUDIES_SUMMARY_SUCCESS,
          payload: payload.results.reduce((acc, study) => {
            acc[study.id] = study;
            return acc;
          }, {}),
        });
      })
      .catch(error => {
        dispatch({ type: ActionTypes.FETCH_STUDIES_SUMMARY_ERROR, payload: error });
      });
  },
  doClearStudiesSummaryData: () => ({ dispatch }) => {
    dispatch({ type: ActionTypes.CLEAR_STUDY_SUMMARY });
  },

  // Reactors
  reactShouldFetchStudiesSummaryData: createSelector(
    'selectStudiesSummaryDataRaw',
    'selectAppTime',
    'selectUserLogin',
    'selectUserRole',
    (studiesSummary, appTime, userLogin, userRole) => {
      if (!userLogin || userRole !== roles.ADMIN || studiesSummary.loading) {
        return null;
      }

      let shouldFetch = false;

      if (!studiesSummary.data && !studiesSummary.lastError) {
        shouldFetch = true;
      } else if (studiesSummary.lastError) {
        const timePassed = appTime - studiesSummary.lastError;
        if (timePassed > ERROR_TIME) {
          shouldFetch = true;
        }
      } else if (studiesSummary.lastFetch) {
        const timePassed = appTime - studiesSummary.lastFetch;
        if (timePassed > REFRESH_TIME) {
          shouldFetch = true;
        }
      }

      if (shouldFetch) {
        return { actionCreator: 'doFetchStudiesSummaryList' };
      }
    },
  ),
};
