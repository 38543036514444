import React from 'react';

/**
 *
 * @param {Objecct} Props The object prop for this component
 */

const PenalizationPanel = ({ bodyPanelData }) => (
  <div className="table-responsive">
    <table className="table table-sm table-nowrap card-table table-hover">
      <thead>
        <tr>
          {bodyPanelData.headers.map(th => (
            <th scope="col" key={th}>
              {th}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          {bodyPanelData.data.map(d => (
            <td key={d}> {d} </td>
          ))}
        </tr>
      </tbody>
    </table>
  </div>
);

export default PenalizationPanel;
