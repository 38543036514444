import React from 'react';

const GenotypeResultsErrorString = ({ errors }) => {
  return errors.genotypingResults &&
    typeof errors.genotypingResults === 'string' &&
    errors.genotypingResults ? (
    <div className="col-12 invalid-feedback" style={{ display: 'block' }}>
      {errors.genotypingResults}
    </div>
  ) : null;
};

export default GenotypeResultsErrorString;
