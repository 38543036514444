import React, { useState, useEffect } from 'react';
import { Provider } from 'redux-bundler-react';
import getStore from './bundles';
import cache from './helpers/cache'; // here to use it https://github.com/HenrikJoreteg/money-clip#example

import Layout from './components/Layout';

function App() {
  const [storeData, setInitialData] = useState(null);
  useEffect(() => {
    cache.getAll().then(initialData => {
      setInitialData(initialData);
    });
  }, []);

  return (
    storeData && (
      <Provider store={getStore(storeData)}>
        <Layout />
      </Provider>
    )
  );
}

export default App;
