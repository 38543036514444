import React from 'react';
import { connect } from 'redux-bundler-react';
import Toasts from './views/Toasts';

const Layout = ({ alertsList, route, navLinks, layoutWrapper, userAuthorized }) => {
  const Page = route.component;
  const Wrapper = layoutWrapper;

  return (
    <>
      {userAuthorized && (
        <Wrapper title={route.title} nav={navLinks}>
          <Page />
        </Wrapper>
      )}
      {!userAuthorized && (
        <Wrapper title="Not Authorized" nav={navLinks}>
          <div>You are not authorized to access this page!</div>
        </Wrapper>
      )}
      <Toasts toastList={alertsList} />
    </>
  );
};

export default connect(
  'selectRoute',
  'selectNavLinks',
  'selectAlertsList',
  'selectLayoutWrapper',
  'selectUserAuthorized',
  Layout,
);
