import { cloneDeep } from 'lodash';
import { createSelector } from 'redux-bundler';
import { roles } from '../helpers/constants';
import { AlertLevels } from './alerts';

const ERROR_TIME = 15000;
const REFRESH_TIME = 300000;
const ActionTypes = {
  FETCH_LABENROLLMENTS_START: 'FETCH_LABENROLLMENTS_START',
  FETCH_LABENROLLMENTS_ERROR: 'FETCH_LABENROLLMENTS_ERROR',
  FETCH_LABENROLLMENTS_SUCCESS: 'FETCH_LABENROLLMENTS_SUCCESS',
  CLEAR_LABENROLLMENTS: 'CLEAR_LABENROLLMENTS',
  UPDATE_LABENROLLMENTS_ADMIN_NOTE_START: 'UPDATE_LABENROLLMENTS_ADMIN_NOTE_START',
  UPDATE_LABENROLLMENTS_ADMIN_NOTE_SUCCESS: 'UPDATE_LABENROLLMENTS_ADMIN_NOTE_SUCCESS',
  UPDATE_LABENROLLMENTS_ADMIN_NOTE_ERROR: 'UPDATE_LABENROLLMENTS_ADMIN_NOTE_ERROR',
};

/**
 * Bundle to store labs enrollments
 * @typedef labsEnrollments
 * @property {string} name
 * @property {Function} getReducer
 *
 */
export default {
  name: 'labsEnrollments',
  getReducer: () => {
    const initialState = {
      loading: false,
      lastError: null,
      lastFetch: null,
      data: null,
      error: null,
    };

    return (state = initialState, { type, payload }) => {
      switch (type) {
        case ActionTypes.FETCH_LABENROLLMENTS_START:
          return {
            ...state,
            loading: true,
          };
        case ActionTypes.FETCH_LABENROLLMENTS_ERROR:
          return {
            ...state,
            loading: false,
            lastError: new Date(),
            error: payload,
          };
        case ActionTypes.FETCH_LABENROLLMENTS_SUCCESS:
          return {
            ...state,
            error: null,
            loading: false,
            lastError: null,
            data: payload,
            lastFetch: new Date(),
          };
        case ActionTypes.CLEAR_LABENROLLMENTS:
          return { ...initialState };

        case ActionTypes.UPDATE_LABENROLLMENTS_ADMIN_NOTE_START: {
          const updatedState = {
            ...cloneDeep(state),
            loading: true,
          };

          return updatedState;
        }

        case ActionTypes.UPDATE_LABENROLLMENTS_ADMIN_NOTE_SUCCESS: {
          const { labId, enrollmentId, updatedNote } = payload;
          const updatedState = {
            ...cloneDeep(state),
            loading: false,
            error: null,
            lastFetch: new Date(),
          };
          updatedState.data[labId][enrollmentId].adminNote = updatedNote;

          return updatedState;
        }

        case ActionTypes.UPDATE_LABENROLLMENTS_ADMIN_NOTE_ERROR: {
          const updatedState = {
            ...cloneDeep(state),
            error: payload,
            loading: false,
            lastError: new Date(),
          };

          return updatedState;
        }

        default:
          return state;
      }
    };
  },

  // selectors
  selectLabsEnrollmentDataRaw: state => state.labsEnrollments,
  selectLoadingLabsEnrollment: state => state.labsEnrollments.loading,
  selectLabsEnrollmentData: state => state.labsEnrollments.data,
  selectCurrentLabEnrollments: createSelector(
    'selectLabsEnrollmentData',
    'selectCurrentLab',
    (labsEnrollmentData, currentLab) => {
      if (currentLab && labsEnrollmentData && labsEnrollmentData[currentLab.id]) {
        return Object.values(labsEnrollmentData[currentLab.id]);
      }
      return [];
    },
  ),
  selectCurrentLabEnrollmentDetails: createSelector(
    'selectCurrentLab',
    'selectLabsEnrollmentData',
    'selectRouteParams',
    (currentLab, labsEnrollmentData, routeParams) => {
      if (
        currentLab &&
        labsEnrollmentData &&
        labsEnrollmentData[currentLab.id] &&
        routeParams.enrollmentId
      ) {
        return labsEnrollmentData[currentLab.id][routeParams.enrollmentId];
      }
      return null;
    },
  ),
  selectCurrentLabHasTestFinalized: createSelector(
    'selectCurrentLabEnrollmentDetails',
    currentLabEnrollmentDetails => {
      if (!currentLabEnrollmentDetails) return null;

      // eslint-disable-next-line no-unused-expressions
      const allTestAreFinalized = currentLabEnrollmentDetails?.Tests.map(test => {
        if (test?.EnrolledTest && test?.EnrolledTest?.status === 'FINALIZED') {
          return true;
        }
        return false;
      }).every(item => item);

      return allTestAreFinalized;
    },
  ),
  selectCurrentLabSomeTestResultsSubmitted: createSelector(
    // Some Results have been Submitted, but not all of them have been Finalized
    'selectCurrentLabEnrollmentDetails',
    currentLabEnrollmentDetails => {
      if (!currentLabEnrollmentDetails) return null;
      const someTestSubmitted = currentLabEnrollmentDetails?.Tests.map(test => {
        if (test?.EnrolledTest && test?.EnrolledTest?.status === 'GRADING_PENDING') {
          return true;
        }
        return false;
      }).some(item => item);
      return someTestSubmitted;
    },
  ),
  selectCurrentLabSomeTestResultsSubmittedOrFinalizedOrUnlocked: createSelector(
    // Some Results have been Submitted or Finalized or Unlocked
    'selectCurrentLabEnrollmentDetails',
    currentLabEnrollmentDetails => {
      if (!currentLabEnrollmentDetails) return null;
      const someTestSubmittedOrFinalized = currentLabEnrollmentDetails?.Tests.map(test => {
        if (
          test?.EnrolledTest &&
          (test?.EnrolledTest?.status === 'GRADING_PENDING' ||
            test?.EnrolledTest?.status === 'FINALIZED' ||
            test?.EnrolledTest?.status === 'UNLOCKED')
        ) {
          return true;
        }
        return false;
      });
      return someTestSubmittedOrFinalized.some(item => item);
    },
  ),

  /**
   * Get the data of the poc that created the current lab enrollment
   */
  selectCurrentLabEnrollmentPoc: createSelector(
    'selectCurrentLabEnrollmentDetails',
    'selectPocsData',
    (currentLabEnrollmentDetails, pocsData) => {
      if (currentLabEnrollmentDetails && pocsData[currentLabEnrollmentDetails.LabParticipantId]) {
        const { LabParticipantId, enrolledBy } = currentLabEnrollmentDetails;
        const userOfEnrollment = Object.values(pocsData[LabParticipantId]).find(
          poc => poc.UserId === enrolledBy,
        );
        if (userOfEnrollment) {
          return pocsData[LabParticipantId][userOfEnrollment.id];
        }
        return null;
      }
      return null;
    },
  ),

  // ActionCreators

  /**
   * Fetch enrollments for a single lab
   * The api response is based on Studies
   * but we will store stuffs based on Enrollment property of each enrolled study
   * since study data lives in studies bundle
   * @param {number} labId  The Lab Participant Id
   * @return  {null}
   */
  doFetchLabEnrollment: labId => ({ dispatch, apiFetch, getState }) => {
    dispatch({ type: ActionTypes.FETCH_LABENROLLMENTS_START });
    apiFetch({
      method: 'GET',
      endpoint: `laboratories/${labId}/enrollments`,
      redirectOnNotFound: false,
    })
      .then(response => {
        const existinglabsEnrollments = getState().labsEnrollments.data || {};
        const enrollments = response.results.map(enrolledStudy => {
          return enrolledStudy.Enrollment;
        });

        const payload = enrollments.reduce(
          (acc, enrollment) => {
            acc[labId][enrollment.id] = enrollment;
            return acc;
          },
          { [labId]: {} },
        );

        dispatch({
          type: ActionTypes.FETCH_LABENROLLMENTS_SUCCESS,
          payload: { ...existinglabsEnrollments, ...payload },
        });
      })
      .catch(err => {
        dispatch({
          type: ActionTypes.FETCH_LABENROLLMENTS_ERROR,
          payload: err,
        });
      });
  },

  // Remove labs enrollments from store
  // this action creator is expected to be called on logout
  doCleanLabsEnrollmentsData: () => ({ dispatch }) => {
    dispatch({ type: ActionTypes.CLEAR_LABENROLLMENTS });
  },

  doUpdateEnrollmentAdminNote: (labId, enrollmentId, updatedNote) => ({ dispatch, apiFetch }) => {
    const reqBody = {
      note: updatedNote,
    };

    dispatch({ type: ActionTypes.UPDATE_LABENROLLMENTS_ADMIN_NOTE_START });
    apiFetch({
      endpoint: `enrollments/${enrollmentId}/adminNote`,
      method: 'PATCH',
      data: reqBody,
    })
      .then(res => {
        dispatch({
          type: ActionTypes.UPDATE_LABENROLLMENTS_ADMIN_NOTE_SUCCESS,
          payload: {
            updatedNote: res.adminNote,
            labId,
            enrollmentId,
          },
        });
        dispatch({
          actionCreator: 'doCreateAlert',
          args: [
            {
              msg: `The Admin Note has been successfully updated`,
              title: 'Lab Enrollment Details',
            },
          ],
        });
        dispatch({
          actionCreator: 'doFetchlabEnrollmentResultsReport',
          args: [enrollmentId],
        });
      })
      .catch(error => {
        dispatch({
          actionCreator: 'doCreateAlert',
          args: [
            {
              msg: `Error updating Admin Note. Changes were not saved.`,
              title: 'Lab Enrollment Details',
              level: AlertLevels.ERROR,
            },
          ],
        });
        dispatch({
          type: ActionTypes.UPDATE_LABENROLLMENTS_ADMIN_NOTE_ERROR,
          payload: error,
        });
      });
  },

  // Reactors

  /**
   * Fetch labs enrollment when currentLab selector has a lab
   */
  reactShouldFetchLabEnrollments: createSelector(
    'selectUserRole',
    'selectCurrentLab',
    'selectLabsEnrollmentDataRaw',
    'selectRouteParams',
    'selectAppTime',
    (userRole, currentLab, labsEnrollmentDataRaw, routeParams, appTime) => {
      if (!userRole || userRole !== roles.ADMIN || !currentLab || labsEnrollmentDataRaw.loading) {
        return null;
      }

      let shouldFetch = false;

      // If user lands to an enrollmentId that does not exist or does not belongs to the current lab
      if (
        labsEnrollmentDataRaw.data &&
        labsEnrollmentDataRaw.data[currentLab.id] &&
        routeParams.enrollmentId &&
        !labsEnrollmentDataRaw.data[currentLab.id][parseInt(routeParams.enrollmentId, 10)]
      ) {
        return { actionCreator: 'doUpdateUrl', args: ['/not-found'] };
      }

      if (
        (!labsEnrollmentDataRaw.data && !labsEnrollmentDataRaw.lastError) ||
        (labsEnrollmentDataRaw.data && !labsEnrollmentDataRaw.data[currentLab.id]) ||
        (routeParams.enrolledTestId && !labsEnrollmentDataRaw.data)
      ) {
        shouldFetch = true;
      } else if (labsEnrollmentDataRaw.lastError) {
        const timePassed = appTime - labsEnrollmentDataRaw.lastError;
        if (timePassed > ERROR_TIME) {
          shouldFetch = true;
        }
      } else if (labsEnrollmentDataRaw.lastFetch) {
        const timePassed = appTime - labsEnrollmentDataRaw.lastFetch;
        if (timePassed > REFRESH_TIME) {
          shouldFetch = true;
        }
      }

      if (shouldFetch) {
        return { actionCreator: 'doFetchLabEnrollment', args: [currentLab.id] };
      }
    },
  ),
};
