import React from 'react';

const ResultReportTestCard = ({ children, title = '' }) => {
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-header-title header-pretitle text-left">{title}</h3>
      </div>
      <div className="card-body">{children}</div>
    </div>
  );
};

export default ResultReportTestCard;
