/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { strCapitalize } from '../../utils';
import { confirmationTextForUnlockTest, enrolledTestStatus } from '../../helpers/constants';

/**
 *
 * @param {String} Props.note The text of the note to concatanate with the email
 * @param {Boolean} Props.checkboxValue The boolean value if the checkbox is true|false
 * @param {Function} Props.handleCheckbox The callback function to handle the event of the checkbox
 * @param {Function} Props.setNote Callback to set the value of the note
 * @param {Function} Props.onOkCallback The function to be trigger once the *okay* button is clicked
 * @param {String} Props.submissionType The type of submission, FINALIZED|ALLOW_RESUBMISSION
 * @param {Function} Props.onOkCancelCallback The function to be trigger once the *Cancel* button is clicked
 * @param {String} Props.testType The current test type
 *
 */
const ConfirmResubmissionTest = ({
  note,
  checkboxValue,
  handleCheckbox,
  setNote,
  onOkCallback,
  submissionType,
  onCancelCallback,
  testType,
}) => {
  function renderParagraphText() {
    if (submissionType === enrolledTestStatus.UNLOCKED) {
      return (
        <p className="mb-4">
          Are you sure you want to allow{' '}
          <strong> {`${strCapitalize(true, testType || '')}`} - Test Resubmission </strong> for this
          Lab Participant?
        </p>
      );
    }
    if (submissionType === enrolledTestStatus.FINALIZED) {
      return (
        <p className="mb-4">
          Are you sure you want to finalize{' '}
          <strong> {`${strCapitalize(true, testType || '')}`} - Test </strong> for this Lab
          Participant?
        </p>
      );
    }
    if (submissionType === enrolledTestStatus.GRADING_PENDING) {
      return (
        <p className="mb-4">
          Are you sure you want to save draft{' '}
          <strong> {`${strCapitalize(true, testType || '')}`} - Test </strong> for this Lab
          Participant?
          <br />
          <br />
          <em>
            {' '}
            This action will not complete the grading process. You can preview the Results Report
            and return later to finalize it.{' '}
          </em>
        </p>
      );
    }
  }

  return (
    <>
      <div className="card-body px-0 pt-0 justify-content-center">
        {renderParagraphText()}

        {enrolledTestStatus.FINALIZED !== submissionType &&
          enrolledTestStatus.GRADING_PENDING !== submissionType && (
            <div className="custom-control custom-switch text-muted mt-2">
              <input
                type="checkbox"
                className="custom-control-input"
                id="sendEmail"
                value={checkboxValue}
                onChange={() => handleCheckbox(!checkboxValue)}
              />
              <label className="custom-control-label" htmlFor="sendEmail">
                Send a custom note in the resubmission email?
              </label>
            </div>
          )}
        {checkboxValue && (
          <div className="mt-2">
            <textarea
              rows="5"
              cols="55"
              name="noteEiaResubmission"
              id="noteEiaResubmission"
              type="text"
              value={note}
              onChange={e => setNote(e.target.value)}
              onBlur={e => setNote(e.target.value)}
              className="form-control"
            />
          </div>
        )}
      </div>
      <div className="card-footer d-flex justify-content-end px-0">
        <button
          type="button"
          className="btn btn-md btn-outline-danger mr-2"
          onClick={onCancelCallback}
        >
          Cancel
        </button>
        <button type="button" className="btn btn-md btn-outline-primary" onClick={onOkCallback}>
          {confirmationTextForUnlockTest[submissionType].btnText}
        </button>
      </div>
    </>
  );
};

export default ConfirmResubmissionTest;
