import React from 'react';
import Chart from 'react-apexcharts';

const StudyProgressChart = ({ title, categories = [], seriesData = [] }) => {
  const series = [
    {
      data: seriesData,
    },
  ];
  const options = {
    title: {
      text: title,
      align: 'left',
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: undefined,
        color: '#263238',
      },
    },
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      min: 0,
      max: Math.max(4, ...seriesData),
      tickAmount: 4,
      categories,
      labels: {
        formatter: val => {
          return val.toFixed(0);
        },
      },
    },
  };

  return (
    <div>
      <Chart type="bar" width="100%" series={series} options={options} />
    </div>
  );
};

export default StudyProgressChart;
