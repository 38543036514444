/* eslint-disable prefer-spread */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { Formik, Form, Field } from 'formik';
import Input from './Input';
import DatePicker from './DatePicker';
import TextArea from '../../lib/TextArea';
import { monthNames } from '../../../helpers/constants';
import StudiesSchema from '../../form-schemas/studiesSchema';

const NewStudyForm = ({ action, cancelAction }) => {
  const initialValues = {
    name: '',
    year: '',
    startDate: '',
    endDate: '',
    panelManufactureDate: '',
    notes: '',
    cboMonth: '',
    cboYear: '',
  };

  // Get the current year
  const currentYear = new Date().getFullYear();

  const submitForm = (values, { setSubmitting }) => {
    const { cboMonth, cboYear } = values;
    const concatDate = `${cboYear}/${cboMonth}/01`;
    // we can ommit forcing the admin to select a month and year for the panel DOM,
    // if it does, we can simply send it to the Endpoint with these values otherwise we send it empty
    const panelManufactureDate = cboMonth === '' || cboYear === '' ? '' : new Date(concatDate);
    const newValues = { ...values, panelManufactureDate };
    action(newValues);
    setSubmitting(false);
  };

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validate={values => {
          const errors = {};
          if (values.endDate && new Date(values.endDate) <= new Date(values.startDate)) {
            errors.endDate = 'End date should be greater than start date';
          }

          return errors;
        }}
        validationSchema={StudiesSchema}
        onSubmit={submitForm}
      >
        {({ isSubmitting, dirty, isValid, errors, touched }) => (
          <Form>
            <Field
              type="text"
              name="name"
              placeholder="Name"
              label="Name"
              upperClass="form-group"
              component={Input}
            />
            <Field
              type="text"
              name="year"
              placeholder="Year"
              label="Year"
              upperClass="form-group"
              component={Input}
            />
            <Field
              name="startDate"
              placeholder="Start Date"
              label="Start Date"
              upperClass="form-group"
              component={DatePicker}
            />

            <Field
              name="endDate"
              placeholder="End Date"
              label="End Date"
              upperClass="form-group"
              component={DatePicker}
            />
            <div className="form-row">
              <label htmlFor="panelMYearCustomDatePicker" className="col-sm-12">
                Panel Manufacture Date
              </label>
              <div className="col-lg-6 form-group">
                <Field
                  name="cboMonth"
                  component="select"
                  placeholder="Select Month"
                  className={
                    errors.cboMonth
                      ? 'mr-2 form-control form-control is-invalid'
                      : 'mr-2 form-control form-control'
                  }
                >
                  <option defaultValue>Select a month</option>
                  {monthNames.map(month => (
                    <option value={month} key={month}>
                      {month}
                    </option>
                  ))}
                </Field>
                {touched.cboMonth && errors.cboMonth && (
                  <span className="invalid-feedback">{errors.cboMonth}</span>
                )}
              </div>
              <div className="col-lg-6 form-group">
                <Field
                  name="cboYear"
                  component="select"
                  placeholder="Select Year"
                  className={
                    errors.cboYear
                      ? 'mr-2 form-control form-control is-invalid'
                      : 'mr-2 form-control form-control'
                  }
                >
                  <option defaultValue>Select a year</option>
                  {Array.apply(null, {
                    length: 10,
                  }).map((e, i) => (
                    <option value={currentYear - i} key={i.toString()}>
                      {currentYear - i}
                    </option>
                  ))}
                </Field>
                {touched.cboYear && errors.cboYear && (
                  <span className="invalid-feedback">{errors.cboYear}</span>
                )}
              </div>
            </div>
            <Field
              name="notes"
              placeholder="Notes"
              classNameParent="form-group"
              component={TextArea}
            />
            <div>
              <button
                type="submit"
                className="btn btn-primary mr-2"
                disabled={!isValid || isSubmitting || !dirty}
              >
                {isSubmitting ? 'Submiting...' : 'Save'}
              </button>
              <button type="button" className="btn btn-link text-danger" onClick={cancelAction}>
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default NewStudyForm;
