import React from 'react';
import { customTestInterpretation } from '../../../helpers/constants';
import ErrorMessage from '../../lib/errorMessage';

const customRowSelect = ({
  field, // { name, value, onChange, onBlur }
  handleChange,
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <>
      <select
        {...props}
        className="form-control custom-select "
        {...field}
        onChange={handleChange}
        placeholder="Select an Option"
      >
        <option value="">Select an Option</option>
        <option value={customTestInterpretation.POSITIVE}>Positive</option>
        <option value={customTestInterpretation.NEGATIVE}>Negative</option>
        <option value={customTestInterpretation.EQUIVOCAL}>Equivocal</option>
        <option value={customTestInterpretation.NA}>Not Applicable</option>
      </select>
      <ErrorMessage name={field.name} />
    </>
  );
};

export default customRowSelect;
