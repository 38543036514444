// {“idLab”: {“enrollmentId”: {…the data}}}
import { createSelector } from 'redux-bundler';
import { roles } from '../helpers/constants';

const ERROR_TIME = 15000;
const REFRESH_TIME = 300000;

const ActionTypes = {
  FETCH_LAB_ENROLLMENT_RESULTS_REPORT_START: 'FETCH_LAB_ENROLLMENT_RESULTS_REPORT_START',
  FETCH_LAB_ENROLLMENT_RESULTS_REPORT_ERROR: 'FETCH_LAB_ENROLLMENT_RESULTS_REPORT_ERROR',
  FETCH_LAB_ENROLLMENT_RESULTS_REPORT_SUCCESS: 'FETCH_LAB_ENROLLMENT_RESULTS_REPORT_SUCCESS',

  CLEAR_LAB_ENROLLMENT_RESULTS_REPORT: 'CLEAR_LAB_ENROLLMENT_RESULTS_REPORT',
};
export default {
  name: 'labsEnrollmentResultsReport',
  getReducer: () => {
    const initialState = {
      loading: false,
      lastError: null,
      lastFetch: null,
      data: null,
      error: null,
    };

    return (state = initialState, { type, payload }) => {
      switch (type) {
        case ActionTypes.FETCH_LAB_ENROLLMENT_RESULTS_REPORT_START:
          return { ...state, loading: true };

        case ActionTypes.FETCH_LAB_ENROLLMENT_RESULTS_REPORT_SUCCESS:
          return {
            ...state,
            loading: false,
            error: null,
            lastError: null,
            lastFetch: new Date(),
            data: payload,
          };

        case ActionTypes.FETCH_LAB_ENROLLMENT_RESULTS_REPORT_ERROR:
          return {
            ...state,
            loading: false,
            error: payload,
            lastError: new Date(),
          };

        default:
          return state;
      }
    };
  },
  selectLabsEnrollmentResultsReportDataRaw: state => state.labsEnrollmentResultsReport,
  selectLabsEnrollmentResultsReportData: state => state.labsEnrollmentResultsReport.data,
  selectLabsEnrollmentResultsReportErrorMessage: createSelector(
    'selectLabsEnrollmentResultsReportDataRaw',
    labsEnrollmentResultsReportDataRaw => {
      return labsEnrollmentResultsReportDataRaw.error &&
        labsEnrollmentResultsReportDataRaw.error.message
        ? labsEnrollmentResultsReportDataRaw.error.message
        : null;
    },
  ),
  selectLabResultReport: createSelector(
    'selectLabsEnrollmentResultsReportData',
    'selectRouteParams',
    (labsEnrollmentResultsReportData, routeParams) => {
      if (
        !labsEnrollmentResultsReportData ||
        !labsEnrollmentResultsReportData[routeParams.labId] ||
        !labsEnrollmentResultsReportData[routeParams.labId][routeParams.enrollmentId]
      ) {
        return null;
      }
      return labsEnrollmentResultsReportData[routeParams.labId][routeParams.enrollmentId];
    },
  ),
  doFetchlabEnrollmentResultsReport: enrollmentId => ({ dispatch, apiFetch, getState }) => {
    dispatch({ type: ActionTypes.FETCH_LAB_ENROLLMENT_RESULTS_REPORT_START });
    apiFetch({
      method: 'GET',
      endpoint: `enrollments/${enrollmentId}/studyResultsReport`,
      redirectOnNotFound: true,
    })
      .then(response => {
        const existingLabsEnrollmentResultsReport =
          getState().labsEnrollmentResultsReport.data || {};

        let payload = {};

        if (!existingLabsEnrollmentResultsReport[response?.labParticipant?.id]) {
          payload = {
            ...existingLabsEnrollmentResultsReport,
            [response.labParticipant.id]: {
              [enrollmentId]: {
                ...response,
              },
            },
          };
        } else {
          payload = {
            ...existingLabsEnrollmentResultsReport,
            [response.labParticipant.id]: {
              ...existingLabsEnrollmentResultsReport[response.labParticipant.id],
              [enrollmentId]: {
                ...response,
              },
            },
          };
        }

        dispatch({ type: ActionTypes.FETCH_LAB_ENROLLMENT_RESULTS_REPORT_SUCCESS, payload });
      })
      .catch(err => {
        dispatch({ type: ActionTypes.FETCH_LAB_ENROLLMENT_RESULTS_REPORT_ERROR, payload: err });
      });
  },
  // Reactors
  reactShouldFetchLabsEnrollmentReport: createSelector(
    'selectLabsEnrollmentResultsReportDataRaw',
    'selectRouteParams',
    'selectUserRole',
    'selectAppTime',
    'selectRouteInfo',
    (labsEnrollmentResultsReportDataRaw, routeParams, userRole, appTime, routeInfo) => {
      const { enrollmentId, labId } = routeParams;
      const routeToMatch = '/labs/:labId/enrollments/:enrollmentId/studyResultsReport';

      if (
        !userRole ||
        (userRole !== roles.ADMIN && userRole !== roles.POC) ||
        labsEnrollmentResultsReportDataRaw.loading ||
        !routeInfo.pattern.includes(routeToMatch) ||
        !enrollmentId
      ) {
        return null;
      }

      let shouldFetch = false;
      if (
        (!labsEnrollmentResultsReportDataRaw.data &&
          !labsEnrollmentResultsReportDataRaw.lastError) ||
        !labsEnrollmentResultsReportDataRaw.data[labId] ||
        !labsEnrollmentResultsReportDataRaw.data[labId][enrollmentId]
      ) {
        shouldFetch = true;
      } else if (labsEnrollmentResultsReportDataRaw.lastError) {
        const timePassed = appTime - labsEnrollmentResultsReportDataRaw.lastError;
        if (timePassed > ERROR_TIME) {
          shouldFetch = true;
        }
      } else if (labsEnrollmentResultsReportDataRaw.lastFetch) {
        const timePassed = appTime - labsEnrollmentResultsReportDataRaw.lastFetch;
        if (timePassed > REFRESH_TIME) {
          shouldFetch = true;
        }
      }
      if (shouldFetch) {
        return {
          actionCreator: 'doFetchlabEnrollmentResultsReport',
          args: [enrollmentId],
        };
      }
    },
  ),
};
