import React from 'react';
import { Field, Form, Formik } from 'formik';
import { connect } from 'redux-bundler-react';
import TextArea from '../../lib/TextArea';
import './AdminStudyNotesForm.css';

const AdminStudyNotesForm = ({ labId, enrollmentId, notes, doUpdateEnrollmentAdminNote }) => {
  const initialValues = {
    notes: notes || '',
  };

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      await doUpdateEnrollmentAdminNote(labId, enrollmentId, values.notes);
    } catch (err) {
      console.log('Error updating lab enrollment admin note');
    }
    setSubmitting(false);
  };

  return (
    <div id="AdminStudyNotesForm">
      <h4 className="mb-4">Admin Notes</h4>
      <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, errors, isSubmitting, dirty, touched }) => (
          <Form>
            <Field name="notes" classNameParent="col-12 p-0" component={TextArea} />
            <div className="text-right">
              <button type="submit" className="btn btn-primary mr-2 mt-4">
                Save
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default connect('doUpdateEnrollmentAdminNote', AdminStudyNotesForm);
