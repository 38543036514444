/* eslint-disable no-constant-condition */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-spread */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import isEqual from 'lodash/isEqual';
import { Form, Formik, Field } from 'formik';
import { validateFirstIndex, validateSecondIndex } from './customValidations';
import EditableRowsForExpectedResults from './EditableRowsForExpectedResults';
import HeadTable from './HeadTable';
import InputEIA from './InputEIA';
import DisplaySumCode from './DisplaySumCode';
import GenotypingExpectedInputField from './GenotypingExpectedInputField';
import SelectEIAResult from './SelectEIAResult';
import inputError from './inputError';
import MinTestInterpretationMessage from './MinTestInterpretationMessage';
import sampleIDLengthExample from './sampleIDLengthExample';
import useFormatExpectedResultsResponse from '../../../hooks/useFormatExpectedResultsResponse';
import { testInterpretationStoreResult } from '../../../../helpers/constants';

import {
  setEIAPayload,
  isThisValueRepeated,
  isAValidExpectedValueToAdd,
  setGenotypePayload,
  newGenotypeExpectedResultExist,
  genotypeContainsInValidCharacters,
} from '../../../../utils';

/**
 *
 * The study expected result form
 *
 * @type {React.Component}
 * @param {Object}        props
 * @param {string}        props.submitText                        'Text for the submit button
 * @param {Object}        props.schema                            'Yup schema definition
 * @param {Object}        props.initValues                        'Initial values when empty
 * @param {number|string} props.studyId                           'The study ID
 * @param {Function}      props.createEIA                         'The create EIA expected result function
 * @param {Function}      props.createGenotype                    'The create Genotype expected result function
 * @param {Function}      props.updateEIA                         'The update EIA expected result function
 * @param {Function}      props.updateGenotype                    'The update genotype expected result function
 * @param {Array}         props.expectedTestResultForStudy        'Array of existing expected result for this study
 * @param {Function}      props.updateAllTheExpectedResults       'The update all the expected result function
 * @param {Function}      props.createAllTheExpectedResults       'The create all the expected result function
 * @param {Function}      props.updateEiaAndCreateGenotype        'The update EIA and create Genotype Expected result
 * @param {Function}      props.deleteGenotypeRecords             'The delete genotype expected genotype function
 *
 */
const ExpectedResultForm = ({
  submitText,
  schema,
  initValues,
  studyId,
  createEIA,
  createGenotype,
  updateEIA,
  updateGenotype,
  expectedTestResultForStudy,
  updateAllTheExpectedResults,
  createAllTheExpectedResults,
  updateEiaAndCreateGenotype,
  deleteGenotypeRecords,
}) => {
  const INIT_EXPECTED_VALUE_INPUTS = { sumCode: 0, eiaResult: '', expectedGenotype: '' };
  const [expectedValue, setExpectedValue] = useState(INIT_EXPECTED_VALUE_INPUTS);
  const [errorMessage, setErrorMessage] = useState(null);
  const [ids, setIds] = useState([]);
  const [data] = useFormatExpectedResultsResponse(expectedTestResultForStudy);

  // Function to store values that will be added in the expected result table
  // once onClickAddNewExpectedValue function is triggered
  const addNewExpectedValue = (key, value) => {
    const valueToUpperCase = value.toUpperCase();

    // Set expectedGenotype to NEGATIVE when eiaResult is set to NEGATIVE
    if (key === 'eiaResult' && value === testInterpretationStoreResult.NEGATIVE) {
      setExpectedValue({
        ...expectedValue,
        [key]: value,
        expectedGenotype: testInterpretationStoreResult.NEGATIVE,
      });
      return;
    }

    // If eiaResult is set to POSITIVE check current value of expectedGenotype
    // If current value is NEGATIVE change to '' or keep the current value otherwise
    if (key === 'eiaResult' && value === testInterpretationStoreResult.POSITIVE) {
      setExpectedValue({
        ...expectedValue,
        [key]: value,
        expectedGenotype:
          expectedValue.expectedGenotype === testInterpretationStoreResult.NEGATIVE
            ? ''
            : expectedValue.expectedGenotype,
      });
      return;
    }

    setExpectedValue({
      ...expectedValue,
      [key]: key === 'expectedGenotype' ? valueToUpperCase : value,
    });
  };

  // click event bind to add genotype to table of expected results
  // Some checks happens here
  // 1) invalid or repeated genotype 2) existing sumCode
  const onClickAddNewExpectedValue = (fn, expectedValues) => {
    if (
      newGenotypeExpectedResultExist({
        arrayOfExpectedResults: expectedValues,
        newExpectedResult: expectedValue,
      })
    ) {
      setErrorMessage('You cannot set duplicated values');
      return;
    }

    const stringHasInvalidCharacters = genotypeContainsInValidCharacters({
      newExpectedResult: expectedValue,
    });

    if (stringHasInvalidCharacters) {
      setErrorMessage(stringHasInvalidCharacters);
      return;
    }

    // Go ahead and add the genotype
    setErrorMessage(null);
    const { sumCode, eiaResult, expectedGenotype } = expectedValue;
    const currentExpectedValues = [...expectedValues];
    const newGenotype =
      eiaResult === testInterpretationStoreResult.NEGATIVE
        ? testInterpretationStoreResult.NEGATIVE
        : expectedGenotype;
    currentExpectedValues.push({
      sumCode,
      eiaResult,
      expectedGenotype: {
        expectedGenotype: newGenotype,
        sumCode: +sumCode,
        EIAResult: eiaResult,
      },
    });
    fn('expectedResults', currentExpectedValues);
    setExpectedValue(INIT_EXPECTED_VALUE_INPUTS);
  };

  async function submitExpectedResult(rules, expectedValues) {
    /** IN ANY CASE OF THIS VALIDATION THE EIA SHOULD GO FIRST */

    const isGenotypingInStore = expectedTestResultForStudy.some(expItem => expItem.GenotypingTest);
    const genotypeFormattedPayload = setGenotypePayload(expectedValues);

    const isGenotypeInFormik = genotypeFormattedPayload?.expectedResults.length;
    const eiaHasNotChanged = data?.expectedResults
      ? isEqual(setEIAPayload(rules, expectedValues), setEIAPayload(rules, data?.expectedResults))
      : false;
    if (data?.eIAExpectedResults.length) {
      /** SO THIS MEANS IS A PATCH TO EIA */

      /** NOW CHECK IF THERE'S A GENOTYPE IN THE STORE and IN FORMIK */

      if (isGenotypingInStore && isGenotypeInFormik) {
        /** THIS IS A PATCH FOR BOTH */

        if (eiaHasNotChanged) {
          await updateGenotype(studyId, setGenotypePayload(expectedValues));
        } else {
          await updateAllTheExpectedResults(
            studyId,
            setEIAPayload(rules, expectedValues),
            setGenotypePayload(expectedValues),
          );
        }
      }
      /** CHECK IF THERE IS NOT IN STORE AND ITS IN FORMIK */
      if (!isGenotypingInStore && isGenotypeInFormik) {
        /** PATCH FOR EIA and POST FOR GENOTYPE  */
        if (eiaHasNotChanged) {
          await createGenotype(studyId, setGenotypePayload(expectedValues));
        } else {
          await updateEiaAndCreateGenotype(
            studyId,
            setEIAPayload(rules, expectedValues),
            setGenotypePayload(expectedValues),
          );
        }
      }
    }
    if (!data?.eIAExpectedResults.length && isGenotypeInFormik) {
      /** SO THIS MEANS IS A POST TO BOTH TEST */

      await createAllTheExpectedResults(
        studyId,
        setEIAPayload(rules, expectedValues),
        setGenotypePayload(expectedValues),
      );
    }
    if (!data?.eIAExpectedResults.length && !isGenotypeInFormik) {
      /** SO THIS MEANS IS A POST EIA */

      await createEIA(studyId, setEIAPayload(rules, expectedValues));
    }
    if (data?.eIAExpectedResults.length && !eiaHasNotChanged && !isGenotypeInFormik) {
      /** If eia is the store and has changed in FORMIK and Genotype is not being submitted,
       * lets just update EIA  */
      await updateEIA(studyId, setEIAPayload(rules, expectedValues));
    }
  }

  function addGenotypeRecordsIdsToDelete(id, index, removeRecordFromFormik) {
    const currIds = [...ids];
    currIds.push(id);
    setIds(currIds);
    removeRecordFromFormik(index);
  }

  return (
    <Formik
      enableReinitialize
      initialValues={data || initValues}
      validationSchema={schema}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const { expectedResults, sampleLength, firstIndex, secondIndex } = values;
          // HERE DELETE THE IDS of GENOTYPE
          if (ids.length) {
            await deleteGenotypeRecords(studyId, ids);
          }
          await submitExpectedResult({ sampleLength, firstIndex, secondIndex }, expectedResults);
          setSubmitting(false);
        } catch (err) {
          setSubmitting(false);
        }
      }}
      validate={values => {
        const errors = {};
        if (!values.expectedResults.length) {
          errors.expectedResultIsEmpty = 'At least 1 expected value should be filled';
        }
        return errors;
      }}
    >
      {({ values, errors, touched, dirty, setFieldValue, isSubmitting, handleChange }) => (
        <Form className="mb-3">
          <div className="row mb-2">
            <div className="col-12 col-md-12 col-xl-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="header-pretitle">Set Expected Results Rules</h4>

                  <div className="text-right ">
                    <a href={`/studies/${studyId}`} className="btn btn-md btn-link">
                      Go To Study Detail
                    </a>
                  </div>
                </div>

                <div className="card-body">
                  <div>
                    <ul className="list-group list-group-flush">
                      <li className="row list-group-item d-flex align-items-center px-0">
                        <Field
                          name="sampleLength"
                          labelContainerClassName="col-9 col-lg-11"
                          inputContainerClassName="col-3 col-lg-1 px-0"
                          extraInputClass="bg-white"
                          labelTxt="Sample ID Number Length"
                          labelId="sampleLength"
                          component={InputEIA}
                          disabled
                          handleChange={handleChange}
                        />
                        {inputError(touched, errors, 'sampleLength')}
                      </li>
                      <li className="row list-group-item d-flex align-items-center px-0">
                        <Field
                          name="firstIndex"
                          labelTxt="1st Item Position"
                          labelId="firstIndex"
                          labelContainerClassName="col-9 col-lg-4"
                          inputContainerClassName="col-3 col-lg-1 px-0"
                          component={InputEIA}
                          extraInputClass="bg-white"
                          disabled={data?.firstIndex}
                          handleChange={handleChange}
                          validate={() =>
                            validateFirstIndex(
                              values.firstIndex,
                              values.sampleLength,
                              values.secondIndex,
                            )
                          }
                        />
                        <div className="col-12 col-lg-2 my-2 my-lg-0" />
                        <Field
                          name="secondIndex"
                          labelTxt="2nd Item Position"
                          labelId="secondIndex"
                          labelContainerClassName="col-9 col-lg-4"
                          inputContainerClassName="col-3 col-lg-1 px-0"
                          component={InputEIA}
                          extraInputClass="bg-white"
                          disabled={data?.secondIndex}
                          validate={() =>
                            validateSecondIndex(values.sampleLength, values.secondIndex)
                          }
                          handleChange={handleChange}
                        />
                        {inputError(touched, errors, 'firstIndex')}
                        {inputError(touched, errors, 'secondIndex')}
                      </li>
                      <li className="row list-group-item d-flex align-items-center px-0">
                        {sampleIDLengthExample(values)}
                      </li>
                    </ul>
                  </div>

                  <div className="card-header px-0 mt-5">
                    <h4 className="header-pretitle">Set Expected Results Values</h4>
                  </div>

                  <div className="row d-flex align-items-end px-0 mt-3">
                    <DisplaySumCode
                      total={expectedValue.sumCode}
                      addNewExpectedValue={addNewExpectedValue}
                    />
                    <SelectEIAResult
                      value={expectedValue.eiaResult}
                      onChange={addNewExpectedValue}
                    />
                    <GenotypingExpectedInputField
                      data={expectedValue}
                      value={expectedValue.expectedGenotype}
                      onChange={addNewExpectedValue}
                    />
                    <div className="col-12 col-sm-6 col-lg-2 text-center">
                      <button
                        id="addNewExpectedValue"
                        type="button"
                        disabled={
                          !isAValidExpectedValueToAdd(
                            expectedValue.sumCode,
                            expectedValue.eiaResult,
                          )
                        }
                        className="btn btn-primary w-100"
                        onClick={() =>
                          onClickAddNewExpectedValue(setFieldValue, values.expectedResults)
                        }
                      >
                        Add
                      </button>
                    </div>
                    {errorMessage && (
                      <div className="invalid-feedback col-12" style={{ display: 'block' }}>
                        {errorMessage}
                      </div>
                    )}
                  </div>
                </div>
                {/* HERE */}

                <div className="col-12 align-items-center justify-content-between mt-3">
                  <div className="table-responsive">
                    <table className="table table-sm table-nowrap table-outline card-table mb-0">
                      <HeadTable />
                      <tbody>
                        <EditableRowsForExpectedResults
                          handleChange={handleChange}
                          values={values.expectedResults}
                          validateOnRepeatedSumCode={isThisValueRepeated}
                          setFieldValue={setFieldValue}
                          deleteGenotypeByIds={addGenotypeRecordsIdsToDelete}
                        />
                      </tbody>
                    </table>
                  </div>
                  <div className="row mt-4 mb-4 justify-content-between align-items-center">
                    <MinTestInterpretationMessage message={errors.expectedResultIsEmpty} />
                    <div className="col-auto">
                      <button
                        type="submit"
                        className="btn btn-outline-primary"
                        disabled={isSubmitting || !dirty}
                      >
                        {submitText}
                      </button>
                      <a href={`/studies/${studyId}`} className="btn btn-outline-danger ml-3">
                        Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ExpectedResultForm;
