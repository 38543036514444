import React from 'react';

/**
 *
 * @typedef {React.Component}
 * @param {String} justificationNotes   Shows Justification Notes if they exist          T
 */
const LabSubscribedTestResultsJustificationNotes = ({ justificationNotes }) => {
  return (
    <div className="card text-left">
      <div className="card-header">
        <h4 className="card-header-title header-pretitle">Justification Notes</h4>
      </div>
      <div className="col pl-5">
        <p>{justificationNotes}</p>
      </div>
    </div>
  );
};

export default LabSubscribedTestResultsJustificationNotes;
