import React from 'react';

const MinTestInterpretationMessage = ({ message }) => {
  return (
    <div className="col-auto mb-3 mb-sm-0 mb-md-3 mb-lg-0">
      {message && (
        <small>
          <span className="text-danger">* </span>
          <span className="text-muted">{message}</span>
        </small>
      )}
    </div>
  );
};

export default MinTestInterpretationMessage;
