import React from 'react';
import orderBy from 'lodash/orderBy';
import RowValueForReportedTest from './RowValueForReportedTest';
import { extraPropertiesForResultsTable, enrolledTestStatus } from '../../helpers/constants';

/**
 *
 * @param {Array} Props.columnsHeaders The array of strings for the headers table
 * @param {Array} Props.items The Arrays of values for the test
 * @param {ReactDOM} Props.children React component
 * @param {String} Props.status The status of the test FINALIZED|GRADING_PENDING|INITIAL_STATE
 * @param {Boolean} Props.GenotypingTest A boolean value to check if this is comming from EIA|GENOTYPE
 * @param {Boolean} Props.EIATest A boolean value to check if this is comming from EIA|GENOTYPE
 */
const TestResultsTable = ({ columnsHeaders, items, children, status, GenotypingTest, EIATest }) => {
  const results = orderBy(items, ['id']);

  let extraProperties = [];
  if (GenotypingTest) {
    extraProperties =
      status === enrolledTestStatus.FINALIZED
        ? [...extraPropertiesForResultsTable, 'suggestedScore', 'score']
        : [
            ...extraPropertiesForResultsTable,
            'finalScore',
            'score',
            'suggestedScore',
            'overrideNote',
          ];
  }
  if (EIATest) {
    extraProperties =
      status === enrolledTestStatus.FINALIZED
        ? [...extraPropertiesForResultsTable, 'suggestedScore', 'score']
        : [
            ...extraPropertiesForResultsTable,
            'finalScore',
            'score',
            'suggestedScore',
            'overrideNote',
          ];
  }

  return (
    <div className="table-responsive">
      <table className="table table-nowrap mb-0">
        <thead>
          <tr>
            {columnsHeaders &&
              columnsHeaders.map((header, index) => (
                <th className="bg-transparent border-top-0" key={index.toString()}>
                  <span className="h6">{header}</span>
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {results &&
            results.map(item => (
              <tr key={item.id}>
                {Object.keys(item).map(prop => {
                  if (!extraProperties.includes(prop)) {
                    return (
                      <RowValueForReportedTest
                        item={item}
                        prop={prop}
                        key={`item-${item.id}-${prop}`}
                      />
                    );
                  }
                  return null;
                })}
              </tr>
            ))}
          {children}
        </tbody>
      </table>
    </div>
  );
};

export default TestResultsTable;
