import { createSelector } from 'redux-bundler';
import { roleHomeRedirect } from '../utils';

export default {
  name: 'nav',
  selectNavLinks: createSelector('selectUserRole', 'selectRoutes', (userRole, routes) => {
    if (routes) {
      return Object.keys(routes).reduce((acc, route) => {
        const routeInfo = routes[route].value;
        if (
          routeInfo.mainNav &&
          ((routeInfo.roles && routeInfo.roles.includes(userRole)) || !routeInfo.roles)
        ) {
          acc.push({
            url: route,
            label: routeInfo.mainNav.label || routeInfo.title,
            icon: routeInfo.mainNav.icon || routeInfo.icon,
            ...routeInfo.mainNav,
          });
        }
        return acc;
      }, []);
    }
  }),

  selectUserAuthorized: createSelector('selectUserRole', 'selectRoute', (userRole, route) =>
    route.roles ? route.roles.includes(userRole) : true,
  ),
  selectEncodedRedirectionPath: createSelector(
    'selectRouteInfo',
    'selectUserAuthorized',
    'selectUrlObject',
    (routeInfo, userAuthorized, urlObject) => {
      return !userAuthorized && urlObject.pathname !== '/'
        ? `?redirectTo=${encodeURIComponent(routeInfo.url)}`
        : '';
    },
  ),
  reactShouldRedirectRestricted: createSelector(
    'selectUserRole',
    'selectUserAuthorized',
    'selectEncodedRedirectionPath',
    (userRole, userAuthorized, encodedRedirectionPath) => {
      if (!userAuthorized) {
        return {
          actionCreator: 'doUpdateUrl',
          args: [roleHomeRedirect(userRole, encodedRedirectionPath)],
        };
      }
    },
  ),
  reactShouldRedirectHome: createSelector(
    'selectUserRole',
    'selectUrlObject',
    (userRole, urlObject) => {
      if (urlObject.pathname === '/') {
        return { actionCreator: 'doUpdateUrl', args: [roleHomeRedirect(userRole)] };
      }
    },
  ),
};
