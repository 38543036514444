/* eslint-disable prefer-spread */
import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';
import { enrolledTestStatus } from '../../helpers/constants';

/**
 * This custom hook is to return the EIA previous data
 *
 * @param  {Object} data The previous data of the EIA Test
 * @return {Object}
 */
export const useCurrentEIADataHook = data => {
  if (!isEmpty(data) && data?.EIATest && data?.status === enrolledTestStatus.UNLOCKED) {
    const { investigator, testDate, amplificationRounds, EIATest } = data;
    const eiaResults = orderBy(EIATest.EIAResults, ['id']);

    return {
      investigator,
      testDate,
      id: amplificationRounds[0].id,
      AmplificationKitId: !amplificationRounds[0].AmplificationKitId
        ? '0'
        : amplificationRounds[0].AmplificationKitId,
      otherAmplificationKitBrand: !amplificationRounds[0].otherAmplificationKitBrand
        ? ''
        : amplificationRounds[0].otherAmplificationKitBrand,
      vendorName: amplificationRounds[0].vendorName,
      serialNumber: amplificationRounds[0].serialNumber,
      expiryDate: amplificationRounds[0].expiryDate,
      eiaResults: eiaResults.map(res => {
        return {
          id: res.id,
          EIATestId: res.EIATestId,
          sampleIDNumber: res.sampleIDNumber,
          antigenOD: res.antigenOD,
          testInterpretation: res.testInterpretation,
        };
      }),
      antigenODPositiveControl: EIATest.antigenODPositiveControl,
      antigenODNegativeControl: EIATest.antigenODNegativeControl,
      positiveControlTestInterpretation: EIATest.positiveControlTestInterpretation,
      negativeControlTestInterpretation: EIATest.negativeControlTestInterpretation,
      kitCutOffFormula: EIATest.kitCutOffFormula,
      kitCutOff: EIATest.kitCutOff,
    };
  }
};

/**
 * This custom hook is to return the Genotype previous data
 *
 * @param  {Object} data The previous data of the Genotype Test
 * @return {Object}
 */
export const useCurrentGenotypeDataHook = data => {
  if (!isEmpty(data) && data?.GenotypingTest && data?.status === enrolledTestStatus.UNLOCKED) {
    const { investigator, testDate, amplificationRounds, GenotypingTest } = data;
    const genotypeResults = orderBy(GenotypingTest.GenotypingResults, ['id']);

    return {
      investigator,
      testDate,
      amplificationRounds: amplificationRounds.map(res => {
        return {
          id: res.id,
          vendorName: res.vendorName,
          serialNumber: res.serialNumber,
          expiryDate: res.expiryDate,
          numberOfRound: res.numberOfRound,
          AmplificationKitId: !res.AmplificationKitId ? '0' : res.AmplificationKitId,
          otherAmplificationKitBrand: !res.otherAmplificationKitBrand
            ? ''
            : res.otherAmplificationKitBrand,
        };
      }),
      genotypingResults: genotypeResults.map(res => {
        return {
          id: res.id,
          sampleIDNumber: res.sampleIDNumber,
          gGenotypeVP7: res.gGenotypeVP7,
          gGenotypeVP7Value: !res.gGenotypeVP7Value ? '' : res.gGenotypeVP7Value,
          pGenotypeVP4: res.pGenotypeVP4,
          pGenotypeVP4Value: !res.pGenotypeVP4Value ? '' : res.pGenotypeVP4Value,
          finalGenotype: res.finalGenotype,
          finalGenotypeValue: !res.finalGenotypeValue ? '' : res.finalGenotypeValue,
        };
      }),
      gGenotypeVP7PositiveControl: GenotypingTest.gGenotypeVP7PositiveControl,
      pGenotypeVP4PositiveControl: GenotypingTest.pGenotypeVP4PositiveControl,
      finalGenotypePositiveControl: GenotypingTest.finalGenotypePositiveControl,
      gGenotypeVP7NegativeControl: GenotypingTest.gGenotypeVP7NegativeControl,
      pGenotypeVP4NegativeControl: GenotypingTest.pGenotypeVP4NegativeControl,
      finalGenotypeNegativeControl: GenotypingTest.finalGenotypeNegativeControl,
    };
  }
};

/**
 * This custom hook is to initialize the genotype data with the sample ids from the EIA test if this was already submitted
 *
 * @param {Object} sampleIds Object with the sample ids from the EIA
 * @return {Object}
 */
export const useGenotypeSampleIdsHook = sampleIds => {
  if (!isEmpty(sampleIds) && sampleIds?.isSubmitted === true) {
    return {
      investigator: '',
      testDate: '',
      amplificationRounds: [],
      genotypingResults: Array.apply(null, { length: 10 }).map((e, i) => {
        return {
          sampleIDNumber: sampleIds.submittedSamples[i],
          gGenotypeVP7: '',
          gGenotypeVP7Value: '',
          pGenotypeVP4: '',
          pGenotypeVP4Value: '',
          finalGenotype: '',
          finalGenotypeValue: '',
        };
      }),
      gGenotypeVP7PositiveControl: '',
      pGenotypeVP4PositiveControl: '',
      finalGenotypePositiveControl: '',
      gGenotypeVP7NegativeControl: '',
      pGenotypeVP4NegativeControl: '',
      finalGenotypeNegativeControl: '',
    };
  }
};
