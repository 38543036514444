import React from 'react';
import { getIn } from 'formik';

const ListItemInput = ({
  field,
  form: { setFieldValue, touched, errors, values },
  title,
  className,
}) => {
  const invalidValue = getIn(touched, field.name) && getIn(errors, field.name);
  const currentValue = getIn(values, field.name);

  const classWithError =
    className && invalidValue ? 'list-group-item flex-fill border-danger' : className;

  return (
    <li
      className={
        classWithError ||
        `${
          invalidValue
            ? 'list-group-item d-lg-flex w-lg-20 border-danger'
            : 'list-group-item d-lg-flex w-lg-20'
        }`
      }
    >
      <span className={`${invalidValue ? 'column-title text-danger' : 'column-title text-muted'}`}>
        {invalidValue || title}
      </span>
      <input
        type="text"
        value={currentValue}
        className="form-control text-secondary my-auto"
        name={field.name}
        onChange={evt => {
          setFieldValue(`${field.name}`, evt.target.value);
        }}
      />
    </li>
  );
};

export default ListItemInput;
