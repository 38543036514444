/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

const DisplaySumCode = ({ total, addNewExpectedValue }) => {
  return (
    <>
      <div className="col-12 col-sm-6 col-lg-3 text-center">
        <label htmlFor="sumInputValue " className="text-muted">
          Sum Code
        </label>
        <input
          type="number"
          min="0"
          max="18"
          id="sumInputValue"
          className="form-control text-center"
          value={total}
          onChange={e => {
            addNewExpectedValue('sumCode', e.target.value);
          }}
        />
      </div>
    </>
  );
};

export default DisplaySumCode;
