import { useState } from 'react';

const useCheckbox = defaultValue => {
  const [checkbox, setCheckbox] = useState(defaultValue);

  function handleChange(check) {
    setCheckbox(check);
  }

  return [checkbox, handleChange];
};

export default useCheckbox;
