import { composeBundles, createCacheBundle } from 'redux-bundler';
import cache from '../helpers/cache';

/** Redux  Bundler and UI */
import api from './lib/api';
import appIdle from './lib/app-idle';
import routes from './routes';
import alerts from './alerts';
import nav from './nav';

/** Users and Auth --- */
import user from './user';
import pocs from './pocs';
import auth from './auth/auth';
import passwordReset from './auth/passwordReset';
import actions from './actions';
import labs from './labs';
/** - Ref Data - */
import whoRegions from './whoRegions';
import whoNetworkLabCategories from './whoNetworkLabCategories';
import amplificationKits from './amplificationKits';
import accessStatus from './accessStatus';
import countries from './countries';
import states from './states';
import refTests from './refTests';

/** Studies, Tests and Enrollment */
import studies from './studies';
import enrollment from './enrollment';
import labsEnrollments from './labsEnrollments';
import labsSubscribedTests from './labsSubscribedTests';
import tests from './tests';
import expectedTestsResults from './expectedTestsResults';
import enrolledTests from './enrolledTests';
import labsEnrollmentResultsReport from './labsEnrollmentResultsReport';
import studiesSummary from './studiesSummary';
import studyProgress from './studyProgress';

/** Grading */
import gradingResults from './gradingResults';

export default composeBundles(
  appIdle({ idleTimeout: process.env.NODE_ENV === 'production' ? 1000 : 15000 }),
  actions,
  alerts,
  api,
  labs,
  nav,
  pocs,
  routes,
  user,
  whoRegions,
  amplificationKits,
  whoNetworkLabCategories,
  accessStatus,
  refTests,
  auth,
  passwordReset,
  countries,
  states,
  studies,
  enrollment,
  tests,
  enrolledTests,
  expectedTestsResults,
  labsEnrollments,
  labsSubscribedTests,
  gradingResults,
  labsEnrollmentResultsReport,
  studiesSummary,
  studyProgress,
  createCacheBundle({ cacheFn: cache.set }),
);
