/* eslint-disable no-console */
import React, { useState } from 'react';

// import LabForm from '../forms/LabForm';
import LabInformation from '../views/LabInformation';
import PocVerificationForm from '../forms/PointOfContactVerificationForm';
import PrimaryPointOfContactVerificationForm from '../forms/PrimaryPointOfContactVerificationForm';

/**
 * @type {React.Component}
 * @param {string} verificationToken
 * @param {Object} pointOfContact
 * @param {Object} laboratory Information (Redux selector)

 */
const PocInformationVerification = ({ verificationToken, pointOfContact, laboratory }) => {
  const [pocVerifiedInfo] = useState(false);

  const renderVerification = () => {
    const { isVerified } = pointOfContact;
    return !isVerified ? (
      <React.Fragment>
        {pointOfContact.primaryPOC ? (
          <PrimaryPointOfContactVerificationForm
            poc={pointOfContact}
            laboratory={laboratory}
            confirmationToken={verificationToken}
          />
        ) : (
          <React.Fragment>
            {/* The laboratory information card */}
            <div className="card">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col">
                    <h4 className="card-header-title">
                      {pointOfContact.primaryPOC
                        ? 'Edit Laboratory Information'
                        : 'Laboratory Information'}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <LabInformation {...laboratory} />
              </div>
            </div>

            <div className="row">
              {/* The POC info card */}
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-header-title">My profile</h4>
                  </div>
                  <div className="card-body">
                    <PocVerificationForm
                      edit
                      poc={pointOfContact}
                      confirmationToken={verificationToken}
                    />
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    ) : (
      <div className="alert alert-light" role="alert">
        This Point of contact has already verified his/her information.
      </div>
    );
  };

  return (
    <React.Fragment>
      {pocVerifiedInfo && (
        <div className="alert alert-success fade show" role="alert">
          <h4 className="alert-heading">Contact information verified!</h4>
          <p>You have successfully verified your contact information.</p>
          <hr />
          <p className="mb-0">
            <a title="Go to lab page" href="/" className="alert-link">
              Go to my homepage
            </a>
          </p>
        </div>
      )}

      {!pointOfContact && (
        <div className="alert alert-danger" role="alert">
          Points of contact not found for this Lab Participant.
        </div>
      )}

      {pointOfContact && !pocVerifiedInfo && renderVerification()}
    </React.Fragment>
  );
};

export default PocInformationVerification;
