import React from 'react';
import { connect } from 'redux-bundler-react';
import LabSubscribedTestResultsTable from '../views/LabSubscribedTestResultsTable';
import LabSubscribedTestAmplificationRounds from '../views/LabSubscribedTestAmplificationRounds';
import { strCapitalize } from '../../utils';
import { testTypes, customTestInterpretation } from '../../helpers/constants';
import RedirectionNav from '../lib/RedirectionNav';
import LabSubscribedTestResultsJustificationNotes from '../views/LabSubscribedTestResultsJustificationNotes';

// Helper Functions
const shouldIncludeAdditionalColumns = (results) => {
  return results?.some((result) => result.finalScore !== null && result.overrideNote !== null);
};

/**
 * @typedef {React.Component}
 * @param {Object} currentLab                       Selector for the current lab participant information
 * @param {Object} labCurrentSubscribedTestsResult  Selector for the current subscribed test results
 * @param {Object} labSubscribedTestInvestigator    Selector for the POC that submitted results
 */

const LabEnrolledTestResultsPage = ({
  labCurrentSubscribedTestResultErrorMessage,
  currentLab,
  labCurrentSubscribedTestsResult,
  labSubscribedTestInvestigator,
}) => {
  const AdditionalEIAResultsColumns = ['Final Score', 'Overriding Comments'];
  const EIAResultsColumns = [
    'Sample ID Number', 
    'Antigen O.D', 
    'Test Interpretation', 
    ...(shouldIncludeAdditionalColumns(labCurrentSubscribedTestsResult?.EIATest?.EIAResults) ? AdditionalEIAResultsColumns: []),
  ];
  const GenotypeResultsColumns = [
    'Sample ID NUmber',
    'G-Genotype (VP7)',
    'P-Genotype(VP4)',
    'Final Genotype',
  ];

  const testType =
    labCurrentSubscribedTestsResult &&
    labCurrentSubscribedTestsResult.type &&
    testTypes[labCurrentSubscribedTestsResult.type]
      ? testTypes[labCurrentSubscribedTestsResult.type]
      : null;
  const amplificationRoundsTitle =
    labCurrentSubscribedTestsResult && labCurrentSubscribedTestsResult.EIATest
      ? 'EIA Kit Information'
      : 'Genotyping Kit Information';

  const redirectionNav = {
    navItems: [
      { route: '/labs', text: 'Labs' },
      {
        route: `/labs/${currentLab?.id}/enrollments/${labCurrentSubscribedTestsResult?.EnrollmentId}`,
        text: 'Lab Enrollment Details',
      },
    ],

    lastNavItemText: 'Test Results',
  };

  const resultsSummary = () => {
    if (labCurrentSubscribedTestsResult && labCurrentSubscribedTestsResult.type) {
      if (labCurrentSubscribedTestsResult.type === testTypes.GENOTYPING) {
        const { GenotypingTest } = labCurrentSubscribedTestsResult;
        return (
          <React.Fragment>
            <tfoot className="text-center">
              <tr>
                <td>
                  <strong>Positive Control</strong>
                </td>
                <td>{GenotypingTest.gGenotypeVP7PositiveControl}</td>
                <td>{GenotypingTest.pGenotypeVP4PositiveControl}</td>
                <td>{GenotypingTest.finalGenotypePositiveControl}</td>
              </tr>
              <tr>
                <td>
                  <strong>Negative Control</strong>
                </td>
                <td>{GenotypingTest.gGenotypeVP7NegativeControl}</td>
                <td>{GenotypingTest.pGenotypeVP4NegativeControl}</td>
                <td>{GenotypingTest.finalGenotypeNegativeControl}</td>
              </tr>
            </tfoot>
          </React.Fragment>
        );
      }
      if (labCurrentSubscribedTestsResult.type === testTypes.EIA) {
        const { EIATest } = labCurrentSubscribedTestsResult;
        return (
          <React.Fragment>
            <tfoot className="text-center">
              <tr>
                <td>
                  <strong>Positive Control</strong>
                </td>
                <td>{EIATest.antigenODPositiveControl}</td>
                <td>
                  {EIATest.positiveControlTestInterpretation === customTestInterpretation.NA
                    ? 'Not Applicable'
                    : strCapitalize(true, EIATest.positiveControlTestInterpretation)}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Negative Control</strong>
                </td>
                <td>{EIATest.antigenODNegativeControl}</td>
                <td>
                  {EIATest.negativeControlTestInterpretation === customTestInterpretation.NA
                    ? 'Not Applicable'
                    : strCapitalize(true, EIATest.negativeControlTestInterpretation)}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Kit cut-off Formula</strong>
                </td>
                <td>{EIATest.kitCutOffFormula}</td>
                <td />
              </tr>

              <tr>
                <td>
                  <strong>Kit cut-off value</strong>
                </td>
                <td>{EIATest.kitCutOff}</td>
                <td />
              </tr>
            </tfoot>
          </React.Fragment>
        );
      }
      return null;
    }
  };

  return (
    <>
      {labCurrentSubscribedTestsResult && (
        <RedirectionNav
          navItems={redirectionNav.navItems}
          lastNavItemText={redirectionNav.lastNavItemText}
        />
      )}
      <div className="row justify-content-center">
        <div className="col-12 col-lg-12 col-xl-12">
          {labCurrentSubscribedTestResultErrorMessage && (
            <div className="alert alert-primary" role="alert">
              <p className="my-auto">
                <i className="fe fe-info" />
                &nbsp;
                {labCurrentSubscribedTestResultErrorMessage}.&nbsp;
                <a href={`/lab/${currentLab.id}/edit`} className="alert-link">
                  Back to laboratory information
                </a>
              </p>
            </div>
          )}
          {labCurrentSubscribedTestsResult && (
            <div className="card">
              <div className="card-header">
                <h4 className="card-header-title text-left header-pretitle">Details</h4>
              </div>
              <div className="table-responsive mb-0">
                <table className="table table-sm table-nowrap card-table">
                  <thead className="text-center">
                    <tr>
                      <th>Laboratory</th>
                      <th>Submitted By</th>
                      <th>Test Type</th>
                      <th>Investigator</th>
                      <th>Test Date</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    <tr>
                      <td>
                        <span>{currentLab && `${currentLab.laboratoryName}`}</span>
                      </td>

                      <td>
                        <span>
                          {labCurrentSubscribedTestsResult &&
                            labSubscribedTestInvestigator &&
                            `${labSubscribedTestInvestigator.firstName} ${labSubscribedTestInvestigator.lastName}`}
                        </span>
                      </td>
                      <td>
                        <span>{testTypes && `${testType} Test`}</span>
                      </td>
                      <td>
                        <span>
                          {labCurrentSubscribedTestsResult &&
                            `${labCurrentSubscribedTestsResult.investigator}`}
                        </span>
                      </td>
                      <td>
                        <span>
                          {labCurrentSubscribedTestsResult &&
                            new Date(labCurrentSubscribedTestsResult.testDate).toDateString()}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {labCurrentSubscribedTestsResult &&
            labCurrentSubscribedTestsResult.amplificationRounds && (
              <LabSubscribedTestAmplificationRounds
                title={amplificationRoundsTitle}
                amplificationRounds={labCurrentSubscribedTestsResult.amplificationRounds}
                showMethodColumn={!!labCurrentSubscribedTestsResult.GenotypingTest}
              />
            )}

          <div>
            {labCurrentSubscribedTestsResult && (
              <div>
                <LabSubscribedTestResultsJustificationNotes
                  justificationNotes={labCurrentSubscribedTestsResult.justificationNotes}
                ></LabSubscribedTestResultsJustificationNotes>
              </div>
            )}
          </div>

          {labCurrentSubscribedTestsResult && labCurrentSubscribedTestsResult.EIATest && (
            <div>
              <LabSubscribedTestResultsTable
                resultsColumnsHeader={EIAResultsColumns}
                items={labCurrentSubscribedTestsResult.EIATest.EIAResults}
                enrolledTestId={labCurrentSubscribedTestsResult.EIATest.EnrolledTestId}
                currentLabID={currentLab.id}
                EIATest
                status={labCurrentSubscribedTestsResult.status}
              >
                {resultsSummary()}
              </LabSubscribedTestResultsTable>
            </div>
          )}

          {labCurrentSubscribedTestsResult && labCurrentSubscribedTestsResult.GenotypingTest && (
            <div>
              <LabSubscribedTestResultsTable
                resultsColumnsHeader={GenotypeResultsColumns}
                items={labCurrentSubscribedTestsResult.GenotypingTest.GenotypingResults}
                enrolledTestId={labCurrentSubscribedTestsResult.GenotypingTest.EnrolledTestId}
                currentLabID={currentLab.id}
                GenotypingTest
                status={labCurrentSubscribedTestsResult.status}
              >
                {resultsSummary()}
              </LabSubscribedTestResultsTable>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default connect(
  'selectCurrentLab',
  'selectLabCurrentSubscribedTestResultErrorMessage',
  'selectLabCurrentSubscribedTestsResult',
  'selectLabSubscribedTestInvestigator',
  LabEnrolledTestResultsPage,
);
