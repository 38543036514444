import React from 'react';
import format from 'date-fns/format';
import { statusObjTextForCard } from '../../helpers/constants';

const TestCard = ({ test: { id, name, EnrolledTest }, answerData }) => {
  return (
    <div className="col-12 col-lg-4">
      <div className="card">
        <div className="card-body">
          <h6 className="text-uppercase text-center text-muted my-4">
            {answerData.study ? `${answerData.study.name}, ${answerData.study.year}` : ''}
          </h6>
          <div className="row no-gutters align-items-center justify-content-center">
            <div className="col-auto">
              <div className="display-4 mb-3">{name}</div>
            </div>
          </div>

          <div className="mb-3">
            <ul className="list-group list-group-flush">
              <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                <small>Completion Date</small>
                <small> {format(new Date(EnrolledTest.testDate), 'PP')}</small>
              </li>
              <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                <small>Investigator</small>
                <small>{answerData ? answerData.investigator : ''}</small>
              </li>
              <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                <small>Current Status</small>
                {statusObjTextForCard && statusObjTextForCard[EnrolledTest.status] && (
                  <small>{statusObjTextForCard[EnrolledTest.status]}</small>
                )}
              </li>
              <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                <small>Submitted by</small>
                <small>
                  {answerData.createdBy
                    ? `${answerData.createdBy.firstName} ${answerData.createdBy.lastName}`
                    : ''}
                </small>
              </li>
            </ul>
          </div>

          <p className="text-center pt-2">
            <a
              href={`/enrolled-studies/${EnrolledTest.EnrollmentId}/submitted-tests/answers/${id}`}
              className="btn btn-block btn-outline-primary"
            >
              <i className="fe fe-eye" /> View Answers
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TestCard;
