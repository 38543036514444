import React from 'react';

/**
 *
 * @param {Array} props.navItems Array of an objects with the text and route for the navigation
 * @param {String} props.lastNavItemText Text for the Current Page In the Navigation
 */
const RedirectionNav = ({ navItems = [], lastNavItemText = '' }) => {
  return (
    <div className="card">
      <div className="card-header">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            {navItems.map(({ route, text }) => (
              <li className="breadcrumb-item" key={text}>
                <a href={route} title={text}>
                  {text}
                </a>
              </li>
            ))}
            <li className="breadcrumb-item active" aria-current="page">
              {lastNavItemText}
            </li>
          </ol>
        </nav>
      </div>
    </div>
  );
};

export default RedirectionNav;
