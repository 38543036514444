/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Field } from 'formik';
import TextArea from '../lib/TextArea';

/**
 *
 * @typedef {React.Component}
 * @param {string} adminGradingNotes The graded notes from Admin.
 * If the test status is FINALIZED, display the notes.
 * Otherwise, show an input box from Formik."
 */

const LabSubscribedTestResultsGradingAdminNotes = ({ statusIsFINALIZED, adminGradingNotes }) => {
  return (
    <div className="card text-left">
      <div className="card-header">
        <h4 className="card-header-title header-pretitle text-left">Testing Grading Admin Notes</h4>
      </div>
      {statusIsFINALIZED ? (
        <div className="col pl-5">
          <p>{adminGradingNotes}</p>
        </div>
      ) : (
        <div className="col pl-5">
          <Field
            name="adminGradingNotes"
            classNameParent="col-12 p-0"
            textPlaceHolder="Optional: notes from administrators after grading results"
            component={TextArea}
          />
        </div>
      )}

      <div className="card-footer d-flex justify-content-end">
        <a title="View test results grading" href="/dashboard" className="btn btn-primary">
          Back to Dashboard
        </a>
      </div>
    </div>
  );
};

export default LabSubscribedTestResultsGradingAdminNotes;
