import { createSelector } from 'redux-bundler';

const ERROR_TIME = 15000;
const REFRESH_TIME = 1800000;
const ActionTypes = {
  FETCH_AMPLIFICATION_KITS_START: 'FETCH_AMPLIFICATION_KITS_START',
  FETCH_AMPLIFICATION_KITS_ERROR: 'FETCH_AMPLIFICATION_KITS_ERROR',
  FETCH_AMPLIFICATION_KITS_SUCCESS: 'FETCH_AMPLIFICATION_KITS_SUCCESS',
};

export default {
  name: 'amplificationKits',
  getReducer: () => {
    const initialState = {
      loading: false,
      lastError: null,
      lastFetch: null,
      data: null,
      error: null,
    };

    // Reducer
    return (state = initialState, { type, payload }) => {
      switch (type) {
        case ActionTypes.FETCH_AMPLIFICATION_KITS_START:
          return Object.assign({}, state, {
            loading: true,
          });
        case ActionTypes.FETCH_AMPLIFICATION_KITS_ERROR:
          return Object.assign({}, state, {
            lastError: Date.now(),
            loading: false,
            error: payload,
          });
        case ActionTypes.FETCH_AMPLIFICATION_KITS_SUCCESS:
          return Object.assign({}, state, {
            lastFetch: Date.now(),
            loading: false,
            lastError: null,
            error: null,
            data: payload,
          });
        default:
          return state;
      }
    };
  },

  // Selectors
  selectAmplificationKitsDataRaw: state => state.amplificationKits,
  selectAmplificationKitsData: state => state.amplificationKits.data,
  selectAmplificationKitsList: createSelector(
    'selectAmplificationKitsData',
    amplificationKitsData => {
      return amplificationKitsData ? Object.values(amplificationKitsData) : [];
    },
  ),
  selectAmplificationKitsErrorMessage: createSelector(
    'selectAmplificationKitsDataRaw',
    amplificationKitsDataRaw =>
      amplificationKitsDataRaw.error && amplificationKitsDataRaw.error.message
        ? amplificationKitsDataRaw.error.message
        : null,
  ),

  // Action Creators
  doFetchAmplificationKitsList: () => ({ dispatch, apiFetch }) => {
    dispatch({ type: ActionTypes.FETCH_AMPLIFICATION_KITS_START });
    apiFetch({
      endpoint: 'amplificationKits',
    })
      .then(response => {
        const payload = response.reduce((acc, amplificationKit) => {
          acc[amplificationKit.id] = amplificationKit;
          return acc;
        }, {});
        dispatch({
          type: ActionTypes.FETCH_AMPLIFICATION_KITS_SUCCESS,
          payload,
        });
      })
      .catch(error => {
        dispatch({ type: ActionTypes.FETCH_AMPLIFICATION_KITS_ERROR, payload: error });
      });
  },

  // Reactors
  reactShouldFetchAmplificationKitsData: createSelector(
    'selectAmplificationKitsDataRaw',
    'selectAppTime',
    'selectUserLogin',
    (amplificationKits, appTime, userLogin) => {
      if (!userLogin || amplificationKits.loading) {
        return null;
      }

      let shouldFetch = false;

      if (!amplificationKits.data && !amplificationKits.lastError) {
        shouldFetch = true;
      } else if (amplificationKits.lastError) {
        const timePassed = appTime - amplificationKits.lastError;
        if (timePassed > ERROR_TIME) {
          shouldFetch = true;
        }
      } else if (amplificationKits.lastFetch) {
        const timePassed = appTime - amplificationKits.lastFetch;
        if (timePassed > REFRESH_TIME) {
          shouldFetch = true;
        }
      }

      if (shouldFetch) {
        return { actionCreator: 'doFetchAmplificationKitsList' };
      }
    },
  ),
};
