/* eslint-disable no-console */
import React from 'react';
import { connect } from 'redux-bundler-react';
import { enrolledTestStatus } from '../../helpers/constants';
import { setPayloadForGenotypingTest, setPayloadForGenotypingTestUpdate } from '../../utils';

const ConfirmGenotypeTestSubmission = ({
  routeParams,
  valuesData,
  currentStatus,
  onCancelCallback,
  doSubmitTest,
  doUpdateTest,
}) => {
  const handleSubmit = async () => {
    try {
      if (currentStatus === enrolledTestStatus.UNLOCKED) {
        onCancelCallback();
        await doUpdateTest(
          setPayloadForGenotypingTestUpdate(valuesData, routeParams, +routeParams.testId),
        );
      } else {
        onCancelCallback();
        await doSubmitTest(
          setPayloadForGenotypingTest(valuesData, routeParams, +routeParams.testId),
        );
      }
    } catch (err) {
      console.log('Error', err);
    }
  };

  return (
    <React.Fragment>
      <div className="card-body px-0 py-0 justify-content-center">
        <p>Are you sure you want to submit these results?</p>
      </div>
      <div className="card-footer d-flex justify-content-end px-0 pb-0">
        <button
          type="button"
          className="btn btn-md btn-outline-danger mr-2"
          onClick={onCancelCallback}
        >
          Cancel
        </button>
        <button type="button" className="btn btn-md btn-outline-primary" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </React.Fragment>
  );
};

export default connect('doSubmitTest', 'doUpdateTest', ConfirmGenotypeTestSubmission);
