import React from 'react';

const ResultReportPenalties = ({
  businessDaysToSubmit = 15,
  extensionRequested = false,
  penaltyForLateEIASubmission = false,
  penaltyForLateGenotypingSubmission = false,
}) => {
  return (
    <>
      <div className="col-12  d-flex">
        <p className="mr-3">
          <strong className="text-body ">
            Number of Business Days Report Submitted Following PT Panel Receipt:
          </strong>
        </p>
        <p className="text-muted ">{businessDaysToSubmit}</p>
      </div>
      <div className="col-12  d-flex">
        <p className="mr-3">
          <strong className="text-body ">
            Five Point Penalty Assessed for Late Submission of EIA Results:
          </strong>
        </p>
        <p className="text-muted ">{penaltyForLateEIASubmission ? 'Yes' : 'No'}</p>
      </div>
      <div className="col-12  d-flex">
        <p className="mr-3">
          <strong className="text-body ">
            Five Point Penalty Assessed for Late Submission of Genotyping Results:
          </strong>
        </p>
        <p className="text-muted ">{penaltyForLateGenotypingSubmission ? 'Yes' : 'No'}</p>
      </div>
      <div className="col-12  d-flex">
        <p className="mr-3">
          <strong className="text-body ">Extension Requested:</strong>
        </p>
        <p className="text-muted ">{extensionRequested ? 'Yes' : 'No'}</p>
      </div>
    </>
  );
};

export default ResultReportPenalties;
