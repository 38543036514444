import React from 'react';

/**
 *
 * @param {Array} users Array of Users Information:  [{title, user}]
 */
const ResultReportUsersInfo = ({ users = [] }) => {
  function renderUsersInfo() {
    return users.map(person => (
      <React.Fragment key={person.type}>
        <h6 className="text-uppercase text-muted">{person.title}</h6>

        <p className="text-muted mb-4">
          <strong className="text-body">{person.user}</strong> <br />
        </p>
      </React.Fragment>
    ));
  }

  return <>{renderUsersInfo()}</>;
};

export default ResultReportUsersInfo;
