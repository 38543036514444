/* eslint-disable import/prefer-default-export */
const roles = {
  POC: 'POC',
  ADMIN: 'ADMIN',
};

const roleHomeRedirectRoutes = {
  ADMIN: '/dashboard',
  POC: '/labs-studies',
  DEFAULT: '/login',
};

const testInstances = {
  EIATest: 'EIATest',
  GenotypingTest: 'GenotypingTest',
};
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const panelShippingStatus = {
  PENDING: 'PENDING',
  SHIPPED: 'SHIPPED',
  RECEIVED: 'RECEIVED',
};

const enrolledTestStatus = {
  INITIAL_STATE: 'INITIAL_STATE',
  GRADING_PENDING: 'GRADING_PENDING',
  UNLOCKED: 'UNLOCKED',
  FINALIZED: 'FINALIZED',
};

const statusObjTextForCard = {
  INITIAL_STATE: 'Waiting for Submission',
  GRADING_PENDING: 'Grading Pending',
  UNLOCKED: 'Unlocked',
  FINALIZED: 'Finalized',
};

const confirmationTextForUnlockTest = {
  [enrolledTestStatus.UNLOCKED]: {
    btnText: 'Ok',
  },
  [enrolledTestStatus.FINALIZED]: {
    btnText: 'Finalize Test',
  },
  [enrolledTestStatus.GRADING_PENDING]: {
    btnText: 'Save Draft',
  },
};

const enrolledTestStatusMessages = {
  [enrolledTestStatus.UNLOCKED]: {
    msg: 'The test has been successfully unlocked for resubmission',
    title: 'Unlock Test for Resubmission',
  },
  [enrolledTestStatus.FINALIZED]: {
    msg: 'The test has been successfully finalized for this Lab',
    title: 'Finalize Test for this Lab',
  },
  [enrolledTestStatus.GRADING_PENDING]: {
    msg:
      'The Test Evaluation for this Lab has been successfully saved, but it has not yet been finalized.',
    title: 'Save Draft Evaluation for this Lab',
  },
};

const testInterpretation = {
  POSITIVE: 'POSITIVE',
  NEGATIVE: 'NEGATIVE',
  EQUIVOCAL: 'EQUIVOCAL',
};

const genotypeTestInterpretations = {
  POSITIVE: 'POSITIVE',
  POSITIVE_NON_TYPEABLE: 'POSITIVE_NON_TYPEABLE',
  NEGATIVE: 'NEGATIVE',
};

const genotypeTestInterpretationsToCapitalized = {
  POSITIVE: 'Positive',
  NEGATIVE: 'Negative',
  POSITIVE_NON_TYPEABLE: 'Positive Non Typeable',
};

const customTestInterpretation = { ...testInterpretation, NA: 'NA' };

const otherOptionsForSelect = {
  id: 0,
  name: 'Other',
};

const testTypes = {
  EIA: 'EIA',
  GENOTYPING: 'GENOTYPING',
};

const testTypesGrading = {
  EIATest: 'EIAResults',
  GenotypingTest: 'GenotypingResults',
};

const expectedTestResultsTypes = {
  EIATest: 'EIATest',
  GenotypingTest: 'GenotypingTest',
};

const testInterpretationStoreResult = {
  POSITIVE: 'POSITIVE',
  NEGATIVE: 'NEGATIVE',
};

const genotypeCorrectnessStatus = {
  CORRECT: 'CORRECT',
  INCORRECT: 'INCORRECT',
  PARTIALLY_CORRECT: 'PARTIALLY_CORRECT',
};

const genotypeCorrectValues = {
  CORRECT: {
    capitalizedValue: 'Correct',
    upperCaseValue: 'CORRECT',
    icon: 'fe-check',
  },
  INCORRECT: {
    capitalizedValue: 'Incorrect',
    upperCaseValue: 'INCORRECT',
    icon: 'fe-x',
  },
  PARTIALLY_CORRECT: {
    capitalizedValue: 'Partially Correct',
    upperCaseValue: 'PARTIALLY_CORRECT',
    icon: 'fe-alert-triangle',
  },
};

const testMappingForEndpoint = {
  1: 'eia',
  2: 'genotyping',
};

const testResultStatus = {
  FAILED: 'FAILED',
  PASSED: 'PASSED',
};

const TestTargetScores = {
  DEFAULT_SCORE: 80,
  RRL_SCORE: 80,
};

const extraPropertiesForResultsTable = [
  'id',
  'EIATestId',
  'createdAt',
  'updatedAt',
  'GenotypingTestId',
  'gGenotypeVP7Value',
  'pGenotypeVP4Value',
  'finalGenotypeValue',
  'correct',
  'penalization',
];

const EIATableHeader = [
  {
    key: 'sample_id',
    text: 'SAMPLE ID',
  },
  {
    key: 'eia_expected_result',
    text: 'EIA Expected Result',
  },
  {
    key: 'eia_reported_result',
    text: 'EIA Reported Result',
  },
  {
    key: 'eia_sample_score',
    text: 'EIA Sample Score',
  },
  {
    key: 'eia_note',
    text: 'Notes from Admin',
  },
];

const GenotypeTableHeader = [
  {
    key: 'sample_id',
    text: 'SAMPLE ID',
  },
  {
    key: 'genotyping_expected_result',
    text: 'Genotyping Expected Result',
  },
  {
    key: 'genotyping_reported-result',
    text: 'Genotyping Reported Result',
  },
  {
    key: 'genotyping_sample_score',
    text: 'Genotyping Sample Score',
  },
  {
    key: 'genotyping_note',
    text: 'Notes from Admin',
  },
];

const testsIntepretationMessage = {
  FAILED: 'Failed',
  PASS: 'Pass',
};

export {
  roles,
  roleHomeRedirectRoutes,
  testInstances,
  testInterpretationStoreResult,
  panelShippingStatus,
  monthNames,
  testInterpretation,
  genotypeTestInterpretations,
  genotypeCorrectnessStatus,
  otherOptionsForSelect,
  testTypes,
  testResultStatus,
  expectedTestResultsTypes,
  customTestInterpretation,
  testMappingForEndpoint,
  enrolledTestStatus,
  statusObjTextForCard,
  enrolledTestStatusMessages,
  confirmationTextForUnlockTest,
  genotypeCorrectValues,
  testTypesGrading,
  genotypeTestInterpretationsToCapitalized,
  extraPropertiesForResultsTable,
  EIATableHeader,
  GenotypeTableHeader,
  TestTargetScores,
  testsIntepretationMessage,
};
