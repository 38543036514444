import React from 'react';
import ErrorMessage from '../../lib/errorMessage';
import { strCapitalize } from '../../../utils';

const RowSelectExpectedResults = ({
  field, // { name, value, onChange, onBlur }
  value,
  handleChange,
  options = [],
  cbForHandleChange,
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <>
      <select
        {...props}
        className="form-control custom-select "
        name={field.name}
        value={field.value}
        onChange={e => {
          handleChange(e);
          if (cbForHandleChange) {
            cbForHandleChange(e.target.value);
          }
        }}
        placeholder="Select an Option"
      >
        <option value="">Select an Option</option>
        {options.map((item, index) => (
          <option key={`${item}-${index.toString()}`} value={item}>
            {strCapitalize(true, item)}
          </option>
        ))}
      </select>
      <ErrorMessage name={field.name} />
    </>
  );
};

export default RowSelectExpectedResults;
