/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { testInterpretationStoreResult } from '../../../../helpers/constants';

const GenotypingExpectedInputField = ({ data, value, onChange }) => {
  return (
    <>
      <div className="col-12 col-sm-6 col-lg-4 align-self-center text-center">
        <label htmlFor="expectedGenotype " className="text-muted">
          Expected Genotype
        </label>
        <input
          type="text"
          id="expectedGenotype"
          className="form-control text-center"
          value={value}
          onChange={e => {
            onChange('expectedGenotype', e.target.value);
          }}
          disabled={data.eiaResult === testInterpretationStoreResult.NEGATIVE}
        />
      </div>
    </>
  );
};

export default GenotypingExpectedInputField;
