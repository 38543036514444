import React from 'react';
import { connect } from 'redux-bundler-react';

import EnrollmentCard from '../lib/EnrollmentCard';
import RedirectionNav from '../lib/RedirectionNav';
import LabEnrollmentSubscribedTestTable from '../views/LabEnrollmentSubscribedTestTable';

const LabEnrollment = ({
  currentLabEnrollmentDetails,
  studiesData,
  currentLabHasTestFinalized,
  currentLabSomeTestResultsSubmitted,
  currentLabSomeTestResultsSubmittedOrFinalizedOrUnlocked,
}) => {
  const renderEnrollmentStudyData = study => {
    return (
      <div className="card">
        <div className="card-header">
          <div className="row align-items-center">
            <div className="col">
              <h4 className="card-header-title">Study information</h4>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-3 col-xl-6">
              <p>
                <strong>Name</strong>
              </p>
              <p className="text-secondary">{study.name}</p>
            </div>
            <div className="col-12 col-md-6 col-lg-3 col-xl-6">
              <p>
                <strong>Year</strong>
              </p>
              <p className="text-secondary">{study.year}</p>
            </div>
            <div className="col-12 col-md-6 col-lg-3 col-xl-6">
              <p>
                <strong>Start date</strong>
              </p>
              <p className="text-secondary">{new Date(study.startDate).toDateString()}</p>
            </div>
            <div className="col-12 col-md-6 col-lg-3 col-xl-6">
              <p>
                <strong>End date</strong>
              </p>
              <p className="text-secondary">
                {study.endDate ? new Date(study.endDate).toDateString() : 'No end date set'}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const redirectionNav = {
    navItems: [
      {
        route: `/labs/edit/${currentLabEnrollmentDetails?.LabParticipantId}`,
        text: 'Edit Lab',
      },
    ],

    lastNavItemText: 'Lab Enrollment Details',
  };

  return (
    <>
      {currentLabEnrollmentDetails && (
        <RedirectionNav
          navItems={redirectionNav.navItems}
          lastNavItemText={redirectionNav.lastNavItemText}
        />
      )}
      <div className="row">
        <div className="col-12 col-xl-8">
          {currentLabEnrollmentDetails && (
            <EnrollmentCard
              enrollment={currentLabEnrollmentDetails}
              currentLabHasTestFinalized={currentLabHasTestFinalized}
              currentLabSomeTestResultsSubmitted={currentLabSomeTestResultsSubmitted}
              currentLabSomeTestResultsSubmittedOrFinalizedOrUnlocked={
                currentLabSomeTestResultsSubmittedOrFinalizedOrUnlocked
              }
            />
          )}
        </div>

        <div className="col-12 col-xl-4">
          {currentLabEnrollmentDetails &&
            studiesData &&
            studiesData[currentLabEnrollmentDetails.StudyId] &&
            renderEnrollmentStudyData(studiesData[currentLabEnrollmentDetails.StudyId])}
        </div>

        <div className="col-12">
          {currentLabEnrollmentDetails && (
            <LabEnrollmentSubscribedTestTable
              enrolledTests={currentLabEnrollmentDetails.Tests}
              labId={currentLabEnrollmentDetails.LabParticipantId}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default connect(
  'selectCurrentLabEnrollmentDetails',
  'selectStudiesData',
  'selectCurrentLabHasTestFinalized',
  'selectCurrentLabSomeTestResultsSubmitted',
  'selectCurrentLabSomeTestResultsSubmittedOrFinalizedOrUnlocked',
  LabEnrollment,
);
