import React from 'react';

const ResultReportDateReceived = ({ date, title }) => {
  return (
    <>
      <h6 className="text-uppercase text-muted">{title}</h6>

      <p className="mb-4">
        <time dateTime="2018-04-23">{date}</time>
      </p>
    </>
  );
};

export default ResultReportDateReceived;
