/* eslint-disable no-multi-assign */
/* eslint-disable no-bitwise */
/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable func-names */
/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-spread */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */

import {
  otherOptionsForSelect,
  expectedTestResultsTypes,
  testInterpretationStoreResult,
  TestTargetScores,
  roleHomeRedirectRoutes,
  testInterpretation,
  roles,
  genotypeTestInterpretationsToCapitalized,
  genotypeTestInterpretations,
  testsIntepretationMessage,
} from './helpers/constants';

export const getApproxTime = time => Math.max(0, Math.round(time));

export const isNumber = value => !Number.isNaN(parseInt(value, 10));
export const isIntegerValue = num => Number.isInteger(num);

export const initialValueForOptionsDataList = (options, value, inputValue) => {
  const optionsIds = [];
  options.forEach(op => {
    optionsIds.push(op.id);
  });

  if (isNumber(value) && optionsIds.includes(value)) {
    const [{ name }] = options.filter(op => op.id === value);
    return name;
  }
  if (!isNumber(value)) {
    return inputValue;
  }
};
export const searchIdFromOptions = (options, value) => {
  let isTheValueFound = false;
  if (!isNumber(value)) {
    options.forEach(op => {
      if (op.name === value) {
        isTheValueFound = true;
      }
    });
    if (!isTheValueFound) {
      return value;
    }
    const [{ id }] = options.filter(op => op.name === value);
    return id;
  }
  return value;
};

export const uuidv4 = (a, b) => {
  for (
    b = a = '';
    a++ < 36;
    b +=
      ~a % 5 | ((a * 3) & 4)
        ? (a ^ 15 ? 8 ^ (Math.random() * (a ^ 20 ? 16 : 4)) : 4).toString(16)
        : '-'
  );
  return b;
};

/**
 * Build the response object from server in a standard way
 * to have message, statusCode, errors (if any) properties
 * @param {Object} err   Axios err.response object
 * @return {Object}
 */
export const getApiError = err => {
  const {
    data: {
      error: { errors, message },
    },
    status,
  } = err;

  return { statusCode: status, message, errors };
};
/**
 * This function return the home route given a role
 *
 * @param {String} role The user role
 * @param {String} path This path is optional when need to redirect when is not logged in
 */
export const roleHomeRedirect = (role, path = '') => {
  switch (role) {
    case roles.ADMIN:
      return roleHomeRedirectRoutes.ADMIN;
    case roles.POC:
      return roleHomeRedirectRoutes.POC;
    default:
      return `${roleHomeRedirectRoutes.DEFAULT}${path}`;
  }
};

export const statusActionsTypes = {
  REGISTRATION_INVITATION: 'REGISTRATION_INVITATION',
  REGISTRATION_INVITATION_CANCEL: 'REGISTRATION_INVITATION_CANCEL',
  REVOKE_ACCESS: 'REVOKE_ACCESS',
  RESTORE_ACCESS: 'RESTORE_ACCESS',
  ACCOUNT_RESET_PASSWORD: 'ACCOUNT_RESET_PASSWORD',
};

export const actionAlertMessagesAndTitle = {
  [statusActionsTypes.REGISTRATION_INVITATION]: {
    msg: 'New user invited successfully!',
    title: 'Invitations Sent',
    errTitle: 'Error on Inviting Users',
    errMsg: 'The users cannot be invited',
  },
  [statusActionsTypes.REGISTRATION_INVITATION_CANCEL]: {
    msg: `User's invitations have been canceled`,
    title: 'Invitations Canceled',
    errTitle: 'Error on Cancel Users Invitation',
    errMsg: 'The users invitation cannot be removed',
  },
  [statusActionsTypes.REVOKE_ACCESS]: {
    msg: `User's access have been revoked`,
    title: 'Access Revoked for these users',
    errTitle: 'Error on Revoking Users Access',
    errMsg: 'The users access cannot be removed',
  },
  [statusActionsTypes.RESTORE_ACCESS]: {
    msg: `User's access have been granted`,
    title: 'Access Granted for these users',
    errTitle: 'Error on granting users access',
    errMsg: 'The users access cannot be granted',
  },
};

export const formatedShortDate = timestamp => {
  const month = new Date(timestamp).toLocaleString('en-GB', { month: 'short' });
  const day = new Date(timestamp).toLocaleString('en-GB', { day: '2-digit' });
  const formatedDate = `${month} ${day}`;
  return formatedDate;
};

export const formatedLongDate = timestamp => {
  const month = new Date(timestamp).toLocaleString('en-GB', { month: 'long' });
  const day = new Date(timestamp).toLocaleString('en-GB', { day: '2-digit' });
  const year = new Date(timestamp).toLocaleString('en-GB', { year: 'numeric' });
  const formatedDate = `${month} ${day}, ${year}`;
  return formatedDate;
};

export const submitActionTypes = {
  INVITE_USERS: 'INVITE_USERS',
  CANCEL_INVITATION_USERS: 'CANCEL_INVITATION_USERS',
  DEACTIVATE_USERS: 'DEACTIVATE_USERS',
  ACTIVATE_USERS: 'ACTIVATE_USERS',
};

export const getChunkArray = (array, size) => {
  const chunked = [];
  let index = 0;
  while (index < array.length) {
    chunked.push(array.slice(index, index + size));
    index += size;
  }
  return chunked;
};

export const has = Object.prototype.hasOwnProperty; // cache the lookup once, in module scope.

/**
 *
 * @param {*} arrPropsToDelete Array of Properties to Delete from a object
 * @param {*} obj => the object to manipulate
 */
export const deletePropertiesFromObject = (arrPropsToDelete, obj) => {
  const newObj = { ...obj };
  arrPropsToDelete.forEach((_, index) => {
    if (arrPropsToDelete.includes(arrPropsToDelete[index])) {
      delete newObj[arrPropsToDelete[index]];
    }
  });
  return newObj;
};

const returnItemsFromObject = (arrayOfKeys, obj) => {
  const newObj = {};
  arrayOfKeys.forEach(arr => {
    if (has.call(obj, arr)) {
      newObj[arr] = obj[arr];
    }
  });
  return newObj;
};

/**
 * This function deletes any property on a object that is either a empty string, null or undefined
 *
 * @param {Object} obj
 */
const removeFalsyItemFromObject = obj => {
  const newObj = {};
  Object.keys(obj).forEach(prop => {
    if (obj[prop]) {
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
};

/**
 * This function is to set the payload for the EIA submitted values
 *
 * @param  {Object} values values object from formik
 * @param {Object} routeParams object with some essentials params in the URL
 * @param {number} testId Id of the test [EIA]
 * @param {number} otherOptionId Id of other amplification kit name
 *
 * @return {Object} Object with the EIA payload
 */
export const setPayloadForEIATest = (values, routeParams, testId, otherOptionId) => {
  let amplificationRounds = [];
  if (+values.AmplificationKitId === otherOptionId) {
    amplificationRounds = [
      returnItemsFromObject(
        ['vendorName', 'serialNumber', 'expiryDate', 'otherAmplificationKitBrand'],
        values,
      ),
    ];
  } else {
    amplificationRounds = [
      {
        ...returnItemsFromObject(['vendorName', 'serialNumber', 'expiryDate'], values),
        AmplificationKitId: +values.AmplificationKitId,
      },
    ];
  }
  return {
    enrolledStudyId: +routeParams.enrollmentId,
    testId,
    payload: {
      investigator: values.investigator,
      testDate: values.testDate,
      eiaControl: {
        antigenODPositiveControl: +values.antigenODPositiveControl,
        antigenODNegativeControl: +values.antigenODNegativeControl,
        positiveControlTestInterpretation: values.positiveControlTestInterpretation,
        negativeControlTestInterpretation: values.negativeControlTestInterpretation,
        kitCutOffFormula: values.kitCutOffFormula,
        kitCutOff: +values.kitCutOff,
      },
      amplificationRounds,
      eiaResults: values.eiaResults.map(res => ({ ...res, antigenOD: +res.antigenOD })),
      justificationNotes: values.justificationNotes,
      gradingNotes: values.adminGradingNotes,
    },
  };
};

/**
 * This function is to set the payload with the new updated values for the EIA Test
 *
 * @param  {Object} values values object from formik
 * @param {Object} routeParams object with some essentials params in the URL
 * @param {number} testId Id of the test to submit
 * @param {number} otherOptionId Id of other amplification kit name
 *
 * @return {Object} Object with the new EIA payload
 */
export const setPayloadForEIATestUpdate = (values, routeParams, testId, otherOptionId) => {
  let amplificationRounds = [];
  if (+values.AmplificationKitId === otherOptionId) {
    amplificationRounds = [
      returnItemsFromObject(
        ['vendorName', 'serialNumber', 'expiryDate', 'otherAmplificationKitBrand', 'id'],
        values,
      ),
    ];
  } else {
    amplificationRounds = [
      {
        ...returnItemsFromObject(['vendorName', 'serialNumber', 'expiryDate', 'id'], values),
        AmplificationKitId: +values.AmplificationKitId,
      },
    ];
  }
  return {
    enrolledStudyId: +routeParams.enrollmentId,
    testId,
    payload: {
      investigator: values.investigator,
      testDate: values.testDate,
      eiaControl: {
        antigenODPositiveControl: +values.antigenODPositiveControl,
        antigenODNegativeControl: +values.antigenODNegativeControl,
        positiveControlTestInterpretation: values.positiveControlTestInterpretation,
        negativeControlTestInterpretation: values.negativeControlTestInterpretation,
        kitCutOffFormula: values.kitCutOffFormula,
        kitCutOff: +values.kitCutOff,
      },
      amplificationRounds,
      eiaResults: values.eiaResults.map(res => ({ ...res, antigenOD: +res.antigenOD })),
      justificationNotes: values.justificationNotes,
      gradingNotes: values.adminGradingNotes,
    },
  };
};

/**
 * Function to set the payload for the Genotype submitted values
 *
 * @param {Object} values values object from formik
 * @param {Object} routeParams object with essential data in the URL
 * @param {number} testId Id of the test to submit
 *
 * @return {Object} Object with the genotype payload
 */
export const setPayloadForGenotypingTest = (values, routeParams, testId) => {
  const amplificationRounds = values.amplificationRounds.map((res, index) => {
    if (+res.AmplificationKitId === otherOptionsForSelect.id) {
      return {
        ...returnItemsFromObject(
          ['otherAmplificationKitBrand', 'vendorName', 'serialNumber', 'expiryDate'],
          res,
        ),
        numberOfRound: index + 1,
      };
    }
    return {
      ...returnItemsFromObject(['vendorName', 'serialNumber', 'expiryDate'], res),
      AmplificationKitId: +res.AmplificationKitId,
      numberOfRound: index + 1,
    };
  });
  return {
    enrolledStudyId: +routeParams.enrollmentId,
    testId,
    payload: {
      investigator: values.investigator,
      testDate: values.testDate,
      genotypingControl: {
        gGenotypeVP7PositiveControl: values.gGenotypeVP7PositiveControl,
        pGenotypeVP4PositiveControl: values.pGenotypeVP4PositiveControl,
        finalGenotypePositiveControl: values.finalGenotypePositiveControl,
        gGenotypeVP7NegativeControl: values.gGenotypeVP7NegativeControl,
        pGenotypeVP4NegativeControl: values.pGenotypeVP4NegativeControl,
        finalGenotypeNegativeControl: values.finalGenotypeNegativeControl,
      },
      amplificationRounds,
      genotypingResults: values.genotypingResults.map(res => removeFalsyItemFromObject(res)),
      justificationNotes: values.justificationNotes,
      gradingNotes: values.adminGradingNotes,
    },
  };
};

/**
 * Function to set the payload for the Genotype submitted values on update
 *
 * @param {Object} values values object from formik
 * @param {Object} routeParams object with essential data in the URL
 * @param {number} testId Id of the test to submit
 *
 * @return {Object} Object with the new genotype payload
 */
export const setPayloadForGenotypingTestUpdate = (values, routeParams, testId) => {
  const amplificationRounds = values.amplificationRounds.map((res, index) => {
    if (+res.AmplificationKitId === otherOptionsForSelect.id) {
      return {
        ...returnItemsFromObject(
          ['otherAmplificationKitBrand', 'vendorName', 'serialNumber', 'expiryDate', 'id'],
          res,
        ),
        numberOfRound: index + 1,
      };
    }
    return {
      ...returnItemsFromObject(['vendorName', 'serialNumber', 'expiryDate', 'id'], res),
      AmplificationKitId: +res.AmplificationKitId,
      numberOfRound: index + 1,
    };
  });
  return {
    enrolledStudyId: +routeParams.enrollmentId,
    testId,
    payload: {
      investigator: values.investigator,
      testDate: values.testDate,
      genotypingControl: {
        gGenotypeVP7PositiveControl: values.gGenotypeVP7PositiveControl,
        pGenotypeVP4PositiveControl: values.pGenotypeVP4PositiveControl,
        finalGenotypePositiveControl: values.finalGenotypePositiveControl,
        gGenotypeVP7NegativeControl: values.gGenotypeVP7NegativeControl,
        pGenotypeVP4NegativeControl: values.pGenotypeVP4NegativeControl,
        finalGenotypeNegativeControl: values.finalGenotypeNegativeControl,
      },
      amplificationRounds,
      genotypingResults: values.genotypingResults.map(res => removeFalsyItemFromObject(res)),
      justificationNotes: values.justificationNotes,
      gradingNotes: values.adminGradingNotes,
    },
  };
};

export const isAnEmptyArray = item => !item.length;

/**
 * This function capitalize an string if the boolean arg is set to true
 * @param { boolean } lower
 * @param { string } strValue
 * @return {string}
 */
export const strCapitalize = (lower, strValue) => {
  return (lower ? strValue.toLowerCase() : strValue).replace(/(?:^|\s)\S/g, function(a) {
    return a.toUpperCase();
  });
};

export const searchEiaOrGenotypeInResponse = data => {
  let response = {};
  if (has.call(data, expectedTestResultsTypes.EIATest) && !!Object.keys(data.EIATest).length) {
    response = { EIATest: data.EIATest };
  } else if (
    has.call(data, expectedTestResultsTypes.GenotypingTest) &&
    !!Object.keys(data.GenotypingTest).length
  ) {
    response = { GenotypingTest: data.GenotypingTest };
  }
  return response;
};

export const searchForEIAorGenotyping = (obj, testId) => {
  let newPayload = {};
  if (has.call(obj, expectedTestResultsTypes.EIATest)) {
    newPayload = { EIATest: { ...obj.EIATest, testId } };
  }
  if (has.call(obj, expectedTestResultsTypes.GenotypingTest)) {
    newPayload = { GenotypingTest: { ...obj.GenotypingTest, testId } };
  }
  return newPayload;
};

export const returnClassesForMatrixOfPairValues = (
  editMode,
  isTheElementIncluded,
  index,
  actualPairValues,
  PAIR_VALUES_LENGTH,
) => {
  if (editMode) {
    if (isTheElementIncluded(index) && actualPairValues.length >= PAIR_VALUES_LENGTH) {
      return `btn btn-info mx-2`;
    }
    return `btn btn-outline-info mx-2`;
  }
  if (isTheElementIncluded(index)) {
    return `btn btn-info mx-2`;
  }
  return `btn btn-outline-info mx-2`;
};

export const isValidSampleIDLength = (maxLength, sampleID) =>
  sampleID.toString().length === +maxLength;

export const sumOfIndexesValues = (firstIndex, secondIndex, sampleIDN) => {
  return +sampleIDN.split('')[firstIndex - 1] + +sampleIDN.split('')[secondIndex - 1];
};

/**
 *Set the positive and negative from EIA
 * @param {Array} expectedResults The expected result array
 * @returns {Object} The object with positive and negative
 */
export const getPositiveAndNegativeFromEIA = expectedResults => {
  const positive = [];
  const negative = [];
  expectedResults.forEach(res => {
    if (res.eiaResult.toUpperCase() === testInterpretation.POSITIVE) {
      positive.push(+res.sumCode);
    }
    if (res.eiaResult.toUpperCase() === testInterpretation.NEGATIVE) {
      negative.push(+res.sumCode);
    }
  });

  return { positive, negative };
};

/**
 * Given the expected values, set the EIA Payload
 * @param {Array} rules The rules for EIA
 * @param {Array} expectedValues The array of expected values
 *
 * @returns {Object} The eia payload as object
 */
export const setEIAPayload = (rules, expectedValues) => {
  const { firstIndex, secondIndex, sampleLength } = rules;

  const { positive, negative } = getPositiveAndNegativeFromEIA(expectedValues);
  return {
    firstIndex: +firstIndex,
    secondIndex: +secondIndex,
    sampleLength,
    positive,
    negative,
    equivocal: [],
  };
};

/**
 * Given the expected values, set the Genotype Payload
 * @param {Array} expectedValues The array of expected values
 * @returns {Array} The array transformed
 */
export const setGenotypePayload = expectedValues => {
  const result = [];
  expectedValues.forEach(ex => {
    if (ex?.expectedGenotype?.expectedGenotype?.length) {
      result.push(ex.expectedGenotype);
    }
  });

  return { expectedResults: result };
};

/**
 * This function validates the Expected Genotype in a Formik Row for Expected Result
 * @params {Object} The expected result row
 * @return {String} The error;
 */
export const validateExpectedGenotypeInFormikRow = e => {
  let error;
  if (!e.eiaResult.length && !!e.expectedGenotype.expectedGenotype.length) {
    error = 'You need to set EIA Result as Well';
  }
  if (
    e.expectedGenotype?.id &&
    e.eiaResult === testInterpretationStoreResult.POSITIVE &&
    e.expectedGenotype?.expectedGenotype === ''
  ) {
    error = 'This records is required for update';
  } else {
    error = null;
  }
  return error;
};

/**
 * This function is search repeated values in the expected result array given the value.
 * @param {Array} expectedResultsArray This is expected result array
 * @param {String} value The value to Search
 * @param {Integer} indexOfSearchValue The index when is coming from the table
 * @returns {boolean} True/False
 */
export function isThisValueRepeated(expectedResultsArray, value, indexOfSearchValue = null) {
  let isFound = false;
  expectedResultsArray.forEach((expResultItem, index) => {
    if (+value === +expResultItem.sumCode && indexOfSearchValue !== index) {
      isFound = true;
    }
  });
  return isFound;
}

/**
 *
 * Validate if new expected result to be added already exist in the array of expected result
 * @param {Object} params
 * @param {Array}  params.arrayOfExpectedResults
 * @param {Object} params.newExpectedResult
 *
 * @return {Boolean}
 */
export const newGenotypeExpectedResultExist = ({ arrayOfExpectedResults, newExpectedResult }) => {
  const { sumCode, expectedGenotype, eiaResult } = newExpectedResult;

  // If EIA result is negative just check we are not trying to add an already existing sumCode
  if (eiaResult === testInterpretation.NEGATIVE) {
    return arrayOfExpectedResults.find(
      expectedResult => expectedResult.sumCode === parseInt(sumCode, 10),
    );
  }

  // Otherwise check for repeated sumCode or expectedGenotype
  // eslint-disable-next-line array-callback-return
  return arrayOfExpectedResults.find(expectedResult => {
    if (expectedResult.expectedGenotype.expectedGenotype !== '' && expectedGenotype !== '') {
      return (
        expectedResult.sumCode === parseInt(sumCode, 10) ||
        expectedResult.expectedGenotype.expectedGenotype === expectedGenotype
      );
    }
  });
};

/**
 *
 * Return the error message if genotype string contains invalid characters
 * @param {Object} params
 * @param {Object} params.newExpectedResult
 *
 * @return {string|null}
 */
export const genotypeContainsInValidCharacters = ({ newExpectedResult }) => {
  const { expectedGenotype, eiaResult } = newExpectedResult;
  if (eiaResult === testInterpretation.POSITIVE && expectedGenotype !== '') {
    const upperCasedGenotype = expectedGenotype.toUpperCase();
    const genotypeContainsEmptyBrackets = !!upperCasedGenotype.includes('[]');
    if (genotypeContainsEmptyBrackets) {
      return 'Genotype has an invalid structure';
    }

    const cleanedGenotype = upperCasedGenotype.replace(/[\[\]']+/g, '');

    if (!cleanedGenotype.match(/^[0-9A-Z]+$/)) {
      return 'Genotype contains invalid characters';
    }
  }
};

/**
 * Before to add a new value to the expected value array, lets check if it's valid
 *
 * @param   {Integer}  sumCode    The sum code to add
 * @param   {String}  eiaResult  The EIA Result Value
 *
 * @return  {Boolean}             True/False
 */
export function isAValidExpectedValueToAdd(sumCode, eiaResult) {
  if (sumCode < 0 || sumCode > 18 || eiaResult === '') return false;

  return true;
}

export const isTheTestInterpretationKeyFound = key =>
  key === testInterpretationStoreResult.POSITIVE || key === testInterpretationStoreResult.NEGATIVE;

/**
 * [ The score provided as minimum to pass, this will depends on the laboratory network category RRL labs score target is 80, for non RRL labs target score is 80]
 * @var  {Boolean}
 */
export const getTargetScoreForEIA = currentLabIsRegional =>
  currentLabIsRegional
    ? TestTargetScores.RRL_SCORE // 80
    : TestTargetScores.DEFAULT_SCORE; // 80

/**
 * Get the target score for genotype test given the lab type
 *
 * @var {Boolean}
 */
export const getTargetScoreForGenotype = currentLabIsRegional =>
  currentLabIsRegional
    ? TestTargetScores.RRL_SCORE // 80
    : TestTargetScores.DEFAULT_SCORE; // 80

/**
 * Return the matched value given the genotype interpretation
 *
 * @param   {Object}  item  Raw value of the submitted results
 * @param   {String}  prop  Prop to match with the submitted result
 *
 * @return  {String}        Text of the matched property
 */
export const renderGenotypeResultsValue = (item, prop) => {
  if (item[prop] === genotypeTestInterpretations.POSITIVE) {
    return item[`${prop}Value`];
  }
  if (item[prop] === genotypeTestInterpretations.POSITIVE_NON_TYPEABLE) {
    return 'Positive Non-Typeable';
  }
  return strCapitalize(true, item[prop]);
};

/**
 * This function returns the text caputalized given a genotype
 *
 * @var {String} interpretation This is the string to capitalize
 */
export const genotypeTestInterpretationMappingForReportedGenotype = interpretation => {
  switch (interpretation) {
    case genotypeTestInterpretations.NEGATIVE:
      return genotypeTestInterpretationsToCapitalized[interpretation];

    case genotypeTestInterpretations.POSITIVE_NON_TYPEABLE:
      return genotypeTestInterpretationsToCapitalized[interpretation];

    default:
      return interpretation;
  }
};

// ********* Hightlighting errors functions  on submitted results *********

/**
 * Verify if a given element exists in the provided array passed as params
 * @param {Array} props.arrAuxQC The array containing all the repeated elements
 * @param {String} props.strElem The string containing the value of the element
 * @param {String} props.argCaller string containing the source where function is being invoked
 */
export const fnElemExists = (arrAuxQC, strElem, argCaller) => {
  let boolRes = false;
  for (let i = 0; i < arrAuxQC.length; i++) {
    boolRes =
      argCaller === 'arrAuxQC'
        ? String(arrAuxQC[i].elem).toLowerCase() === strElem.toLowerCase()
        : arrAuxQC[i].toLowerCase() === strElem.toLowerCase();
    if (boolRes) return true;
  }
  return boolRes;
};

/**
 * Track the number of repetitions of a given element in the submitted results
 * @param {Array} props.arrAuxQC The aux array where we store the repeated elements
 * @param {String} props.strElem The string containing the value of the element
 */
export const fnIncTotal = (arrAuxQC, strElem) => {
  for (let i = 0; i < arrAuxQC.length; i++) {
    if (arrAuxQC[i].elem === strElem) {
      arrAuxQC[i].qty++;
      return;
    }
  }
};

/**
 * this function allows us to set submitted results to Proper Text Case when they're
 * come in Uppercase text case [POSITIVE, NEGATIVE, POSITIVE_NON_TYPEABLE & INCORRECT]
 * @param {Array} props.argGenotypeInterpretations Array with Genotype Interpretations
 * @param {String} props.argTextToConvert The string containing the value to be changed
 */
export const fnStrToProper = (argGenotypeInterpretations, argTextToConvert) => {
  const rExp = /_/gi;
  if (argGenotypeInterpretations.includes(argTextToConvert)) {
    return strCapitalize(true, argTextToConvert.replace(rExp, ' '));
  }
  return argTextToConvert;
};

/**
 * Identify and build the genotype alphanumeric combination string set as an array
 * @param {String} props.argStrGenoTypes The string to be converted to an array
 */
export const fnStrToArrayGenoTypes = argStrGenoTypes => {
  return argStrGenoTypes.split(/(?=[A-Za-z])/);
};

/**
 * [This function get the score for a given test and the interpretation]
 *
 * @param   {Function}  getScoreForTest  [Is the callback function to calculate the score fot the test]
 * @param   {Number}  minLabScore      [minLab Score for a LAb]
 *
 * @return  {Object}                   [Returns the score and the interpretation]
 */
export const getScoreAndInterpretationForTests = (getScoreForTest, minLabScore) => {
  const score = getScoreForTest();
  return {
    score,
    interpretation:
      minLabScore > score ? testsIntepretationMessage.FAILED : testsIntepretationMessage.PASS,
  };
};

/**
 * [This function filters among a records of tests, given a Test Type]
 *
 * @param   {[Object]}  data  [Array Of Tests]
 * @param   {String}  type  [The Test Type]
 *
 * @return  {[Object]}        [Returns the Graded Results of the Matched Test]
 */
export const getFilteredTestDataForTableHook = (data = {}, type = null) => {
  let filteredTest = null;
  Object.keys(data).forEach(test => {
    if (data[test]?.type === type) {
      filteredTest = data[test];
    }
  });

  if (!filteredTest) {
    return [];
  }
  return filteredTest.gradedResults;
};

export const getApiUrl = () => {
  const hostnameArr = window.location.hostname.split('.');
  const [, ...domainArr] = hostnameArr;

  if (domainArr.length) {
    // https://rotaeqa-api-int.cdc.gov
    if (hostnameArr[0].endsWith('-int')) {
      return ['https://rotaeqa-api-int', ...domainArr].join('.');
    }

    return ['https://rotaeqa-api', ...domainArr].join('.');
  }
  return process.env.REACT_APP_API_URL;
};

/**
 * [Get the status object for a study]
 *
 * @var {[Object]}
 */
export const getStudyStatus = study => {
  return {
    completed: {
      number: study.completed,
      text: 'Completed',
      icon: 'check-circle',
      class: 'success',
      id: `${study.name}-${study.year}-completed`,
    },
    pending: {
      number: study.pending,
      text: 'Pending',
      icon: 'help-circle',
      class: 'warning',
      id: `${study.name}-${study.year}-pending`,
    },
    failed: {
      number: study.failed,
      text: 'Failed',
      icon: 'alert-circle',
      class: 'danger',
      id: `${study.name}-${study.year}-failed`,
    },
    passed: {
      number: study.passed,
      text: 'Passed',
      icon: 'check-circle',
      class: 'primary',
      id: `${study.name}-${study.year}-passed`,
    },
  };
};

/**
 * [Get a current timestamp in YYYY-MM-DDThh-mm-ss-sss format]
 *
 * @returns {String}
 */
export const fileFormattedTimestamp = () => {
  const d = new Date();
  const year = d.getFullYear();
  const month = d.getMonth();
  const day = d.getDay();
  const hours = d.getHours();
  const minutes = d.getMinutes();
  const seconds = d.getSeconds();
  const milliseconds = d.getMilliseconds();

  return `${year}-${month}-${day}T${hours}-${minutes}-${seconds}-${milliseconds}`;
};
