/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { connect } from 'redux-bundler-react';
import { getNavHelper } from 'internal-nav-helper';
import Header from '../Header';
import Navigation from '../Navigation';

const AdminWrapper = ({ title, nav, children, doUpdateUrl }) => (
  <div onClick={getNavHelper(doUpdateUrl)}>
    <Navigation mainNav={nav} />
    <div className="main-content">
      <Header title={title} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-xl-12">{children}</div>
        </div>
      </div>
    </div>
  </div>
);

export default connect('doUpdateUrl', AdminWrapper);
