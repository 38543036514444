/* eslint-disable react/button-has-type */
import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'redux-bundler-react';
import orderBy from 'lodash/_baseOrderBy';

import { formatedLongDate, formatedShortDate, getChunkArray } from '../../utils';
import EnrollableStudyCard from '../lib/EnrollableStudyCard';
import ViewKitShippingStatus from '../lib/ViewKitShippingStatus';
import PreviousEnrolledStudies from '../views/PreviousEnrolledStudies';

const LaboratoryStudyPage = ({ enrollableStudies, enrollmentList, activeEnrollStudies }) => {
  const [items, setItems] = useState(null);

  useEffect(() => {
    if (enrollableStudies.length) {
      setItems(enrollableStudies[0]);
    }
  }, [enrollableStudies]);

  const formattedData = useCallback(() => {
    return enrollmentList
      ? getChunkArray(
          orderBy(enrollmentList, ['year'], ['desc']).filter(
            item => new Date(item.endDate) < new Date(),
          ),
          3,
        )
      : [];
  }, [enrollmentList]);

  const splitArray = formattedData();

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            {items ? (
              <EnrollableStudyCard
                virusName={items.name}
                StudyYear={items.year}
                startDate={formatedShortDate(items.startDate)}
                endDate={items.endDate ? formatedLongDate(items.endDate) : null}
                btnCardCaption="Enroll"
              />
            ) : (
              <>
                <div className="row d-flex justify-content-center m-4">
                  <h5 className="header-pretitle text-info">
                    <small className="header-pretitle text-info">
                      No study is available for enrollment at this moment.
                    </small>
                  </h5>
                </div>
                <hr />

                <div className="row d-flex justify-content-center m-4">
                  {activeEnrollStudies.map(activeStudy => (
                    <ViewKitShippingStatus key={activeStudy.id} study={activeStudy} />
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <PreviousEnrolledStudies data={splitArray} />
    </>
  );
};

export default connect(
  'selectEnrollableStudies',
  'selectEnrollmentList',
  'selectActiveEnrollStudies',
  LaboratoryStudyPage,
);
