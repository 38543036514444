import { useState, useEffect } from 'react';

const useLabInfoReport = (labInfo = {}) => {
  const [labInfoReport, setLabInfoReport] = useState(labInfo);

  useEffect(() => {
    if (labInfo) {
      const labInformation = {
        laboratoryName: labInfo.laboratoryName,
        address1: labInfo.address1,
        address2: labInfo.address2 || '',
      };

      setLabInfoReport(labInformation);
    }
  }, [labInfo]);

  return [labInfoReport];
};

export default useLabInfoReport;
