import React from 'react';

const HeadTable = () => (
  <thead>
    <tr>
      <th>
        <div className="text-muted">Sample ID Number</div>
      </th>
      <th>
        <div className="text-muted">Antigen O.D.</div>
      </th>
      <th>
        <div className="text-muted">Test Interpretation</div>
      </th>
    </tr>
  </thead>
);

export default HeadTable;
