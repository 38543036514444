/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-danger */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/** Core Libraries */
import React, { useEffect, useState } from 'react';
import { FieldArray, Field } from 'formik';
import orderBy from 'lodash/orderBy';

import useModal from '../hooks/useModal';
import Modal from '../lib/Modal';
import PenalizationPanel from '../lib/PenalizationPanel';
import FinalScore from '../lib/scoring/FinalScore';
import TextArea from '../lib/scoring/TextArea';
import ErrorPanel from '../lib/scoring/ErrorPanel';
import PanelMessage from '../lib/scoring/PanelMessage';
import {
  fnElemExists,
  fnIncTotal,
  fnStrToProper,
  fnStrToArrayGenoTypes,
  strCapitalize,
  genotypeTestInterpretationMappingForReportedGenotype,
} from '../../utils';
import {
  genotypeCorrectValues,
  enrolledTestStatus,
  genotypeTestInterpretations,
  genotypeCorrectnessStatus,
} from '../../helpers/constants';

const ERROR = 'ERROR';
const PENALIZATION = 'PENALIZATION';
const genotypeInterpretations = Object.values(genotypeTestInterpretations);

const IconEvaluation = ({ evaluation }) => {
  if (!evaluation) return null;
  // Let us highlight the icons according to the correctness of each questions
  let iconColor = '';
  if (evaluation === 'CORRECT') {
    iconColor = 'text-success';
  } else if (evaluation === 'PARTIALLY_CORRECT') {
    iconColor = 'colorBlindYellow';
  } else {
    iconColor = 'colorBlindRed';
  }
  return <span className={`fe ${genotypeCorrectValues[evaluation].icon} ${iconColor} mb-0`} />;
};

/**
 *
 * @param {Array} props.data The values for GENOTYPE grading table
 * @param {Integer} props.POINTS_PER_RESPONSE The max points given a right answer
 * @param {Function} props.handleChange This is a formik handle change to track the input change of the fields
 */

const GenotypeTestResultsGradingBodyTable = ({
  data,
  POINTS_PER_RESPONSE,
  handleChange,
  status,
}) => {
  const INITIAL_BODY_PANEL_DATA = { type: '', messages: [], data: [], headers: [] };
  const [bodyPanelData, setBodyPanelData] = useState(INITIAL_BODY_PANEL_DATA);

  const {
    showing: showingVModal,
    toggleVisibility: toggleVisibilityVModal,
    title: titleVModal,
    setModalTitle: setModalTitleVModal,
  } = useModal();

  const [propertyValue, setPropertyValue] = useState();

  useEffect(() => {
    setModalTitleVModal('More Information Panel');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onResetVModal = () => {
    toggleVisibilityVModal();
    setBodyPanelData(INITIAL_BODY_PANEL_DATA);
  };

  const setDataForTablePanel = rowData => {
    const { expectedResult, finalGenotypeValue, finalGenotype, penalization, error } = rowData;

    if (error?.fields) {
      const { fields = [] } = error;

      setBodyPanelData({
        type: ERROR,
        messages: [error.message],
        headers: [],
        data: fields,
      });
    }

    if (penalization?.penalities) {
      const { penalities = [] } = penalization;
      const dataToPanel = penalities.map(({ message }) => message);
      const finalG = [
        genotypeTestInterpretationMappingForReportedGenotype(
          expectedResult?.expectedGenotype || '',
        ),
        genotypeTestInterpretationMappingForReportedGenotype(
          finalGenotypeValue?.value || finalGenotype?.value,
        ),
      ];

      setBodyPanelData({
        type: PENALIZATION,
        messages: dataToPanel,
        headers: ['Expected Genotype', 'Reported Genotype'],
        data: finalG,
      });
    }
    toggleVisibilityVModal();
  };

  function panelTable() {
    return (
      <>
        {bodyPanelData.type === PENALIZATION && <PenalizationPanel bodyPanelData={bodyPanelData} />}

        {bodyPanelData.type === ERROR && <ErrorPanel bodyPanelData={bodyPanelData} />}

        <hr />
        {bodyPanelData.messages.map(message => (
          <PanelMessage key={message} message={message} />
        ))}
      </>
    );
  }

  function displayMoreInfoButton(error, penalization) {
    return error?.message || penalization?.value > 0;
  }

  const records = orderBy(data, ['id.value']);

  /**
   * Main Highlight Error Function for penality and errors on submitted results
   *
   * @param {Object}      params
   * @param {string}      params.strExpectedGenotype
   * @param {string|null} params.strSubmittedGenotype
   * @param {string}      params.submittedFinalGenotypeInterpretation
   * @param {Object}      params.penalization
   * @param {Object}      params.objCorrect
   *
   * @return {Array}
   */
  const fnHighlightErrorString = ({
    strExpectedGenotype,
    strSubmittedGenotype,
    submittedFinalGenotypeInterpretation,
    penalization,
    objCorrect,
  }) => {
    // Interpretation is none of "POSITIVE, NEGATIVE or POSITIVE_NON_TYPEABLE"
    if (!genotypeInterpretations.includes(submittedFinalGenotypeInterpretation)) {
      return <strong className="mb-0 colorBlindRed">{submittedFinalGenotypeInterpretation}</strong>;
    }

    // For NEGATIVES or POSITIVE NON TYPEABLE
    // We deal with the final genotype interpretation instead of the submitted genotype value
    if (submittedFinalGenotypeInterpretation !== genotypeTestInterpretations.POSITIVE) {
      return (
        <React.Fragment>
          {strExpectedGenotype === submittedFinalGenotypeInterpretation ? (
            <span className="mb-0">
              {fnStrToProper(genotypeInterpretations, submittedFinalGenotypeInterpretation)}
            </span>
          ) : (
            <strong className="mb-0 colorBlindRed">
              {fnStrToProper(genotypeInterpretations, submittedFinalGenotypeInterpretation)}
            </strong>
          )}
        </React.Fragment>
      );
    }

    // This is the information we will use to compare both strings
    const arrExpected = strExpectedGenotype ? fnStrToArrayGenoTypes(strExpectedGenotype) : [];
    const arrSubmitted = strSubmittedGenotype ? fnStrToArrayGenoTypes(strSubmittedGenotype) : [];
    const arrAuxQC = []; // To count the number of incidences of a repeated pair
    let boolFirst = true; // starting point element used to compare strings

    const submittedValueColored = arrSubmitted.map((genotype, index) => {
      if (!fnElemExists(arrExpected, genotype, 'arrExpected')) {
        arrAuxQC.push({ elem: genotype, qty: 0 });
        if (
          penalization?.value > 0 &&
          objCorrect?.value === genotypeCorrectnessStatus.PARTIALLY_CORRECT
        ) {
          // If is not present and it has a penalization value then we highlight it differently
          return (
            <strong key={index.toString()} className="mb-0 colorBlindYellow">
              {genotype}
            </strong>
          );
        }
        if (objCorrect?.value === genotypeCorrectnessStatus.CORRECT) {
          // If is not present and it has a penalization value then we highlight it differently
          return (
            <strong key={index.toString()} className="mb-0 colorBlindYellow">
              {genotype}
            </strong>
          );
        }
        return (
          <strong key={index.toString()} className="mb-0 colorBlindRed">
            {genotype}
          </strong>
        );
      }

      if (fnElemExists(arrAuxQC, genotype, 'arrAuxQC')) {
        fnIncTotal(arrAuxQC, genotype);
      } else {
        arrAuxQC.push({ elem: genotype, qty: 1 });
      }
      return <span key={index.toString()}>{genotype}</span>;
    });

    const strArrayWithDuplicates = submittedValueColored.map((element, index) => {
      const { children: genotype } = element.props;
      const repeatedValues = arrAuxQC.find(acc => acc.elem === genotype && acc.qty > 1);

      if (!repeatedValues) {
        return element;
      }

      if (boolFirst) {
        boolFirst = false;
        return element;
      }

      return (
        <strong key={index.toString()} className="mb-0 colorBlindYellow">
          {element.props.children}
        </strong>
      );
    });

    return <span>{strArrayWithDuplicates}</span>;
  };

  return (
    <>
      <tbody className="text-center">
        <FieldArray
          name="gradedResults"
          render={() =>
            records &&
            records.map(
              (
                {
                  sampleIDNumber,
                  expectedResult,
                  correct,
                  finalGenotypeValue,
                  finalGenotype,
                  penalization,
                  suggestedScore,
                  finalScore,
                  overrideNote,
                  error,
                },
                index,
              ) => (
                <tr key={index.toString()} className="fixed-height">
                  <td>{sampleIDNumber?.value}</td>
                  <td>{strCapitalize(true, expectedResult?.EIAResult || '-')}</td>
                  <td>
                    {genotypeTestInterpretationMappingForReportedGenotype(
                      expectedResult?.expectedGenotype || '-',
                    )}
                  </td>
                  <td>
                    {finalGenotypeValue?.value !== expectedResult?.expectedGenotype || '' ? (
                      <>
                        {fnHighlightErrorString({
                          strExpectedGenotype: expectedResult?.expectedGenotype,
                          strSubmittedGenotype: finalGenotypeValue?.value,
                          submittedFinalGenotypeInterpretation: finalGenotype?.value,
                          penalization,
                          objCorrect: correct,
                        })}
                      </>
                    ) : (
                      expectedResult?.expectedGenotype
                    )}
                  </td>
                  <td>
                    <strong>
                      <IconEvaluation evaluation={correct?.value} />
                    </strong>
                  </td>
                  <td>{penalization?.value}</td>
                  <td>{suggestedScore?.value}</td>

                  {status !== enrolledTestStatus.FINALIZED ? (
                    <td className={finalScore?.value === POINTS_PER_RESPONSE ? '' : 'text-muted '}>
                      <div className="input-group input-group-lg input-group-flush text-center p-0 m-0">
                        <Field
                          name={`gradedResults[${index}].finalScore.value`}
                          className="form-control result-row-input-numeric text-center bg-white border"
                          component={FinalScore}
                          handleChange={handleChange}
                          step="2.5"
                          validate={value => {
                            if (value === '' || +value % 2.5 !== 0) {
                              return 'Invalid Score';
                            }
                            return null;
                          }}
                        />
                      </div>
                    </td>
                  ) : (
                    <td className={finalScore?.value === POINTS_PER_RESPONSE ? '' : 'text-muted '}>
                      {finalScore?.value}
                    </td>
                  )}
                  {status !== enrolledTestStatus.FINALIZED ? (
                    <td>
                      <div className="input-group input-group-lg input-group-flush text-center p-0 m-0">
                        <Field
                          name={`gradedResults[${index}].overrideNote.value`}
                          className="form-control bg-white border custom-text-area"
                          component={TextArea}
                          handleChange={handleChange}
                        />
                      </div>
                    </td>
                  ) : (
                    <td>{overrideNote?.value}</td>
                  )}
                  <td>
                    {displayMoreInfoButton(error, penalization) ? (
                      <button
                        type="button"
                        className="btn btn-sm btn-link"
                        style={{
                          marginTop: '-3px',
                        }}
                        onClick={() =>
                          setDataForTablePanel({
                            expectedResult,
                            finalGenotypeValue,
                            finalGenotype,
                            penalization,
                            error,
                          })
                        }
                      >
                        <i className="fe fe-info" />
                      </button>
                    ) : null}
                  </td>
                </tr>
              ),
            )
          }
        />
      </tbody>

      <Modal.Vertical showing={showingVModal} hide={onResetVModal} title={titleVModal}>
        <div className="card-body px-0 pt-0 justify-content-center">{panelTable()}</div>
        <div className="card-footer d-flex justify-content-end px-0">
          <button
            type="button"
            className="btn btn-md btn-outline-primary mr-2"
            onClick={() => onResetVModal()}
          >
            Close Panel
          </button>
        </div>
      </Modal.Vertical>
    </>
  );
};
export default GenotypeTestResultsGradingBodyTable;
