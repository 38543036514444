/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';
import { connect } from 'redux-bundler-react';
import { Form, Formik, Field } from 'formik';
import laboratorySchema from '../form-schemas/laboratorySchema';
import { searchIdFromOptions } from '../../utils';
import DataList from '../lib/DataList';
import Text from '../lib/Text';

/**
 *
 * @type {React.Component}
 * @param {Object}  params
 * @param {number}  params.userId
 * @param {Object}  params.userLabInfo
 * @param {Object}  params.whoRegionsData
 * @param {Object}  params.whoNetworkLabCategoriesData
 * @param {Object}  params.statesData
 * @param {Object}  params.countriesData
 * @param {Object}  params.unitedStatesValues
 * @param {boolean} params.userHasLabPermission
 * @param {Function} params.doUpdateLabDetails
 * @param {Function} params.doFetchUserProfile
 */
const UserLabInformationForm = ({
  userId,
  userLabInfo,
  whoRegionsData,
  whoNetworkLabCategoriesData,
  statesData,
  countriesData,
  unitedStatesValues,
  userHasLabPermission,
  doUpdateLabDetails,
  doFetchUserProfile,
}) => {
  // If no data is return, let's initialize as empty
  const initValues = {
    laboratoryName: '',
    WHORegionId: '',
    WHONetworkLabCategoryId: '',
    CountryId: '',
    address1: '',
    address2: '',
    city: '',
    StateId: '',
    state: '',
    zip: '',
  };
  const initialValues = userLabInfo || initValues;

  // let's declare our hook for the enabling of the controls on screen
  const [editUserLabInfo, setEditUserLabInfo] = useState(true);

  // This function is for sending the stateId as 0 since the api requires it.
  const onInputFieldChange = (e, setFieldValue, CountryId) => {
    if (CountryId !== unitedStatesValues.id && CountryId !== unitedStatesValues.name) {
      setFieldValue('StateId', 0);
    }
  };

  // This function render the data list or the input field for the state or province
  function renderStateDropDownOrInput(CountryId) {
    return CountryId &&
      (CountryId === unitedStatesValues.id || CountryId === unitedStatesValues.name) ? (
      <Field
        name="StateId"
        placeholder="State"
        component={DataList}
        options={statesData ? Object.values(statesData) : {}}
        readOnly={editUserLabInfo}
        style={{
          backgroundColor: 'white',
        }}
      />
    ) : (
      <Field
        name="state"
        placeholder="Province"
        component={Text}
        readOnly={editUserLabInfo}
        style={{
          backgroundColor: 'white',
        }}
      />
    );
  }

  const cancelUpdatePassword = (setSubmitting, resetForm) => {
    setSubmitting(false);
    resetForm();
    setEditUserLabInfo(true);
  };

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={laboratorySchema}
        validate={values => {
          const errors = {};
          if (
            values.CountryId === unitedStatesValues.id ||
            values.CountryId === unitedStatesValues.name
          ) {
            if (!values.StateId) {
              delete errors.state;
              errors.StateId = 'State is required here';
            }
          }
          if (
            values.CountryId !== unitedStatesValues.id &&
            values.CountryId !== unitedStatesValues.name
          ) {
            if (!values.state) {
              delete errors.StateId;
              errors.state = 'Province is required here';
            }
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            const labParticipant = {
              ...values,
              WHORegionId: searchIdFromOptions(Object.values(whoRegionsData), values.WHORegionId),
              WHONetworkLabCategoryId: searchIdFromOptions(
                Object.values(whoNetworkLabCategoriesData),
                values.WHONetworkLabCategoryId,
              ),
              StateId: searchIdFromOptions(Object.values(statesData), values.StateId),
              CountryId: searchIdFromOptions(Object.values(countriesData), values.CountryId),
            };

            delete labParticipant.id;
            await doUpdateLabDetails(userLabInfo.id, labParticipant); // here the action creator for updating the lab
            setSubmitting(false);
            setEditUserLabInfo(true);
            resetForm();
            await doFetchUserProfile(userId);
          } catch (err) {
            setSubmitting(false);
            setEditUserLabInfo(true);
          }
        }}
      >
        {({
          errors,
          touched,
          values,
          isSubmitting,
          setSubmitting,
          resetForm,
          dirty,
          handleChange,
          setFieldValue,
        }) => (
          <Form
            onChange={e => {
              handleChange(e);
              onInputFieldChange(e.target, setFieldValue, values.CountryId);
            }}
            className="mb-4"
          >
            <div className="card container mb-3">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-xl-12 col-lg-12 d-flex justify-content-between">
                    <p className="mt-3 header-pretitle">Laboratory Information</p>
                    <p className="mt-3 pb-4">
                      {userHasLabPermission && editUserLabInfo ? (
                        <button
                          type="button"
                          className="btn btn-link p-0"
                          data-toggle="collapse"
                          onClick={() => setEditUserLabInfo(false)}
                        >
                          <span className="fe fe-edit lead" />
                        </button>
                      ) : (
                        ''
                      )}
                    </p>
                  </div>
                </div>
                <div className="row d-flex justify-content-between mb-4">
                  <div className="col-lg-4 col-xl-4 col-sm-12 mb-3">
                    <label htmlFor="laboratoryName">Laboratory Name</label>
                    <Field
                      id="laboratoryName"
                      name="laboratoryName"
                      placeholder="Mertz - Thiel"
                      type="text"
                      className="form-control"
                      readOnly={editUserLabInfo}
                      style={{
                        backgroundColor: 'white',
                      }}
                    />
                    {errors.laboratoryName && touched.laboratoryName ? (
                      <div className="text-danger">{errors.laboratoryName}</div>
                    ) : (
                      ''
                    )}
                  </div>

                  <Field
                    name="WHORegionId"
                    placeholder="WHO Region"
                    component={DataList}
                    options={whoRegionsData ? Object.values(whoRegionsData) : {}}
                    readOnly={editUserLabInfo}
                    style={{
                      backgroundColor: 'white',
                    }}
                  />

                  <Field
                    name="WHONetworkLabCategoryId"
                    placeholder="WHO Network Laboratory Level"
                    component={DataList}
                    options={
                      whoNetworkLabCategoriesData ? Object.values(whoNetworkLabCategoriesData) : {}
                    }
                    readOnly={editUserLabInfo}
                    style={{
                      backgroundColor: 'white',
                    }}
                  />

                  <Field
                    name="address1"
                    placeholder="Address 1"
                    component={Text}
                    readOnly={editUserLabInfo}
                    style={{
                      backgroundColor: 'white',
                    }}
                  />
                  <Field
                    name="address2"
                    placeholder="Address 2"
                    component={Text}
                    readOnly={editUserLabInfo}
                    style={{
                      backgroundColor: 'white',
                    }}
                  />
                  <Field
                    name="city"
                    placeholder="City/Municipality"
                    component={Text}
                    readOnly={editUserLabInfo}
                    style={{
                      backgroundColor: 'white',
                    }}
                  />

                  <Field
                    name="CountryId"
                    placeholder="Country"
                    component={DataList}
                    options={countriesData ? Object.values(countriesData) : {}}
                    readOnly={editUserLabInfo}
                    style={{
                      backgroundColor: 'white',
                    }}
                  />

                  {renderStateDropDownOrInput(values.CountryId)}

                  <Field
                    name="zip"
                    placeholder="Zip Code"
                    component={Text}
                    readOnly={editUserLabInfo}
                    style={{
                      backgroundColor: 'white',
                    }}
                  />

                  <div
                    id="editLab"
                    className={
                      editUserLabInfo
                        ? 'col-sm-12 col-lg-12 col-xl-12  text-right collapse'
                        : 'col-sm-12 col-lg-12 col-xl-12 text-right'
                    }
                  >
                    <button
                      name="submit"
                      data-toggle="collapse"
                      type="submit"
                      disabled={isSubmitting || !dirty}
                      className="btn btn-primary mr-2 mt-4"
                    >
                      Update
                    </button>

                    <button
                      name="cancel"
                      data-toggle="collapse"
                      // href="#editLab"
                      type="button"
                      onClick={() => cancelUpdatePassword(setSubmitting, resetForm)}
                      className="btn btn-outline-danger mt-4"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default connect(
  'doUpdateLabDetails',
  'doFetchUserProfile',
  'selectUserLabInfo',
  'selectStatesData',
  'selectUnitedStatesValues',
  'selectCountriesData',
  'selectWhoRegionsData',
  'selectWhoNetworkLabCategoriesData',
  'selectUserHasLabPermission',
  UserLabInformationForm,
);
