/* eslint-disable no-console */
/* eslint-disable prefer-spread */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useCallback, useEffect } from 'react';
import { Field, Formik, Form } from 'formik';
import { connect } from 'redux-bundler-react';
import Col from '../../lib/Col';
import Row from '../../lib/Row';
import StudiesSchema from '../../form-schemas/studiesSchema';
import TextArea from '../../lib/TextArea';

import StudyNameField from './StudyNameField';
import StudyYearField from './StudyYearField';
import StartDateField from './StartDateField';

import EndDateField from './EndDateField';
// import PanelManufactureDateField from './PanelManufactureDateField';
import { monthNames } from '../../../helpers/constants';
import { uuidv4 } from '../../../utils';

const buildSubmitPayload = values => {
  const { cboMonth, cboYear } = values;
  const concatDate = `${cboYear}/${cboMonth}/01`;
  const newValues = { ...values };

  if (!values.endDate) {
    delete newValues.endDate;
  } else {
    newValues.endDate = newValues.endDate && new Date(newValues.endDate);
  }

  if (cboMonth === '' || cboYear === '') {
    delete newValues.panelManufactureDate;
  } else {
    newValues.panelManufactureDate = new Date(concatDate);
  }

  return {
    ...newValues,
    year: parseInt(newValues.year, 10),
    startDate: new Date(newValues.startDate),
  };
};

const StudyDetailForm = ({ currentStudy, doUpdateStudyDetails, routeParams, doUpdateUrl }) => {
  const [testAvailables, setTestsAvailables] = useState([]);

  const initialValues = {
    name: currentStudy ? currentStudy.name : '',
    year: currentStudy ? currentStudy.year : '',
    startDate: currentStudy ? new Date(currentStudy.startDate) : '',
    endDate: currentStudy && currentStudy.endDate ? new Date(currentStudy.endDate) : '',
    cboYear:
      currentStudy && currentStudy.panelManufactureDate
        ? new Date(currentStudy.panelManufactureDate).getFullYear()
        : '',
    cboMonth:
      currentStudy && currentStudy.panelManufactureDate
        ? monthNames[new Date(currentStudy.panelManufactureDate).getMonth()]
        : '',
    panelManufactureDate:
      currentStudy && currentStudy.panelManufactureDate
        ? new Date(currentStudy.panelManufactureDate)
        : '',
    notes: (currentStudy && currentStudy.notes) || '',
  };

  const retrieveTests = useCallback(() => {
    if (currentStudy) {
      const { Tests } = currentStudy || [];
      setTestsAvailables(Tests);
    }
  }, [currentStudy]);

  useEffect(() => {
    retrieveTests();
  }, [retrieveTests]);

  // Get the current year
  const currentYear = new Date().getFullYear();
  const cboMonth =
    currentStudy && currentStudy.panelManufactureDate
      ? new Date(currentStudy.panelManufactureDate).getMonth()
      : '';
  const cboYear =
    currentStudy && currentStudy.panelManufactureDate
      ? new Date(currentStudy.panelManufactureDate).getFullYear()
      : '';

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validate={values => {
        const errors = {};

        if (values.endDate) {
          if (new Date(values.endDate) <= new Date(values.startDate)) {
            errors.endDate = 'End Date should be greater than Start Date';
          }
        }

        return errors;
      }}
      validationSchema={StudiesSchema}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const payload = buildSubmitPayload(values);
          await doUpdateStudyDetails(routeParams.studyId, payload);
          doUpdateUrl('/studies');
        } catch (err) {
          console.log('Error', err);
        }
        setSubmitting(false);
      }}
    >
      {({ values, errors, isSubmitting, dirty, touched }) => (
        <Form>
          <div className="card">
            <div className="card-body">
              <div className="form-row mb-3">
                <Col col={12} lg={6}>
                  <Row>
                    <label htmlFor="name" className="col-12 col-xl-3 col-form-label">
                      Name
                    </label>
                    <Col col={12} xl={9}>
                      <Field name="name" component={StudyNameField} />
                    </Col>
                  </Row>
                </Col>
                <Col col={12} lg={6}>
                  <Row>
                    <label htmlFor="year" className="col-12 col-xl-3 col-form-label">
                      Year
                    </label>
                    <Col col={12} xl={9}>
                      <Field name="year" component={StudyYearField} />
                    </Col>
                  </Row>
                </Col>
              </div>

              <div className="form-row mb-3">
                <Col col={12} lg={6}>
                  <Row>
                    <label htmlFor="startDate" className="col-12 col-xl-3 col-form-label">
                      Start Date
                    </label>
                    <Col col={12} xl={9}>
                      <Field name="startDate" component={StartDateField} value={values.startDate} />
                    </Col>
                  </Row>
                </Col>
                <Col col={12} lg={6}>
                  <Row>
                    <label htmlFor="endDateInput" className="col-12 col-xl-3 col-form-label">
                      End Date
                    </label>

                    <Col col={12} xl={9}>
                      <Field name="endDate" component={EndDateField} value={values.endDate} />
                    </Col>
                  </Row>
                </Col>
              </div>
            </div>
          </div>
          <hr />
          <div className="form-row mb-3">
            <Col col={12} lg={6}>
              <div className="card-adjust-lg">
                <div className="card mb-4 pb-3">
                  <div className="card-header">
                    <h6 className="header-pretitle">Tests</h6>
                  </div>
                  <div className="card-body d-flex align-items-center justify-content-center">
                    {testAvailables &&
                      !!testAvailables.length &&
                      testAvailables.map(test => (
                        <div key={uuidv4(2, 3)} className="col-auto pl-0">
                          <p className="card-text">
                            <span className="badge badge-soft-secondary">{test.name} Test</span>
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </Col>

            <Col col={12} lg={6}>
              <div className="card">
                <div className="card-header">
                  <h6 className="header-pretitle">Panel Manufacture Date</h6>
                </div>
                <div className="card-body">
                  <Row className="justify-content-center">
                    <Col col={12} lg={6}>
                      <div className="form-group">
                        <Field
                          name="cboMonth"
                          component="select"
                          placeholder="Select Month"
                          className={
                            errors.cboMonth
                              ? 'mr-2 form-control form-control is-invalid'
                              : 'mr-2 form-control form-control'
                          }
                        >
                          <React.Fragment>
                            {cboMonth || cboYear ? (
                              <>
                                {monthNames.map((month, i) =>
                                  i === monthNames[cboMonth] ? (
                                    <option defaultValue>{monthNames[cboMonth]}</option>
                                  ) : (
                                    <option value={month} key={month}>
                                      {month}
                                    </option>
                                  ),
                                )}
                              </>
                            ) : (
                              <>
                                <option defaultValue>Select a month</option>
                                {monthNames.map(month => (
                                  <option value={month} key={month}>
                                    {month}
                                  </option>
                                ))}
                              </>
                            )}
                          </React.Fragment>
                        </Field>
                        {touched.cboMonth && errors.cboMonth && (
                          <span className="invalid-feedback">{errors.cboMonth}</span>
                        )}
                      </div>
                    </Col>
                    <Col col={12} lg={6}>
                      <div className="form-group">
                        <Field
                          name="cboYear"
                          component="select"
                          placeholder="Select Year"
                          className={
                            errors.cboYear
                              ? 'mr-2 form-control form-control is-invalid'
                              : 'mr-2 form-control form-control'
                          }
                        >
                          <React.Fragment>
                            {cboMonth || cboYear ? (
                              <>
                                {Array.apply(null, {
                                  length: 10,
                                }).map((e, i) =>
                                  i === cboYear ? (
                                    <option defaultValue>{cboYear}</option>
                                  ) : (
                                    <option value={currentYear - i} key={i.toString()}>
                                      {currentYear - i}
                                    </option>
                                  ),
                                )}
                              </>
                            ) : (
                              <>
                                <option defaultValue>Select a year</option>
                                {Array.apply(null, {
                                  length: 10,
                                }).map((e, i) => (
                                  <option value={currentYear - i} key={i.toString()}>
                                    {currentYear - i}
                                  </option>
                                ))}
                              </>
                            )}
                          </React.Fragment>
                        </Field>
                        {touched.cboYear && errors.cboYear && (
                          <span className="invalid-feedback">{errors.cboYear}</span>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </div>
          <hr />
          <div className="form-row mb-3">
            <Field name="notes" placeholder="Notes" classNameParent="col-12" component={TextArea} />
          </div>

          <div className="text-right">
            <button
              type="submit"
              disabled={isSubmitting || !dirty || Object.keys(errors).length}
              className="btn btn-primary mr-2 mt-4"
            >
              Update
            </button>
            <a href="/studies" className="btn btn-outline-danger mt-4">
              Cancel
            </a>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default connect(
  'selectCurrentStudy',
  'doUpdateStudyDetails',
  'selectRouteParams',
  'doUpdateUrl',
  StudyDetailForm,
);
