import { useState, useEffect, useCallback } from 'react';
import { genotypeTestInterpretationMappingForReportedGenotype, strCapitalize } from '../../utils';
/**
 * [This hooks, provides the data for the table of reports for each test, also provide the total score for the test]
 *
 * @param   {[Object]}  testData  [The data of each test]
 * @param   {[String]}  type      [The Test Type]
 *
 * @return  {[Object]}            [This return the formatted data ready for the table, and the total score for the test]
 */
const useReportTestTableData = (testData, type) => {
  const [data, setData] = useState([]);
  const [totalScore, setScore] = useState(0);
  const [notes, setNotes] = useState([]);

  const getEiaData = () => {
    let scoreCount = 0;
    const eiaData = testData.map(d => {
      scoreCount += d?.finalScore?.value;
      return {
        sample_id: d.sampleIDNumber?.value,
        eia_expected_result: strCapitalize(true, d.expectedResult?.value),
        eia_reported_result: strCapitalize(true, d.submittedResult?.value),
        eia_sample_score: d?.finalScore?.value,
        eia_note: d?.overrideNote?.value,
      };
    });

    return { eiaData, scoreCount };
  };

  const getGenotypeData = () => {
    let scoreCount = 0;
    const genotypingData = testData.map(d => {
      scoreCount += d?.finalScore.value;
      return {
        sample_id: d.sampleIDNumber.value,
        genotyping_expected_result: genotypeTestInterpretationMappingForReportedGenotype(
          d.expectedResult?.expectedGenotype,
        ),
        genotyping_reported:
          genotypeTestInterpretationMappingForReportedGenotype(d.finalGenotypeValue?.value) ||
          genotypeTestInterpretationMappingForReportedGenotype(d.finalGenotype?.value),
        genotyping_sample_score: d.finalScore.value,
        genotyping_note: d.overrideNote.value,
      };
    });

    return { genotypingData, scoreCount };
  };

  const memoizedGetEiaData = useCallback(() => {
    return getEiaData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testData]);

  const memoizedGetGenotypeData = useCallback(() => {
    return getGenotypeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testData]);

  useEffect(() => {
    if (testData.length > 0) {
      const testFormattedData =
        type === 'EIA' ? memoizedGetEiaData().eiaData : memoizedGetGenotypeData().genotypingData;
      setData(testFormattedData);
      setScore(
        type === 'EIA' ? memoizedGetEiaData().scoreCount : memoizedGetGenotypeData().scoreCount,
      );
      setNotes(
        type === 'EIA'
          ? memoizedGetEiaData().notesForEia
          : memoizedGetGenotypeData().notesForGenotye,
      );
    }
  }, [testData, type, memoizedGetEiaData, memoizedGetGenotypeData]);

  return [data, totalScore, notes];
};

export default useReportTestTableData;
