import React, { useCallback, useEffect } from 'react';
import { monthNames, enrolledTestStatus, panelShippingStatus } from '../../helpers/constants';
import $ from 'jquery';

const ViewKitShippingStatus = ({ study }) => {
  const singleCallback = useCallback(() => {
    const allFormsAreFinalized = study.Enrollment.Tests.every(
      test => test.EnrolledTest.status === enrolledTestStatus.FINALIZED,
    );
    const allFormsAreFilledUpOrFinalized = study.Enrollment.Tests.every(
      test =>
        test.EnrolledTest.status === enrolledTestStatus.GRADING_PENDING ||
        test.EnrolledTest.status === enrolledTestStatus.FINALIZED,
    );
    const formsAreUnlocked = study.Enrollment.Tests.every(
      test => test.EnrolledTest.status === enrolledTestStatus.UNLOCKED,
    );
    const someFormsAreUnlocked = study.Enrollment.Tests.some(
      test => test.EnrolledTest.status === enrolledTestStatus.UNLOCKED,
    );
    const submittedTestQuantity = study.Enrollment.Tests.filter(
      test =>
        test.EnrolledTest.status !== enrolledTestStatus.GRADING_PENDING &&
        test.EnrolledTest.status !== enrolledTestStatus.FINALIZED,
    );

    return {
      allFormsAreFinalized,
      allFormsAreFilledUpOrFinalized,
      formsAreUnlocked,
      someFormsAreUnlocked,
      submittedTestQuantity,
    };
  }, [study]);

  const {
    allFormsAreFinalized,
    allFormsAreFilledUpOrFinalized,
    formsAreUnlocked,
    someFormsAreUnlocked,
    submittedTestQuantity,
  } = singleCallback();

  useEffect(() => {
    // Initialize the Bootstrap tooltip functionality for renderButton()
    $('[data-toggle="tooltip"]').tooltip();
  }, []);

  const stl = { fontSize: '13px' };
  let domDate = '';
  let kitMonthDOM = '';
  let kitYearDOM = '';
  let monthName = '';

  // convert the ISO date
  if (study.Enrollment.dateOfManufacture) {
    domDate = new Date(study.Enrollment.dateOfManufacture);
    kitMonthDOM = domDate.getMonth();
    kitYearDOM = domDate.getFullYear();
    // Format the months to a more friendly format
    monthName = monthNames[kitMonthDOM];
  }

  function renderButton() {
    if (study.Enrollment.shipmentStatus === panelShippingStatus.RECEIVED) {
      if (allFormsAreFinalized) {
        return (
          <a
            href={`/labs/${study.Enrollment.LabParticipantId}/enrollments/${study.Enrollment.id}/studyResultsReport`}
            role="button"
            className="btn btn-primary btn-sm"
          >
            View Results
          </a>
        );
      }
      if (allFormsAreFilledUpOrFinalized) {
        return (
          <div
            title="Some tests are still pending grading"
            data-toggle="tooltip"
            data-placement="top"
          >
            <a role="button" className="btn btn-primary btn-sm disabled">
              View Results
            </a>
          </div>
        );
      }
      if (formsAreUnlocked) {
        return (
          <a
            href={`/enrolled-studies/${study.Enrollment.id}/submit-tests`}
            role="button"
            className="btn btn-primary btn-sm"
          >
            Pending Tests to Resubmit ({`${submittedTestQuantity.length}`})
          </a>
        );
      }
      if (someFormsAreUnlocked) {
        return (
          <a
            href={`/enrolled-studies/${study.Enrollment.id}/submit-tests`}
            role="button"
            className="btn btn-primary btn-sm"
          >
            Pending Test to Resubmit/Submit ({`${submittedTestQuantity.length}`})
          </a>
        );
      }
      return (
        <a
          href={`/enrolled-studies/${study.Enrollment.id}/submit-tests`}
          role="button"
          className="btn btn-primary btn-sm"
        >
          Submit Pending Tests ({`${submittedTestQuantity.length}`})
        </a>
      );
    }
    if (study.Enrollment.shipmentStatus === panelShippingStatus.SHIPPED) {
      return (
        <a
          href={`/enrolled-studies/${study.Enrollment.id}`}
          role="button"
          className="btn btn-primary"
        >
          Set Kit as Received
        </a>
      );
    }
    return null;
  }

  const renderStatusBadge = test => {
    if (test.EnrolledTest.status === enrolledTestStatus.FINALIZED) {
      return <span className="text-success">●</span>;
    }
    if (test.EnrolledTest.status === enrolledTestStatus.UNLOCKED) {
      return <span className="text-danger">●</span>;
    }
    if (test.EnrolledTest.status === enrolledTestStatus.GRADING_PENDING) {
      return <span className="text-primary">●</span>;
    }
    return <span className="text-warning">●</span>;
  };

  return (
    <>
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <div className="card">
          <div className="card-header text-secondary">Currently Enrolled to: </div>
          <div className="card-body text-center">
            <h2 className="text-primary header-pretitle card-title">
              {study.name} {study.year}
            </h2>
            <p className="small text-muted text-uppercase mb-3">Available Tests</p>
            <p className="d-flex flex-column d-sm-block">
              {study.Enrollment.Tests.map(test => (
                <small className="mb-3 mb-sm-0" key={test.name}>
                  {renderStatusBadge(test)}
                  <span className="badge badge-soft-secondary ml-2 mr-3">{test.description}</span>
                </small>
              ))}
            </p>

            <div className="d-flex flex-column d-sm-none">
              <div className="col-auto">
                <h6 className="text-uppercase text-secondary">Kit Date Of Manufacture</h6>
                <h2 className="mb-0">
                  <span style={stl} className="small text-muted">
                    {study.Enrollment.dateOfManufacture ? `${monthName}, ${kitYearDOM}` : '--'}
                  </span>
                </h2>
              </div>
              <hr className="w-100" />
              <div className="col-auto">
                <h6 className="text-uppercase text-secondary"> Shipping Details</h6>
                <h2 className="mb-0">
                  <span style={stl} className="small text-muted">
                    {study.Enrollment.shippingDetail ? study.Enrollment.shippingDetail : '--'}
                  </span>
                </h2>
              </div>
            </div>

            <div className="d-none d-sm-flex flex-sm-wrap border-top">
              <div className="col-6 py-4 text-center">
                <div className="px-2">
                  <h6 className="text-uppercase text-secondary">Kit Date Of Manufacture</h6>
                  <h2 className="mb-0">
                    <span style={stl} className="small text-muted">
                      {study.Enrollment.dateOfManufacture ? `${monthName}, ${kitYearDOM}` : '--'}
                    </span>
                  </h2>
                </div>
              </div>
              <div className="col-6 py-4 text-center border-left">
                <div className="px-2">
                  <h6 className="text-uppercase text-secondary"> Shipping Details</h6>
                  <h2 className="mb-0">
                    <span style={stl} className="small text-muted">
                      {study.Enrollment.shippingDetail ? study.Enrollment.shippingDetail : '--'}
                    </span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer py-3 px-3">
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
              <small className="text-center text-sm-left mb-2 mb-sm-0">
                <span
                  className={
                    study.Enrollment.shipmentStatus === panelShippingStatus.RECEIVED
                      ? 'text-success'
                      : 'text-warning'
                  }
                >
                  ●
                </span>{' '}
                KIT {study.Enrollment.shipmentStatus}
              </small>

              {renderButton()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewKitShippingStatus;
