import React from 'react';

const NoDataAndRedirect = () => (
  <div className="card card-inactive">
    <div className="card-body text-center">
      <h4 className="text-uppercase text-muted mb-4">Data unavailable</h4>
      Back to{' '}
      <a href="/" className="small">
        Dashboard
      </a>
    </div>
  </div>
);

export default NoDataAndRedirect;
