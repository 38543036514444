import React, { useState, useEffect } from 'react';
import { connect } from 'redux-bundler-react';
import EiaResults from '../test-results/EIA/EiaResults';
import GenotypingResults from '../test-results/Genotyping/GenotypingResults';
import NoDataAndRedirect from '../lib/NoDataAndRedirect';

const SubmitTestPage = ({ routeParams, testsToSubmit, currentAnswersTestsList }) => {
  const { testId } = routeParams;
  const [tests, setTests] = useState([]);
  const [answers, setAnswers] = useState({});

  useEffect(() => {
    setTests([]);
    if (testsToSubmit && testsToSubmit.length) {
      setTests(testsToSubmit);
    }
  }, [testsToSubmit]);

  useEffect(() => {
    setAnswers({});
    if (currentAnswersTestsList) {
      const [resp] = currentAnswersTestsList;
      setAnswers(resp);
    }
  }, [currentAnswersTestsList]);

  const testToMap = {
    1: {
      component: <EiaResults testId={+testId} currentAnswers={answers} />,
    },
    2: {
      component: <GenotypingResults testId={+testId} currentAnswers={answers} />,
    },
  };

  function renderForm() {
    const filteredForm = tests.filter(test => test.id === +testId);

    if (tests.length && filteredForm.length) {
      return testToMap[+testId].component;
    }
    return <NoDataAndRedirect />;
  }
  return (
    <div className="card">
      <div className="card-body">{renderForm()}</div>
    </div>
  );
};

export default connect(
  'selectRouteParams',
  'selectTestsToSubmit',
  'selectCurrentAnswersTestsList',
  SubmitTestPage,
);
