import React from 'react';
import ErrorMessage from '../../lib/errorMessage';

const RowInput = ({
  field, // { name, value, onChange, onBlur }
  handleChange,
  className,
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <>
      <input
        type="text"
        {...props}
        className={className || 'form-control text-secondary'}
        onChange={handleChange}
        {...field}
      />
      <ErrorMessage name={field.name} />
    </>
  );
};
export default RowInput;
