/* eslint-disable prefer-spread */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

function sampleIDLengthExample({ sampleLength, firstIndex, secondIndex }) {
  return (
    <>
      <div className="col-3 col-lg-2 ">
        <label htmlFor="example" className="text-muted mb-0">
          Example
        </label>
      </div>
      <div className="col-9 col-lg-10 px-0">
        <ul className="list-inline float-right">
          {Array.apply(null, { length: sampleLength }).map((_, index) => (
            <li key={index.toString()} className="list-inline-item">
              <span
                className={`badge badge-soft-${
                  index === +firstIndex - 1 || index === +secondIndex - 1 ? 'danger' : 'primary'
                } mr-2`}
              >
                X
              </span>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default sampleIDLengthExample;
