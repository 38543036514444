/* eslint-disable prefer-spread */
import * as Yup from 'yup';

const ExpectedResult = Yup.object().shape({
  sumCode: Yup.number()
    .typeError('Must be a number')
    .min(0)
    .max(18)
    .required('Sum Code is Required'),
  eiaResult: Yup.string().required('Please select a value'),
  expectedGenotype: Yup.object().shape({
    id: Yup.number(),
    StudyTestId: Yup.number(),
    sumCode: Yup.number(),
    EIAResult: Yup.string(),
    expectedGenotype: Yup.string(),
  }),
});

const ExpectedResultSchema = Yup.object().shape({
  sampleLength: Yup.number(),
  firstIndex: Yup.number()
    .typeError('Must be a number')
    .min(0)
    .required('First Index is Required'),
  secondIndex: Yup.number()
    .typeError('Must be a number')
    .min(0)
    .required('Second Index is Required'),
  expectedResults: Yup.array().of(ExpectedResult),
});

const initExpectedResultsValues = {
  expectedResults: [],
  sampleLength: 6,
  firstIndex: '',
  secondIndex: '',
};

export { initExpectedResultsValues, ExpectedResultSchema };
