/* eslint-disable no-irregular-whitespace */
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'redux-bundler-react';
import { has } from '../../utils';
import { testInstances } from '../../helpers/constants';
import TestCard from '../views/TestCard';
import NoDataAndRedirect from '../lib/NoDataAndRedirect';

const TestSubmittedAnswersPage = ({ enrolledSubmittedTests, answersTestsList }) => {
  const [submittedTests, setSubmittedTests] = useState([]);
  const [answersList, setAnswersList] = useState([]);

  const filterResult = useCallback(
    idTest => {
      let currentData = {};
      const [activeResult] = submittedTests.filter(subTest => subTest.id === idTest);
      if (activeResult) {
        const [result] = answersList.filter(answer => answer.id === activeResult.EnrolledTest.id);
        if (result) {
          if (has.call(result, testInstances.EIATest)) {
            currentData = { ...result };
          } else if (has.call(result, testInstances.GenotypingTest)) {
            currentData = { ...result };
          }
        }
      }
      return currentData;
    },
    [answersList, submittedTests],
  );

  useEffect(() => {
    if (enrolledSubmittedTests && enrolledSubmittedTests.length) {
      setSubmittedTests(enrolledSubmittedTests);
      setAnswersList(answersTestsList);
    }
  }, [answersTestsList, enrolledSubmittedTests]);

  return (
    <>
      <div className="row align-items-center justify-content-center">
        {submittedTests.length ? (
          submittedTests.map(test => (
            <TestCard test={test} key={test.id} answerData={filterResult(+test.id)} />
          ))
        ) : (
          <NoDataAndRedirect />
        )}
      </div>
    </>
  );
};

export default connect(
  'selectEnrolledSubmittedTests',
  'selectAnswersTestsList',
  TestSubmittedAnswersPage,
);
