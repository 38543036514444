import React from 'react';
import { otherOptionsForSelect } from '../../../helpers/constants';
import { formatedLongDate } from '../../../utils';
import Row from '../../lib/Row';
import GenotypeAmplificationErrorString from './GenotypeAmplificationErrorString';

const ListGroup = ({ currentKitData, items, removeItem, editItem, errorBadge }) => {
  const renderKitBrandName = (kitId, otherBrand) => {
    if (+kitId === otherOptionsForSelect.id) {
      return otherBrand;
    }
    if (currentKitData) {
      const filterKit = Object.values(currentKitData).find(kit => kit.id === +kitId);

      return filterKit.name;
    }
  };

  const updateData = (values, index) => {
    editItem(values);
    removeItem(index);
  };

  return (
    <div className="card-adjust-xl">
      <div className="card">
        <div className="card-header">
          <Row>
            <div className="col">
              <h4 className="card-header-title">Amplification rounds</h4>
            </div>
            <GenotypeAmplificationErrorString errors={errorBadge} />
          </Row>
        </div>
        <div className="card-body">
          <div className="list-group list-group-flush">
            {items &&
              items.map((rounds, index) => {
                return (
                  <div key={`list-${index.toString()}`} className="list-group-item py-3 px-0">
                    <div className="row align-items-center">
                      <div className="col-auto">
                        <div className="avatar avatar-sm">
                          <div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                            <i className="fe fe-archive" />
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <p className="small mb-0">
                          <strong>
                            {renderKitBrandName(
                              rounds.AmplificationKitId,
                              rounds.otherAmplificationKitBrand,
                            )}
                          </strong>
                          {`. ${rounds.vendorName} / ${rounds.serialNumber}`}
                        </p>
                        <small className="text-muted">
                          {formatedLongDate(new Date(rounds.expiryDate).toISOString())}
                        </small>
                      </div>
                      <div className="col-auto">
                        <button
                          className="close button-sm ml-3 font-weight-bold text-danger no-opacity"
                          type="button"
                          onClick={() => removeItem(index)}
                        >
                          <i className="fe fe-trash" />
                        </button>
                        <button
                          className="close text-warning button-sm no-opacity font-weight-bold"
                          type="button"
                          onClick={() => updateData(rounds, index)}
                        >
                          <i className="fe fe-edit" />
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListGroup;
