// Importing Libraries
import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { connect } from 'redux-bundler-react';
import { TestTargetScores } from '../../helpers/constants';
import ResultReportView from '../views/ResultsReportView';
const DOWNLOAD_REPORT_BUTTON = process.env.REACT_APP_DOWNLOAD_REPORT_FROM_RESULT_REPORT_PAGE === 'true';

/**
 *
 * @param {Object} labResultReport This is the Report given an enrollment for a lab
 * @param {Object} currentLabEnrollmentDetails This is the details of the given lab
 * @param {Boolean} currentLabIsRegional This is a boolean value, that tells if the lab is 'Regional'
 */

 const handleDownloadReportClick = () => {
    console.log('Not Implemented Error!');
    window.alert('Not Implemented Error!');
    return null;
  };

const ResultsReportPage = ({
  labResultReport,
  currentLabEnrollmentDetails,
  currentLabIsRegional,
  userIsAdmin,
}) => {
  const element = useRef(null);
  const showBreadcrumbs = currentLabEnrollmentDetails && userIsAdmin;

  return labResultReport?.testResults ? (
    <>
      {showBreadcrumbs ? (
        <div className="card">
          <div className="card-header">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/labs" title="Labs">
                    Labs
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a
                    href={`/labs/${currentLabEnrollmentDetails.LabParticipantId}/enrollments/${currentLabEnrollmentDetails.id}`}
                    title="Lab Enrollment"
                  >
                    Lab Enrollment
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Report
                </li>
              </ol>
            </nav>
          </div>
        </div>
      ) : null}

      <div className="d-flex justify-content-end gap-5">

        {DOWNLOAD_REPORT_BUTTON && (
          <div className="mb-4 text-right">
            <button type="button" className="btn btn-light" onClick={handleDownloadReportClick}>
              Download Report
            </button>
          </div>
        )}

        <div className="mb-4 ml-3 text-right">
          <ReactToPrint
            trigger={() => (
              <button type="button" className="btn btn-light">
                Print Report
              </button>
            )}
            content={() => element.current}
            documentTitle="WHO-EQA-proficiency-testing-challenge-results-report"
          />
        </div>
      </div>

      <ResultReportView
        ref={element}
        labResultReport={labResultReport}
        minScoreToPass={
          currentLabIsRegional ? TestTargetScores.RRL_SCORE : TestTargetScores.DEFAULT_SCORE
        }
      />
    </>
  ) : null;
};

export default connect(
  'selectLabResultReport',
  'selectCurrentLabEnrollmentDetails',
  'selectCurrentLabIsRegional',
  'selectUserIsAdmin',
  ResultsReportPage,
);
