import React from 'react';

/**
 *
 * @param {String} laboratoryName The Name of the Lab
 * @param {String} address1 The Addrress 1
 *  @param {String} address2 The Addrress 2
 */
const ResultReportLabInfo = ({ laboratoryName, address1, address2 }) => {
  return (
    <>
      <h6 className="text-uppercase text-muted">Particpating Laboratory Name and Address</h6>

      <p className="text-muted mb-4">
        <strong className="text-body">{laboratoryName}</strong> <br />
        {address1} <br />
        {address2} <br />
      </p>
    </>
  );
};

export default ResultReportLabInfo;
