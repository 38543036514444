import React from 'react';
import orderBy from 'lodash/orderBy';
import RowValueForReportedTest from '../lib/RowValueForReportedTest';
import { extraPropertiesForResultsTable, enrolledTestStatus } from '../../helpers/constants';

/**
 *
 * @typedef {React.Component}
 * @param {Array} resultsColumnsHeaders  List of header columns
 * @param {Array} items                  List of results
 * @param {React.Component} children     Children Component
 * @param {Number} enrolledTestId        Id of Enrolled Test
 * @param {Number} currentLabID          Id of the Lab the study belongs to
 * @param {Boolean} EIATest              Shows EAI Results if they exist
 * @param {Boolean} GenotypingTest       Shows Genotype Results if they exist
 * @param {String} status                Test Status [GRADING_PENDING | UNLOCKED | FINALIZED]
 */
const LabSubscribedTestResultsTable = ({
  resultsColumnsHeader,
  items,
  children,
  enrolledTestId,
  currentLabID,
  EIATest,
  GenotypingTest,
  status,
}) => {
  const results = orderBy(items, ['id']);

  let extraProperties = [];
  if (GenotypingTest) {
    extraProperties = [
      ...extraPropertiesForResultsTable,
      'suggestedScore',
      'score',
      'finalScore',
      'overrideNote',
    ];
  }
  if (EIATest) {
    if (enrolledTestStatus.FINALIZED === status) {
      extraProperties = [...extraPropertiesForResultsTable, 'score', 'suggestedScore'];
    } else {
      extraProperties = [
        ...extraPropertiesForResultsTable,
        'score',
        'suggestedScore',
        'finalScore',
      ];
    }
  }

  return (
    <div className="card">
      <div className="card-header">
        <div className="row align-items-center">
          <div className="col">
            <h4 className="card-header-title text-left header-pretitle">Results</h4>
          </div>
        </div>
      </div>
      <div className="table-responsive mb-0">
        <table className="table table-sm table-nowrap card-table table-hover">
          <thead className="text-center">
            <tr>
              {resultsColumnsHeader &&
                resultsColumnsHeader.map((headerColumn, index) => (
                  <th key={index.toString()}>{headerColumn}</th>
                ))}
            </tr>
          </thead>
          <tbody className="text-center">
            {results &&
              results.map(item => (
                <tr key={item.id}>
                  {Object.keys(item).map(prop => {
                    if (!extraProperties.includes(prop)) {
                      return (
                        <RowValueForReportedTest
                          item={item}
                          prop={prop}
                          key={`item-${item.id}-${prop}`}
                        />
                      );
                    }
                    return null;
                  })}
                </tr>
              ))}
          </tbody>
          {children}
        </table>
      </div>
      <div className="card-footer d-flex justify-content-end">
        <a
          title="See submitted results grading"
          href={`/labs/${currentLabID}/enrolledTests/${enrolledTestId}/grading`}
          className="btn btn-primary"
        >
          View Test Grading
        </a>
      </div>
    </div>
  );
};

export default LabSubscribedTestResultsTable;
