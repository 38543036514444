import React, { useEffect } from 'react';
import { statusObjTextForCard } from '../../helpers/constants';
import $ from 'jquery';
import { connect } from 'redux-bundler-react';
import ConfirmActionButton from '../lib/ConfirmActionButton';

/**
 * @type {React.Component}
 * @param {Function} doEnrollInTest  Action Creator to add a test to existing enrollment
 */

const LabEnrollmentSubscribedTestTable = ({ enrolledTests, labId, currentLab, doEnrollInTest }) => {
  useEffect(() => {
    // Initialize the Bootstrap tooltip functionality for renderButton()
    $('[data-toggle="tooltip"]').tooltip();
  }, []);

  return (
    <div className="card">
      <div className="card-header">
        <div className="row align-items-center">
          <div className="col">
            <h4 className="card-header-title">Subscribed Tests</h4>
          </div>
          {/* The default value for trigger is 'hover focus', 
          thus the tooltip stay visible after a button is clicked */}
          <div
            title="Use this button cautiously and only when a user specifically requests to add a genotyping test."
            data-toggle="tooltip"
            data-placement="top"
            data-trigger="hover"
          >
            {/* Display the button only if the user is enrolled only in the EIA test. */}
            {Array.isArray(enrolledTests) &&
              enrolledTests.length === 1 &&
              enrolledTests[0]?.type === 'EIA' && (
                <ConfirmActionButton
                  action={() => doEnrollInTest(enrolledTests, labId)}
                  bodyText={`Are you sure you want to change enrollment for lab ${
                    currentLab ? `${currentLab.laboratoryName}` : `${labId}`
                  }? The lab will be enrolled in the genotyping test.`}
                  cancelText="Cancel"
                  className="btn btn-link p-0"
                  confirmText="Enroll to Genotyping"
                  confirmButtonClass="btn btn-danger"
                  disabled={enrolledTests && enrolledTests.length > 1}
                  icon="trash"
                  modalTitle="Confirm enrollment"
                >
                  <div id="enrollButton" className="btn btn-primary mr-2 mt-0">
                    Enroll to Genotyping
                  </div>
                </ConfirmActionButton>
              )}
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-sm table-nowrap card-table table-hover">
          <thead className="text-center">
            <tr>
              <th className="text-muted">#</th>
              <th className="text-muted">Name</th>
              <th className="text-muted">Current Status</th>
              <th className="text-muted">Actions</th>
            </tr>
          </thead>
          <tbody className="list text-center">
            {enrolledTests &&
              enrolledTests.map((enrolledTest, index) => (
                <tr key={index.toString()}>
                  <td className="text-secondary">{enrolledTest.id}</td>
                  <td className="text-secondary">{enrolledTest.name}</td>
                  <td className="text-secondary">
                    {statusObjTextForCard[enrolledTest.EnrolledTest.status]}
                  </td>
                  <td className="text-secondary">
                    {enrolledTest.EnrolledTest.submitted ? (
                      <div>
                        <a
                          title="See submitted results"
                          href={`/labs/${labId}/enrolledTests/${enrolledTest.EnrolledTest.id}`}
                        >
                          Submitted Results
                        </a>

                        <span className="mx-3">|</span>
                        <a
                          title="View test results grading"
                          href={`/labs/${labId}/enrolledTests/${enrolledTest.EnrolledTest.id}/grading`}
                        >
                          Test Grading
                        </a>
                      </div>
                    ) : (
                      <p className="nav-link p-0 m-0">Results not submitted yet</p>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default connect('selectCurrentLab', 'doEnrollInTest', LabEnrollmentSubscribedTestTable);
