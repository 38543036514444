import { createRouteBundle } from 'redux-bundler';
import MainDashboard from '../components/pages/MainDashboard';
import LabEditPage from '../components/pages/LabEditPage';
import LabNewPage from '../components/pages/LabNewPage';
import LabEnrollmentPage from '../components/pages/LabEnrollmentPage';
import LabEnrolledTestResultsPage from '../components/pages/LabEnrolledTestResultsPage';
import LabParticipantsTable from '../components/views/LabParticipantsTable';
import UserList from '../components/pages/UserList';
import UserNewPage from '../components/pages/UserNewPage';
import EiaResults from '../components/test-results/EIA/EiaResults';
import ExpectedResultsPage from '../components/pages/ExpectedResultsPage';
import ResultsReportPage from '../components/pages/ResultsReportPage';
import ResultsReportPreviewPage from '../components/pages/ResultsReportPreviewPage';
import GenotypingResults from '../components/test-results/Genotyping/GenotypingResults';
import UserVerification from '../components/pages/UserAccountVerification';
import UserProfile from '../components/pages/UserProfile';
import AdminStudyDetailPage from '../components/pages/AdminStudyDetailPage';
import ListingStudies from '../components/pages/ListingStudies';
import SubmitTestsPage from '../components/pages/SubmitTestsPage';
import SubmitTestPage from '../components/pages/SubmitTestPage';

import TestSubmittedAnswersPage from '../components/pages/TestSubmittedAnswersPage';
import TestAnswersDetailPage from '../components/pages/TestAnswersDetailPage';
import PocShippingAddress from '../components/pages/PocShippingAddress';
import PanelVerification from '../components/views/PanelVerification';

// auth
import Login from '../components/pages/Login';
import PasswordReset from '../components/pages/PasswordReset';
import NewPassword from '../components/pages/NewPassword';
import LaboratoryStudyPage from '../components/pages/LaboratoryStudyPage';
import LabEnrolledGradingResultsPage from '../components/pages/LabEnrolledGradingResultsPage';

import NoWrapper from '../components/views/wrappers/NoWrapper';

import { roles } from '../helpers/constants';

export default createRouteBundle({
  '/dashboard': {
    title: 'Proficiency Testing',
    component: MainDashboard,
    mainNav: { label: 'Dashboard', icon: 'home' },
    roles: [roles.ADMIN],
  },
  '/labs': {
    title: 'Labs',
    component: LabParticipantsTable,
    mainNav: true,
    roles: [roles.ADMIN],
    icon: 'edit',
  },
  '/studies': {
    title: 'Studies',
    component: ListingStudies,
    mainNav: false,
    roles: [roles.ADMIN],
    icon: 'activity',
  },
  '/enrollment': {
    title: 'Kit Shipping Address',
    component: PocShippingAddress,
    roles: [roles.POC],
  },
  '/labs/new': { title: 'Add Lab', component: LabNewPage, roles: [roles.ADMIN] },
  '/labs/edit/:labId': {
    title: 'Edit Lab',
    component: LabEditPage,
    roles: [roles.ADMIN, roles.POC],
  },
  '/labs/:labId/enrollments/:enrollmentId': {
    title: 'Lab Enrollment details',
    component: LabEnrollmentPage,
    roles: [roles.ADMIN],
  },
  '/labs/:labId/enrollments/:enrollmentId/studyResultsReport': {
    title: 'Results Report Page',
    component: ResultsReportPage,
    roles: [roles.ADMIN, roles.POC],
  },
  '/labs/:labId/enrollments/:enrollmentId/studyResultsReportPreview': {
    title: 'Results Report Preview Page',
    component: ResultsReportPreviewPage,
    roles: [roles.ADMIN, roles.POC],
  },
  '/labs/:labId/enrolledTests/:enrolledTestId': {
    title: 'Test results',
    component: LabEnrolledTestResultsPage,
    roles: [roles.ADMIN],
  },

  '/labs/:labId/enrolledTests/:enrolledTestId/grading': {
    title: 'Test Evaluation Results',
    component: LabEnrolledGradingResultsPage,
    roles: [roles.ADMIN],
  },

  '/users': {
    title: 'Users',
    component: UserList,
    mainNav: true,
    roles: [roles.ADMIN],
    icon: 'user',
  },
  '/users/new': { title: 'Add User', component: UserNewPage, roles: [roles.ADMIN] },
  '/studies/:studyId/expected-results': {
    title: 'Expected Results',
    component: ExpectedResultsPage,
    mainNav: false,
    roles: [roles.ADMIN],
    icon: 'briefcase',
  },
  '/users/confirm-account': {
    title: 'User account confirmation',
    component: UserVerification,
    wrapper: NoWrapper,
  },
  '/users/new-password-request': {
    title: 'Password Reset',
    component: PasswordReset,
    wrapper: NoWrapper,
  },
  '/users/password-reset': {
    title: 'New Password',
    component: NewPassword,
    wrapper: NoWrapper,
  },
  '/login': {
    title: 'Login',
    component: Login,
    wrapper: NoWrapper,
  },
  '/studies/:studyId': {
    title: 'Study Detail',
    component: AdminStudyDetailPage,
    roles: [roles.ADMIN],
  },
  '/eia-recording': {
    title: 'EIA Recording Sheet',
    component: EiaResults,
    mainNav: false,
    roles: [roles.ADMIN, roles.POC],
  },
  '/genotype-recording': {
    title: 'Genotyping Recording Sheet',
    component: GenotypingResults,
    mainNav: false,
    roles: [roles.ADMIN, roles.POC],
  },
  '/labs-studies': {
    title: 'Labs & Studies',
    component: LaboratoryStudyPage,
    mainNav: true,
    roles: [roles.POC],
    icon: 'folder',
  },
  '/enrolled-studies/:enrollmentId/submit-tests': {
    title: 'Submit Tests',
    component: SubmitTestsPage,
    roles: [roles.POC],
    icon: 'folder',
  },
  '/enrolled-studies/:enrollmentId/submit-tests/:testId': {
    title: 'Submit Test',
    component: SubmitTestPage,
    roles: [roles.POC],
    icon: 'folder',
  },
  '/enrolled-studies/:enrollmentId/submitted-tests/answers': {
    title: 'Tests Submitted Answers',
    component: TestSubmittedAnswersPage,
    roles: [roles.POC],
    icon: 'folder',
  },
  '/enrolled-studies/:enrollmentId/submitted-tests/answers/:testId': {
    title: 'Tests Submitted Answers',
    component: TestAnswersDetailPage,
    roles: [roles.POC],
    icon: 'folder',
  },

  '/profile': {
    title: 'My Profile',
    component: UserProfile,
    mainNav: true,
    roles: [roles.ADMIN, roles.POC],
    icon: 'settings',
  },

  '/enrolled-studies/:enrollmentId': {
    title: 'Enrolled Studies',
    component: PanelVerification,
    roles: [roles.POC],
  },

  '*': { title: 'Page Not Found', component: MainDashboard, roles: [roles.ADMIN, roles.POC] },
});
