import { expectedTestResultsTypes } from '../../helpers/constants';

/**
 * This hooks returns the graded data given a test type and the admin data
 *
 * @param {String} testType The Test Type to get the graded data
 * @param {Object} data The data of the test to get graded
 * @param {Object} testData Current Test Data submission
 *
 * @return  {Object}             Returns the graded data given a test type
 */
const useGradedResultsData = (testType, data = {}, testData = {}) => {
  let gradedResultsData = {};

  switch (testType) {
    case expectedTestResultsTypes.GenotypingTest: {
      gradedResultsData =
        data && data.gradedResults
          ? data.gradedResults.map(({ suggestedScore: { value } }, index) => {
              return { ...testData.GenotypingResults[index], score: value };
            })
          : testData.GenotypingResults.map(results => {
              return { ...results, score: '--' };
            });
      break;
    }

    case expectedTestResultsTypes.EIATest: {
      gradedResultsData =
        data && data.gradedResults
          ? data.gradedResults.map(({ score: { value } }, index) => {
              return { ...testData.EIAResults[index], score: value };
            })
          : testData.EIAResults.map(results => {
              return { ...results, score: '--' };
            });
      break;
    }

    default:
      return {};
  }

  return [gradedResultsData];
};

export default useGradedResultsData;
