import { createSelector } from 'redux-bundler';
import { roles, testTypes, enrolledTestStatus } from '../helpers/constants';

const ERROR_TIME = 15000;
const REFRESH_TIME = 300000;
const ActionTypes = {
  FETCH_START_GRADING_RESULTS: 'FETCH_START_GRADING_RESULTS',
  FETCH_ERROR_GRADING_RESULTS: 'FETCH_ERROR_GRADING_RESULTS',
  FETCH_SUCCESS_GRADING_RESULTS: 'FETCH_SUCCESS_GRADING_RESULTS',
  CLEAR: 'CLEAR_GRADING_RESULTS',
};

export default {
  name: 'gradingResults',
  getReducer: () => {
    const initialState = {
      loading: false,
      lastError: null,
      lastFetch: null,
      data: null,
      error: null,
    };
    return (state = initialState, { type, payload }) => {
      switch (type) {
        case ActionTypes.FETCH_START_GRADING_RESULTS:
          return {
            ...state,
            loading: true,
          };
        case ActionTypes.FETCH_ERROR_GRADING_RESULTS:
          return {
            ...state,
            data: payload.data,
            loading: false,
            lastError: Date.now(),
            error: payload.error,
          };
        case ActionTypes.FETCH_SUCCESS_GRADING_RESULTS:
          return {
            ...state,
            lastFetch: Date.now(),
            error: null,
            lastError: null,
            loading: false,
            data: payload,
          };
        case ActionTypes.CLEAR:
          return initialState;
        default:
          return state;
      }
    };
  },

  // Selectors
  selectGradingResultsRawData: state => state.gradingResults,
  selectGradingResultsData: state => state.gradingResults.data,
  selectGradingResultErrorMessage: createSelector(
    'selectGradingResultsRawData',
    gradingResultsRawData =>
      gradingResultsRawData.error && gradingResultsRawData.error.message
        ? gradingResultsRawData.error.message
        : null,
  ),
  // Let's create a selector to retrieve the current lab category code.
  // if this code is RRL, let's return true, otherwise return false.
  // This will help us assign the proper Target Score depending on the lab category
  selectVerifyCurrentLabIsRegional: createSelector(
    'selectUserLabInfo',
    'selectWhoNetworkLabCategoriesData',
    (userLabInfo, whoNetworkLabCategoriesData) => {
      if (!userLabInfo || !whoNetworkLabCategoriesData) {
        return null;
      }
      if (whoNetworkLabCategoriesData[userLabInfo.WHONetworkLabCategoryId]?.code === 'RRL') {
        return true;
      }
      return false;
    },
  ),

  selectAdminCurrentGradingData: createSelector(
    'selectGradingResultsData',
    'selectRouteParams',
    (gradingResultsData, routeParams) => {
      if (
        gradingResultsData &&
        routeParams.labId &&
        gradingResultsData[routeParams.labId] &&
        routeParams.enrolledTestId &&
        gradingResultsData[routeParams.labId][routeParams.enrolledTestId]
      ) {
        return gradingResultsData[routeParams.labId][routeParams.enrolledTestId];
      }
      return null;
    },
  ),
  selectScoreCurrentAdminData: createSelector(
    'selectAdminCurrentGradingData',
    adminCurrentGradingData => {
      if (adminCurrentGradingData?.testType === testTypes.EIA) {
        return adminCurrentGradingData && adminCurrentGradingData.gradedResults
          ? adminCurrentGradingData.gradedResults.reduce((accumulator, { score, finalScore }) => {
              if (
                adminCurrentGradingData.enrolledTestStatus === enrolledTestStatus.FINALIZED ||
                adminCurrentGradingData.enrolledTestStatus === enrolledTestStatus.GRADING_PENDING ||
                adminCurrentGradingData.enrolledTestStatus === enrolledTestStatus.UNLOCKED
              ) {
                return accumulator + finalScore.value;
              }
              return accumulator + score.value;
            }, 0)
          : 0;
      }
      if (adminCurrentGradingData?.testType === testTypes.GENOTYPING) {
        return adminCurrentGradingData && adminCurrentGradingData.gradedResults
          ? adminCurrentGradingData.gradedResults.reduce(
              (accumulator, { suggestedScore, finalScore }) => {
                if (
                  adminCurrentGradingData.enrolledTestStatus === enrolledTestStatus.FINALIZED ||
                  adminCurrentGradingData.enrolledTestStatus ===
                    enrolledTestStatus.GRADING_PENDING ||
                  adminCurrentGradingData.enrolledTestStatus === enrolledTestStatus.UNLOCKED
                ) {
                  return accumulator + finalScore.value;
                }
                return accumulator + suggestedScore.value;
              },
              0,
            )
          : 0;
      }
      return 0;
    },
  ),

  selectPocCurrentGradingData: createSelector(
    'selectGradingResultsData',
    'selectFilteredTestAnswer',
    'selectUserLabInfo',
    (gradingResultsData, filteredTestAnswer, userLabInfo) => {
      if (
        filteredTestAnswer &&
        userLabInfo &&
        gradingResultsData &&
        gradingResultsData[userLabInfo.id][filteredTestAnswer.id]
      ) {
        return gradingResultsData[userLabInfo.id][filteredTestAnswer.id];
      }
      return null;
    },
  ),
  selectPocGradingScore: createSelector('selectPocCurrentGradingData', pocCurrentGradingData => {
    return pocCurrentGradingData && pocCurrentGradingData.gradedResults
      ? pocCurrentGradingData.gradedResults.reduce(
          (accumulator, { suggestedScore, finalScore }) => {
            if (pocCurrentGradingData.testType === testTypes.GENOTYPING) {
              if (
                pocCurrentGradingData.enrolledTestStatus === enrolledTestStatus.FINALIZED ||
                pocCurrentGradingData.enrolledTestStatus === enrolledTestStatus.GRADING_PENDING ||
                pocCurrentGradingData.enrolledTestStatus === enrolledTestStatus.UNLOCKED
              ) {
                return accumulator + finalScore.value;
              }
              return accumulator + suggestedScore.value;
            }

            if (pocCurrentGradingData.testType === testTypes.EIA) {
              if (
                pocCurrentGradingData.enrolledTestStatus === enrolledTestStatus.FINALIZED ||
                pocCurrentGradingData.enrolledTestStatus === enrolledTestStatus.GRADING_PENDING ||
                pocCurrentGradingData.enrolledTestStatus === enrolledTestStatus.UNLOCKED
              ) {
                return accumulator + finalScore;
              }
              return accumulator + suggestedScore;
            }

            return 0;
          },
          0,
        )
      : 0;
  }),

  // Action Creator
  doFetchLabParticipantGradeResults: (labId, enrolledTestId) => ({
    dispatch,
    apiFetch,
    getState,
  }) => {
    dispatch({ type: ActionTypes.FETCH_START_GRADING_RESULTS });
    const existingGradingData = getState().gradingResults.data || {};
    apiFetch({
      method: 'GET',
      endpoint: `laboratories/${labId}/enrolledTests/${enrolledTestId}/grading`,
    })
      .then(response => {
        // We build the response based in the LabId and enrolledTestId
        // That way would handle caching data
        const payload = {
          ...existingGradingData,
          [labId]: {
            ...existingGradingData[labId],
            [enrolledTestId]: response,
          },
        };
        dispatch({
          type: ActionTypes.FETCH_SUCCESS_GRADING_RESULTS,
          payload,
        });
      })
      .catch(error => {
        const payloadOnError = {
          ...existingGradingData,
          [labId]: {
            ...existingGradingData[labId],
            [enrolledTestId]: {},
          },
        };
        dispatch({
          type: ActionTypes.FETCH_ERROR_GRADING_RESULTS,
          payload: {
            data: payloadOnError,
            error,
          },
        });
        throw error;
      });
  },
  doClearGradingData: () => ({ dispatch }) => {
    dispatch({ type: ActionTypes.CLEAR });
  },

  /**
   * This reactor depends on the expected result and the enrolledTests bundle
   * in order to dispatch the `doCreateGradingData`
   *
   */
  reactShouldCreateGradingData: createSelector(
    'selectGradingResultsRawData',
    'selectCurrentLab',
    'selectLabCurrentSubscribedTestsResult',
    'selectCurrentExpectedTestResultsForGrading',
    'selectAppTime',
    'selectUserRole',
    'selectRouteInfo',
    (
      gradingResultsRawData,
      currentLab,
      labCurrentSubscribedTestsResult,
      currentExpectedTestResultsForGrading,
      appTime,
      userRole,
      routeInfo,
    ) => {
      const routeToMatch = '/labs/:labId/enrolledTests/:enrolledTestId/grading';
      if (
        gradingResultsRawData.loading ||
        !currentLab ||
        !labCurrentSubscribedTestsResult ||
        !currentExpectedTestResultsForGrading ||
        userRole !== roles.ADMIN ||
        !routeInfo.pattern.includes(routeToMatch)
      ) {
        return null;
      }

      let shouldFetch = false;
      const labId = currentLab.id;
      const enrolledTestId = labCurrentSubscribedTestsResult.id;

      if (
        (!gradingResultsRawData.data && !gradingResultsRawData.lastError) ||
        (gradingResultsRawData.data && !gradingResultsRawData.data[labId]) ||
        (gradingResultsRawData.data[labId] && !gradingResultsRawData.data[labId][enrolledTestId])
      ) {
        shouldFetch = true;
      } else if (gradingResultsRawData.lastError) {
        const timePassed = appTime - gradingResultsRawData.lastError;
        if (timePassed > ERROR_TIME) {
          shouldFetch = true;
        }
      } else if (gradingResultsRawData.lastFetch) {
        const timePassed = appTime - gradingResultsRawData.lastFetch;
        if (timePassed > REFRESH_TIME) {
          shouldFetch = true;
        }
      }

      if (shouldFetch) {
        return {
          actionCreator: 'doFetchLabParticipantGradeResults',
          args: [labId, enrolledTestId],
        };
      }
    },
  ),

  reactShouldFetchPocGradingData: createSelector(
    'selectGradingResultsRawData',
    'selectUserRole',
    'selectFilteredTestAnswer',
    'selectUserLabInfo',
    'selectAppTime',
    (gradingResultsRawData, userRole, filteredTestAnswer, userLabInfo, appTime) => {
      if (
        gradingResultsRawData.loading ||
        userRole !== roles.POC ||
        !userLabInfo ||
        !filteredTestAnswer ||
        !filteredTestAnswer.id
      ) {
        return null;
      }

      let shouldFetch = false;
      const { id: labId } = userLabInfo;
      const { id: EnrolledTestId } = filteredTestAnswer;

      if (
        (!gradingResultsRawData.data && !gradingResultsRawData.lastError) ||
        (gradingResultsRawData.data && !gradingResultsRawData.data[labId]) ||
        (gradingResultsRawData.data[labId] && !gradingResultsRawData.data[labId][EnrolledTestId])
      ) {
        shouldFetch = true;
      } else if (gradingResultsRawData.lastError) {
        const timePassed = appTime - gradingResultsRawData.lastError;
        if (timePassed > ERROR_TIME) {
          shouldFetch = true;
        }
      } else if (gradingResultsRawData.lastFetch) {
        const timePassed = appTime - gradingResultsRawData.lastFetch;
        if (timePassed > REFRESH_TIME) {
          shouldFetch = true;
        }
      }

      if (shouldFetch) {
        return {
          actionCreator: 'doFetchLabParticipantGradeResults',
          args: [labId, EnrolledTestId],
        };
      }
    },
  ),
};
