import React from 'react';
import format from 'date-fns/format';
import { enrolledTestStatus, statusObjTextForCard } from '../../helpers/constants';

const TestToSubmitCard = ({
  test: {
    id,
    EnrolledTest: { EnrollmentId, testDate, status },
    name,
  },
  study,
}) => {
  const URL =
    status === enrolledTestStatus.INITIAL_STATE || status === enrolledTestStatus.UNLOCKED
      ? `/enrolled-studies/${EnrollmentId}/submit-tests/${id}`
      : `/enrolled-studies/${EnrollmentId}/submitted-tests/answers/${id}`;
  const submitResults =
    status === enrolledTestStatus.GRADING_PENDING || status === enrolledTestStatus.FINALIZED;

  const renderCheckStatus = () => {
    if (status === enrolledTestStatus.FINALIZED) {
      return <i className="fe fe-check-circle text-success" />;
    }
    if (status === enrolledTestStatus.UNLOCKED) {
      return <i className="fe fe-check-circle text-danger" />;
    }
    if (status === enrolledTestStatus.GRADING_PENDING) {
      return <i className="fe fe-check-circle text-primary" />;
    }
    return <i className="fe fe-check-circle text-warning" />;
  };

  const renderLinkIcon = () => {
    if (submitResults) {
      return (
        <a href={URL} title="View Answers" className="btn btn-block btn-outline-primary">
          <i className="fe fe-eye" /> View Answers
        </a>
      );
    }
    return (
      <a href={URL} title="Submit Results" className="btn btn-block btn-outline-primary">
        <i className="fe fe-rotate-cw" /> Submit Results
      </a>
    );
  };

  return (
    <div className="col-12 col-lg-4">
      <div className="card">
        <div className="card-body">
          <h6 className="text-uppercase text-center text-muted my-4">{`${study.name}, ${study.year}`}</h6>
          <div className="row no-gutters align-items-center justify-content-center">
            <div className="col-auto">
              <div className="display-4 mb-3">{name}</div>
            </div>
          </div>

          <div className="mb-3">
            <ul className="list-group list-group-flush">
              <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                <small>Status</small>
                {renderCheckStatus()}
              </li>
              <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                <small>Completion Date</small>
                <small>{testDate ? format(new Date(testDate), 'PP') : 'No Completed Yet'}</small>
              </li>
              <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                <small>Current Status</small>
                {statusObjTextForCard && statusObjTextForCard[status] && (
                  <small>{statusObjTextForCard[status]}</small>
                )}
              </li>
            </ul>
          </div>

          <p className="text-center pt-2">
            {status === enrolledTestStatus.UNLOCKED ? (
              <a href={URL} title="Retake Test" className="btn btn-block btn-outline-primary">
                <i className="fe fe-rotate-ccw" /> Retake Test
              </a>
            ) : (
              renderLinkIcon()
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TestToSubmitCard;
