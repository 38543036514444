import React from 'react';

const GenotypeAmplificationErrorString = ({ errors }) => {
  return errors.amplificationRounds &&
    typeof errors.amplificationRounds === 'string' &&
    errors.amplificationRounds ? (
    <div className="col-auto" style={{ display: 'block' }}>
      <span className="badge badge-pill badge-danger text-uppercase">
        {errors.amplificationRounds}
      </span>
    </div>
  ) : null;
};

export default GenotypeAmplificationErrorString;
