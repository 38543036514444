import React from 'react';
import { renderGenotypeResultsValue, strCapitalize } from '../../utils';

/**
 *
 * @param {Object} Props This is the object prop for this component
 */
const RowValueForReportedTest = ({ item, prop }) => {
  if (prop === 'gGenotypeVP7') {
    return <td>{renderGenotypeResultsValue(item, prop)}</td>;
  }
  if (prop === 'pGenotypeVP4') {
    return <td>{renderGenotypeResultsValue(item, prop)}</td>;
  }
  if (prop === 'finalGenotype') {
    return <td>{renderGenotypeResultsValue(item, prop)}</td>;
  }
  if (prop === 'testInterpretation') {
    return <td>{strCapitalize(true, item[prop])}</td>;
  }

  return <td>{item[prop]}</td>;
};

export default RowValueForReportedTest;
