import React from 'react';

const LabSubscribedTestGradingAmplificationRounds = ({
  title,
  amplificationRounds,
  showMethodColumn = false,
}) => {
  const columns = ["Kit's Brand Name", "Vendor's name", 'Batch/Lot No.', 'Expiry Date'];
  return (
    <div className="card text-left">
      <div className="card-header">
        <div className="row align-items-center">
          <div className="col">
            <h4 className="card-header-title header-pretitle">{title}</h4>
          </div>
        </div>
      </div>
      <div className="table-responsive mb-0">
        <table className="table table-sm table-nowrap card-table">
          <thead className="text-center">
            <tr>
              {showMethodColumn && <th>METHOD</th>}
              {columns &&
                columns.map((headerColumn, index) => (
                  <th key={index.toString()}>{headerColumn}</th>
                ))}
            </tr>
          </thead>
          <tbody className="text-center">
            {amplificationRounds &&
              amplificationRounds.map(round => (
                <tr key={round.id}>
                  {showMethodColumn && <td>{`${round.numberOfRound} round`}</td>}
                  <td>{round.AmplificationKitName}</td>
                  <td>{round.vendorName}</td>
                  <td>{round.serialNumber}</td>
                  <td>{new Date(round.expiryDate).toLocaleDateString()}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LabSubscribedTestGradingAmplificationRounds;
