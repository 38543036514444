import React from 'react';
import { formatedLongDate, uuidv4 } from '../../utils';
import PreviousEnrolledStudiesCard from '../lib/PreviousEnrolledStudiesCard';

const chevronWidth = { width: '5%' };

const PreviousEnrolledStudies = ({ data }) => (
  <div className="container">
    <div className="row text-center d-flex justify-content-center">
      <h5 className="text-muted header-pretitle">
        {data.length > 0 ? 'Previous Studies' : 'No Previous Studies'}
      </h5>
    </div>

    <div
      id="previousEnrolledStudies"
      className="carousel slide"
      data-interval="false"
      data-ride="carousel"
    >
      <div className="carousel-inner">
        {data &&
          data.map((values, index) => (
            <div
              key={uuidv4(2, 3)}
              className={index === 0 ? 'carousel-item active' : 'carousel-item'}
            >
              <div className="row justify-content-around">
                {values.map(cards => (
                  <PreviousEnrolledStudiesCard
                    key={cards.id}
                    virusName={cards.name}
                    btnCardCaption="View Results"
                    btnLinkOne={`/labs/${cards.Enrollment.LabParticipantId}/enrollments/${cards.Enrollment.id}/studyResultsReport`}
                    StudyYear={cards.year}
                    completedOnDate={formatedLongDate(cards.endDate)}
                  />
                ))}
              </div>
            </div>
          ))}
      </div>
      {data.length > 1 ? (
        <React.Fragment>
          <a
            style={chevronWidth}
            className="carousel-control-prev display-4"
            href="#previousEnrolledStudies"
            role="button"
            data-slide="prev"
          >
            <i className="fe fe-chevron-left text-secondary" />
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="sr-only">Previous</span>
          </a>
          <a
            style={chevronWidth}
            className="carousel-control-next display-4"
            href="#previousEnrolledStudies"
            role="button"
            data-slide="next"
          >
            <i className="fe fe-chevron-right text-secondary" />
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="sr-only">Next</span>
          </a>
        </React.Fragment>
      ) : null}
    </div>
  </div>
);

export default PreviousEnrolledStudies;
