import * as Yup from 'yup';

const pocUpdatePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .matches(/.*[0-9]+.*$/, { message: 'The Password should have at least a number' })
    .required('Password is required')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
  confirmPassword: Yup.string()
    .matches(/.*[0-9]+.*$/, { message: 'The Password should have at least a number' })
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password'), null], 'Password do not match'),
});

export default pocUpdatePasswordSchema;
