/* eslint-disable prefer-spread */

import * as Yup from 'yup';
import { otherOptionsForSelect, testInterpretation } from '../../helpers/constants';

const sampleIdYupValidation = Yup.string()
  .required('Sample ID Number is Required')
  .matches(/^[0-9]{6,6}$/, {
    message: 'Sample ID must have exactly 6 numeric characters',
    excludeEmptyString: true,
  });

const EIAResultsSchema = Yup.object().shape({
  sampleIDNumber: sampleIdYupValidation,
  antigenOD: Yup.number()
    .typeError('Must be a number')
    .required('Antigen OD is Required'),
  testInterpretation: Yup.string().required(' Please Select a Value'),
});

const EiaSchema = Yup.object().shape({
  investigator: Yup.string().required('Investigator name is required'),
  testDate: Yup.date().required('Test date is required'),
  AmplificationKitId: Yup.string().required("Kit's Brand name is required"),
  otherAmplificationKitBrand: Yup.string().when('AmplificationKitId', {
    is: val => +val === otherOptionsForSelect.id,
    then: Yup.string().required('Please Enter Other Kit Brand'),
    otherwise: Yup.string(),
  }),
  vendorName: Yup.string().required("Vendor's name is required"),
  serialNumber: Yup.string('Lot Number should be a String').required('Lot number is required'),
  expiryDate: Yup.date().required('Expiration Date is Required'),
  eiaResults: Yup.array()
    .of(
      Yup.object().shape({
        sampleIDNumber: sampleIdYupValidation,
        antigenOD: Yup.number()
          .typeError('Must be a number')
          .required('Antigen OD is Required'),
        testInterpretation: Yup.string().required(' Please Select a Value'),
      }),
    )
    .required('EIA Results are Required')
    .min(10)
    .max(10),
  antigenODPositiveControl: Yup.number().required('Positive Control for Antigen O.D is required'),
  antigenODNegativeControl: Yup.number().required('Negative Control for Antigen O.D is required'),
  positiveControlTestInterpretation: Yup.string().required(' Please Select a Value'),
  negativeControlTestInterpretation: Yup.string().required(' Please Select a Value'),
  kitCutOffFormula: Yup.string().required('Kit Cut Off Formula is Required'),
  kitCutOff: Yup.number()
    .typeError('Must be a number')
    .required('Kit Cut Off Value is Required'),
});

const initEIAValues = {
  investigator: '',
  testDate: '',
  AmplificationKitId: '',
  otherAmplificationKitBrand: '',
  vendorName: '',
  serialNumber: '',
  expiryDate: '',
  eiaResults: Array.apply(null, { length: 10 }).map(() => {
    return {
      sampleIDNumber: '',
      antigenOD: '',
      testInterpretation: '',
    };
  }),
  antigenODPositiveControl: '',
  antigenODNegativeControl: '',
  positiveControlTestInterpretation: '',
  negativeControlTestInterpretation: '',
  kitCutOffFormula: '',
  kitCutOff: '',
};

const GenotypingAmplificationSchema = Yup.object().shape({
  AmplificationKitId: Yup.string(),
  otherAmplificationKitBrand: Yup.string().required("Kit's Brand name is required"),
  vendorName: Yup.string().required("Vendor's name is required"),
  serialNumber: Yup.string('Lot Number should be a String').required('Lot number is required'),
  expiryDate: Yup.date().required('Expiration Date is Required'),
  gGenotypeVP7: Yup.string(),
  pGenotypeVP4: Yup.string(),
});

const GenotypingAmplificationInit = {
  AmplificationKitId: '',
  otherAmplificationKitBrand: '',
  vendorName: '',
  serialNumber: '',
  expiryDate: '',
  gGenotypeVP7: '',
  pGenotypeVP4: '',
};

const GenotypingResultsSchema = Yup.object().shape({
  sampleIDNumber: sampleIdYupValidation,
  gGenotypeVP7: Yup.string().required('G-Genotyping (VP7) is required'),
  gGenotypeVP7Value: Yup.string().when('gGenotypeVP7', {
    is: testInterpretation.POSITIVE,
    then: Yup.string().required('G-Genotyping (VP7) value is required'),
    otherwise: Yup.string(),
  }),
  pGenotypeVP4: Yup.string().required('P-Genotyping (VP4) is required'),
  pGenotypeVP4Value: Yup.string().when('pGenotypeVP4', {
    is: testInterpretation.POSITIVE,
    then: Yup.string().required('P-Genotyping (VP4) value is required'),
    otherwise: Yup.string(),
  }),
  finalGenotype: Yup.string().required('Final genotype is required'),
  finalGenotypeValue: Yup.string().when('finalGenotype', {
    is: testInterpretation.POSITIVE,
    then: Yup.string().required('Final genotype value is required'),
    otherwise: Yup.string(),
  }),
});

const GenotypingSchema = Yup.object().shape({
  investigator: Yup.string().required('Investigator name is required'),
  testDate: Yup.date().required('Test date is required'),
  amplificationRounds: Yup.array()
    .of(GenotypingAmplificationSchema)
    .required('At least one round required')
    .min(1),
  genotypingResults: Yup.array()
    .of(GenotypingResultsSchema)
    .required('Genotyping results are required')
    .min(10)
    .max(10),
  gGenotypeVP7PositiveControl: Yup.string().required('Positive control for G-Genotype is required'),
  pGenotypeVP4PositiveControl: Yup.string().required('Positive control for P-Genotype is required'),
  finalGenotypePositiveControl: Yup.string().required(
    'Positive control for Final Genotype is required',
  ),
  gGenotypeVP7NegativeControl: Yup.string().required('Negative control for G-Genotype is required'),
  pGenotypeVP4NegativeControl: Yup.string().required('Negative control for P-Genotype is required'),
  finalGenotypeNegativeControl: Yup.string().required(
    'Negative control for Final Genotype is required',
  ),
});

const initGenotypingValues = {
  investigator: '',
  testDate: '',
  amplificationRounds: [],
  genotypingResults: Array.apply(null, { length: 10 }).map(() => {
    return {
      sampleIDNumber: '',
      gGenotypeVP7: '',
      gGenotypeVP7Value: '',
      pGenotypeVP4: '',
      pGenotypeVP4Value: '',
      finalGenotype: '',
      finalGenotypeValue: '',
    };
  }),
  gGenotypeVP7PositiveControl: '',
  pGenotypeVP4PositiveControl: '',
  finalGenotypePositiveControl: '',
  gGenotypeVP7NegativeControl: '',
  pGenotypeVP4NegativeControl: '',
  finalGenotypeNegativeControl: '',
};

export {
  EIAResultsSchema,
  EiaSchema,
  initEIAValues,
  GenotypingSchema,
  GenotypingAmplificationSchema,
  GenotypingAmplificationInit,
  initGenotypingValues,
};
