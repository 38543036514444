import React from 'react';

function inputError(touched, errors, name) {
  return (
    touched[name] &&
    errors[name] && (
      <div className="invalid-feedback col-12" style={{ display: 'block' }}>
        {errors[name]}
      </div>
    )
  );
}

export default inputError;
