/* eslint-disable no-console */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-return-assign */
/* eslint-disable no-const-assign */
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';
import { Formik, Form } from 'formik';
import omit from 'lodash/omit';

/** Schemas */
import {
  GenotypeScoringSchema,
  EIAScoringSchema,
  getInitialValues,
} from '../form-schemas/scoringSchema';

/** Hooks */
import useResubmissionNote from '../hooks/useResubmissionNote';
import useCheckbox from '../hooks/useCheckbox';
import useModal from '../hooks/useModal';
/** Libs */
import Modal from '../lib/Modal';
import ActionsForGradingTest from '../lib/ActionsForGradingTest';
import Alert from '../lib/Alert';
import RedirectionNav from '../lib/RedirectionNav';
/** Lib */
import ConfirmResubmissionTest from '../lib/ConfirmResubmissionTest';
import LabSubscribedTestResultsGradingTable from '../views/LabSubscribedTestResultsGradingTable';
import LabSubscribedTestResultsGradingAdminNotes from '../views/LabSubscribedTestResultsGradingAdminNotes';
import LabSubscribedTestGradingAmplificationRounds from '../views/LabSubscribedTestGradingAmplificationRounds';
import LabSubscribedTestResultsJustificationNotes from '../views/LabSubscribedTestResultsJustificationNotes';
/** Helpers */
import { getTargetScoreForEIA, strCapitalize } from '../../utils';
import {
  testTypes,
  testResultStatus,
  enrolledTestStatus,
  expectedTestResultsTypes,
} from '../../helpers/constants';

/**
 * @typedef {React.Component}
 * @param {Object}  currentLab                       Selector for the current lab participant information
 * @param {Boolean} currentLabIsRegional
 * @param {Object}  labCurrentSubscribedTestsResult  Selector for the current subscribed test results
 * @param {Object}  labSubscribedTestInvestigator    Selector for the POC that submitted results
 * @param {string}  gradingResultErrorMessage
 * @param {string}  labCurrentSubscribedTestResultErrorMessage
 * @param {Object}  currentGradingData
 * @param {Object}  currentExpectedTestResultsForGrading
 * @param {Object}  adminCurrentGradingData
 */

/**
 * Let's set the columns for the table tests
 */

const EIAResultsColumns = [
  'SAMPLE ID',
  'EIA EXPECTED RESULT',
  'EIA REPORTED RESULT',
  'SUGGESTED SCORE',
  'FINAL SCORE',
  'OVERRIDING COMMENTS',
  'NOTES',
];

const GenotypingResultsColumns = [
  'SAMPLE ID',
  'EIA EXPECTED RESULT',
  'GENOTYPE EXPECTED RESULT',
  'GENOTYPE REPORTED RESULT',
  'CORRECT',
  'PENALTY',
  'SUGGESTED SCORE',
  'FINAL SCORE',
  'OVERRIDING COMMENTS',
  'MORE INFO',
];

const sumData = ({ gradedResults }) => {
  const result = gradedResults
    .map(item => item.finalScore.value)
    .reduce((acc, item) => acc + item, 0);
  return result;
};

const getScoringSchema = testTypeVal => {
  if (testTypeVal === testTypes.GENOTYPING) {
    return GenotypeScoringSchema;
  }
  if (testTypeVal === testTypes.EIA) {
    return EIAScoringSchema;
  }
  return null;
};

const getResultsColumnHeaders = testTypeVal => {
  if (testTypeVal === testTypes.GENOTYPING) {
    return GenotypingResultsColumns;
  }
  if (testTypeVal === testTypes.EIA) {
    return EIAResultsColumns;
  }
  return null;
};

const getTestType = testTypeVal => {
  if (testTypeVal === testTypes.GENOTYPING) {
    return expectedTestResultsTypes.GenotypingTest;
  }
  if (testTypeVal === testTypes.EIA) {
    return expectedTestResultsTypes.EIATest;
  }
  return null;
};

const getGradingFormState = (testTypeVal, labTestResult) => {
  if (testTypeVal === testTypes.GENOTYPING && labTestResult.GenotypingTest) {
    return true;
  }
  if (testTypeVal === testTypes.EIA && labTestResult.EIATest) {
    return true;
  }
  return false;
};

const LabEnrolledGradingResultsPage = ({
  currentLab,
  currentLabIsRegional,
  labCurrentSubscribedTestsResult,
  labSubscribedTestInvestigator,
  gradingResultErrorMessage,
  labCurrentSubscribedTestResultErrorMessage,
  currentExpectedTestResultsForGrading,
  doReOpenOrFinalizeLabSubscribedTest,
  adminCurrentGradingData,
  scoreCurrentAdminData,
}) => {
  const [note, setNote] = useResubmissionNote('');
  const [checkboxValue, handleCheckbox] = useCheckbox(false);
  const [data, setData] = useState({});
  const [gradedResults, setGradedResults] = useState([]);
  const [adminGradingNotes, setAdminGradingNotes] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const {
    showing: showingHModal,
    toggleVisibility: toggleVisibilityHModal,
    title: titleHModal,
    setModalTitle: setModalTitleHModal,
  } = useModal();

  useEffect(() => {
    setModalTitleHModal('Add Resubmission Notes');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @param {*} data The required data to set unlock or finalize the EIA test
   */
  const openOrFinalizeTest = newData => {
    toggleVisibilityHModal();
    setData(newData);
  };

  const onResetConf = () => {
    setNote('');
    setAdminGradingNotes('');
    setGradedResults([]);
    handleCheckbox(false);
    toggleVisibilityHModal();
  };

  const toggleModalAndOpenOrFinalizeTest = async () => {
    const { labId, enrollmentId, testId, status } = data;

    try {
      await doReOpenOrFinalizeLabSubscribedTest(
        labId,
        enrollmentId,
        testId,
        status,
        note,
        gradedResults,
        adminCurrentGradingData?.testType,
        adminCurrentGradingData?.enrolledTestId,
        adminGradingNotes,
      );
      onResetConf();
    } catch (err) {
      console.log('Error', err);
    }
  };

  const getCurrentStudyId = useCallback(() => {
    return labCurrentSubscribedTestsResult ? labCurrentSubscribedTestsResult.study.id : null;
  }, [labCurrentSubscribedTestsResult]);

  // inform if the test has passed or not
  const testStatus =
    getTargetScoreForEIA(currentLabIsRegional) <= scoreCurrentAdminData
      ? testResultStatus.PASSED
      : testResultStatus.FAILED;

  const getDataForActionsForGradingTest = () => {
    const dataForActions = {
      labId: currentLab?.id,
      enrollmentId: labCurrentSubscribedTestsResult?.EnrollmentId,
      testId: labCurrentSubscribedTestsResult?.testId,
      status: labCurrentSubscribedTestsResult?.status,
      testStatus,
    };

    if (adminCurrentGradingData?.testType === testTypes.GENOTYPING) {
      return { ...dataForActions, gradingData: adminCurrentGradingData };
    }
    return dataForActions;
  };

  const getActionsForTestsGradingAndComponent = () => {
    return {
      data: { ...getDataForActionsForGradingTest() },
      component(errors) {
        return (
          <ActionsForGradingTest
            currentStudyId={getCurrentStudyId()}
            data={this.data}
            callback={openOrFinalizeTest}
            errors={errors}
          />
        );
      },
    };
  };

  const handleSubmit = (values, { setSubmitting }) => {
    let cleanedValues = [];
    if (labCurrentSubscribedTestsResult.GenotypingTest) {
      cleanedValues = values.gradedResults.map(val =>
        omit(val, [
          'GenotypingTestId',
          'expectedResult',
          'finalGenotype',
          'finalGenotypeValue',
          'gGenotypeVP7',
          'gGenotypeVP7Value',
          'pGenotypeVP4',
          'pGenotypeVP4Value',
          'sampleIDNumber',
          'error',
        ]),
      );
    } else if (labCurrentSubscribedTestsResult.EIATest) {
      cleanedValues = values.gradedResults.map(val =>
        omit(val, ['sampleIDNumber', 'expectedResult', 'submittedResult']),
      );
    }
    setGradedResults(cleanedValues);
    setSubmitting(false);
    setAdminGradingNotes(values.adminGradingNotes);
  };

  function renderAmplificationRoundsTitle() {
    return labCurrentSubscribedTestsResult?.EIATest
      ? 'EIA Kit Information'
      : 'Genotype Kits Information';
  }

  const testType =
    labCurrentSubscribedTestsResult?.type && testTypes[labCurrentSubscribedTestsResult.type];

  const redirectionNav = {
    navItems: [
      { route: '/labs', text: 'Labs' },
      {
        route: `/labs/${currentLab?.id}/enrollments/${labCurrentSubscribedTestsResult?.EnrollmentId}`,
        text: 'Lab Enrollment Details',
      },
    ],

    lastNavItemText: 'Grading',
  };

  const scoringSchema = getScoringSchema(adminCurrentGradingData?.testType);

  const resultsColumnHeaders = getResultsColumnHeaders(adminCurrentGradingData?.testType);

  const testTypeValue = getTestType(adminCurrentGradingData?.testType);

  const shouldDisplayGradingForm = getGradingFormState(
    adminCurrentGradingData?.testType,
    labCurrentSubscribedTestsResult,
  );

  return (
    <>
      {labCurrentSubscribedTestsResult && (
        <RedirectionNav
          navItems={redirectionNav.navItems}
          lastNavItemText={redirectionNav.lastNavItemText}
        />
      )}

      <div className="row justify-content-center">
        {/* Alert to show if expected result has not been submitted */}
        {!currentExpectedTestResultsForGrading &&
          currentExpectedTestResultsForGrading !== false &&
          labCurrentSubscribedTestsResult && (
            <div className="card-body text-center">
              <h3 className="m-0 header-pretitle text-info">
                Expected results for this test has not yet been submitted
              </h3>

              <a
                title="Add Expected Results"
                href={`/studies/${getCurrentStudyId()}/expected-results`}
                className="nav-link header-pretitle text-primary"
              >
                Add Expected Results
              </a>
            </div>
          )}
        {/* Alert to show if Lab who network category could not be determinate */}
        {currentLabIsRegional === null && (
          <div className="col-12">
            <div className="alert alert-warning alert-dismissible" role="alert">
              <p>
                <strong>The WHO network category from this lab could not be retrieved.</strong>
              </p>
              <p className="mb-0">Target Score has been set to its default value.</p>
            </div>
          </div>
        )}

        {/* Messaging to let admin know if lab has not submitted result yet */}
        {labCurrentSubscribedTestResultErrorMessage !== null ? (
          <>
            <div className="card-body">
              <h4 className="small header-pretitle text-primary">
                {labCurrentSubscribedTestResultErrorMessage}.
                <a href="/dashboard" className="alert-link mx-2 text-primary">
                  Return to Main Dashboard
                </a>
              </h4>
            </div>
          </>
        ) : (
          <div className="col-12 col-lg-12 col-xl-12 text-center">
            <div className="card">
              <div className="card-header">
                <h4 className="card-header-title text-left header-pretitle">Details</h4>
              </div>
              <div className="table-responsive">
                <table className="table table-sm table-nowrap card-table">
                  <thead>
                    <tr>
                      <th>Laboratory</th>
                      <th>Submitted By</th>
                      <th>Test Type</th>
                      <th>Investigator</th>
                      <th>Test Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span>{`${currentLab?.laboratoryName}`}</span>
                      </td>

                      <td>
                        <span>
                          {labCurrentSubscribedTestsResult &&
                            labSubscribedTestInvestigator &&
                            `${labSubscribedTestInvestigator.firstName} ${labSubscribedTestInvestigator.lastName}`}
                        </span>
                      </td>
                      <td>
                        <span>{`${strCapitalize(true, testType || '')} Test`}</span>
                      </td>
                      <td>
                        <span>{`${labCurrentSubscribedTestsResult?.investigator}`}</span>
                      </td>
                      <td>
                        <span>
                          {labCurrentSubscribedTestsResult &&
                            new Date(labCurrentSubscribedTestsResult.testDate).toDateString()}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {labCurrentSubscribedTestsResult &&
              labCurrentSubscribedTestsResult.amplificationRounds && (
                <LabSubscribedTestGradingAmplificationRounds
                  title={renderAmplificationRoundsTitle()}
                  amplificationRounds={labCurrentSubscribedTestsResult.amplificationRounds}
                  showMethodColumn={!!labCurrentSubscribedTestsResult.GenotypingTest}
                />
              )}

            {labCurrentSubscribedTestsResult && (
              <LabSubscribedTestResultsJustificationNotes
                justificationNotes={labCurrentSubscribedTestsResult.justificationNotes}
              ></LabSubscribedTestResultsJustificationNotes>
            )}

            {getActionsForTestsGradingAndComponent().data.status ===
              enrolledTestStatus.UNLOCKED && (
              <Alert
                text="This Test has been unlocked to this lab for resubmission!"
                type="secondary"
              />
            )}
            {getActionsForTestsGradingAndComponent().data.status ===
              enrolledTestStatus.FINALIZED && (
              <Alert text="This Test has been finalized for this lab" type="light" />
            )}
            {adminCurrentGradingData && labCurrentSubscribedTestsResult && (
              <>
                {shouldDisplayGradingForm && (
                  <Formik
                    initialValues={{
                      ...getInitialValues(adminCurrentGradingData),
                      adminGradingNotes: labCurrentSubscribedTestsResult.gradingNotes,
                    }}
                    validationSchema={scoringSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ values, errors, handleChange }) => {
                      const scoreSum = sumData(values);
                      const targetScore = getTargetScoreForEIA(currentLabIsRegional);
                      const tempTestStatus =
                        scoreSum >= targetScore ? testResultStatus.PASSED : testResultStatus.FAILED;
                      return (
                        <>
                          <GradingCards
                            targetScore={targetScore}
                            scoreCurrentAdminData={scoreSum}
                            currentExpectedTestResultsForGrading={
                              currentExpectedTestResultsForGrading
                            }
                            testStatus={tempTestStatus}
                          />
                          <Form>
                            <div>
                              <LabSubscribedTestResultsGradingTable
                                resultsColumnsHeader={resultsColumnHeaders}
                                gradingResultsData={adminCurrentGradingData.gradedResults}
                                testStatus={testStatus}
                                testType={testTypeValue}
                                currentStudyId={getCurrentStudyId()}
                                actionsForGrading={getActionsForTestsGradingAndComponent()}
                                gradingResultErrorMessage={gradingResultErrorMessage}
                                handleChange={handleChange}
                                gradedResults={values.gradedResults}
                                status={labCurrentSubscribedTestsResult.status}
                                errors={errors}
                              />
                              <LabSubscribedTestResultsGradingAdminNotes
                                statusIsFINALIZED={
                                  labCurrentSubscribedTestsResult.status ===
                                  enrolledTestStatus.FINALIZED
                                }
                                adminGradingNotes={labCurrentSubscribedTestsResult.gradingNotes}
                              />
                            </div>
                          </Form>
                        </>
                      );
                    }}
                  </Formik>
                )}
              </>
            )}
          </div>
        )}
      </div>

      <Modal.Centered showing={showingHModal} hide={onResetConf} title={titleHModal}>
        <ConfirmResubmissionTest
          note={note}
          checkboxValue={checkboxValue}
          handleCheckbox={handleCheckbox}
          setNote={setNote}
          onOkCallback={toggleModalAndOpenOrFinalizeTest}
          onCancelCallback={onResetConf}
          submissionType={data.status}
          testType={testType}
        />
      </Modal.Centered>
    </>
  );
};

const GradingCards = ({
  targetScore,
  scoreCurrentAdminData,
  currentExpectedTestResultsForGrading,
  testStatus,
}) => {
  return (
    <div className="row text-center">
      <div className="col-12 col-lg-6 col-xl-4">
        <div className="card">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col">
                <h5 className="card-title text-uppercase text-muted mb-2">Target Score</h5>
                <span className="h2 mb-0">{targetScore}</span>
              </div>
              <div className="col-auto">
                <span className="h2 fe fe-crosshair text-muted mb-0" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-6 col-xl-4">
        <div className="card">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col">
                <h5 className="card-title text-uppercase text-muted mb-2">Current Score</h5>
                <span className="h2 mb-0">{scoreCurrentAdminData}</span>
              </div>
              <div className="col-auto">
                <span className="h2 fe fe-activity text-muted mb-0" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-12 col-xl-4">
        <div className="card">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col">
                <h5 className="card-title text-uppercase text-muted mb-2">Test Status</h5>
                {!currentExpectedTestResultsForGrading ? (
                  <span className="text-muted">--</span>
                ) : (
                  <span
                    className={
                      testStatus === testResultStatus.PASSED
                        ? 'h2 mb-0 text-success'
                        : 'h2 mb-0 text-danger'
                    }
                  >
                    {testStatus}
                  </span>
                )}
              </div>
              <div className="col-auto">
                <span
                  className={
                    testStatus === testResultStatus.PASSED
                      ? 'h2 fe fe-check-circle text-muted mb-0'
                      : 'h2 fe fe-slash text-muted mb-0'
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  'selectCurrentLab',
  'selectCurrentLabIsRegional',
  'selectLabCurrentSubscribedTestsResult',
  'selectLabSubscribedTestInvestigator',
  'selectGradingResultErrorMessage',
  'selectLabCurrentSubscribedTestResultErrorMessage',
  'selectCurrentExpectedTestResultsForGrading',
  'doReOpenOrFinalizeLabSubscribedTest',
  'selectAdminCurrentGradingData',
  'selectScoreCurrentAdminData',
  LabEnrolledGradingResultsPage,
);
