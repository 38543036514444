import React from 'react';

/**
 *
 * @param {Array} headers The Headers of the table
 * @param {Number} total The sum of the records score value
 * @param {Array} data The data records for the Table
 */
const ResultReportTestTable = ({ headers, total = 0, data = [] }) => {
  function renderHeader() {
    return headers.map(th => <th key={th.key}>{th.text}</th>);
  }

  return (
    <div className="table-responsive">
      <table className="table table-sm card-table table-hover table-layout-fixed">
        <thead className="text-center">
          <tr>{renderHeader()}</tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index.toString()}>
              {Object.keys(item).map((record, i) => (
                <td key={`${item[record]}-${i.toString()}`} className="text-center">
                  {item[record]}
                </td>
              ))}
            </tr>
          ))}
          <tr>
            <td colSpan="2" />
            <td className="text-center">
              <strong>Total</strong>
            </td>
            <td className="text-center"> {total}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ResultReportTestTable;
