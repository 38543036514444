import React from 'react';

/**
 *
 * @param {Array} notes Array of Notes for the Report
 */
const ResultReportGradingNote = ({ gradingNotes = '' }) => {
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-header-title header-pretitle text-left">Admin Grading Notes</h3>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-12 ">
            <p className="text-muted mb-0" style={{ whiteSpace: 'pre-line' }}>
              {gradingNotes}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultReportGradingNote;
