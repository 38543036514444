/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect, useRef } from 'react';
import { getIn } from 'formik';
import { genotypeTestInterpretations } from '../../../helpers/constants';

const RadioButtonGroup = ({
  form: { setFieldValue, touched, errors, values },
  interpretation,
  genotypeValue,
  title,
}) => {
  const invalid = getIn(touched, interpretation) && getIn(errors, interpretation);
  const invalidValue = getIn(touched, genotypeValue) && getIn(errors, genotypeValue);
  const isChecked = getIn(values, interpretation);
  const currentValue = getIn(values, genotypeValue);

  const [disabled, setDisabled] = useState(true);
  const [value, setValue] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    if (isChecked === genotypeTestInterpretations.POSITIVE) {
      inputRef.current.focus();
    }
    setValue(currentValue);
    setDisabled(isChecked !== genotypeTestInterpretations.POSITIVE);
  }, [isChecked, currentValue]);

  const renderTitleError = (headerTitle, fieldError, fieldValueError) => {
    if (fieldError) {
      return <span className="column-title text-danger">{`${headerTitle} is Required`}</span>;
    }
    if (fieldValueError) {
      return <span className="column-title text-danger">{`${headerTitle} Value is Required`}</span>;
    }
    return <span className="column-title text-muted">{headerTitle}</span>;
  };

  return (
    <li
      className={`${
        invalid || invalidValue
          ? 'list-group-item flex-fill border-danger'
          : 'list-group-item flex-fill'
      }`}
    >
      {renderTitleError(title, invalid, invalidValue)}
      <div className="form-check mb-3">
        <input
          id={`first-${interpretation}`}
          type="radio"
          name={interpretation}
          value={genotypeTestInterpretations.POSITIVE}
          checked={isChecked === genotypeTestInterpretations.POSITIVE}
          onChange={() => {
            setFieldValue(`${interpretation}`, genotypeTestInterpretations.POSITIVE);
            setDisabled(false);
          }}
          className="form-check-input"
        />
        <label className="form-check-label mb-1" htmlFor={`first-${interpretation}`}>
          Positive
        </label>
        <input
          className={
            invalidValue
              ? 'form-control text-secondary bg-white border-danger'
              : 'form-control text-secondary bg-white'
          }
          ref={inputRef}
          type="text"
          value={value}
          onChange={evt => {
            setValue(evt.target.value);
            setFieldValue(`${genotypeValue}`, evt.target.value);
          }}
          placeholder="Specify Genotype"
          disabled={disabled}
        />
      </div>
      <div className="form-check mb-3">
        <input
          id={`second-${interpretation}`}
          type="radio"
          name={interpretation}
          value={genotypeTestInterpretations.POSITIVE_NON_TYPEABLE}
          checked={isChecked === genotypeTestInterpretations.POSITIVE_NON_TYPEABLE}
          onChange={() => {
            setFieldValue(`${interpretation}`, genotypeTestInterpretations.POSITIVE_NON_TYPEABLE);
            setValue('');
            setDisabled(true);
          }}
          className="form-check-input"
        />
        <label className="form-check-label" htmlFor={`second-${interpretation}`}>
          Positive Non-Typeable
        </label>
      </div>
      <div className="form-check">
        <input
          id={`third-${interpretation}`}
          type="radio"
          name={interpretation}
          value={genotypeTestInterpretations.NEGATIVE}
          checked={isChecked === genotypeTestInterpretations.NEGATIVE}
          onChange={() => {
            setFieldValue(`${interpretation}`, genotypeTestInterpretations.NEGATIVE);
            setValue('');
            setDisabled(true);
          }}
          className="form-check-input"
        />
        <label className="form-check-label" htmlFor={`third-${interpretation}`}>
          Negative
        </label>
      </div>
    </li>
  );
};

export default RadioButtonGroup;
