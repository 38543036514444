/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import EIATestResultsGradingBodyTable from './EIATestResultsGradingBodyTable';
import GenotypeTestResultsGradingBodyTable from './GenotypeTestResultsGradingBodyTable';
import { testInstances } from '../../helpers/constants';

const POINTS_PER_RESPONSE_EIA = 10;
const POINTS_PER_RESPONSE_GENOTYPE = 10;

/**
 *
 * @typedef {React.Component}
 * @param {Array}  resultsColumnsHeaders  List of header columns
 * @param {Array}  gradingResultsData     List of results
 * @param {number} currentStudyId Id of the current study
 * @param {string} testStatus The status of the test FAILED|PASSED
 * @param {string} testType which test is being displayed
 * @param {string} gradingResultErrorMessage
 * @param {string} status The status of the test GRADING_PENDING|FINALIZED|INITIAL_STATE
 * @param {function} handleChange this function is from formik, to handle the onchange in the array field
 * @param {Array} gradedResults The array of graded results comming from formik
 */
const LabSubscribedTestResultsGradingTable = ({
  resultsColumnsHeader,
  gradingResultsData,
  gradingResultErrorMessage,
  status,
  testType,
  actionsForGrading,
  handleChange,
  gradedResults,
  errors,
}) => {
  return (
    <div className="card">
      <div className="card-header">
        <div className="row flex-column align-items-start flex-sm-row align-items-sm-center justify-content-sm-between">
          <div className="col-auto mb-3 mb-sm-0">
            <h4 className="card-header-title header-pretitle text-left">Test Results</h4>
          </div>
          {actionsForGrading.component(errors)}
        </div>
      </div>
      {gradingResultErrorMessage !== null ? (
        <div className="card-body">
          <h4 className="small header-pretitle text-primary">
            {gradingResultErrorMessage}. To view the grades, please add the missing information.
          </h4>
        </div>
      ) : (
        <div className="table-responsive mb-0">
          <table className="table table-sm table-nowrap card-table table-hover">
            <thead className="text-center">
              <tr>
                {resultsColumnsHeader &&
                  resultsColumnsHeader.map((headerColumn, index) => (
                    <th key={index.toString()}>{headerColumn}</th>
                  ))}
              </tr>
            </thead>

            {testType === testInstances.EIATest && (
              <EIATestResultsGradingBodyTable
                POINTS_PER_RESPONSE={POINTS_PER_RESPONSE_EIA}
                data={gradedResults}
                handleChange={handleChange}
                status={status}
                errors={errors}
              />
            )}

            {testType === testInstances.GenotypingTest && (
              <GenotypeTestResultsGradingBodyTable
                POINTS_PER_RESPONSE={POINTS_PER_RESPONSE_GENOTYPE}
                data={gradedResults}
                handleChange={handleChange}
                status={status}
                errors={errors}
              />
            )}
          </table>
        </div>
      )}
    </div>
  );
};

export default LabSubscribedTestResultsGradingTable;
